<template>
    <v-hover v-slot:default="{ hover }">
        <tr>
            <td>{{ formatDay(day) }}</td>
            <td>
                <div class="pl-5">
                    <v-row>
                        <v-btn
                                :class="canInsertAfter ? '' : 'mt-n5'"
                                color="primary"
                                x-small
                                icon
                                bottom
                                right
                                v-show="hover && canInsertBefore"
                                @click="insertBefore"
                        >
                            <v-icon>mdi-plus</v-icon>
                        </v-btn>
                    </v-row>
                    <v-spacer/>
                    <v-row>
                        <v-btn
                                :class="canInsertBefore ? '' : 'mb-n12'"
                                color="primary"
                                icon
                                dark
                                x-small
                                bottom
                                right
                                v-show="hover && canInsertAfter"
                                @click="insertAfter"
                        >
                            <v-icon>mdi-plus</v-icon>
                        </v-btn>
                    </v-row>
                </div>
            </td>
            <td>
                <v-row align="center">
                    <v-btn icon
                           small
                           color="red"
                           v-show="canDelete"
                           @click="$emit('deleteDay', day)">
                        <v-icon>
                            mdi-delete
                        </v-icon>
                    </v-btn>
                </v-row>
            </td>
        </tr>
    </v-hover>
</template>

<script>
    import moment from 'moment';
    const prettyDateFormat = 'dddd, MMM DD YYYY';
    const dateFormat = 'YYYY-MM-DD';
    export default {
        name: "RateRow",
        props: ['day', 'canInsertBefore', 'canInsertAfter', 'canDelete'],
        data: () => ({

        }),
        methods: {
            formatDay(day){
                return moment(day).format(prettyDateFormat);
            },
            insertBefore() {
                let day = moment(this.day).add(-1, 'd').format(dateFormat);
                this.$emit('insertBefore', day)
            },
            insertAfter() {
                let day = moment(this.day).add(1, 'd').format(dateFormat);
                this.$emit('insertAfter', day)
            },
        }
    }
</script>

<style scoped>

</style>