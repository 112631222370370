  <template>
    <v-dialog max-width="750"
              persistent
              :retain-focus="false"
              v-model="shown">
      <v-card class="py-2" :key="refresh">
        <v-text-field @focus="$event.target.select()" class="mx-6" v-if="addendumProp" v-model="addendumProp.title"></v-text-field>
        <ckeditor :config="editorConfig" :editor="editor" v-if="addendumProp" v-model="addendumProp.body"></ckeditor>

        <!-- Save & Cancel Buttons -->
        <v-card-actions class="px-6 mt-2">
          <v-btn class="px-4" color="red" dark @click="toggleShown" large>Cancel</v-btn>
          <v-spacer/>
          <v-btn class="px-4" color="primary" dark @click="saveBtnClicked" large>Save</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </template>

  <script>
  import ClassicEditor from "ckeditor5-build-classic-plus";// npm install --save ckeditor5-build-classic-plus

  export default {
    name: "TrustEditorDialog",// dialog for editing and saving changes to addendum title and/or body
    props: ['addendum', 'username'],
    data: () => ({
      editor: ClassicEditor,
      editorConfig: {
        toolbar: {
          items: [ 'heading', '|', 'bold', 'italic', 'link', 'underline', 'bulletedList', 'numberedList', '|', 'fontColor','highlight', 'alignment', 'outdent', 'indent', 'blockQuote', 'insertTable', '|', 'undo', 'redo' ],
        },
        shouldNotGroupWhenFull: true
      },
      refresh: false,
      refreshWordings: false,
      shown: false
    }),

    computed: {
      addendumProp: {
        get() {
          if (!this.addendum) return;
          return JSON.parse(JSON.stringify(this.addendum));
        },
        set(val) {
          return val
        }
      },
    },

    mounted() {
      this.addendumProp = JSON.parse(JSON.stringify(this.addendum));
    },

    methods: {
      saveBtnClicked: async function () {// patch
        const a = this.addendumProp;
        const data = { uuid: a.uuid, title: a.title, body: a.body };
        try {
          await this.$axios.patch('/admin/trust/addenda', data);
          this.$parent.getAddendumWithDefaultTerms();
          this.$parent.getAddendum(this.username);
        } catch (e) {
          this.$emit('showErrorDialog', {
            errorMessage: "There was a problem saving the addendum changes.",
            forDevelopers: e
          });
        }
        this.toggleShown();
      },

      toggleShown: function() {
        this.refresh = !this.refresh;
        this.$nextTick(() =>  {
          this.shown = !this.shown;
        });
      },

    }
  }
  </script>

  <style>
  /*ckeditor*/
  .ck-content .table {
    margin: 5px 0 5px 0;
  }
  .ck-content .table table {
    width: auto !important;
    padding-top: 20px !important;
  }
  .ck.ck-editor__editable_inline {
    padding: 1vh 1.5vh 1vh 1.5vh !important;
  }
  .ck-editor__editable {
    overflow-y: scroll;
    min-height: 50vh;
    max-height: 70vh;
  }
  /*css below is to solve double space problem when hitting enter and match ul & ol margin-top to p */
  .ck-editor__editable > ul, ol {margin-top: 0 !important} p {margin-top: 0 !important; margin-bottom: 0 !important}
  /*.ck-editor__editable > ul, ol {margin-top: 0 !important} p {margin-top: 0 !important; margin-bottom: 0 !important}*/
  </style>