<template>
    <v-row justify="center">
        <v-dialog
                style="margin: 12px !important"
                v-model="shown"
                max-width="500"
        >
            <v-card>

                <v-row>
                    <!-- Title -->
                    <v-col class="pa-2 pt-1" cols="10">
                      <div id="title" class="pl-3 pt-0">
                          <v-card-title v-if="!errorTitle" class="headline" style="word-break: unset">
                            Sorry, there was a problem</v-card-title>
                          <v-card-title v-else class="headline" style="word-break: unset">{{ errorTitle }}</v-card-title>
                      </div>
                    </v-col>
                    <v-col class="text-right" cols="2">
                      <v-btn
                            color="primary"
                            @click="shown = false"
                            icon
                            class="px-3 ml-n6 mr-3"
                            style="margin-top: 6px"
                      >
                          <v-icon>
                              mdi-close
                          </v-icon>
                      </v-btn>
                    </v-col>
                </v-row>

                <!-- Error Message -->
                <v-card-text>
                    <span class="body-1">
                        {{ errorMessage }}
                    </span>
                </v-card-text>

                <!-- "For Developers" message -->
                <div v-if="forDevelopers">
                    <v-expansion-panels>
                        <v-expansion-panel>
                          <v-expansion-panel-header>For Developers</v-expansion-panel-header>
                            <v-expansion-panel-content>{{ forDevelopers }}</v-expansion-panel-content>
                        </v-expansion-panel>
                    </v-expansion-panels>
                </div>


            </v-card>
        </v-dialog>
    </v-row>
</template>

<script>
    export default {
        name: "ErrorDialog",
        data: () => ({
            shown: false,
            errorTitle: "",
            errorMessage: "",
            forDevelopers: ""
        }),
        methods: {
            toggleShown: function() {
                this.shown = !this.shown
            },
            showError: function(options){
                let that = this; // Binds this into scope.
                let keys = Object.keys(options);

                keys.forEach(function (key) {
                    // Set's the value of the key in the data object equal to the value of the corresponding key in the
                    // options object.
                    that._data[key] = options[key]
                });

                this.toggleShown()
            }
        }
    }
</script>

<style scoped>

</style>