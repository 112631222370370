<template>
  <v-dialog
      persistent
      v-model="shown"
  >
        <v-card>
          <v-toolbar class="pt-1 pb-4">
            <v-spacer></v-spacer>
            <v-tooltip bottom>
              <template v-slot:activator="{ on }">
                <v-icon v-on="on" @click="viewTotals()" class="mr-2">mdi-calculator</v-icon>
              </template>
              <span>View/print totals</span>
            </v-tooltip>
            <v-spacer></v-spacer>
            <span :class="{'caption': $vuetify.breakpoint.smAndDown}" class="mt-1">
              <span v-if="!['xs'].includes($vuetify.breakpoint.name)">Settled Transactions:
              </span> {{ startTimestamp.slice(0,10) }} - {{ endTimestamp.slice(0,10) }}
            </span>
            <v-spacer></v-spacer>
            <v-text-field v-model="search" append-icon="mdi-magnify" label="Search"
                          clearable single-line hide-details dense class="mt-n1 mr-2"
            ></v-text-field>
            <v-btn @click="toggleShown()" icon class="mt-n1"><v-icon>mdi-close</v-icon>
            </v-btn>
          </v-toolbar>

          <v-data-table
            fixed-header
            :headers="transactionsTableHeaders"
            height="calc(100vh - 220px)"
            hide-default-footer
            :items-per-page="-1"
            :items="transactionsBetween"
            no-data-text="Sorry, there is no data available for the selected time period."
            :search="search"
          >
            <template v-slot:item.action="{ item }">
              <v-tooltip bottom>
                <template v-slot:activator="{ on }">
                  <v-icon v-on="on" class="mr-2" @click="viewDetail(item)">mdi-open-in-new</v-icon>
                </template>
                View/Print Transaction Details in Popup Window
              </v-tooltip>
            </template>
            <template v-slot:item.amount="{ item }">
              {{ item.amount }}
              {{ getSuffix(item.asset_type_id) }}
            </template>
          </v-data-table>
        </v-card>

      <ErrorDialog ref="errorDialog"/>
  </v-dialog>
</template>

<script>
// @ is an alias to /src
import ErrorDialog from "@/components/ErrorDialog";

export default {
  name: "MemberTransactionsDetail",
  components: { ErrorDialog },
  props: ['endTimestamp', 'startTimestamp', 'transactionsBetween'],
  data() {
    return {
      search: '',
      shown: false,
      transactionsTableHeaders: [
        { width:   '0px', text: '',              value: 'action',        align: 'start' },
        { width: '300px', text: 'Username',      value: 'username',      align: 'start' },
        { width: '200px', text: 'Type',          value: 'type',          align: 'start' },
        { width: '100px', text: 'Amount',        value: 'amount',        align: 'start' },
        { width: '200px', text: 'Posted',        value: 'posted',        align: 'start' },
        { width: '200px', text: 'Settled',       value: 'settled_date',  align: 'start' },
        { width: '300px', text: 'Counterparties',value: 'counterparties',align: 'start' },
        { width: '300px', text: 'Description',   value: 'description',   align: 'start' },
      ],
    }
  },

  methods: {

    getSuffix(specie) {
      if (specie === 3373) return 'GD';
      if (specie === 3374) return 'SD';
      if (specie === 7000) return 'GB';
      if (specie === 5062) return 'PD';
    },

    viewTotals() {
      let baseUrl = window.location.origin;
      window.open(baseUrl +  '/memberTotals/' +  this.startTimestamp +  '/' +  this.endTimestamp , '_blank',
          'resizeable=yes, width=' + (800)
          + ', left=' + (500)
          + ', top=' + (22)
          + ', height=' + (800));
    },

    viewDetail(transaction) {
      let baseUrl = window.location.origin;
      window.open(baseUrl +  '/memberTransactionDetail/' +  transaction.journal_num, '_blank',
          'resizeable=yes, width=' + (800)
          + ', left=' + (500)
          + ', top=' + (22)
          + ', height=' + (800));
    },

    toggleShown: function () {
      this.shown = !this.shown;
    },

  }
}
</script>

<style scoped>

</style>