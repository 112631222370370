<template>
    <div class="mb-10">
        <div v-if="allowedComponents.includes('Rates')">

            <RatesHeader class="mr-5 ml-5"
                         @prev="$refs.calendar.prev()"
                         @next="$refs.calendar.next()"/>

            <RatesCalendar ref="calendar"
                           class="ml-5 mr-5 elevation-2"/>

            <v-row class="mt-5 mr-5 ml-5 mb-5">
                <v-btn
                        color="primary"
                        dark
                        @click="$refs.configRatesDialog.toggleShown()"
                >
                    Configure
                </v-btn>
                <v-spacer/>
                <v-btn @click="$refs.setRatesDialog.toggleShown()"
                       color="primary"
                       dark>
                    Set Rates
                </v-btn>
            </v-row>
            <SetRatesDialog @changesSaved="$refs.calendar.refresh()" ref="setRatesDialog"/>
            <ConfigureRatesDialog @changesSaved="$refs.calendar.refresh()" @showErrorDialog="showError" ref="configRatesDialog"/>
            <ErrorDialog ref="errorDialog"/>
        </div>
        <div v-else class="pl-12 pr-12 pt-12 mt-12 headline text-center">Sorry, this page is not available.</div>
    </div>
</template>

<script>
    import RatesCalendar from "../components/rates/RatesCalendar";
    import RatesHeader from "../components/rates/RatesHeader";
    import SetRatesDialog from "../components/rates/SetRatesDialog";
    import ErrorDialog from "../components/ErrorDialog";
    import ConfigureRatesDialog from "../components/rates/ConfigureRatesDialog";
    export default {
        name: "Rates",
        components: {ErrorDialog, SetRatesDialog, RatesHeader, RatesCalendar, ConfigureRatesDialog},
        data: () => ({
            allowedComponents: JSON.parse(localStorage.getItem('allowedComponents') || ''),
        }),
        methods: {
            showError: function (options) {
                this.$refs.errorDialog.showError(options)
            }
        },
        mounted() {
            localStorage.title = 'Rates';
        },
    }
</script>

<style scoped>

</style>