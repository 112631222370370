<template>
  <div class="px-5 py-2">
    <div v-if="allowedComponents.includes('Lease')">
      <v-container :class="{'pa-0':$vuetify.breakpoint.xs}" :key="refresh">
        <v-row>
          <v-col cols="8">
            <v-card height="30" class="mb-4">
              <download-excel
                  :fetch = "fetchData"
                  type="csv"
                  :escapeCsv= "false"
                  :name = "filename">
                <v-toolbar style="cursor: pointer" dark color="silver lighten-2">
                  <v-toolbar-title class="flex text-center">Download Lease Report</v-toolbar-title>
                </v-toolbar>
              </download-excel>
            </v-card>
          </v-col>
          <v-col cols="4">
            <v-card height="30" class="mb-4">
              <download-excel
                  :fetch = "fetchGoldbackIncData"
                  type="csv"
                  :name = "filename">
                <v-toolbar style="cursor: pointer" dark color="blue lighten-2">
                  <v-toolbar-title class="flex text-center">Goldback, Inc Report</v-toolbar-title>
                </v-toolbar>
              </download-excel>
            </v-card>
          </v-col>
        </v-row>

        <v-row>
          <v-col
              class="pb-0"
              cols="12"
              :key="n"
              v-for="(n,i) in 3"
          >
            <v-row :dense="$vuetify.breakpoint.xs" class="pb-0">
              <v-col cols="6">
                <v-card height="150" class="text-center" @click="detail('leased', leases.leasedTotals, i)" cols="6">
                  <v-toolbar dark :color="getColor('leased', i)">
                    <v-toolbar-title :class="{'caption':$vuetify.breakpoint.xs}">Leased {{ getAsset('leased', i) }} Total:</v-toolbar-title>
                  </v-toolbar><br>
                  <h1 >{{ getTotal('leased', i) }}</h1>
                </v-card>
              </v-col>
              <v-col cols="6">
                <v-card height="150" class="text-center" @click="detail('pending', leases.pendingTotals, i)" cols="6">
                  <v-toolbar dark :color="getColor('pending', i)">
                    <v-toolbar-title :class="{'caption':$vuetify.breakpoint.xs}">Pending {{ getAsset('pending', i) }} Total:</v-toolbar-title>
                  </v-toolbar><br>
                  <h1 >{{ getTotal('pending', i) }}</h1>
                </v-card>
              </v-col>
            </v-row>
          </v-col>
        </v-row>

        <v-row>
          <v-col>
            <v-card>
              <v-row justify="center">
                <v-col
                    :key="n"
                    v-for="(n,i) in 3"
                >
                  <v-row no-gutters>
                    <v-list dense style="padding-left: .5rem">
                      <v-list-tile class="dense">
                        <v-list-tile-content>
                          <v-list-tile-title class="ml-2 small font-weight-medium">
                            {{ getMonthYear(i - 1) }}
                          </v-list-tile-title>
                        </v-list-tile-content>
                        <v-data-table
                            :headers="getTableHeaders(i)"
                            :items="liabilityReport[i]"
                            hide-default-footer
                            :loading="!pageLoaded"
                            align="start"
                        >
                          <template v-slot:item.asset_type_id="{ item }">
                            <span :style="{color: assetColorDark(item.asset_type_id)}">{{ assetIDToName(item.asset_type_id) }}</span>
                          </template>
                          <template v-slot:item.future_payment_liability="{ item }">
                            <span :style="{color: assetColorDark(item.asset_type_id)}">{{ penniesToWhole(item.future_payment_liability) }}</span>
                          </template>
                          <template v-slot:item.future_return_liability="{ item }">
                            <span :style="{color: assetColorDark(item.asset_type_id)}">{{ penniesToWhole(item.future_return_liability) }}</span>
                          </template>
                          <template v-slot:item.payment="{ item }">
                            <span :style="{color: assetColorDark(item.asset_type_id)}">{{ penniesToWhole(item.payment) }}</span>
                          </template>
                          <template v-slot:item.returned="{ item }">
                            <span :style="{color: assetColorDark(item.asset_type_id)}">{{ penniesToWhole(item.returned) }}</span>
                          </template>
                          <template v-slot:item.total_liability="{ item }">
                            <span :style="{color: assetColorDark(item.asset_type_id)}">
                              <strong>{{ penniesToWhole(Number(item.future_payment_liability) + Number(item.future_return_liability)) }}</strong>
                            </span>
                          </template>
                          <template v-slot:item.total_settled="{ item }">
                            <span :style="{color: assetColorDark(item.asset_type_id)}">
                              <strong>{{ penniesToWhole(Number(item.payment) + Number(item.returned)) }}</strong>
                            </span>
                          </template>
                        </v-data-table>
                      </v-list-tile>
                    </v-list>
                  </v-row>
                </v-col>
              </v-row>
              <span class="ml-2">*Upcoming payments and returns are estimations.</span>
              <v-spacer></v-spacer>
              <span class="ml-2">**Lease returns and payments will not account for 'Adjustment' fixes.</span>
            </v-card>
          </v-col>
        </v-row>
      </v-container>

      <leases-detail v-bind="{ asset, assetTypeId, details, index, total, type }" ref="leasesDetail"/>
      <ErrorDialog ref="errorDialog"/>
    </div>
    <div v-else class="pl-12 pr-12 pt-12 mt-12 headline text-center">Sorry, this page is not available.</div>
  </div>
</template>

<script>
// @ is an alias to /src
import ErrorDialog from "@/components/ErrorDialog";
import LeasesDetail from "@/components/reports/LeasesDetail";
import _ from 'lodash';

export default {
  name: "Lease",
  components: {
    ErrorDialog,
    LeasesDetail
  },
  data: () => ({

    //-------- Toggle Variables --------//

    showApproveWithdrawal: false,
    showRejectWithdrawal: false,
    refresh: false,
    pageLoaded: false,
    monthsLoaded: 0,


    //-------- Lease Variables --------//

    allowedComponents: JSON.parse(localStorage.getItem('allowedComponents') || ''),
    asset: '',
    assetTypeId: null,
    details: [],
    filename: '',
    index: null,
    leaseDownload: [],
    leases: {},
    liabilityReport: [[]],
    pendingState: 'loading',
    searchPending: "",
    spec: [],
    specie: [// to set the titles and colors if there is no data
      { name: 'gold', id: 3373 },
      { name: 'silver', id: 3374 },
      { name: 'goldback', id: 7000 }
    ],
    total: null,
    type: '',
  }),

  methods: {

    getTableHeaders(index) {
      let liabilityHeaders = [];
      liabilityHeaders.push({text: 'Asset', value: 'asset_type_id', align: 'left' });
      if (index > 0) {
        liabilityHeaders.push({text: ''});
        liabilityHeaders.push({text: 'Upcoming Payments', value: 'future_payment_liability',  align: 'right' });
        liabilityHeaders.push({text: 'Upcoming Returns', value: 'future_return_liability', align: 'right' });
        liabilityHeaders.push({text: 'Total Liability', value: 'total_liability', align: 'right'})
      }
      if (index < 2) {
        liabilityHeaders.push({text: ''});
        liabilityHeaders.push({text: 'Lease Payments', value: 'payment', align: 'right' });
        liabilityHeaders.push({text: 'Lease Returns', value: 'returned', align: 'right' });
        liabilityHeaders.push({text: 'Total Settled', value: 'total_settled', align: 'right'})
      }

      return liabilityHeaders;
    },

    getMonthYear(offset) {
      let date = new Date();
      date.setMonth(new Date().getMonth() + offset);
      return date.toLocaleString('default', { month: 'long', year:'numeric' });
    },

    capitalize(str) {
      return _.capitalize(str);
    },

    dark(str) {
      let dark =  str === 'goldback' ? 'darkGoldBack' : 'dark' + _.capitalize(str);
      return dark + ' lighten-2';
    },

    detail(type, totals, index) {
      if (!totals) return;
      let match = false;
      for (let asset of totals) if (this.name(asset.asset_type_id) === this.specie[index].name) {
        match = true;
        this.asset = this.capitalize(this.name(asset.asset_type_id));
        this.assetTypeId = asset.asset_type_id;
        this.total = asset.asset_amount;
      }
      if (!match) return;
      this.details =  this.leases[type + this.asset + 'Leases']
      this.index = index;
      this.type = type;
      this.$refs.leasesDetail.toggleShown();
    },

    fetchData: async function () {
        try {
          let res = await this.$axios.get(`/admin/reports/leases/download?externalID=0`);
          this.leaseDownload = res.data;
          this.leaseDownload.asset_amount = Number(this.leaseDownload.asset_amount);
          this.filename = 'Leases Report ' + new Date().toISOString().substr(0, 10) + '.csv';
        } catch (e) {
          this.$refs.errorDialog.showError({
            errorMessage: "Failed to get Download.",
            forDevelopers: e
          });
        }
        return this.leaseDownload;
      },

    fetchGoldbackIncData: async function () {
      try {
        let res = await this.$axios.get(`/admin/reports/leases/download?externalID=4`);
        this.leaseDownload = res.data;
        this.filename = 'Goldback, Inc Leases Report ' + new Date().toISOString().substr(0, 10) + '.csv';
      } catch (e) {
        this.$refs.errorDialog.showError({
          errorMessage: "Failed to get Download.",
          forDevelopers: e
        });
      }
      return this.leaseDownload;
    },

    getAsset(type, i) {
      return _.capitalize(this.specie[i].name);
    },

    getColor(type, i) {
      let color = type === 'leased' ? this.dark(this.specie[i].name) : this.light(this.specie[i].name);
      return color;
    },

    getTotal(type, i) {//find total, if none then 0.00
      let totals = this.leases[type + 'Totals'];
      if (!totals) return '0.00';
      let total = '0.00';
      for (let asset of totals) if (this.name(asset.asset_type_id) === this.specie[i].name) total = asset.asset_amount;
      return total.replace(/\B(?=(\d{3})+(?!\d))/g, ',');// this adds commas if needed
    },

    getLeases: async function () {
      this.pendingState = 'loading';
      try {
        let res = await this.$axios.get(`/admin/reports/leases`);
        this.leases = res.data;
        this.refresh = !this.refresh;
      } catch (e) {
        this.$refs.errorDialog.showError({
          errorMessage: "Failed to get Leases.",
          forDevelopers: e
        });
      }
      this.pendingState = 'display';
    },

    getLiability: async function (index) {
      let date = new Date()
      date.setMonth(new Date().getMonth() - 1 + index);
      let input_date = date.getFullYear() + '-' + (date.getMonth() + 1) + '-01';
      try {
        let res = await this.$axios.get('/admin/reports/leases/liability?date=' + input_date);
        this.monthsLoaded++;
        if(this.monthsLoaded === 3) this.pageLoaded = true;
        this.liabilityReport[index] = res.data;
      } catch (e) {
        this.$refs.errorDialog.showError({
          errorMessage: "Failed to get Liability Report.",
          forDevelopers: e
        });
      }
    },

    light(str) {
      let light =  str === 'goldback' ? 'goldBack' : str;
      return light + ' lighten-2';
    },

    name(id) {
      return id === 3373 ? 'gold' : id === 3374 ? 'silver' : 'goldback';
    },
  },

  created: async function() {
    await this.getLeases();
    this.getLiability(0);
    this.getLiability(1);
    this.getLiability(2);
  },

  mounted() {
    localStorage.title = 'Leases';
  },
}
</script>

<style scoped>
.v-data-table::v-deep th {
  font-size: 11px !important;
}
</style>