<template>
  <v-dialog
      persistent
      v-model="shown"
  >
        <v-row class="mx-0">
          <v-col md="12" class="pa-0">
            <v-card class="elevation-4" :key="refresh">
              <v-toolbar dark :color="type === 'leased' ? $parent.dark(asset.toLowerCase()) : $parent.light(asset.toLowerCase())" elevation="2" dense>
                <v-toolbar-title class="ml-4">{{ $parent.capitalize(type) }} {{ asset }}: {{ total }} </v-toolbar-title>
                <v-spacer></v-spacer>
                <v-btn @click="toggleShown()" icon class=""><v-icon>mdi-close</v-icon>
                </v-btn>
              </v-toolbar>
              <v-row class="px-6 pt-3 pb-0 mb-n2">
                <v-text-field dense v-model="searchPending" append-icon="mdi-magnify" label="Search Leases" class="ml-4 mr-1 pt-4 pt-sm-0"></v-text-field>
                <v-btn class="ml-4 mt-1 mr-0" small
                       v-if="type === 'leased'"
                       @click="resetContractParams(); action = 'Modify'; sourceOfContract.name = null; showLeaseAction = true"
                >Modify</v-btn>
                <v-btn class="ml-4 mt-1 mr-0" small @click="resetContractParams(); action = 'Return'; showLeaseAction = true">Return</v-btn>
                <v-btn class="ml-3 mt-1 mr-2" small v-if="type === 'pending'" @click="resetContractParams(); action = 'Lease'; showLeaseAction = true">Approve</v-btn>
              </v-row>

              <v-data-table
                  class="row-pointer px-3"
                  fixed-header
                  :headers="headers"
                  height="60vh"
                  hide-default-footer
                  :items-per-page="-1"
                  :items="indexedDetails"
                  item-key="journal_num"
                  multi-sort
                  :search="searchPending"
                  show-select
                  :single-select="false"
                  :sort-desc="true"
                  :sort-by="type === 'pending' ? 'creationStamp' : 'inceptStamp'"
                  style="white-space: pre"
                  v-model="selectedLeases"
              >
                <template v-slot:item.amount="{ item }">
                  {{ item.amount.replace(/\B(?=(\d{3})+(?!\d))/g, ',') }}
                </template>
                <template v-slot:item.creationStamp="{ item }">
                  {{ item.creationDate }}
                </template>
                <template v-slot:item.inceptStamp="{ item }">
                  {{ item.inceptDate }}
                </template>
                <template v-slot:item.expiryStamp="{ item }">
                  {{ item.expiryDate }}
                </template>
                <template v-slot:item.nextPaymentStamp="{ item }">
                  {{ item.nextPaymentDate }}
                </template>
              </v-data-table>
            </v-card>
          </v-col>
        </v-row>

        <!--Lease Action Dialog-->

        <v-row justify="center">
          <v-dialog v-model="showLeaseAction" max-width="500">
            <v-card>
              <v-row>
                <!-- Title -->
                <div id="title" class="pl-3">
                  <v-card-title :class="{'body-1':$vuetify.breakpoint.xs}" class="headline">
                    <span v-if="action === 'Lease'">Approve Lease Confirmation</span>
                    <span v-else-if="action === 'Modify'">Modify Lease(s)</span>
                    <span v-else>Return Lease Confirmation</span>
                  </v-card-title>
                </div>
                <v-spacer/>
                <v-btn
                    color="primary"
                    @click="showLeaseAction = false"
                    icon
                    class="mt-2 mr-5"
                >
                  <v-icon>mdi-close</v-icon>
                </v-btn>
              </v-row>
              <v-card-text>
                <v-row>
                  <v-col cols="12" sm="12" class="d-flex justify-center">
                    <span class="body-1">Leases Selected: {{ selectedLeases.length }}</span>
                  </v-col>
                </v-row>
              </v-card-text>
              <v-card-text v-if="action === 'Lease' || action === 'Modify'">
                <v-row>
                  <v-layout :class="{'py-1':$vuetify.breakpoint.xs}" wrap>
                    <v-flex xs12>
                      <v-form ref="contractParams">
                        <v-btn color="primary"
                               :disabled="goldbackIncManagedLease"
                               v-if="action === 'Lease'"
                               @click="setGoldbackIncManaged()"
                               justify="right"
                               class="mb-4 mr-4">
                          Set Goldback, Inc Managed <v-icon v-if="goldbackIncManagedLease" class="mb-1 ml-2">mdi-check-bold</v-icon>
                        </v-btn>
                        <v-select :items="sourceOptions"
                                  item-text="name"
                                  :class="{'mobile-height': $vuetify.breakpoint.xsOnly}"
                                  v-model="sourceOfContract"
                                  single-line
                                  return-object
                                  menu-props="bottom"
                                  solo
                        >
                        </v-select>
                        <v-text-field
                            v-if="action === 'Lease' && !goldbackIncManagedLease"
                            solo
                            label="Custom Contract Length (optional)"
                            type="number"
                            min="2"
                            max="36"
                            step="1"
                            suffix="Months"
                            v-model="numMonths"
                            :rules="rules.numMonths"
                        ></v-text-field>
                        <v-select :items="commissionedParties"
                                  label="-- Commissioned Party (optional) --"
                                  item-text="name"
                                  :class="{'mobile-height': $vuetify.breakpoint.xsOnly}"
                                  v-model="commissionedParty"
                                  single-line
                                  return-object
                                  menu-props="bottom"
                                  solo
                        >
                        </v-select>
                        <v-text-field
                            solo
                            label="Custom APR Percent (optional)"
                            type="number"
                            min="0"
                            max="10"
                            step=".1"
                            suffix="%"
                            v-model="aprPercent"
                            :rules="rules.aprPercent"
                        ></v-text-field>
                        <v-text-field
                            v-if="!goldbackIncManagedLease"
                            solo
                            label="Custom External ID (optional)"
                            v-model="externalIDInput"
                            :rules="rules.externalID"
                        ></v-text-field>
                        <v-text-field
                            solo
                            label="Custom Description (optional)"
                            v-model="descriptionInput"
                            :rules="rules.description"
                        ></v-text-field>
                        <v-select :items="boolItems"
                                  label="Compound Payments? (optional)"
                                  item-text="name"
                                  :class="{'mobile-height': $vuetify.breakpoint.xsOnly}"
                                  v-model="compoundPayments"
                                  single-line
                                  return-object
                                  menu-props="bottom"
                                  solo
                        >
                        </v-select>
                        <v-menu
                            v-if="action === 'Lease'"
                            ref="leasePaymentDateMenu"
                            v-model="leasePaymentDateMenu"
                            :close-on-content-click="false"
                            :nudge-right="40"
                            transition="scale-transition"
                            offset-y
                            max-width="290px"
                            min-width="290px"
                        >
                          <template v-slot:activator="{ on }">
                            <v-text-field
                                solo
                                label="Custom First Lease Payment Date (optional)"
                                v-model="firstPayment"
                                readonly
                                v-on="on"
                            ></v-text-field>
                          </template>
                          <v-date-picker
                              v-model="firstPayment"
                              :min="tomorrow()"
                              no-title
                              @input="leasePaymentDateMenu = false"
                          ></v-date-picker>
                        </v-menu>
                        <v-menu
                            v-if="action === 'Modify'"
                            ref="endDateMenu"
                            v-model="endDateMenu"
                            :close-on-content-click="false"
                            :nudge-right="40"
                            transition="scale-transition"
                            offset-y
                            max-width="290px"
                            min-width="290px"
                        >
                          <template v-slot:activator="{ on }">
                            <v-text-field
                                solo
                                label="Custom End Month (optional)"
                                v-model="customEndDate"
                                readonly
                                v-on="on"
                            ></v-text-field>
                          </template>
                          <v-date-picker
                              v-model="customEndDate"
                              type="month"
                              :min="nextMonth()"
                              no-title
                              @input="endDateMenu = false"
                          ></v-date-picker>
                        </v-menu>
                      </v-form>
                      <v-row>
                        <v-spacer></v-spacer>
                        <v-btn text color='red' @click="resetContractParams">RESET FORM</v-btn>
                      </v-row>
                    </v-flex>
                  </v-layout>
                </v-row>
              </v-card-text>
              <v-divider></v-divider>
              <v-card-actions>
                <v-btn @click="showLeaseAction = false" text>Close</v-btn>
                <v-btn
                    @click="leaseAction(action)"
                    :color="action === 'Return' ? 'red' : ''"
                    :disabled="selectedLeases.length < 1"
                    text
                >
                <span v-if="action === 'Return'">Return Lease(s)</span>
                <span v-else-if="action === 'Modify'">Modify Lease(s)</span>
                <span v-else>Approve</span>
                </v-btn>
              </v-card-actions>
            </v-card>
          </v-dialog>
        </v-row>

      <ErrorDialog ref="errorDialog"/>
  </v-dialog>
</template>

<script>
// @ is an alias to /src
import ErrorDialog from "@/components/ErrorDialog";

export default {
  name: "LeasesDetail",
  components: {
    ErrorDialog
  },
  props: ['asset', 'assetTypeId', 'details', 'index', 'type', 'total'],
  data() {
    return {
      leasePaymentDateMenu: false,
      endDateMenu: false,
      shown: false,
      todayDate: new Date(),

      rules: {
        // --- fields for which full validation is required now because it was required in sign up ---  //
        aprPercent: [
          (v) => /^[.0-9\s]+$/i.test(v) || 'APR Percent must be numeric',
        ],

        externalID: [
          (v) => /^[0-9\s]+$/i.test(v) || 'External ID must be numeric',
          (v) => (v !== '4') || 'External ID 4 is reserved for Goldback, Inc'
        ],

        description: [
          (v) => /^[.a-zA-Z0-9\s]+$/i.test(v) || 'Description must be alphanumeric',
          (v) => (!!v && v.length <= 100) || 'Description cannot be longer than 100 characters'
        ],

        numMonths: [
          (v) => /^[.0-9\s]+$/i.test(v) || 'Contract length must be numeric',
          (v) => (v !== '1') || 'Setting a lease for \'1 month\' makes it indefinite'
        ],
      },

      //-------- Toggle Variables --------//

      refresh: false,
      showLeaseAction: false,


      //-------- Lease Variables --------//

      action: '',
      commissionedParty: {email:null},
      firstPayment: null,
      sourceOptions: {},
      aprPercent: null,
      compoundPayments: null,
      descriptionInput: null,
      externalIDInput: null,
      goldbackIncManagedLease: false,
      customEndDate: null,
      numMonths: null,
      commissionedParties: {},
      sourceOfContract: {name: 'Alpine Gold'},
      hiddenHeaders: [// mobile view doesn't use align, so only add hidden headers on larger screens
        { value: 'creationDate',text: 'hidden',sortable: true, width: '120px',align:' d-none'},// 'd-none' hides column but keeps search ability
        { value: 'inceptDate',  text: 'hidden',sortable: true, width: '120px',align:' d-none'},// 'd-none' hides column but keeps search ability
        { value: 'expiryDate',  text: 'hidden',sortable: true, width: '120px',align:' d-none'},// 'd-none' hides column but keeps search ability
        { value: 'nextPaymentStamp',  text: 'hidden',sortable: true, width: '120px',align:' d-none'},// 'd-none' hides column but keeps search ability
      ],
      leasedTableHeaders: [
        {text:'Begin',      value: 'inceptStamp', align: 'start'},
        {text:'End',        value: 'expiryStamp', align: 'start'},
        {text:'Next Payment',value: 'nextPaymentStamp', align: 'start'},
        {text:'APR',        value: 'apr',         align: 'start'},
        {text:'Commission', value: 'commission',  align: 'start'},
        {text:'Source',     value: 'source',      align: 'start'},
        {text:'Renew',      value: 'renew',       align: 'start'},
        {text:'Compound',   value: 'compound',    align: 'start'},
      ],
      pendingTableHeaders: [
        {text:'Amount',     value: 'amount',       align: 'end'},
        {text:'Lessor',     value: 'username',     align: 'start'},
        {text:'Offer',      value: 'creationStamp',align: 'start'},
      ],
      boolItems: ['true', 'false'],
      searchPending: '',
      selectedLeases: [],
      startDate: new Date().toISOString().substr(0, 10),
    }
  },

  computed: {
    headers() {
      let hdrs = this.type === 'pending' ? this.pendingTableHeaders : this.pendingTableHeaders.concat(this.leasedTableHeaders);
      return this.$vuetify.breakpoint.name === 'xs' ? hdrs : hdrs.concat(this.hiddenHeaders);
    },

    indexedDetails() {
      if (!this.details){
        return [];
      }
      return this.details.map((item) => ({
        creationStamp: item.creation_timestamp,
        inceptStamp: item.incept_timestamp,
        expiryStamp: item.expiry_timestamp,
        nextPaymentStamp: item.next_payment_timestamp,
        creationDate: item.creation_date,
        inceptDate: item.incept_date,
        expiryDate: item.expiry_date,
        nextPaymentDate: item.next_payment_date,
        commission: item.commissioned_party,
        apr: item.apr_percent,
        amount: item.asset_amount,
        source: item.source,
        username: item.username,
        journal_num: item.journal_num,
        renew: item.auto_renew === 'false' ? false : true,
        compound: item.compound
      }))
    },
  },

  methods: {
    nextMonth() {
      let date = new Date();
      date.setMonth(date.getMonth() + 1);
      return date.toJSON();
    },
    tomorrow(){
      let date = new Date();
      date.setDate(date.getDate() + 1);
      return date.toJSON();
    },

    convertDate(date){
      return Date.parse(date);
    },

    leaseAction: async function (action) {
      try {
        let journalNums = this.selectedLeases.map(item => item.journal_num);
        this.showLeaseAction = false;
        let data = {};
        if (action === 'Lease') data = {
          numMonths: this.numMonths ?? 12,
          description: this.descriptionInput ?? 'Lease',
          externalID: this.externalIDInput ?? 0,
          assetTypeID: this.assetTypeId,
          firstPayment: this.firstPayment,
          sourceOfContract: this.sourceOfContract.name,
          commissionedEmail: this.commissionedParty.email,
          aprPercent: this.aprPercent ? (this.aprPercent / 100) : null,
          compound: this.compoundPayments
        }
        if (action === 'Modify') data = {
          description: this.descriptionInput, //allow null
          externalID: this.externalIDInput, //allow null
          sourceOfContract: this.sourceOfContract.name, //allow null
          commissionedEmail: this.commissionedParty.email, //allow null
          aprPercent: this.aprPercent ? (this.aprPercent / 100) : null,
          endDate: this.customEndDate,
          compound: this.compoundPayments
        }
        data.action = action;
        data.journalNums = journalNums;
        await this.$axios.post(`/admin/reports/leases/action`, data );
        await this.$parent.getLeases();
        let leases = this.$parent.leases[this.type + this.asset + 'Leases']
        let success = action === 'Lease' ? 'approved' : (action === 'Modify' ? 'modified' : 'returned');
        alert("Lease(s) successfully " + success);
        this.selectedLeases = [];
        this.resetContractParams();
        if (!leases) {// no leases remaining to be viewed so close dialog
          this.toggleShown();
          return;
        }
        this.$parent.details = leases;
        for (let asset of this.$parent.leases[this.type + 'Totals'])  {
          if (this.$parent.name(asset.asset_type_id) === this.$parent.specie[this.index].name) this.$parent.total = asset.asset_amount;
        }
        this.refresh = !this.refresh;
      } catch (e) {
        let failure = action === 'Lease' ? 'approve' : 'return';
        this.$refs.errorDialog.showError({
          errorMessage: "Failed to " + failure + " the lease(s).",
          forDevelopers: e
        })
      }
    },

    selectTransaction: async function (item, row) {
      row.select(!row.isSelected);
    },

    setGoldbackIncManaged: function () {
      this.goldbackIncManagedLease = true
      this.numMonths = 1;
      this.externalIDInput = 4;
    },

    resetContractParams: function () {
      if (this.$refs.contractParams) this.$refs.contractParams.reset();
      this.sourceOfContract = {name:'Alpine Gold'};
      this.firstPayment = null;
      this.aprPercent = null;
      this.numMonths = null;
      this.descriptionInput = null;
      this.externalIDInput = null;
      this.goldbackIncManagedLease = false;
      this.customEndDate = null;
      this.compoundPayments = null;
      this.commissionedParty = {email:null};
    },

    toggleShown: function() {
      this.shown = !this.shown;
      this.selectedLeases = [];
      this.resetContractParams();
    },

    getSources: async function () {
        let res = await this.$axios.get(`/admin/list/sources`);
        this.sourceOptions = res.data;
    },

    getSales: async function () {
        let res = await this.$axios.get(`/admin/list/usernamesByScope?filter=SALES`);
        this.commissionedParties = res.data;
    }
  },

  mounted: async function() {
    await this.getSources();
    await this.getSales();
  }
}
</script>

<style scoped>

</style>