<template>
  <div>
    <v-card flat>
      <v-card-title>
        <v-select
            class="flex-grow-0 flex-shrink-0 mr-4"
            :loading="loading"
            :items="productGroupItems"
            v-model="selectedGroup"
            @input="onSelectedGroupChange()"

            >

        </v-select>
        <div class=" body-2">
        Show Inactive Products:
          </div>
        <v-switch class="ml-2" v-model="showInactive"/>
        <v-spacer/>
        <v-btn fab x-small color="primary" @click="newProductDialog = true">
          <v-icon>
            mdi-plus
          </v-icon>
        </v-btn>
        
      </v-card-title>
      <v-card-text>
        <v-data-table
                      :loading="loading"
                      class="elevation-2 mt-n3 mb-2 pb-0"
                      scrollable
                      loading-text="Loading... Please wait"
                      :headers="inactiveHeaders ? productTableHeadersInactive: productTableHeadersActive"
                      fixed-header
                      :sort-by="['denomination']"
                      multi-sort
                      :items="productItems"
                      :items-per-page="10">
          <template v-slot:item.active="{ item }">
            <v-lazy>
              <div v-if="item.active" class="green--text">
                Active
              </div>
              <div v-if="!item.active" class="red--text">
                Inactive
              </div>
            </v-lazy>
          </template>
          <template v-slot:item.actions="{ item }">
            <v-lazy>
              <div>
                <v-tooltip bottom style="z-index: 99" class="ml-n2 mr-n4 py-0 pl-0 pr-2">
                  <template v-slot:activator="{ on }">
                    <v-btn small icon><v-icon v-on="on" @click="onEditButtonClick(item)">
                      mdi-pencil</v-icon></v-btn>
                  </template>
                  <span>Edit Product</span>
                </v-tooltip>
              </div>
            </v-lazy>
          </template>
          </v-data-table>
      </v-card-text>
    </v-card>

    <v-dialog scrollable width="400" v-model="newProductDialog" @click:outside="clearNewDialogState()">
          <v-tabs centered>
              <v-tab :style="{fontSize: (['xs'].includes($vuetify.breakpoint.name) ? '60%' : '')}"
              >
                New Product Group
              </v-tab>
              <v-tab :style="{fontSize: (['xs'].includes($vuetify.breakpoint.name) ? '60%' : '')}"
              >
                New Product
              </v-tab>
            <v-tab-item>
              <v-card flat>
                <v-card-text>
                  <v-form v-model="validProductGroup" ref="groupForm">
                  <v-text-field
                      outlined
                      :rules="[rules.required]"
                      label="Group Name"
                      hide-details="auto"
                      v-model="newProductGroup.groupName"/>
                  <v-textarea
                      outlined
                      :rules="[rules.required]"
                      v-model="newProductGroup.description"
                      label="Description"
                      hide-details="auto"
                      class="mt-2"/>
                  </v-form>
                </v-card-text>
                <v-card-actions class="mr-2">
                  <v-spacer/>
                  <v-btn @click="clearNewDialogState(), newProductDialog = false">
                    Cancel
                  </v-btn>
                  <v-btn color="primary" v-if="validProductGroup" @click="onNewProductGroupSubmit()">
                    Add Product Group
                  </v-btn>
                  <v-btn color="primary" disabled v-if="!validProductGroup" >
                    Add Product Group
                  </v-btn>
                </v-card-actions>
              </v-card>
            </v-tab-item>
            <v-tab-item>
              <v-card flat>
                <v-card-text>
                  <v-form v-model="validProduct" ref="productForm">
                  <v-select
                      outlined
                      label="Product Group"
                      :rules="[rules.required]"
                      v-model="newProduct.groupName"
                      :items="productGroupItems"
                      hide-details="auto"/>
                  <v-text-field
                      outlined
                      label="Product Name"
                      :rules="[rules.required]"
                      class="mt-2"
                      v-model="newProduct.productName"
                      hide-details="auto"/>
                  <v-select
                      outlined
                      clearable
                      label="Asset Type (optional)"
                      class="mt-2"
                      :items="assetItems"
                      v-model="selectedAsset"
                      hide-details="auto"/>
                  <v-text-field
                      outlined
                      label="Denomination (optional)"
                      :rules="[rules.isNumber]"
                      v-model="denomination"
                      class="mt-2"
                      hide-details="auto"/>
                  <v-textarea
                      outlined
                      label="Description"
                      :rules="[rules.required]"
                      v-model="newProduct.description"
                      hide-details="auto"
                      class="mt-2"/>
                  </v-form>
                </v-card-text>
                <v-card-actions class="mr-2">
                  <v-spacer/>
                  <v-btn @click="clearNewDialogState(), newProductDialog = false">
                    Cancel
                  </v-btn>
                  <v-btn color="primary" v-if="validProduct" @click="onNewProductSubmit()">
                    Add Product
                  </v-btn>
                  <v-btn color="primary" v-if="!validProduct" disabled>
                    Add Product
                  </v-btn>
                </v-card-actions>
              </v-card>
            </v-tab-item>
          </v-tabs>
    </v-dialog>

    <v-dialog v-model="editProductDialog" width="400">
      <v-card flat>
        <v-card-title>
          Edit Product
        </v-card-title>
        <v-card-text>

          <v-select
              outlined
              label="Status"
              class="mt-2"
              :items="['Active', 'Inactive']"
              @change="onProductEdited()"
              v-model="editStatus"
              hide-details="auto"
          ></v-select>
          <v-text-field
              outlined
              label="Product Name (optional)"
              v-model="newName"
              @keyup="onProductEdited()"
              class="mt-2"
              hide-details="auto"/>
          <v-textarea
              outlined
              label="Description (optional)"
              v-model="newDescription"
              @keyup="onProductEdited()"
              class="mt-2"
              hide-details="auto"/>
        </v-card-text>
        <v-card-actions class="mr-2">
          <v-spacer/>
          <v-btn @click="editProductDialog = false">
            Cancel
          </v-btn>
          <v-btn color="primary" v-if="isProductEdited" @click="onEditProductSubmit()">
            Update Product
          </v-btn>
          <v-btn color="primary" v-if="!isProductEdited" disabled>
            Update Product
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-snackbar color="success" class="mt-n2 ml-n2" v-model="newProductGroupSuccess" top>
      New product group: {{tempName}} successfully added.
      <v-btn color="white" text @click="newProductGroupSuccess = false">Close</v-btn>
    </v-snackbar>
    <v-snackbar color="success" class="mt-n2 ml-n2" v-model="newProductSuccess" top>
      New product: {{tempName}} successfully added.
      <v-btn color="white" text @click="newProductSuccess = false">Close</v-btn>
    </v-snackbar>
    <v-snackbar color="success" class="mt-n2 ml-n2" v-model="editProductSuccess" top>
      Product updated successfully.
      <v-btn color="white" text @click="editProductSuccess = false">Close</v-btn>
    </v-snackbar>
    <v-snackbar color="error" class="mt-n2 ml-n2" v-model="newProductError" top>
      Error: {{newProductErrorMessage}}
      <v-btn color="white" text @click="newProductError = false">Close</v-btn>
    </v-snackbar>
    <v-snackbar color="error" class="mt-n2 ml-n2" v-model="newProductGroupError" top>
      Error: {{newProductGroupErrorMessage}}
      <v-btn color="white" text @click="newProductGroupError = false">Close</v-btn>
    </v-snackbar>
  </div>
</template>

<script>

export default {
  name: 'Products',
  data() {
    return {
      newProductDialog: false,
      productGroups: [],
      newProductGroup: {},
      loading: false,
      newProduct: {},
      rules: {
        required: value => !!value || 'Required.',
        isNumber: value => {
          if(!value) {
            return true
          }
          const pattern =  /^[1-9]\d*$/
          return pattern.test(value) || 'Positive Whole Numbers Only.'
        },
      },
      inactiveHeaders: false,
      productTableHeadersActive: [
        {value: 'name', text: 'Product Name'},
        {value: 'asset_type_id', text: 'Asset Type'},
        {value: 'denomination', text: 'Denomination'},
        {value: 'description', text: 'Description'},
        {value: 'actions', text: '', width: '75px'}
      ],
      productTableHeadersInactive: [
        {value: 'name', text: 'Product Name'},
        {value: 'active', text: 'Status'},
        {value: 'asset_type_id', text: 'Asset Type'},
        {value: 'denomination', text: 'Denomination'},
        {value: 'description', text: 'Description'},
        {value: 'actions', text: '', width: '75px'}
      ],
      newProductGroupSuccess: false,
      newProductGroupResponse: {},
      newProductSuccess: false,
      editProductSuccess: false,
      newProductError: false,
      newProductErrorMessage: '',
      newProductGroupError: '',
      newProductGroupErrorMessage: '',
      tempName: '',
      editProductDialog: false,
      productItems: [],
      assetTypes: [
        {id: 3373, name: "Gold"},
        {id: 3374, name: "Silver"},
        {id: 7000, name: 'Goldback'}
      ],
      selectedProduct: {},
      editedProduct: {},
      isProductEdited: false,
      selectedGroup: '',
      validProductGroup: false,
      validProduct: false,
    }
  },

  computed: {
    productGroupItems() {
      return this.productGroups.map(x => x.name)
    },

    assetItems() {
      return this.assetTypes.map(x => x.name)
    },

    newName: {
      get() {
        return this.selectedProduct.name
      },
      set(val) {
        if(val === this.selectedProduct.name){
          delete this.editedProduct['newName']
        }
        else{
          this.editedProduct.newName = val
        }
        return val
      }
    },

    denomination: {
      get() {
        return ''
      },
      set(val) {
        if(val === "") {
          delete this.newProduct['denomination']
          return ""
        }
        else{
          this.newProduct.denomination = val
          return val
        }


      }
    },

    newDescription: {
      get() {
        return this.selectedProduct.description
      },
      set(val) {
        if(val === this.selectedProduct.description) {
          delete this.editedProduct['newDescription']
        }
        else{
          this.editedProduct.newDescription = val
        }
        return val
      }
    },

    editStatus: {
      get() {
        if(this.editedProduct.active) {
          return 'Active'
        }
        else{
          return 'Inactive'
        }
      },
      set(val) {
        if(val === 'Active'){
          this.editedProduct.active = true
        }
        else{
          this.editedProduct.active = false
        }
        return val
      }
    },

    selectedAsset: {
      get() {
        return ''
      },
      set(val) {
        this.newProduct.assetTypeId = this.assetTypes.filter((x) => {return x.name === val})[0].id
        console.log(this.newProduct.assetTypeId)
        return val
      }
    },

    showInactive: {
      get() {
        return false
      },
      set(val) {
        this.inactiveHeaders = val
        this.getProducts(this.selectedGroup)
        return val
      }
    },

  },

  mounted() {
    localStorage.title = 'Products';
    this.getProductGroups(true)
  },

  methods: {
    onSelectedGroupChange() {
      this.getProducts(this.selectedGroup)
    },

    onProductEdited() {
      if(this.editedProduct.active !== this.selectedProduct.active){
        this.isProductEdited = true
      }
      else if(this.editedProduct.newName || this.editedProduct.newDescription){
        this.isProductEdited = true
      }
      else{
        this.isProductEdited = false
      }
    },
    onEditButtonClick( item ) {
      this.editProductDialog = true
      this.selectedProduct = {...this.productItems.filter((x) => {return x.name === item.name})[0]}
      this.editedProduct = {
        uuid: this.selectedProduct.uuid,
        active: this.selectedProduct.active,
      }
    },

    clearNewDialogState() {
      this.newProduct = {}
      this.newProductGroup = {}
      if(this.$refs.groupForm){
        this.$refs.groupForm.reset()
      }
      if(this.$refs.productForm){
        this.$refs.productForm.reset()
      }
    },

    async onNewProductGroupSubmit() {
      this.newProductGroup.newGroupFlag = 1
        const url = '/products/add'
        await this.$axios.post(url, this.newProductGroup).then(({data}) => {
          this.getProductGroups()
          this.selectedGroup = this.newProductGroup.groupName
          this.getProducts(this.selectedGroup)
          this.tempName = this.newProductGroup.groupName
          this.newProductGroupResponse = data.message
          this.newProductGroupSuccess = true
        })
        .catch(({response}) => {
          this.newProductGroupError = true
          this.newProductGroupErrorMessage = response.data.message
        })
        this.clearNewDialogState()
        this.newProductDialog = false
    },

    async onNewProductSubmit() {
      this.newProduct.newGroupFlag = 0
      console.log(this.newProduct)
        const url = '/products/add'
        await this.$axios.post(url, this.newProduct).then(({data}) => {
          this.selectedGroup = this.newProduct.groupName
          this.getProducts(this.selectedGroup)
          this.tempName = this.newProduct.productName
          this.newProduct= data.message
          this.newProductSuccess = true
        })
        .catch(({response}) => {
          this.newProductError = true
          this.newProductErrorMessage = response.data.message
        })
        this.clearNewDialogState()
        this.newProductDialog = false
    },

    clearEditDialogState(){
      this.selectedProduct = {}
      this.editedProduct = {}
    },

    async onEditProductSubmit() {
      const url = '/products/modify'
      console.log(this.selectedGroup)
      await this.$axios.patch(url, this.editedProduct).then(() => {
        this.getProducts(this.selectedGroup)
        this.editProductSuccess = true
      })
      .catch(({response}) => {
        this.newProductError = true
        this.newProductErrorMessage = response.data.message
      })
      this.clearEditDialogState()
      this.editProductDialog = false
    },

    async getProductGroups(isInitialLoad=false) {
      this.loading = true
      const url = '/products'
      await this.$axios.get(url, {params: {}}).then(({data}) => {
        this.productGroups = data
        console.log(data)
        if(isInitialLoad) {
          this.selectedGroup = this.productGroups[0].name
          this.getProducts(this.selectedGroup)
        }
      })
      .catch(({response}) => {
        alert(response)
      })
      this.loading = false
    },

    async getProducts(groupName) {
      this.loading = true
      const url = '/products?groupName=' + groupName
      const params = this.inactiveHeaders ? {showInactive: 1} : {showInactive: 0}
      await this.$axios.get(url, {params: params}).then(({data}) => {
        this.productItems = data
        console.log(data)
      })
      .catch(({response}) => {
        alert(response)
      })
      this.loading = false
    },

  },


}
</script>