import Vue from 'vue'
import './plugins/axios'
import './vee-validate'
import App from './App.vue'
import ButtonTip from "./components/ButtonTip";
import CKEditor from '@ckeditor/ckeditor5-vue2';
import router from './router'
import vuetify from './plugins/vuetify';
import JsonExcel from 'vue-json-excel';
import vueCountryRegionSelect from 'vue-country-region-select';

Vue.use(CKEditor);

Vue.component('ButtonTip', ButtonTip);

Vue.component('downloadExcel', JsonExcel);

Vue.use(vueCountryRegionSelect);

Vue.prototype.$eventBus = new Vue({})

Vue.config.productionTip = false;

Vue.mixin({
  methods: {
    assetColor: function (assetType){
      switch (assetType){
        case 3373:
        case '3373':
        case 'GLT':
        case 'Gold Dollar':
          return this.$vuetify.theme.themes.light.gold;

        case 3374:
        case '3374':
        case 'SLT':
        case 'Silver Dollar':
          return this.$vuetify.theme.themes.light.silver;

        case 5062:
        case '5062':
        case 'USD':
        case 'Paper Dollar':
          return this.$vuetify.theme.themes.light.usd;

        case 7000:
        case '7000':
        case 'GBK':
        case 'Goldback':
          return this.$vuetify.theme.themes.light.goldBack;
      }
    },

    addDays: function (date, days) {
      let result = new Date(date);
      result.setDate(result.getDate() + days);
      return result;
    },

    assetColorDark: function (assetType){
      switch (assetType){
        case 3373:
        case 'GLT':
        case 'Gold Dollar':
          return this.$vuetify.theme.themes.light.darkGold;

        case 3374:
        case 'SLT':
        case 'Silver Dollar':
          return this.$vuetify.theme.themes.light.darkSilver;

        case 5062:
        case 'USD':
        case 'Paper Dollar':
          return this.$vuetify.theme.themes.light.darkUsd;

        case 7000:
        case 'GBK':
        case 'Goldback':
          return this.$vuetify.theme.themes.light.darkGoldBack;
      }
    },

    assetIDToName: function (assetTypeID){
      switch (assetTypeID) {
        case 3373:
          return 'Gold Dollar'

        case 3374:
          return 'Silver Dollar'

        case 5062:
          return 'Paper Dollar'

        case 7000:
          return 'Goldback'
      }
    },

    mixin_addCommas: function (num){// add commas if needed
      return !num ? '' : num.replace(/\B(?=(\d{3})+(?!\d))/g, ',');
    },

    mixin_suffix: function (asset_type){
      switch (asset_type) {
        case 3373:
        case '3373':
        case 'GLT':
        case 'Gold Dollar':
          return 'GD'

        case 3374:
        case '3374':
        case 'SLT':
        case 'Silver Dollar':
          return 'SD'

        case 5062:
        case '5062':
        case 'USD':
        case 'Paper Dollar':
          return 'USD'

        case 7000:
        case '7000':
        case 'GBK':
        case 'Goldback':
          return 'GB'
      }
      return ''
    },

    mixin_UTCToPretty: function (UTC_STAMP, type) {
      if (!UTC_STAMP) return null;
      let date = new Date(UTC_STAMP + 'Z');
      let dateOnly = date.toLocaleString('en-US', {
        day: 'numeric', // numeric, 2-digit
        year: 'numeric', // numeric, 2-digit
        month: 'short', // numeric, 2-digit, long, short, narrow
      });
      switch (type) {
        case 'date':
          return dateOnly;
        case 'dateLong':  // long date
          return date.toLocaleString('en-US', {
            weekday: 'long', // e.g., Thursday
            day: 'numeric', // numeric, 2-digit
            year: 'numeric', // numeric, 2-digit
            month: 'long', // e.g., March
          });
        case 'time':
          return date.toLocaleString('en-US', {
            hour: 'numeric', // numeric, 2-digit
            minute: 'numeric', // numeric, 2-digit
            second: 'numeric', // numeric, 2-digit
            timeZoneName: 'short',
            hour12: false
          });
        case 'dateTime':  // space instead of comma between date and time, and no seconds
          return dateOnly + ' ' + ' ' + date.toLocaleString('en-US', {
            hour: 'numeric', // numeric, 2-digit
            minute: 'numeric', // numeric, 2-digit
            timeZoneName: 'short',
            hour12: false
          });
        default:
          return date.toLocaleString('en-US', {
            day: 'numeric', // numeric, 2-digit
            year: 'numeric', // numeric, 2-digit
            month: 'short', // numeric, 2-digit, long, short, narrow
            hour: 'numeric', // numeric, 2-digit
            minute: 'numeric', // numeric, 2-digit
            second: 'numeric', // numeric, 2-digit
            timeZoneName: 'short',
            hour12: false
          });
      }
    },

    penniesToWhole: function (amount){
      return (amount / 100).toFixed(2) !== 'NaN' ? (amount / 100).toFixed(2) : 0.00;
    },

  }
});

new Vue({
  data: () => ({
    token: localStorage.getItem('user-token') || '',
    user: {
      username: ''
    },
    inactivityTimeout: 45 * 60 * 1000, // 45 minutes in milliseconds
  }),
  methods: {
    handleLoggedIn: function (user) {
      this.user = user;
    },
    handleLoggedOut: async function () {
      // When a user is logged out we wipe the data and set the username to '' for the isAuthenticated method.
      // Also, set the refresh token to '' so the request for a new access token fails.
      router.push('/login');
      this.user = { username: '' };
      localStorage.setItem("refresh_token", '');
      localStorage.setItem("access_token", '');
      localStorage.setItem('user-token', '');//should have been access_token, but have this here for regression
      // this.localStorage.setItem("refresh_token", '');
    },
    resetInactivityTimer() {
      // Save the current timestamp as the last activity time
      localStorage.setItem('lastActivityTime', new Date().getTime());
    },
    checkInactivity() {
      const lastActivityTime = parseInt(localStorage.getItem('lastActivityTime'), 10);
      const currentTime = new Date().getTime();
      if (currentTime - lastActivityTime > this.inactivityTimeout) {
        if(this.$route.path !== '/login') this.$eventBus.$emit('inactiveUser', true);
      }
    },
  },
  mounted() {
    // Attach event listeners to reset the inactivity timer
    this.$eventBus.$emit('inactiveUser', false);
    document.addEventListener("mousemove", this.resetInactivityTimer);
    document.addEventListener("keydown", this.resetInactivityTimer);
    document.addEventListener("click", this.resetInactivityTimer);
    // Start the inactivity timer
    this.resetInactivityTimer();
    setInterval(this.checkInactivity, 1000);
  },
  beforeDestroy() {
    // Remove event listeners and clear the inactivity timer when the component is destroyed
    this.$eventBus.$emit('inactiveUser', false);
    document.removeEventListener("mousemove", this.resetInactivityTimer);
    document.removeEventListener("keydown", this.resetInactivityTimer);
    document.removeEventListener("click", this.resetInactivityTimer);
  },
  computed: {
    isAuthenticated: function () {
      // Returns true if the user is set.
      return this.user.username !== ''
    }
  },
  render: function (createElement) {
    const context = {
      on: {
        'logged-in': this.handleLoggedIn,
        'logged-out': this.handleLoggedOut
      },
      props: {
        user: this.user
      }
    };

    return createElement(App, context)
  },
  created: function () {
    this.$on('logged-in', this.handleLoggedIn);
  },
  router,
  vuetify,
}).$mount('#app');
