<template>
  <v-row justify="center">
    <v-dialog
        v-model="shown"
        max-width="550"
    >
      <ValidationObserver ref="validationOb" v-slot="{ invalid, valid }">
        <v-card>

        <v-row>
          <!-- Title -->
          <div id="title" class="pl-4">
            <v-card-title class="headline text-center mb-n5">Edit Card</v-card-title>
          </div>
          <v-spacer/>
          <v-btn
              color="primary"
              @click="shown = false"
              icon
              class="mr-5 mt-2"
          >
            <v-icon>
              mdi-close
            </v-icon>
          </v-btn>
        </v-row>

        <!-- Error Message -->
        <v-card-text>
                    <span class="body-1">
<!--                        {{ errorMessage }}-->
                    </span>
        </v-card-text>
        <v-card>
          <v-card-text class="mt-n4">
            <span :class="updatedCard.cardTypeName !== 'UPMA TrueLink' ? 'red--text' : ''">
            Card Type: {{ updatedCard.cardTypeName }}
            </span>
            <br>
            Public Num: {{ updatedCard.publicNum }}
            <p><strong>Put a unique letter on the end for automation to work!</strong></p>
            <v-container>
              <ValidationProvider rules="required:Full Name"
                                  name="Full Name"
                                  v-slot="{ errors }">
                <v-text-field persistent-hint hint="Full name as it is to appear on card"
                              placeholder="Enter name to go on card here"
                              :error-messages="errors" v-model="updatedCard.fullName"></v-text-field>
              </ValidationProvider>
              <ValidationProvider rules="required:Card Number"
                                  name="Card Number"
                                  v-slot="{ errors }">
               <v-text-field persistent-hint
                             hint="Card Number"
                             placeholder="Enter card number here"
                             :error-messages="errors"
                             v-model="updatedCard.cardNum"></v-text-field>
              </ValidationProvider>
              <strong>Card Number Needs To Match For Automation</strong>
              <ValidationProvider rules="required:Top-Off Amount"
                                  name="Top-Off Amount"
                                  v-slot="{ errors }">
                <v-text-field persistent-hint prefix="$"
                              hint="Top-Off Amount"
                              placeholder="Enter top-off amount here"
                              :error-messages="errors"
                              v-model="updatedCard.cardLimit"
                              @change="removeCommasAndParseFloat()"
                ></v-text-field>
              </ValidationProvider>
              <ValidationProvider rules="required:Card Status"
                                  name="Card Status"
                                  v-slot="{ errors }">
                <v-select persistent-hint
                          hint="Card Status"
                          :items="status"
                          :error-messages="errors"
                          placeholder="Enter card status here"
                          v-model="updatedCard.cardStatus"
                ></v-select>
              </ValidationProvider>
            </v-container>
          </v-card-text>
          <v-card-actions>
            <v-btn color="primary" x-large text @click="shown = false">Close</v-btn>
            <v-spacer></v-spacer>
            <v-btn color="primary" x-large @click="save(updatedCard)"
                   :disabled="invalid || !valid">Save Changes</v-btn>
          </v-card-actions>
        </v-card>
      </v-card>
      </ValidationObserver>
    </v-dialog>
    <ErrorDialog ref="errorDialog"/>
    <SnackBar ref="snackbar"/>
  </v-row>
</template>

<script>
  import { ValidationObserver, ValidationProvider } from 'vee-validate';
  import { extend } from 'vee-validate';
  import { required } from 'vee-validate/dist/rules';
  import ErrorDialog from "../../components/ErrorDialog";
  import SnackBar from "../../components/Snackbar";
  import _ from 'lodash';

  extend("required", {
    ...required,
    message: "{_field_} is required"
  });

  export default {
    name: "CardEditDialog",
    components: {
      SnackBar,
      ErrorDialog,
      ValidationObserver,
      ValidationProvider },
    props: {
      card: Object,
      default() {
        return {};
      }
    },
    data: () => ({
      shown: false,
      updatedCard: {},
      status: ['Activated', 'Closed', 'Ordered', 'Requested']
    }),

    beforeUpdate () {
      this.updatedCard = _.cloneDeep(this.card);
    },

    methods: {

      toggleShown: function () {
        this.shown = !this.shown;
      },
      removeCommasAndParseFloat: function () {
        let cardLimitWithoutCommas = this.updatedCard.cardLimit.replace(/,/g, '');
        this.updatedCard.cardLimit = parseFloat(cardLimitWithoutCommas).toFixed(2)
      },

      save: async function (updatedCard) {
        if (updatedCard.cardStatus === 'Temporarily Deactivated') {
          // updatedCard.cardStatus = "";
          updatedCard.cardStatus = null;
        }
        this.toggleShown();
        let params = {
          fullName: updatedCard.fullName,
          cardNum: updatedCard.cardNum,
          cardLimit: updatedCard.cardLimit,
          cardStatus: updatedCard.cardStatus,
          cardId: updatedCard.cardId,
        };

        if (confirm('Are you sure you want to SAVE these card changes?')) {
          try {
            this.$parent.state = 'loading';
            await this.$axios.patch('/admin/cards', params);
            this.$refs.snackbar.showSnackbar({
              text: `Successfully saved the card changes`,
              color: 'success',
              style: "margin-top: calc(-8px + .25vw)"
            })
            this.$emit('update');// refresh the table
          } catch (e) {
            this.$refs.errorDialog.showError({
              errorMessage: 'An error occurred while trying to SAVE the card changes. Please ensure the form is filled ' +
                'out completely and correctly. If the problem persists please notify the development team with a ' +
                'detailed description of the error.',
              forDevelopers: e
            })
          }
          this.$parent.state = 'display';
        }
      }
    },
  }
</script>

<style scoped>

</style>
