<template>
    <div class="px-2 pt-2 mb-12">
        <div v-if="allowedComponents.includes('Physical Deposit')">
            <ValidationObserver ref="validationOb" v-slot="{ invalid, validated, handleSubmit, valid }">
                <v-sheet class="elevation-3">

                    <!-- Select Member -->
                    <v-row v-if="showSearchMembers" justify="center">
                        <SearchMembers class="mb-0 pb-0" @memberSelected="memberSelected" />
                    </v-row>

                    <!-- Amount Deposited & Desired Asset -->
                    <v-row justify="center" class="py-2">
                        <v-sheet elevation="1"
                                 max-width="800"
                                 color="grey lighten-4">
                          <v-row class="pt-2">
                                <v-col cols="12" sm="7" class="px-8">
                                    <v-sheet width="350"
                                             class="mx-auto"
                                             color="grey lighten-4">
                                        <DesiredAssetPicker ref="desiredAssetPicker" rules="required" :hideBalance="true"
                                                            :clear="clear" :label="'Currency'" :reverse="false"
                                                            @desiredAccountSelected="desiredAccountSelected"/>
                                        <ValidationProvider ref="amountValidator"
                                                            rules="required|greaterThanZero"
                                                            name="Amount"
                                                            v-slot="{ errors }">
                                                <v-text-field
                                                        label="Deposit Amount (In Asset)"
                                                        hint="The amount brought in for deposit"
                                                        class="mt-3"
                                                        value="0.00" dense
                                                        :error-messages="errors"
                                                        v-model="amount"/>
                                        </ValidationProvider>
                                    </v-sheet>
                                </v-col>

                                <!-- Description -->
                                <v-col cols="12" sm="5">
                                    <ValidationProvider ref="descriptionValidator"
                                                        rules="required"
                                                        name="Description"
                                                        v-slot="{ errors }">
                                        <v-col class="px-8">
                                            <v-sheet max-width="200"
                                                     class="mx-auto"
                                                     color="grey lighten-4">
                                                <v-textarea
                                                        style="margin-bottom: -18px"
                                                        outlined dense
                                                        v-model="description"
                                                        :error-messages="errors"
                                                        label="Deposit Description"/>
                                            </v-sheet>
                                        </v-col>
                                    </ValidationProvider>
                                </v-col>

                          </v-row>
                        </v-sheet>
                    </v-row>

                    <v-row justify="center">
                      <v-btn
                          v-if="allowedComponents.includes('Pending Transactions')"
                          :loading="state === 'saving settle'"
                          color="primary"
                          @click="settle = true; handleSubmit(saveBtnClicked)"
                          :disabled="invalid || !valid || desiredAccountNum === '' || state === 'saving request'"
                          height="30"
                          class="mt-n1 mb-1 mx-2"
                          width="150">
                        Settle
                      </v-btn>
                      <v-btn
                              color="primary"
                              @click="$parent.state = 'initial'"
                              height="30"
                              class="mt-n1 mb-1 mx-2"
                              text
                              width="150">
                        Close
                      </v-btn>
                    </v-row>
                </v-sheet>
            </ValidationObserver>
            <ErrorDialog ref="errorDialog"/>
            <SnackBar ref="snackbar"/>
            <ConfirmationDialog ref="confirmationDialog"
                                @saveConfirmed="saveConfirmed"
                                :member="member"
                                :amount="amount"
                                :accountName="accountDescription"
                                :description="description"
                                :settle="settle"
            />
    </div>
    <div v-else class="pl-12 pr-12 pt-12 mt-12 headline text-center">Sorry, this page is not available.</div>
    </div>
</template>

<script>
    import ErrorDialog from "../components/ErrorDialog";
    import SnackBar from "../components/Snackbar";
    import SearchMembers from "../components/SearchMembers";
    import DesiredAssetPicker from "../components/DesiredAssetPicker";
    import { ValidationObserver, ValidationProvider } from 'vee-validate'
    import { extend } from 'vee-validate'
    import { required } from 'vee-validate/dist/rules'
    import ConfirmationDialog from "../components/physicalDeposit/ConfirmationDialog";

    extend("required", {
        ...required,
        message: "{_field_} is required"
    });

    extend('greaterThanZero', {
        validate: value => {
            return value > 0
        },
        message: "{_field_} must be greater than 0"
    });

    export default {
        name: "PhysicalDeposit",
        props: {
            showSearchMembers: {
                type: Boolean,
                default() {
                    return true;
                }
            },
            memberUpdate: {
                type: Boolean,
                default() {
                    return false;
                }
            },
            memberProp: {
                type: Object,
                default() {
                    return {}
                }
            },
        },
        components: {ConfirmationDialog, DesiredAssetPicker, SearchMembers, SnackBar, ErrorDialog, ValidationObserver,
            ValidationProvider},
        data() {
            return {
                allowedComponents: JSON.parse(localStorage.getItem('allowedComponents') || ''),
                amount: "",
                clear: false,
                description: "",
                desiredAccountNum: "",
                member: this.memberProp,
                state: 'display',
                settle: false
            }
        },

        watch: {
            'memberUpdate': function () {
                this.clear = !this.clear;
                this.resetValues();
                // if (this.member && this.member.email) {
                    this.member = this.memberProp;
                    this.$refs.desiredAssetPicker.setAccounts(this.member);
                // }
            },
        },

        mounted() {
            localStorage.title = 'Physical Deposit';
            if (this.member && this.member.email) {
                this.$refs.desiredAssetPicker.setAccounts(this.member);
            }
        },
        methods: {
            memberSelected: function (member) {
                this.member = member;
                this.clear = !this.clear;
                this.resetValues();
                this.$refs.desiredAssetPicker.setAccounts(this.member);
            },
            desiredAccountSelected: function (account) {
                this.desiredAccountNum = account.num;
            },
            resetValues: function () {
                this.desiredAccountNum = ''
                this.description = "";
                this.amount = "";
                this.$refs.amountValidator.reset();
                this.$refs.descriptionValidator.reset();
            },
            saveBtnClicked: function () {
                this.$refs.confirmationDialog.toggleShown()
            },
            saveConfirmed: async function() {
                this.state = this.settle ? 'saving settle' : 'saving request';

                try {
                    await this.$axios.post('/admin/assetManagement/physicalDeposit', {
                        amount: this.amount,
                        description: this.description,
                        metalAccountNum: this.desiredAccountNum,
                        settle: this.settle
                    });
                    this.state = 'display';
                    this.$refs.snackbar.showSnackbar({
                        text: "Recorded the Physical Deposit",
                        color: "success"
                    })
                    this.memberSelected(this.member);
                    this.$emit('refreshTable');
                } catch (e) {
                    this.state = 'display';
                    this.$refs.errorDialog.showError({
                        errorMessage: e.response.message ? e.response.message : "There was an error creating the Physical Deposit. Please ensure all of the fields are filled out correctly, then try again. If you continue to experience problems please notify the development team.",
                        forDevelopers: e
                    })
                }
            }
        },
        computed: {
            accountDescription: function() {
                if (!this.desiredAccountNum) {
                    return ""
                }
                return this.$refs.desiredAssetPicker.getAccountDescription(this.desiredAccountNum);
            }
        }
    }
</script>

<style scoped>

</style>