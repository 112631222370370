<template xmlns:v-slot="http://www.w3.org/1999/XSL/Transform">

  <v-row justify="center">
    <v-dialog v-model="shown" max-width="500">
      <v-card>

        <v-row>
          <!-- Title -->
          <div id="title" class="pl-3">
            <v-card-title class="headline">Edit Transactions</v-card-title>
          </div>
          <v-spacer/>
          <v-btn
              color="primary"
              @click="shown = false"
              icon
              class="pa-4 ml-n3 mr-6"
              style="margin-top: 14px"
          >
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-row>

        <!-- Body -->
        <v-card-text>
          <v-row>
            <v-col cols="12" sm="12" class="d-flex justify-center">
              <span class="body-1">Transactions Selected: {{ transactions.length }}</span>
            </v-col>

            <v-col cols="12" sm="12" class="d-flex justify-center pb-0">
              <v-menu
                  v-model="datePicker"
                  :close-on-content-click="false"
                  :nudge-right="40"
                  transition="scale-transition"
                  offset-y
                  min-width="290px"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field
                      v-model="dayDue"
                      label="Day Due"
                      prepend-icon="mdi-calendar"
                      readonly
                      v-bind="attrs"
                      v-on="on"
                  ></v-text-field>
                </template>
                <v-date-picker v-model="dayDue" @input="datePicker = false"></v-date-picker>
              </v-menu>
            </v-col>
          </v-row>
        </v-card-text>


        <v-divider></v-divider>

        <v-card-actions class="px-1 px-sm-2 mx-1 mx-sm-2">
          <v-btn
              @click="updateTransactions()"
              text
              :style="{fontSize: (['xs'].includes($vuetify.breakpoint.name) ? '75%' : '')}"
          >Save Changes
          </v-btn>

          <v-btn
              @click="cancelTransactions()"
              text
              color="red"
              :style="{fontSize: (['xs'].includes($vuetify.breakpoint.name) ? '75%' : '')}"
          >Cancel Transaction(s)
          </v-btn>
        </v-card-actions>

      </v-card>
    </v-dialog>

    <ErrorDialog ref="errorDialog"/>

  </v-row>
</template>

<script>
  // @ is an alias to /src
  import ErrorDialog from "@/components/ErrorDialog";

  export default {
    name: "EditTransactionsDialog",
    components: {
      ErrorDialog
    },

    data(){
      return {
        shown: false,
        dayDue: new Date().toISOString().substr(0, 10),
        datePicker: false,
        transactions: {}
      }


    },

    methods: {

      buildDialog: function (transactions) {
        this.transactions = transactions;
        this.shown = true;
      },

      cancelTransactions: async function () {
        if (confirm('Are you sure you want to cancel ' + this.transactions.length + ' transaction(s)?')) {
          try {
            let journalNums = this.transactions.map(item => item.journal_num);

            this.shown = false;
            await this.$axios.patch(`/admin/transactions/cancel`, {journalNums: journalNums});
            this.$parent.getTransactions();
            alert("Successfully cancelled the transactions(s)");

          } catch (e) {
            this.$refs.errorDialog.showError({
              errorMessage: "Failed to cancel the withdrawal(s).",
              forDevelopers: e
            })
          }
        }
      },

      updateTransactions: async function () {
        try {
          let journalNums = this.transactions.map(item => item.journal_num);
          let dataToUpdate = {
            dayDue: this.dayDue
          };

          this.shown = false;
          await this.$axios.patch(`/admin/reports/transactions`, {journalNums: journalNums, dataToUpdate: dataToUpdate});
          this.$parent.getTransactions();
          alert("Successfully updated the selected transaction(s).");


        } catch (e) {
          this.$refs.errorDialog.showError({
            errorMessage: "Failed to update selected transaction(s).",
            forDevelopers: e
          })
        }
      }
    }
  }
</script>
<style scoped>

</style>