<template>
    <v-card width="250"
            fab
            class="mb-12 mr-3 stick"
            v-if="shown"
            raised
    >
        <!-- Close Button -->
        <v-row>
            <v-spacer/>
            <v-btn icon
                   x-small
                   class="mr-4 mt-1"
                   @click="toggleShown"
            >
                <v-icon>
                    mdi-close
                </v-icon>
            </v-btn>
        </v-row>

        <!-- Content -->
        <v-card-title class="title pt-0">Rates</v-card-title>
        <v-simple-table
                v-if="Object.keys(rates).length > 0"
                class="pr-4 pl-4 pb-2"
                dense>
            <tbody>
            <tr :key="rateKey" v-for="rateKey in Object.keys(rates)">
                <td>{{ rates[rateKey] ? rates[rateKey].type.maxi : rateKey.toLocaleUpperCase() }}</td>
                <td>{{ rates[rateKey] ? rates[rateKey].rate : "N/A" }}</td>
            </tr>
            </tbody>
        </v-simple-table>

        <v-card-text v-else>
            None of the rates have been set for today.
        </v-card-text>

        <!-- Set Rates Button -->
        <v-card-actions>
            <v-spacer/>
            <v-btn color="primary"
                   @click="setRatesBtnClicked"
                   dark>
                Set Rates
            </v-btn>
        </v-card-actions>
    </v-card>
</template>

<script>
    export default {
        name: "RatesSummary",
        data: () => ({
            shown: false,
            rates: {}
        }),
        methods: {
            toggleShown: function () {
                this.shown = !this.shown
            },
            getRates: async function () {
                let res = await this.$axios.get('/admin/rates/current');
                this.rates = res.data
            },
            setRatesBtnClicked: function () {
                this.toggleShown();
                this.$emit('setRatesBtnClicked')
            }
        },
        watch: {
            shown() {
                if (Object.keys(this.rates) > 0){ return }

                this.getRates()
            }
        }
    }
</script>

<style scoped>
    .stick {
        position: sticky;
        bottom: 2.5em;
        left: 100%;
        z-index: 2;
    }
</style>