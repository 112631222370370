import Vue from 'vue';
import Vuetify from 'vuetify/lib';

Vue.use(Vuetify);

export default new Vuetify({
    theme: {
        themes: {
            light: {
                primary: '#295491',
                accent: '#af8d30',
                // shaded: '#cbcbcd',
                // dark: '#c6c6c6',
                usd: '#2E7D32',
                darkUsd: '#2d6e30',
                lightUsd: '#2E7D32',
                gold: '#b49230',
                darkGold: '#a08227',
                lightGold: '#b4923033',
                silver: '#9E9E9E',
                darkSilver: '#878787',
                lightSilver: '#9E9E9E4d',
                goldBack: '#1C97A5',
                darkGoldBack: '#17828e',
                lightGoldBack: '#1C97A533',
            }
        }
    }
});
