<template>
  <div class="px-2 pt-2 mb-12">
      <div v-if="allowedComponents.includes('Exchange')">
          <ValidationObserver ref="validationOb" v-slot="{ invalid, validated, handleSubmit, valid }">
          <v-sheet class="elevation-3">

            <!-- Select Member -->
            <v-row v-if="showSearchMembers" justify="center">
              <SearchMembers class="mb-0 pb-0" @memberSelected="memberSelected" />
            </v-row>

            <v-row>
              <!-- Amount Exchange & Asset Type -->
              <v-row justify="center" class="py-2 ma-0 px-0">
                <v-sheet elevation="1"
                         max-width="800"
                         color="grey lighten-4">
                  <v-row class="pt-2">
                    <v-col cols="12" sm="7" class="px-8">
                        <v-sheet width="350" class="mx-auto pt-0"
                                 color="grey lighten-4">
                          <v-row class="pt-0 mt-n4">
                            <v-col cols="3" class="text-right mt-2"><p>FROM:</p></v-col>
                            <v-col cols="9" class="mt-n2">
                              <DesiredAssetPicker :clear="clear" ref="desiredAssetPickerFrom" rules="required"
                                                  :type="'Exchange'"  single
                                                  @desiredAccountSelected="desiredAccountSelectedFrom"/>
                              <ValidationProvider ref="amountRelinquishedValidator"
                                                  rules="required|greaterThanZero"
                                                  name="Amount Relinquished"
                                                  v-slot="{ errors }">
                                  <v-text-field
                                      style="margin-bottom: -2px"
                                      reverse dense
                                      class="mt-0 pt-0"
                                      label="Amount (In Asset)"
                                      hint="Amount Relinquished"
                                      value="0.00"
                                      :error-messages="errors"
                                      v-model="withdrawAmount"/>
                              </ValidationProvider>
                            </v-col>
                          </v-row>
                          <div
                               class="mt-n4 mb-n2 text-right"
                               style="color: red"
                              :key="Math.random() + desiredAccountNumFrom + desiredAccountNumTo"
                               v-show="accountsSame">
                            FROM and TO accounts must be different
                          </div>
                          <div :key="withdrawAmount + desiredAccountAvailableBalanceFrom"
                               class="mt-n1 mb-n6 text-right"
                               style="color: red"
                               v-show="withdrawAmount > parseFloat(desiredAccountAvailableBalanceFrom.replace(/\$|,/g, ''))">
                            Insufficient Funds
                          </div>
                          <v-row style="margin-top: -3px; margin-bottom: 3px">
                            <v-col cols="3" class="text-right mt-2">
                              <p class="pt-1 mb-n4">TO:</p>
                            </v-col>
                            <v-col cols="9">
                              <DesiredAssetPicker :clear="clear"
                                                  ref="desiredAssetPickerTo"
                                                  rules="required"
                                                  single
                                                  :type="'Exchange'"
                                                  :label="'Currency'"
                                                  :reverse="true"
                                                  :noPaper="false"
                                                  :hideBalance="true"
                                                  @desiredAccountSelected="desiredAccountSelectedTo"/>
                              <ValidationProvider ref="amountReceivedValidator"
                                                  rules="required|greaterThanZero"
                                                  name="Amount Received"
                                                  v-slot="{ errors }">
                                  <v-text-field
                                              reverse dense
                                              class="pt-0"
                                              style="margin-top: -2px; margin-bottom: -34px"
                                              label="Amount (In Asset)"
                                              hint="Amount Received"
                                              value="0.00"
                                              :error-messages="errors"
                                              v-model="depositAmount"/>
                               </ValidationProvider>
                            </v-col>
                          </v-row>
                       </v-sheet>
                    </v-col>

                    <!-- Description -->
                    <v-col cols="12" sm="5">
                      <ValidationProvider ref="descriptionValidator"
                                          rules="required"
                                          name="Description"
                                          v-slot="{ errors }">
                        <v-col class="px-8">
                          <v-sheet max-width="300"
                                   class="mx-auto"
                                   color="grey lighten-4">
                            <v-textarea
                                style="margin-bottom: -18px"
                                outlined dense
                                v-model="description"
                                :error-messages="errors"
                                label="Exchange Description"/>
                          </v-sheet>
                        </v-col>
                      </ValidationProvider>
                    </v-col>
                  </v-row>
                </v-sheet>
              </v-row>
            </v-row>

            <v-row justify="center">
              <v-btn
                  v-if="allowedComponents.includes('Pending Transactions')"
                  :loading="state === 'saving settle'"
                  color="primary"
                  @click="settle = true; handleSubmit(saveBtnClicked)"
                  :disabled="invalid || !valid || accountsSame || state === 'saving request'
                  || (withdrawAmount > parseFloat(desiredAccountAvailableBalanceFrom.replace(/\$|,/g, '')))"
                  height="30"
                  class="mt-n1 mb-1 mx-2"
                  width="150">
                Settle
              </v-btn>
              <v-btn
                    color="primary"
                    @click="$parent.state = 'initial'"
                    height="30"
                    class="mt-n1 mb-1 mx-2"
                    text
                    width="150">
                Close
              </v-btn>
            </v-row>
          </v-sheet>
        </ValidationObserver>
          <ErrorDialog ref="errorDialog"/>
          <SnackBar ref="snackbar"/>
          <ConfirmationDialog ref="confirmationDialog"
                              @saveConfirmed="saveConfirmed"
                              :member="member"
                              :withdrawAmount="withdrawAmount"
                              :depositAmount="depositAmount"
                              :type="'Exchange'"
                              :fromAccount="accountDescriptionFrom"
                              :toAccount="accountDescriptionTo"
                              :description="description"
                              :settle="settle"
          />
      </div>
      <div v-else class="pl-12 pr-12 pt-12 mt-12 headline text-center">Sorry, this page is not available.</div>
  </div>
</template>

<script>
  import ErrorDialog from "../components/ErrorDialog";
  import SnackBar from "../components/Snackbar";
  import SearchMembers from "../components/SearchMembers";
  import DesiredAssetPicker from "../components/DesiredAssetPicker";
  import { ValidationObserver, ValidationProvider } from 'vee-validate'
  import { extend } from 'vee-validate'
  import { required } from 'vee-validate/dist/rules'
  import ConfirmationDialog from "../components/physicalDeposit/ConfirmationDialog";

  extend("required", {
    ...required,
    message: "{_field_} is required"
  });

  extend('greaterThanZero', {
    validate: value => {
      return value > 0
    },
    message: "{_field_} must be greater than 0"
  });

  export default {
    name: "Exchange",
    props: {
      showSearchMembers: {
        type: Boolean,
        default() {
          return true;
        }
      },
      memberUpdate: {
        type: Boolean,
        default() {
          return false;
        }
      },
      memberProp: {
        type: Object,
        default() {
          return {}
        }
      },
    },
    components: { SearchMembers, DesiredAssetPicker, SnackBar, ErrorDialog, ValidationObserver, ConfirmationDialog,
      ValidationProvider},
    data() {
        return {
            accountsSame: false,
            allowedComponents: JSON.parse(localStorage.getItem('allowedComponents') || ''),
            clear: false,
            depositAmount: "",
            description: "",
            desiredAccountAvailableBalanceFrom: "",
            desiredAccountNumFrom: "",
            desiredAccountNumTo: "",
            fromAccount: "",
            member: this.memberProp,
            state: 'display',
            toAccount: "",
            withdrawAmount: "",
            settle: false
        }
    },

    watch: {
      'memberUpdate': function(){
        this.resetValues();
        this.member = this.memberProp;
        this.$refs.desiredAssetPickerFrom.setAccounts(this.member);
        this.$refs.desiredAssetPickerTo.setAccounts(this.member);
      },
    },

    methods: {
      memberSelected: function (member) {
        this.resetValues();
        this.member = member;
        this.$refs.desiredAssetPickerFrom.setAccounts(this.member);
        this.$refs.desiredAssetPickerTo.setAccounts(this.member);
      },
      desiredAccountSelectedFrom: function (account) {
        this.desiredAccountNumFrom = account.num;
        this.desiredAccountAvailableBalanceFrom = account.availableBalance;
        this.accountsSame = (this.desiredAccountNumTo === this.desiredAccountNumFrom);
      },
      desiredAccountSelectedTo: function (account) {
        this.desiredAccountNumTo = account.num;
        this.accountsSame = (this.desiredAccountNumTo === this.desiredAccountNumFrom);
      },
      resetValues: function () {
        this.clear = !this.clear;
        this.accountsSame = false;
        this.description = "";
        this.withdrawAmount = "";
        this.depositAmount = "";
        this.desiredAccountNumFrom = "";
        this.desiredAccountNumTo = "";
        this.$refs.amountRelinquishedValidator.reset();
        this.$refs.amountReceivedValidator.reset();
        this.$refs.descriptionValidator.reset();
      },

      saveBtnClicked: function () {
        this.$refs.confirmationDialog.toggleShown()
      },

      saveConfirmed: async function() {
        this.state = this.settle ? 'saving settle' : 'saving request';
        try {
          await this.$axios.post('/admin/assetManagement/exchange', {
            withdrawAmount: this.withdrawAmount,
            depositAmount: this.depositAmount,
            description: this.description,
            withdrawAccountNum: this.desiredAccountNumFrom,
            depositAccountNum: this.desiredAccountNumTo,
            settle: this.settle
          });
          this.memberSelected(this.member);
          this.state = 'display';
          this.$refs.snackbar.showSnackbar({
            text: "Recorded the exchange",
            color: "success"
          })
          this.$emit('refreshTable');
        } catch (e) {
          this.state = 'display';
          this.$refs.errorDialog.showError({
            errorMessage: e.response.message ? e.response.message : "There was an error creating the Exchange. Please ensure all of the fields are filled out correctly, then try again. If you continue to experience problems please notify the development team.",
            forDevelopers: e
          })
        }
      }
    },

    mounted() {
      localStorage.title = 'Exchange';
      if (this.member && this.member.email) {
        this.$refs.desiredAssetPickerFrom.setAccounts(this.member);
        this.$refs.desiredAssetPickerTo.setAccounts(this.member);
      }
    },
    computed: {
      accountDescriptionFrom: function() {
        if (!this.desiredAccountNumFrom) {
          return ""
        }
        return this.$refs.desiredAssetPickerFrom.getAccountDescription(this.desiredAccountNumFrom);
      },
      accountDescriptionTo: function() {
        if (!this.desiredAccountNumTo) {
          return ""
        }
        return this.$refs.desiredAssetPickerTo.getAccountDescription(this.desiredAccountNumTo);
      }
    }
  }
</script>

<style scoped>

</style>