<template>
    <ValidationProvider :rules="rules"
                        name="Currency"
                        v-slot="{ errors }">
        <v-select :key="clear"
                :items="Object.keys(accounts)"
                :single-line="single"
                dense
                :label="label ? label : 'Currency and Available Balance'"
                v-model="desiredAccount"
                :error-messages="errors"
                :no-data-text="noDataText"
                :reverse="!hideBalance || reverse"
                required>
            <template slot="selection" slot-scope="data">
                <v-col v-if="!hideBalance" cols="6" class="pa-0 ma-0" style="text-align: end">{{data.item.split(" ")[0]}}</v-col>
                <v-col v-if="hideBalance" class="pa-0 ma-0" style="text-align: start">{{data.item.split(" ")[1]}}</v-col>
                <v-col v-if="!hideBalance" cols="6" class="pa-0 ma-0" style="text-align: end">{{data.item.split(" ")[1]}}</v-col>
            </template>
            <template slot="item" slot-scope="data" class="mx-0 px-0">
                <v-col v-if="!hideBalance" cols="3"></v-col>
                <v-col v-if="hideBalance" cols="4" class="mx-0 px-0">{{data.item.split(" ")[1]}} </v-col>
                <v-col v-else cols="4" class="mx-0 px-0" style="text-align: end">{{data.item.split(" ")[1]}} </v-col>
                <v-col cols="1"></v-col>
                <v-col v-if="!hideBalance" cols="4" class="mx-0 px-0" style="text-align: end">{{data.item.split(" ")[0]}}</v-col>
            </template>
        </v-select>

        <ErrorDialog ref="errorDialog"/>
    </ValidationProvider>
</template>

<script>
    import { ValidationProvider } from 'vee-validate';
    import ErrorDialog from "../components/ErrorDialog";

    export default {
        name: "DesiredAssetPicker",
        props: ['clear', 'rules', 'single', 'errors', 'noPaper', 'type','label', 'hideBalance', 'reverse'],
        components: {ValidationProvider,  ErrorDialog},
        data: () => ({
            accounts: {},
            desiredAccount: "",
            noDataText: "No data available. Did you select a member above?",
            state: "display",
        }),

        methods: {
            setAccounts: async function (member) {
                this.state = 'loading';
                try {
                    if (!member.email) {
                        this.$refs.errorDialog.showError({
                            errorMessage: 'There was a problem selecting the accounts for a user. No user was selected prior to attempting to fulfill the request.',
                            forDevelopers: `Got a falsy value for user email. The value was: ${member.email} .`
                        })
                    }
                    let res = await this.$axios.get(`/admin/user/balances?username=${member.email}`);
                    this.accounts = this.parseAccounts(res.data);
                } catch (e) {
                    // this.$refs.errorDialog.showError({
                    //     errorMessage: `There was a problem trying to get the accounts for the selected user with the email of ${member.email}. If the problem persists please let the development team know.`,
                    //     forDevelopers: e
                    // });
                }
            },
            parseAccounts: function (accounts) {
                let res = {};
                for (const account of accounts.accountBreakdowns) {
                    // Skip paper accounts if noPaper prop is true
                    if (this.noPaper && account.name.includes('Paper')) continue;

                    // if (this.type === 'Exchange') {
                        let balance = account.availableBalance.replace(/\$/g,'');
                        res[`${balance} ${account.assetType}s available`] = account;
                    // } else {
                    //     let parsedAccountNum = account.num.substring(account.num.length - 4, account.num.length);
                    //     res[`${account.name} (...${parsedAccountNum})`] = account
                    // }
                }
                this.state = "display";
                return res;
            },
            resetData: function () {
                this.accounts = {};
                this.desiredAccount = "";
            },
            getAccountDescription: function(num) {
                if (!num) {
                    return
                }

                let keys = Object.keys(this.accounts);

                for (const key of keys) {
                    if (this.accounts[key].num === num) {
                        let type = this.accounts[key].assetType;
                        return type;
                        // return key
                    }
                }
            }
        },
        watch: {
            state: function () {
                this.noDataText = this.state === 'display' ? 'No data available. Did you select a member above?' : 'Loading...';
            },
            clear: function () {
                this.resetData();
            },
            desiredAccount: function (newValue) {
                this.$emit('desiredAccountSelected', this.accounts[newValue] ? this.accounts[newValue] : null)
            }
        }
    }
</script>

<style scoped>

</style>