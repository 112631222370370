<template>
    <v-footer
            app
            color="neutral"
            class="pa-0"
            elevation="5"
            height="30"
    >
<!--        <span class="pl-3">&copy; 2020</span>-->
        <span class="pl-3">&copy; {{ new Date().getFullYear() }}</span>
        <v-spacer/>
        <div v-if="$root.isAuthenticated"
             class="pr-3">
            <v-btn text>
                Trust Needs
            </v-btn>
            <v-btn text
                @click="ratesClicked"
            >
                Rates
            </v-btn>
        </div>
    </v-footer>
</template>

<script>
    export default {
        name: "Footer",
        data: () => ({
            state: 'display',
        }),
        methods: {
            ratesClicked: function () {
                this.$emit('ratesClicked')
            }
        }
    }
</script>

<style scoped>

</style>