<template>
    <v-col :cols="small ? '4' : fullwidth ? '12' : '7'">
        <v-autocomplete
                v-model="selectedMember"
                :items="items"
                :loading="state === 'loading'"
                :search-input.sync="search"
                color="white"
                :clearable="clearable"
                hide-no-data
                :hide-details="hideDetails"
                :chips="chips"
                hide-selected
                item-text="memberInfo"
                placeholder="Search Members (Search by email, name, or legal name)"
                return-object
                solo-inverted
                @click:append-outer="clicked"
        >
          <template v-slot:append-outer>
            <v-icon
                v-if="clearableAddedIcon && (search && search.length > 0)"
                @click="clearInput"
                :style="{ color: 'white' }"
            >
              mdi-close-circle
            </v-icon>
        </template>
        </v-autocomplete>
        <ErrorDialog ref="errorDialog"/>
    </v-col>
</template>

<script>
    import ErrorDialog from "./ErrorDialog";
    export default {
        name: "SearchMembers",
        props: {
          clearable: Boolean,
          clearableAddedIcon: {
            type: Boolean,
            default: false
          },
          fullwidth: Boolean,
          small: Boolean,
          hideDetails: String,
          chips: Boolean
        },
        components: {ErrorDialog},
        data: () => ({
            descriptionLimit: 60,
            members: [],
            state: 'display',
            selectedMember: null,
            search: '',
            awaitingSearch: false,
        }),
        methods: {
            searchMembers: async function () {
                if (this.search) {
                  this.state = 'loading';
                  this.$axios.get(`/admin/users/search?searchText=${this.search}`)
                      .then(({data}) => {

                        let newMembers = data;
                        let that = this;

                        // Add results into the list of members if they don't already exist.
                        newMembers.forEach((newMember) => {
                          // Check if the member is already in the list
                          let count = that.members.filter((member) => {
                            return member.email === newMember.email
                          }).length;

                          // If not a duplicate.
                          if (count < 1) {
                            that.members.push(newMember)
                          }
                        });
                        this.tableLoading = false;
                        this.state = 'display';
                      })
                      .catch(({response}) => {
                        this.state = 'display';
                        this.$refs.errorDialog.showError({
                          errorMessage: `Failed to search for ${this.search}. Please try again with different text.`,
                          forDevelopers: response
                        })
                      });
                }
            },
            clearInput: function () {
              this.selectedMember = null;
              this.search = '';
            },
            clicked: function () {
                // console.log("clicked.")
            }
        },
        computed: {
            // Returns the member info in the format the search autocomplete needs.
            items () {
                return this.members.map(member => {
                    const memberInfo = `${member.name}, ${member.email}, ${member.legalName} ${member.num}`;

                    return Object.assign({}, member, { memberInfo })
                })
            },
        },
        watch: {
             'search' (search) {
               this.search = search;
              if (!this.awaitingSearch) {
                setTimeout(() => {
                  this.awaitingSearch = false;
                  this.searchMembers();
                }, 600); // .5 sec delay
              }
              this.awaitingSearch = true;
            },
            selectedMember(selectedMember) {
                // If the member is null just return
                if (!selectedMember) {
                    return
                }

                // Else emit an event that the member was selected and pass the selected member to the register callback.
                this.$emit('memberSelected', selectedMember);
            }
        },
    }
</script>

<style scoped>

</style>