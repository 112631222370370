<template>
<div class="px-5 py-2 mb-12">
  <div v-if="allowedComponents.includes('IRA Accounts')">
<!--    Search for member to convert button-->
    <v-row class="justify-start px-4 mb-n1">
    <v-btn color="primary" @click="member= {}, refreshSearch = !refreshSearch, noMemberDialog = true">
      Find member to convert
        <v-icon class="ml-3">mdi-account-search</v-icon>
      </v-btn>

    </v-row>

<!--    IRA Accounts display table-->
    <v-data-table sort-by='name'
                  multi-sort
                  :headers="tableHeaders"
                  :items="members"
                  :loading="state === 'loading'"
                  :footer-props="{ 'items-per-page-options': [ 50, 100, 500 ] }">
    </v-data-table>

<!--    Select member popup-->
    <v-dialog v-model="noMemberDialog" max-width="1000px" max-height="400">
      <v-card>
        <v-card-title class="pb-0 mt-1 mb-n1">
          <span>Select Member to Convert:</span>
          <v-spacer/>
          <v-btn class="mt-n1 mr-n1" @click="noMemberDialog = false" icon><v-icon>mdi-close</v-icon></v-btn>
        </v-card-title>
        <!-- Select Member -->
        <div class="search-members-container">
          <v-row class="justify-center mt-4">
            <SearchMembers  :key='refreshSearch' clearable @memberSelected="memberSelected"/>
          </v-row>
        </div>
        <v-card-actions>
          <v-btn color="error"
                 dark class="mt-2 px-4 mb-4 ml-4"
                 @click="noMemberDialog = false">
            Cancel
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

<!--    Selected member display and conversion button-->
    <v-dialog v-model="selectedMemberDialog" max-width="800px">
      <v-card>
        <v-card-title class="pb-0 mt-1 mb-n1">
          <span>Is this the account you would like to convert?</span>
          <v-spacer/><v-btn class="mt-n1 mr-n1" @click="selectedMemberDialog = false" icon><v-icon>mdi-close</v-icon></v-btn>
        </v-card-title>
        <v-card-title class="pb-0 headline justify-center">
          {{ member.name }}
        </v-card-title>
        <v-card-title class="headline justify-center">
          {{member.email}}
        </v-card-title>
        <v-card-actions  class="justify-space-between">
          <v-btn color="error"
                 dark
                 class="mt-2 px-4 mb-4 ml-6"
                 @click="selectedMemberDialog = false; noMemberDialog = true">
            Cancel
          </v-btn>
          <v-btn color="primary"
                 dark
                 class="mt-2 px-4 mb-4 mr-6"
                 @click="addIRAAccount">
            Convert to IRA<v-icon class="ml-3">mdi-account-convert</v-icon>
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <ErrorDialog ref="errorDialog"/>
    <SnackBar ref="snackbar"/>
  </div>
  <div v-else class="pl-12 pr-12 pt-12 mt-12 headline text-center">Sorry, this page is not available.</div>
</div>
</template>

<script>
// @ is an alias to /src
import ErrorDialog from "@/components/ErrorDialog";
import SearchMembers from "@/components/SearchMembers";
import SnackBar from "@/components/Snackbar";

export default {
  name: "IRAAccounts",
  components: {
    SnackBar,
    ErrorDialog,
    SearchMembers
  },
  data: () => ({
    allowedComponents: JSON.parse(localStorage.getItem('allowedComponents') || ''),
    state: 'loading',
    members: [],
    member: {},
    noMemberDialog: false,
    selectedMemberDialog: false,
    refreshSearch: true,
    tableHeaders: [
      {
        text: 'Name',
        value: 'name',
        align: 'start'
      },
      {
        text: 'Email',
        value: 'email',
        align: 'start'
      },
      {
        text: 'Phone',
        value: 'phone',
        align: 'right'
      }]
  }),
  methods: {
    getIRAAccounts: async function () {
      try {
        let res = await this.$axios.get(`/admin/users/ira`);
        this.members = res.data;
      } catch (e) {
        let errorInfo = ' ';
        if (e.response && e.response.data && e.response.data.message) {
          errorInfo = " " + e.response.data.message;
        }
        this.$refs.errorDialog.showError({
          errorMessage: "Failed to get IRA accounts.",
          forDevelopers: e + errorInfo
        })
      }
    },

    memberSelected: function (member) {
      this.member = member;
      this.showConfirmDialog();
    },

    showConfirmDialog: function () {
      this.noMemberDialog = false;
      this.selectedMemberDialog = true;
    },

    addIRAAccount: async function () {
      let data = {};
      data.username = this.member.email;
      try {
        this.state = 'loading';
        await this.$axios.patch(`/admin/users/ira`, data);
        this.$refs.snackbar.showSnackbar({
          text: "The account was successfully converted!",
          color: "success"
        });
      } catch (e) {
        let message = "Failed to change account to an IRA account."
        if (e.response && e.response.data && e.response.data.message) {
          message += " " + e.response.data.message;
        }
        this.$refs.errorDialog.showError({
          errorMessage: message,
          forDevelopers: e
        })
      }
      this.selectedMemberDialog = false;
      await this.resetState();
    },

    resetState: async function () {
      await this.getIRAAccounts();
      this.state = 'display';
    }
  },

  mounted() {
    localStorage.title = 'IRA Accounts';
  },

  created: async function() {
    await this.resetState();
  }
}
</script>

<style scoped>
.search-members-container {
  max-width: 975px;
  margin: auto;
}
</style>