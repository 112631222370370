<template>
  <div class="px-5 py-2">
    <div v-if="allowedComponents.includes('Cards')">
      <v-data-table
          :headers="tableHeaders"
          :items="cards"
          :search="search"
          class="elevation-4"
          @click:row="editCard"
          :loading="state === 'loading'"
      >
        <template v-slot:top>
          <v-text-field v-model="search" append-icon="mdi-magnify" label="Search Cards"
                        class="mx-4 pt-7"></v-text-field>
        </template>
        <template v-slot:item.cardLimit="{ item }">
          ${{ item.cardLimit }}
        </template>
        <template v-slot:item.cardNum="{ item }">
          <span style="font-weight: lighter;">xxxx -</span>{{ item.cardNum.substr(item.cardNum.length-4,4) }}
        </template>
        <template v-slot:item.cardStatus="{ item }">
          <strong class='primary--text text--lighten-2'>{{ item.cardStatus.toUpperCase() }}</strong>
        </template>
      </v-data-table>

      <CardEditDialog :card="this.card" @update="update" ref="CardEditDialog"/>
      <ErrorDialog ref="errorDialog"/>
    </div>
    <div v-else class="pl-12 pr-12 pt-12 mt-12 headline text-center">Sorry, this page is not available.</div>
  </div>
</template>

<script>
  // @ is an alias to /src
  import ErrorDialog from "@/components/ErrorDialog";
  import CardEditDialog from "./CardEditDialog";
  export default {
    name: "Cards",
    components: {
      ErrorDialog, CardEditDialog
    },
    data: () => ({
      state: 'loading',
      search: "",
      manualDeposits: [],
      cards: [],
      card: {},
      allowedComponents: JSON.parse(localStorage.getItem('allowedComponents') || ''),

      tableHeaders: [
        {
          text: 'Card Name',
          value: 'fullName',
          align: 'start'
        },
        {
          text: 'Card Number',
          value: 'cardNum',
          align: 'end'
        },
        {
          text: 'Public Num',
          value: 'publicNum',
          align: 'end'
        },
        {
          text: 'Top-Off Amount',
          value: 'cardLimit',
          align: 'end'
        },
        {
          text: 'Date Created',
          value: 'cardCreated',
          align: 'start'
        },
        {
          text: 'Status',
          value: 'cardStatus',
          align: 'start'
        },
      ]
    }),

    methods: {
      update: async function () {
        await this.getCards();
        this.state = 'display';
      },

      getCards: async function () {
        try {
          let res = await this.$axios.get(`/admin/cards`);
          this.cards = res.data;
        } catch (e) {
          this.$refs.errorDialog.showError({
            errorMessage: "Failed to get Card Transactions.",
            forDevelopers: e
          })
        }
      },

      editCard: function (card) {
        let cardLimitWithoutCommas = card.cardLimit.replace(/,/g, '');
        card.cardLimit = parseFloat(cardLimitWithoutCommas).toFixed(2);
        this.card = card;
        this.$refs.CardEditDialog.toggleShown();
      },
    },

    created: async function() {
      await this.getCards();
      this.state = 'display';
    },
    mounted() {
      localStorage.title = 'True Link Cards';
    },
  }
</script>

<style scoped>

</style>