<template>
  <div>
    <div v-if="allowedComponents.includes('Assets')">
      <div class="px-1 d-print-none">
          <v-row class="px-1 mt-n5 mb-n6" justify="space-around">
              <v-col cols="12" sm="6">
                <v-row justify="space-around">
                <!-- Select Member -->
                  <SearchMembers class="justify-center mt-2 mb-n7 pb-0" @memberSelected="memberSelected" />
                  <v-tooltip bottom>
                    <template v-slot:activator="{ on }">
                      <span v-on="on">
                        <v-btn color="primary"
                               @click="getTransactions()"
                               :disabled="!member.email || (!!endDate && !transDate)"
                               class="mt-7">
                          Get
                        </v-btn>
                      </span>
                    </template>
                    <span>Get transactions: member required, single date or date range optional</span>
                  </v-tooltip>
                </v-row>
              </v-col>

              <v-col cols="12" sm="6">
                <v-row class="px-4" justify="space-around">
                  <v-menu
                        v-model="menu"
                        :close-on-content-click="true"
                        :nudge-right="40"
                        transition="scale-transition"
                        offset-y
                    >
                      <template v-slot:activator="{ on, attrs }">
                        <v-text-field
                            class="mt-4 px-2"
                            clearable
                            label="Start date/Single date"
                            readonly
                            v-bind="attrs"
                            v-model="transDate"
                            v-on="on"
                            style="width:14vw"
                        ></v-text-field>
                      </template>
                      <v-date-picker v-model="transDate" no-title @input="menu = false"></v-date-picker>
                    </v-menu>
<!--                  <v-spacer/>-->
                  <v-menu
                      v-model="menu2"
                      :close-on-content-click="true"
                      :nudge-right="40"
                      transition="scale-transition"
                      offset-y
                  >
<!--                      min-width="100px"-->
                    <template v-slot:activator="{ on, attrs }">
                      <v-text-field
                          class="mt-4 px-2"
                          clearable
                          label="End date"
                          readonly
                          v-bind="attrs"
                          v-model="endDate"
                          v-on="on"
                          style="width:14vw"
                      ></v-text-field>
<!--                          @keyup.enter="member ? getTransactions() : ''"-->
                    </template>
                    <v-date-picker v-model="endDate" no-title @input="menu2 = false"></v-date-picker>
                  </v-menu>
                </v-row>
              </v-col>
          </v-row>

        <div v-if="state !== 'initial'">
          <Withdrawal v-if="state === 'Withdrawal'" @refreshTable="getTransactions" class="mt-n7 mb-n2"
                    :memberUpdate="memberUpdate" :memberProp="member" :showSearchMembers="false"/>
          <PhysicalDeposit v-if="state === 'Deposit'" class="mt-n8 mb-n1" @refreshTable="getTransactions"
                    :memberUpdate="memberUpdate" :memberProp="member" :showSearchMembers="false"/>
          <Exchange v-if="state === 'Exchange'" class="mt-n8 mb-n1" @refreshTable="getTransactions"
                    :memberUpdate="memberUpdate" :memberProp="member" :showSearchMembers="false"/>
          <Bailment v-if="state === 'Bailment'" class="mt-n8 mb-n1" @refreshTable="getTransactions"
                    :memberUpdate="memberUpdate" :memberProp="member" :showSearchMembers="false"/>
          <Adjustment v-if="state === 'Adjustment'" class="mt-n8 mb-n1" @refreshTable="getTransactions"
                    :memberUpdate="memberUpdate" :memberProp="member" :linkProp="link" :showSearchMembers="false"/>
        </div>
        <v-row v-if="memberIsSelected" justify="center" :style="$vuetify.breakpoint.name === 'xs' ? 'transform: scaleX(.6)' : ''">
          <div style="width: 180px">
            <v-text-field v-model="searchTransactions" dense append-icon="mdi-magnify"
            class="pt-2 pb-0 mb-n6 mx-4"></v-text-field>
          </div>
          <v-btn-toggle v-model="toggle"
            class="justify-center mb-n4 pt-2 pb-1"
            color="primary" group>
            <v-btn v-if="allowedComponents.includes('Withdrawal')" small @click="state='Withdrawal'">Withdrawal</v-btn>
            <v-btn v-if="allowedComponents.includes('Physical Deposit')" small @click="state='Deposit'">Physical Deposit</v-btn>
            <v-btn v-if="allowedComponents.includes('Exchange')" small @click="state='Exchange'">Exchange</v-btn>
            <v-btn v-if="allowedComponents.includes('Bailment')" small @click="state='Bailment'">Bailment</v-btn>
            <v-btn v-if="allowedComponents.includes('Adjustment')" small @click="state='Adjustment'">Adjustment</v-btn>
            <v-divider class="mr-4" inset vertical></v-divider>
            <v-tooltip bottom>
              <template v-slot:activator="{ on }">
                <v-btn small @click="printConfigDialog = true " v-on="on">
                  Print {{ selectedTransactions.length }} / {{ transactions.length }}
                </v-btn>
              </template>
              <span>Print/Email items in order checked</span>
            </v-tooltip>
          </v-btn-toggle>
        </v-row>
        <br>
        <div class="elevation-4 ma-3 mt-n2">
          <v-data-table
              @click:row="rowClick"
              fixed-header
              :height="state === 'initial' ? 'calc(100vh - 205px)' : 'calc(100vh - 450px)'"
              :headers="headers"
              hide-default-footer
              :items="transactions"
              :items-per-page="-1"
              :loading="loadingState === 'loading'"
              :search="searchTransactions"
              show-select
              :single-select="false"
              style="cursor: pointer"
              v-if="memberIsSelected"
              v-model="selectedTransactions"
            >
            <template v-slot:item.asset_type="{ item }">
              <span style="white-space: pre">{{ item.asset_type }}</span>
            </template>
            <template v-slot:item.posted="{ item }">
              <span style="white-space: pre">{{ item.prettyPosted }}</span>
            </template>
            <template v-slot:item.first_settled_date="{ item }">
              <span style="white-space: pre">{{ item.prettySettled }}</span>
            </template>
            <template v-slot:item.amount="{ item }">
              <span style="white-space: pre">{{ item.prettyAmount }}</span>
            </template>
          </v-data-table>
        </div>

        <v-dialog v-model="printConfigDialog" max-width="600">
          <v-card class="pa-4">
            <v-card-title>Print Configuration</v-card-title>
            <v-card-text>
              <v-card outlined class="pa-8">
                <br>
                <p>Print {{ selectedTransactions.length }} transactions</p>
                <v-checkbox v-model="printSignatureLines" label="Include signature lines"></v-checkbox>
                <br>
              </v-card>
            </v-card-text>

            <!-- Print & Cancel Buttons -->
            <v-card-actions>
              <v-btn color="red"
                     dark
                     @click="printConfigDialog = false">
                Cancel
              </v-btn>
              <v-spacer/>
              <v-btn color="primary"
                     @click="print(true)">
                Print Preview
              </v-btn>
              <v-btn color="primary"
                     :loading="loadingPDF"
                     @click="print(false)">
                Email
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>

        <ErrorDialog ref="errorDialog"/>
        <br><br>
      </div>

<!--      <div v-if="printNow" style="width: 100%; font-size: 14px" class="px-3 d-print-block div2">-->

<!--        <h3>Receipt of selected transactions for {{member.firstName}} {{member.lastName}}-->
<!--          <br>-->
<!--          <span class="caption" style="margin-top: 5px">Printed: {{ mixin_UTCToPretty(currentDate.toUTCString(), 'dateLong')}}-->
<!--              by {{ $root.user.firstName }} {{ $root.user.lastName }}-->
<!--              ({{ selectedTransactions.length }}</span>-->
<!--          <span class="caption" v-if="selectedTransactions.length > 1"> entries)</span>-->
<!--          <span class="caption" v-else> entry)</span>-->
<!--        </h3>-->
<!--        <v-divider></v-divider>-->
<!--        <v-row>-->
<!--          <v-col cols="2" class="text-start">-->
<!--            <h3>Date</h3>-->
<!--          </v-col>-->
<!--          <v-col cols="2" class="text-start font-weight-bold">-->
<!--            <b>Type</b>-->
<!--          </v-col>-->
<!--          <v-col cols="4" class="text-start">-->
<!--            <b>Entry / Journal / Memo</b>-->
<!--          </v-col>-->
<!--          <v-col cols="2" class="text-start">-->
<!--            <b>Asset</b>-->
<!--          </v-col>-->
<!--          <v-col cols="2" class="text-end pr-3">-->
<!--            <b>Amount</b>-->
<!--          </v-col>-->
<!--        </v-row>-->
<!--        <v-divider></v-divider>-->
<!--        <br><br><br>-->

<!--        <div :key="tran.id" v-for="(tran, index) of selectedTransactions" :class="{'mt-n12 py-0': index === 0}">-->
<!--          <v-row style="page-break-inside: avoid">-->
<!--            <v-col cols="2">-->
<!--              {{tran.date}}-->
<!--            </v-col>-->
<!--            <v-col cols="2">-->
<!--              <div v-if="tran.type === 'Physical Deposit'">Deposit</div>-->
<!--              <div v-else-if="tran.type === 'Shipping Charge'">Shipping</div>-->
<!--              <p v-else>{{tran.type}}</p>-->
<!--            </v-col>-->
<!--            <v-col cols="4" class="text-start">-->
<!--              {{index + 1}} / {{tran.journalNum}}-->
<!--              <span v-if="tran.description"> / {{tran.description}}</span>-->
<!--            </v-col>-->
<!--            <v-col cols="2">-->
<!--              {{tran.asset_type}}-->
<!--            </v-col>-->
<!--            <v-col cols="2" class="text-end">-->
<!--              {{tran.prettyAmount}}-->
<!--            </v-col>-->
<!--          </v-row>-->
<!--        </div>-->

<!--        <div v-if="printSignatureLines" style="page-break-inside: avoid">-->
<!--          <br>-->
<!--          <br>-->
<!--          <br>-->
<!--          <v-row class="px-4 py-0 mb-n4">-->
<!--            <v-row class="justify-start">-->
<!--              <v-col cols="3">-->
<!--                <div style="border-bottom: 1px solid"></div>-->
<!--                &nbsp;&nbsp;Teller-->
<!--              </v-col>-->
<!--              <v-col cols="3">-->
<!--                <div style="border-bottom: 1px solid"></div>-->
<!--                &nbsp;&nbsp;Witness-->
<!--              </v-col>-->
<!--              <v-col cols="3">-->
<!--                <div style="border-bottom: 1px solid"></div>-->
<!--                &nbsp;&nbsp;Member-->
<!--              </v-col>-->
<!--              <v-col cols="3">-->
<!--                <div style="border-bottom: 1px solid"></div>-->
<!--                &nbsp;&nbsp;Identification-->
<!--              </v-col>-->
<!--            </v-row>-->
<!--          </v-row>-->
<!--        </div>-->
<!--      </div>-->

    </div>

    <div v-else class="pl-12 pr-12 pt-12 mt-12 headline text-center">Sorry, this page is not available.</div>
    <SnackBar ref="snackbar"/>
  </div>
</template>

<script>
  import Adjustment from "@/views/Adjustment";
  import Bailment from "@/views/Bailment";
  import ErrorDialog from "../components/ErrorDialog";
  import SnackBar from "../components/Snackbar";
  import Exchange from "@/views/Exchange";
  import PhysicalDeposit from "./PhysicalDeposit";
  import SearchMembers from "../components/SearchMembers";
  import Withdrawal from "@/views/Withdrawal";
  import jspdf from "jspdf";
  import autoTable from "jspdf-autotable";


  export default {
    name: "Assets",
    components: {
      Adjustment,
      Bailment,
      ErrorDialog,
      Exchange,
      PhysicalDeposit,
      SearchMembers,
      Withdrawal,
      SnackBar
    },
    data: () => ({
      allowedComponents: JSON.parse(localStorage.getItem('allowedComponents') || ''),
      endDate: '',
      currentDate: new Date(),
      hiddenHeaders: [// mobile view doesn't use align, so only add hidden headers on larger screens
        { value: 'prettyPosted', text: 'hidden',sortable: true, width: '120px',align:' d-none'},// 'd-none' hides column but keeps search ability
        { value: 'prettySettled',text: 'hidden',sortable: true, width: '120px',align:' d-none'},// 'd-none' hides column but keeps search ability
        { value: 'prettyAmount', text: 'hidden',sortable: true, width: '120px',align:' d-none'},// 'd-none' hides column but keeps search ability
      ],
      link: {},
      loadingState: 'loading',
      member: {},
      memberIsSelected: false,
      memberUpdate: false,
      menu: false,
      menu2: false,
      printConfigDialog: false,
      printNow: false,
      loadingPDF: false,
      printSignatureLines: false,
      refresh: false,
      searchTransactions: "",
      selectedTransactions: [],
      showBailment: false,
      showWithdrawal: false,
      state: 'initial',
      toggle: undefined,
      transDate: '',
      transaction: 'none',
      transactions: [],
      transactionHeaders: [
        {text:'Account',     value: 'asset_type', align: 'start'},
        {text:'Journal Num', value: 'journalNum', align: 'end'},
        {text:'Posted ',     value: 'posted',     align: 'center'},
        {text:'Settled ',    value: 'first_settled_date',  align: 'center'},
        {text:'Type',        value: 'type',       align: 'start', width: '140px'},
        {text:'Amount ',      value: 'amount',     align: 'end'},
        {text:'Memo',        value: 'description',align: 'start', width: '500px'},
      ],
    }),

    computed: {
      headers() {
        return this.$vuetify.breakpoint.name === 'xs' ? this.transactionHeaders : this.transactionHeaders.concat(this.hiddenHeaders);
      },
    },

    watch: {

      searchTransactions() {// if search changes, clear selectedTransactions
        this.selectedTransactions = [];
      },

      state() {// if state changes - clear link, if state reverts to initial, clear toggle button
        this.link = {};
        if (this.state !== 'initial') return;
        this.toggle = undefined;
      },
    },

    mounted() {
      localStorage.title = 'Assets';
      this.title = localStorage.title;
      const params = new URLSearchParams(window.location.search);
      let member = {};
      if (params.has('username')) {
        member.firstName = params.get('name');
        member.lastName = '';
        member.email = params.get('username');
        this.memberSelected(member);
      }
    },

    methods: {
      getTransactions: async function () {
        this.loadingState = 'loading';
        this.selectedTransactions = [];
        let data = {
          username: this.member.email,
          startDate: this.transDate,
          endDate: !this.endDate ? this.transDate : this.endDate,
          limit: 1000 //for large accounts
        }
        try {
          let res = await this.$axios.get('/admin/user/activity', {params: data});
          let trans = res.data
          for (let i = 0; i < trans.length; i++) {// format pretty fields
            trans[i].id = i;// need id for v-data-table multi-select to work correctly
            trans[i].prettyPosted = trans[i].posted ? this.mixin_UTCToPretty(trans[i].posted, 'dateTime') : '';
            trans[i].prettySettled = trans[i].first_settled_date ? this.mixin_UTCToPretty(trans[i].first_settled_date, 'dateTime') : '';
            trans[i].prettyAmount = trans[i].amount ? trans[i].amount.replace(/\$/g, '') + ' ' + this.mixin_suffix(trans[i].asset_type) : '';
            trans[i].journalNum = trans[i].journal_num.replace(/^(0+)/g, '');
          }
          this.transactions = trans;
        } catch (e) {
          this.$refs.errorDialog.showError({
            errorMessage: "Failed to get Transactions.",
            forDevelopers: e
          });
        }
        this.loadingState = 'display';
      },

      memberSelected: function (member) {
        this.member = member;
        this.memberUpdate = !this.memberUpdate;
        this.memberIsSelected = true;
        this.getTransactions();
      },

      printDiv() {
        this.printNow = true;
        this.printConfigDialog = false;
        setTimeout(() => {
           window.print();
        }, 500); // .5 second delay
      },

      print(print = true) {
        if (!print) this.loadingPDF = true;

        const pdf = new jspdf({
          orientation: "portrait",
          unit: "in",
          format: "letter"

        })
        const date = new Date().toLocaleString('en-US', {
          weekday: 'long', // e.g., Thursday
          day: 'numeric', // numeric, 2-digit
          year: 'numeric', // numeric, 2-digit
          month: 'long', // e.g., March
        })
        let index = 1
        let transactions = []

        this.selectedTransactions.forEach( (x) => {
          transactions.push([
              x.date,
              x.type,
              index + ' / ' + x.journalNum,
              x.asset_type,
              x.prettyAmount,
            ])
            transactions.push(['', '', (x.description ? (x.description.length > 50 ? x.description.substring(0,40) + '...': x.description) : ''), '', ''])
          index++
        })


        pdf.setDrawColor(0,0,0);
        pdf.setFontSize(13).text("Receipt of Selected Transactions for " + this.member.firstName + " " + this.member.lastName, .5, .5)
        pdf.setFontSize(10).text("(" + transactions.length + " entries)", .5, .7)
        pdf.setFontSize(10).text("Date: " + date, .5, .9)
        autoTable(pdf, { // put bold on number amounts
          startY: 1.3,
          theme: 'plain',
          headStyles: {fontStyle: 'bold', fontSize: 11},
          styles: {
            cellPadding: .02
          },
          columnStyles: { 4: {halign: 'right'}},
          head: [[
            {content: "Date"},
            {content: "Type"},
            // {content: "Memo"},
            {content: "Entry / Journal"},
            {content: "Asset"},
            {content: "Amount", styles: {halign: 'right'}},

          ]],
          body: [
              ...transactions
          ],
          willDrawCell: function(data) {
            if ((data.row.index % 2) === 1) {
              pdf.setTextColor(128,128,128 )
              pdf.setFontSize(8)
            }
            else{
              pdf.setTextColor(0,0,0 )
              pdf.setFontSize(10)
            }
          },
          didDrawCell: function(data) {
            if (data.section === 'head' && data.column.index === 0) {
              pdf.setDrawColor(0,0,0);
              pdf.setLineWidth(.0125)
              pdf.line(data.cursor.x, data.cursor.y + data.table.getHeadHeight(data.table.columns), data.cursor.x + data.table.getWidth(8.5), data.cursor.y + data.table.getHeadHeight(data.table.columns))
            }

          },

        })

        if(this.printSignatureLines){
          autoTable(pdf, { // put bold on number amounts
            theme: 'plain',
            pageBreak: 'avoid',
            headStyles: {fontStyle: 'normal', fontSize: 11, cellPadding: {top: 1}},
            styles: {
              cellPadding: .02
            },
            head: [[
              {content: "__________________"},
              {content: "__________________"},
              {content: "__________________"},
              {content: "__________________"},
            ]],
            body: [
                ["Teller", "Witness", "Member", "Identification"]
            ]
          })
        }

        const pageCount = pdf.internal.getNumberOfPages();
        for(let i = 1; i < pageCount+1; i++) {
          pdf.setPage(i);
          autoTable(pdf,{ // put bold on number amounts
            startY: .2,
            theme: 'plain',
            margin: 0,
            headStyles: {fontStyle: 'normal', halign: 'right'},
            columnStyles: { 0: {halign: 'right'}},
            head: [[
              {content: "Page " + i + " of " + pageCount},
            ]],
          })
        }

        if (print) {
          let string = pdf.output("bloburi", {filename: 'Receipt'})
          let x = window.open();
          x.document.open();
          x.document.location=string;
          this.loadingPDF = false;
        } else {
          const pdfBlob = new Blob([pdf.output('blob')]);
          const formData = new FormData();
          formData.append('pdf', pdfBlob, 'upma_transaction_receipt.pdf');
          formData.append('username', this.member.email);
          formData.append('email_title', 'Transaction Receipt');

          this.$axios.post('/email_pdf', formData, {
            headers: {
              'Content-Type': 'multipart/form-data',
            }
          }).then(response => {
            this.$refs.snackbar.showSnackbar({
              text: response.data.message,
              color: 'success'
            });
            this.loadingPDF = false;
            this.printConfigDialog = false;
          }
          ).catch(({response}) => {
            this.$refs.errorDialog.showError({

              errorMessage: response.data.message,
              forDevelopers: response.data.validationErrors
            });
          });
        }
      },

      rowClick: function (item) {
        this.link = item;
      },
    },
  }
</script>

<style>

@media print {
  header { font-weight: bold }
  body * { visibility: hidden; margin-top:0 !important }
  .div2 * { visibility: visible;  margin: 0 !important; width: 100% !important }
  .div2 { position: absolute; left: 0px; margin: 0 !important }
}
@page { margin-left: .5in; margin-top: .2in; margin-right: .5in }

</style>