<template>
  <div>
    <Cards/>
    <ErrorDialog/>
  </div>
</template>

<script>
  import Cards from "../components/reports/Cards";
  import ErrorDialog from "../components/ErrorDialog";
  export default {
    name: "ReportsCards",
    components: {
      Cards,
      ErrorDialog
    },
  }
</script>

<style scoped>

</style>

