<template>
  <v-card-text class="my-4 text-center trust" :class="{'mbl':$vuetify.breakpoint.xs, 'topMargin': !noTopMargin}">
    <div class="font-lg" :style="`font-size: ${scale * 2.1}rem`">{{ title }}</div>
    <div class="font-md mt-1" :style="`font-size: ${scale * 1.4}rem`">{{ subtitle1 }}</div>
    <div class="font-sm" :style="`font-size: ${scale * 1}rem`">{{ subtitle2 }}</div>
  </v-card-text>
</template>

<script>
export default {
  name: "TrustTitle",
  props: ['noTopMargin', 'subtitle1', 'subtitle2', 'title'],
  computed: {
    scale(){// if mobile, adjust font, but only for screen, not print
      let scale = 1;
      if (this.$vuetify.breakpoint.name === 'xs') scale = .6;
      return scale;
    },
  }
}
</script>

<style scoped>
  .trust * {
    color: rgba(0,0,0,0.65) !important;/* for light font */
    font-family: 'Cinzel', serif;
    line-height: .9;
  }
  @media screen {
    .topMargin { padding-top: 80px } /* add extra top margin for some title headings */
  }
  @media print
  {
    .font-lg { font-size: 2.1rem !important }
    .font-md { font-size: 1.4rem !important }
    .font-sm { font-size: 1.0rem !important }
    .mbl { margin-top: -100px }
    .trust { margin-top: -60px !important; padding-top: 0 !important }
  }
</style>