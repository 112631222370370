<template>
  <div class="px-5 py-2">
    <div v-if="allowedComponents.includes('Holdings')">
      <v-list-item-icon>
        <v-icon>
          mdi-information
        </v-icon>
        Starting 2/12/2022 Member Holdings became Holdings and now includes all admin accounts except for blackbox@upma.org and upma@upma.org.
      </v-list-item-icon>
      <v-card class="elevation-4 mt-8">
        <v-col>
          <v-row class="px-1 mt-n3" justify="space-around">
            <v-text-field v-model="search" append-icon="mdi-magnify" label="Search"
                        clearable single-line hide-details class="mx-4 mb-2"
            ></v-text-field>
            <v-btn color="primary"
                   @click="getSettledVaulted(1)"
                   justify="right"
                   class="mt-4 mr-4">Generate Temp Snapshot</v-btn>
          </v-row>
        </v-col>
        <v-progress-linear v-if="this.pendingState === 'loading'"
                           indeterminate
                           color="primary"
        />
        <v-data-table
            @click:row="seeTransactions"
            fixed-header
            :headers="vaultedTableHeaders"
            height="calc(100vh - 220px)"
            hide-default-footer
            :items-per-page="-1"
            :items="vaultSnaps"
            :search="search"
            style="cursor: pointer"
        >
<!--            height="65vh"-->
          <template v-for="field in columns" v-slot:[`item.${field}`]="{ item }">
            <span :key="field">
              <span v-if="field.includes('difference')">
                <span v-if="parseFloat(item[field]) > 0" style="color: green">+{{item[field].replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,") }}</span>
                <span v-if="parseFloat(item[field]) < 0" style="color: red" >{{item[field].replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,") }}</span>
                <span v-if="parseFloat(item[field])===0" style="color: grey" >0.00</span>
<!--                <span v-if="parseFloat(item[field])===0" style="color: grey" >{{ item[field].replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,") }}</span>-->
              </span>
              <span v-if="field.includes('snap')">{{ mixin_UTCToPretty(item[field]) }}</span>
              <span v-if="!field.includes('snap') && !field.includes('difference')">
                {{ item[field].replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,") }}</span>
            </span>
          </template>
        </v-data-table>
      </v-card>
      <member-transactions-detail v-bind="{ endTimestamp, startTimestamp, transactionsBetween }" ref="memberTransactionsDetail"/>
      <ErrorDialog ref="errorDialog"/>

    </div>
  </div>
</template>

<script>
import ErrorDialog from "@/components/ErrorDialog";
import MemberTransactionsDetail from "@/components/reports/MemberTransactionsDetail";

export default {

  name: "Holdings",
  components: {ErrorDialog, MemberTransactionsDetail},
  data: () => ({
    allowedComponents: JSON.parse(localStorage.getItem('allowedComponents') || ''),
    endTimestamp: '',
    search: '',
    startTimestamp: '',
    transactionsBetween: [],
    vaultSnaps: [],
    pendingState: 'display',
    vaultedTableHeaders: [
      { align:'start', value: 'snap',                      text: 'Snapshot Timestamp', width: '220px'},
      { align: 'end',  value: 'paper_vault',               text: 'Paper' },
      { align: 'end',  value: 'paper_vault_difference',    text: 'Δ' },
      { align: 'end',  value: 'goldDollarVault',           text: 'Gold' },
      { align: 'end',  value: 'gold_oz_vault',             text: '(Oz)' },
      { align: 'end',  value: 'gold_oz_vault_difference',  text: 'Δ' },
      { align: 'end',  value: 'silver_oz_vault',           text: 'Silver' },
      { align: 'end',  value: 'silver_oz_vault_difference',text: 'Δ'  },
      { align: 'end',  value: 'goldback_vault',            text: 'Goldback' },
      { align: 'end',  value: 'goldback_vault_difference', text: 'Δ'  },
      { align: 'end',  value: 'goldDollarLease',           text: 'Leased GD', width: '120px' },
      { align: 'end',  value: 'gold_oz_lease',             text: 'Leased (Oz)', width: '125px' },
      { align: 'end',  value: 'gold_oz_lease_difference',  text: 'Δ'  },
      { align: 'end',  value: 'silver_oz_lease',           text: 'Leased SD', width: '110px' },
      { align: 'end',  value: 'silver_oz_lease_difference',text: 'Δ'  },
      { align: 'end',  value: 'goldback_lease',            text: 'Leased GB', width: '110px'  },
      { align: 'end',  value: 'goldback_lease_difference', text: 'Δ'  },
      { align: 'end',  value: 'goldDollarPawn',           text: 'Pawned GD', width: '120px' },
      { align: 'end',  value: 'gold_oz_pawn',             text: 'Pawned (Oz)', width: '125px' },
      { align: 'end',  value: 'gold_oz_pawn_difference',  text: 'Δ'  },
      { align: 'end',  value: 'silver_oz_pawn',           text: 'Pawned SD', width: '110px' },
      { align: 'end',  value: 'silver_oz_pawn_difference',text: 'Δ'  },
      { align: 'end',  value: 'goldback_pawn',            text: 'Pawned GB', width: '110px'  },
      { align: 'end',  value: 'goldback_pawn_difference', text: 'Δ'  },
    ],
  }),

  computed: {

    columns() {
      let hdrs = [];
      for (let hdr of this.vaultedTableHeaders) hdrs.push(hdr.value);
      return hdrs;
    },
  },

  mounted() {
    localStorage.title = 'Holdings';
  },

  methods: {

    getSettledVaulted: async function (generateTemp) {
      this.pendingState = 'loading';
      try {
        let res = await this.$axios.get(`/admin/reports/vaulted?current=${generateTemp}`);
        this.vaultSnaps = res.data;
        for (let snap of this.vaultSnaps) {
          snap.goldDollarVault = (parseFloat(snap.gold_oz_vault) * 50).toFixed(2).toString();
          snap.goldDollarLease = (parseFloat(snap.gold_oz_lease) * 50).toFixed(2).toString();
          snap.goldDollarPawn = (parseFloat(snap.gold_oz_pawn) * 50).toFixed(2).toString();
        }
      } catch (e) {
        this.$refs.errorDialog.showError({
          errorMessage: "Failed to get Vaulted.",
          forDevelopers: e
        });
      }
      this.pendingState = 'display';
    },

    getTransactions: async function () {
      this.pendingState = 'loading';
      try {
        let res = await this.$axios.get(`/admin/reports/vaulted/transactions?startTimestamp=${this.startTimestamp}&endTimestamp=${this.endTimestamp}`);
        // this.transactionsBetween = res.data;
        this.transactionsBetween = res.data.transactions;
        this.$refs.memberTransactionsDetail.toggleShown();
      } catch (e) {
        this.$refs.errorDialog.showError({
          errorMessage: "Failed to get Transactions.",
          forDevelopers: e
        });
      }
      this.pendingState = 'display';
    },
    seeTransactions: function (selected) {
      this.endTimestamp = selected.snap
      this.startTimestamp = selected.previous_snap
      this.getTransactions()
    },
  },

  created: async function() {
    await this.getSettledVaulted(0);
  },
}
</script>

<style scoped>

</style>