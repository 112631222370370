<template>
  <div>
    <div v-if="allowedComponents.includes('Trust')">
      <div class="d-flex justify-center px-0 mx-0">
        <div class="px-1 px-sm-4 pt-4" :style="{width: (['xs','sm'].includes($vuetify.breakpoint.name) ? '95% !important' : '800px')}">
          <v-card class="rounded-0 elevation-4 pa-0 ma-0 div2 trust-wrap" :class="{'pa-0 ma-0 mobile-font':$vuetify.breakpoint.xs}"
                  style="margin: auto !important; max-width: 750px; min-height: unset">

  <!--  page top buttons   -->
            <v-app-bar color="white" class="px-0 pb-3 mx-0 elevation-1 rounded-0 sticky noprint" elevate-on-scroll style="padding-top: 2px">
              <v-row class="mt-n1 closer" justify="center">
                <v-icon class="ml-n2 mr-n2 mr-sm-2" @click="infoDialog = true" color="grey" style="margin-top: 4px">mdi-information-outline</v-icon>
                <SearchMembers @memberSelected="memberSelected" small/>
                <v-checkbox class="ml-n1 mr-0 mx-sm-1" color="darkGrey" :disabled="!member.email" label="specie"
                            style="margin-top: 26px" v-model="showSpecie"></v-checkbox>
                <v-checkbox class="mx-0 mx-sm-1" @change="refresh = !refresh" color="darkGrey" :disabled="!member.email"
                            label="tools" style="margin-top: 26px" v-model="showTools"></v-checkbox>
                <span v-if="['xs','sm'].includes($vuetify.breakpoint.name)"><v-btn class="ml-0 mr-n2 ml-sm-2 mr-sm-1 mb-2"
                      style="margin-top: 14px" @click="printScreen()" color="primary" icon><v-icon>mdi-printer</v-icon></v-btn></span>
                <span v-else><v-btn class="mx-2 mt-5 mb-2" @click="printScreen()" color="primary">Print</v-btn></span>
                <span v-if="['xs','sm'].includes($vuetify.breakpoint.name)"><v-btn class="mx-n2 mx-sm-1 mb-2"
                      style="margin-top: 14px" @click="printPDF()" color="primary" icon><v-icon>mdi-file-pdf-box</v-icon></v-btn></span>
                <span v-else><v-btn class="mt-5 mb-2" @click="printPDF()" color="primary">Print PDF</v-btn></span>
              </v-row><br>
            </v-app-bar>

  <!--  card 1  -->
            <v-card class="rounded-0 elevation-0 mx-0" justify="center">
              <v-card-text class="px-3 px-sm-8 pt-5 pb-12" :class="{'mobile-font':$vuetify.breakpoint.xs}" :key="refresh + Math.random()">
                <TrustTitle :noTopMargin="true" :subtitle2="dateAs" :title="'Trust Declarations'"/>
                <v-row><v-col cols="4">I. TRUST NAME:</v-col><v-col cols="8">{{ getTrustName() }}</v-col></v-row>
                <v-row>
                  <v-col cols="4">II. TRUSTEE:</v-col>
                  <v-col cols="8" v-if="Object.entries(account).length > 0">
                    {{ account.firstName }} {{ account.lastName }}<br>
                    {{ account.address }} {{ account.zipCode }}<br>
                    {{ account.username }}<br>
                    {{ account.cellPhone.replace(/(\d{3})(\d{3})(\d{4})/,'($1) $2-$3') }}
                  </v-col>
                </v-row>
                <v-row>
                  <v-col cols="4">III. CO-TRUSTEE:</v-col><v-col cols="8">Legal Tender Services PLLC,<br>333 S. Main St., Alpine, UT 84004</v-col>
                </v-row>
                <v-row>
                  <v-col cols="4">IV. PARTNERS:</v-col>
                  <v-col cols="8">
                    <span v-if="!partners.length">None</span>
                    <span v-else><div v-for="p in partners" :key="p.date">{{p.first}} {{p.last}}</div></span>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col cols="4">V. BENEFICIARIES:</v-col>
                  <v-col cols="8">
                    <span v-if="!beneficiaries.length">None</span>
                    <v-row class="justify-space-between" :key="b.created" style="margin: -12px 0 -23px -12px !important"
                           v-else v-for="b in beneficiaries">
                      <v-col cols="8">{{b.first}} {{b.last}}<span v-if="b.special_needs"> (special needs)</span></v-col>
                      <v-col cols="1"></v-col>
                      <v-col cols="2" v-if="b.share === null" class="mx-0 px-0" style="text-align: end">pro-rata</v-col>
                      <v-col cols="2" v-else class="mx-0 px-0 text-right">{{ b.share }}%</v-col>
                    </v-row>
                    <div style="height: 14px" v-if="beneficiaries.length"></div><!-- extra space needed to match -->
                  </v-col>
                </v-row>
                <v-row>
                  <v-col cols="4">VI. AGENTS:</v-col>
                  <v-col cols="8">
                    <span v-if="!agents.length">None</span>
                    <span v-else><div v-for="a in agents" :key="a.created" class="mt-n1 mb-1">{{a.first}} {{a.last}}</div></span>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col cols="4" class="pb-0 mb-3">VII.  WORDINGS:</v-col>
                </v-row>

  <!--  card 1 - default terms & all active addendum titles for this user-click to edit (default terms not editable)-->
                <div class="ml-8 mb-2" :key="Math.random() + addendum + refresh" v-for="addendum in defaultTerms">
                  <span class="ml-n2">{{addendum.title}}</span>
                </div><br>
                <h4 class="ml-6 mb-2" v-if="addenda.length">ADDENDA:</h4>
                <div class="ml-8 mb-2" :key="Math.random() + addendum + refresh" v-for="addendum in addenda">
                  <span class="ml-n2" @click="editAddenda(addendum)" style="cursor: pointer">{{addendum.title}}</span>
                </div>
              </v-card-text>

  <!--  card 1  -- signature block    -->
              <v-container class="py-4 grid-list-xl" :class="$vuetify.breakpoint.name === 'xs' ? 'px-5' : 'px-12'"
                           style="break-inside: avoid">
                <v-layout class="mt-5">
                  <v-flex xs8 style="height: 80px">
                    <div class="sig-line">
                    <span v-if="account.firstName || account.lastName" :class="$vuetify.breakpoint.name === 'xs' ?
                      'sig-mobile' : 'sig'">{{ account.firstName }} {{ account.lastName }}</span>
                      <span v-else :class="$vuetify.breakpoint.name === 'xs' ? 'sig-mobile' : 'sig'">
                      Name on account</span>
                    </div>
                    <span>Trustee</span>
                  </v-flex>
                  <v-flex xs4 style="height: 80px">
                    <div class="sig-line sig-date">
                      <span v-if="account.sign_up">{{ account.sign_up }}</span>
                      <span v-else>Sign-up date</span>
                    </div>
                    <span>Date</span>
                  </v-flex>
                </v-layout>
                <v-layout class="pb-3 justify-center">
                  <v-flex xs8>
                    <div class="sig-line" :class="$vuetify.breakpoint.name === 'xs' ? 'sig-mobile' : 'sig'">Lawrence D. Hilton</div>
                    <span>Legal Tender Services, Co-Trustee</span>
                  </v-flex>
                  <v-flex xs4>
                    <div class="sig-line sig-date">
                      <span v-if="account.sign_up">{{ account.sign_up }}</span>
                      <span v-else>Sign-up date</span>
                    </div>
                    <span>Date</span>
                  </v-flex>
                </v-layout>
              </v-container>
            </v-card>

            <!--  card 2 - assets (& asset tools) from addendum -->
            <v-card class="rounded-0 elevation-0 px-2 px-sm-5 mx-0 mt-1"
                    :key="refresh + Math.random() + allAssets + indeterminateAssets"
                    style="break-before: page" v-if="showSpecie || showTools || allAssets || indeterminateAssets">
              <TrustTitle :subtitle1="getTrustName()" :subtitle2="dateAs" :title="'Trust Assets'"/>

              <v-card-text class="pb-3 mt-n7" :class="{'mobile-font':$vuetify.breakpoint.xs}">
                <v-container class="mx-0 px-3 pb-8" v-if="showSpecie">
                  <v-row class="mt-n2 mb-n4 asset-table-print-padding">
                    <v-col cols="3"><b>Currencies</b></v-col>
                    <v-col cols="3" class="text-right"><b>Vaulted</b></v-col>
                    <v-col cols="3" class="text-right"><b>Leased</b></v-col>
                    <v-col cols="3" class="text-right"><b>Pawned</b></v-col>
                  </v-row>
                  <span v-if="accts['Gold Dollar']">
                    <v-row class="mb-n4 asset-table-print-padding" v-for="type in species" :key="type + Math.random()">
                      <v-col cols="3">{{ type }}s</v-col>
                      <v-col cols="3" class="text-right">{{ accts[type].vaulted }}</v-col>
                      <v-col cols="3" class="text-right">{{ accts[type].leased }}</v-col>
                      <v-col cols="3" class="text-right">{{ accts[type].pawned }}</v-col>
                    </v-row>
                  </span>
                </v-container>

                <!--  card 2 - tools for addenda assets  -->
                <v-row class="ml-n7 mb-n10 addenda-print-padding closer" style="margin-top: -26px"
                       v-if="showTools&& account.username !== usernameDefaultTerms">
                  <button-tip btnclass="mt-4 mr-n1 primary" btnstyle="margin-right: -2px; transform: scale(.6)"
                              @click="createAddendum(true)" elevation="5" fab icolor="white" iname="mdi-plus"
                              tip="Add Assets" xsmall/>
                  <v-checkbox @change="toggleChecks(true)" class="mx-n1" color="darkGrey" :indeterminate="indeterminateAssets"
                              :key="indeterminateAssets + Math.random()" label="All/None" v-if="addendaAssets.length"
                              v-model="allAssets"/>
                </v-row>
                <div class="addenda-print-padding" :key="Math.random() + addendum + refresh" v-for="addendum in addenda">
                  <div :class="showTools ? 'ml-1' : 'ml-10'" v-if="addendum.asset && (addendum.print || showTools)">
                    <v-row class="mt-5">
                      <v-icon class="ml-n4 mr-n1 mr-sm-0 mb-sm-n1" @click="deactivateAddendum(addendum)"
                              style="margin-top: -6px; margin-bottom: -6px" v-if="showTools">mdi-trash-can-outline</v-icon>
                      <v-checkbox class="ml-sm-n1 mb-n6" @change="printChangeHandler(true)" color="darkGrey"
                                  v-if="showTools" v-model="addendum.print" style="margin-top: -6px"/>

                      <!--  card 2 - addenda assets titles & bodies - click title to edit title & body (only selected will show if tools are off) -->
                      <h4 :class="showTools ? 'ml-n2' : 'ml-n3 ml-sm-n5'" @click="editAddenda(addendum)"
                            style="cursor: pointer" v-if="addendum.asset">{{addendum.title}}</h4>
                    </v-row>
                    <v-row :class="showTools ? 'ml-7' : 'ml-n2'" class="pb-2 mr-4 mb-2" v-html="addStyle(addendum.body)" v-if="addendum.body !== 'Type here...'"/>
                  </div>
                </div>
              </v-card-text>
            </v-card>

  <!--  card 3 --Terms--->
            <v-card class="rounded-0 elevation-0 px-2 px-sm-5 mx-0"
                    :key="refresh + Math.random() + allDefaultTerms + indeterminateDefaultTerms"
                    style="break-before: page" v-if="showTools || allDefaultTerms || indeterminateDefaultTerms">
              <TrustTitle :subtitle1="getTrustName()" :subtitle2="dateAs" :title="allDefaultTerms ? 'Trust Terms' : 'Selected Trust Terms'"/>

  <!--  card 3 - tools for default terms  -->
              <v-card-text class="ml-n3 mt-n7 addenda-print-padding" :class="{'mobile-font':$vuetify.breakpoint.xs}">
                <v-row class="closer" v-if="showTools"
                       :style="{margin: (['xs'].includes($vuetify.breakpoint.name) ? '-27px 0 -38px 8px' : '-27px 0 -38px 7px')}">
                  <v-checkbox @change="toggleChecksForDefaultTerms()" color="darkGrey" :indeterminate="indeterminateDefaultTerms"
                              :key="indeterminateDefaultTerms + Math.random()" label="All/None" v-if="defaultTerms.length"
                              v-model="allDefaultTerms"/>
                </v-row>
                <div class="addenda-print-padding" :key="Math.random() + addendum + refresh" v-for="addendum in defaultTerms">
                  <div v-if="addendum.print || showTools">
                    <div class="mt-5">

  <!--  card 3 - default terms addenda titles & bodies (only selected will show if tools are off) -->
                      <h4 :class="showTools ? 'ml-n2' : 'ml-9 ml-sm-7'" v-if="!addendum.asset">
                        <v-row>
                        <v-checkbox @change="printChangeHandlerForDefaultTerms()" color="darkGrey" v-if="showTools"
                                    v-model="addendum.print"
                                    :style="{margin: (['xs'].includes($vuetify.breakpoint.name) ? ' -7px -9px -34px 28px' : ' -7px -9px -34px 27px')}"/>
                        {{addendum.title}}
                      </v-row>
                      </h4>
                    </div>
                    <div class="pb-2 ml-11 mr-4 mb-2" v-html="addStyle(addendum.body)" v-if="addendum.body !== 'Type here...'"/>
                  </div>
                </div>
              </v-card-text><br>
            </v-card>

  <!--  card 4 --Addenda terms--->
            <v-card class="rounded-0 elevation-0 px-2 px-sm-5 mx-0 mt-1"
                    :key="refresh + Math.random() + allTerms + indeterminateTerms"
                    style="break-before: page" v-if="showTools || allTerms || indeterminateTerms">
              <TrustTitle :subtitle1="getTrustName()" :subtitle2="dateAs" :title="'Trust Addenda'"/>

  <!--  card 4 - tools for addenda terms  -->
              <v-card-text class="pb-3 mt-n7 addenda-print-padding" :class="{'mobile-font':$vuetify.breakpoint.xs}">
                <v-row class="ml-n7 mb-n10 closer" style="margin-top: -26px" v-if="showTools">
                  <button-tip btnclass="mt-4 mr-n1 primary" btnstyle="margin-right: -2px; transform: scale(.6)"
                              @click="createAddendum(false)" elevation="5" fab icolor="white" iname="mdi-plus"
                              tip="Add Terms" xsmall/>
                  <v-checkbox @change="toggleChecks(false)" class="mx-n1" color="darkGrey" :indeterminate="indeterminateTerms"
                              :key="indeterminateTerms + Math.random()" label="All/None" v-if="addendaTerms.length"
                              v-model="allTerms"/>
                </v-row>
                <div class="addenda-print-padding" :key="Math.random() + addendum + refresh" v-for="addendum in addenda">
                  <div :class="showTools ? 'ml-1' : 'ml-10'" v-if="!addendum.asset && (addendum.print || showTools)">
                    <v-row class="mt-5">
                      <v-icon class="ml-n4 mr-n1 mr-sm-0 mb-sm-n1" @click="deactivateAddendum(addendum)"
                              style="margin-top: -6px; margin-bottom: -6px" v-if="showTools">mdi-trash-can-outline</v-icon>
                      <v-checkbox class="ml-sm-n1 mb-n6" @change="printChangeHandler(false)" color="darkGrey"
                                  v-if="showTools" v-model="addendum.print" style="margin-top: -6px"/>

  <!--  card 4 - addenda terms titles & bodies - click title to edit title & body (only selected will show if tools are off) -->
                      <h4 :class="showTools ? 'ml-n2' : 'ml-n3 ml-sm-n5'" @click="editAddenda(addendum)"
                            style="cursor: pointer" v-if="!addendum.asset">{{addendum.title}}</h4>
                    </v-row>
                    <v-row :class="showTools ? 'ml-7' : 'ml-n2'" class="pb-2 mr-4 mb-2" v-html="addStyle(addendum.body)" v-if="addendum.body !== 'Type here...'"/>
                  </div>
                </div>
              </v-card-text>
            </v-card>
          </v-card><br><br>

          <!--   dialogs    -->
          <v-dialog v-model="infoDialog" width="680">
            <v-card class="px-3 pt-1 info-font" :class="{'mobile-info-font':$vuetify.breakpoint.xs}" style="line-height: 1">
              <v-row>
                <div class="pl-4"><v-card-title class="headline">Trust</v-card-title></div>
                <v-spacer/>
                <v-btn class="mt-3 mr-4" @click="infoDialog = false" icon style="margin-top: 13px">
                  <v-icon>mdi-close</v-icon>
                </v-btn>
              </v-row>
              <div class="px-4 pt-4 pb-0"><strong>After you select a member, you can:</strong></div>
              <ul>
                <div class="px-5 pb-1"><li>Check "specie" to show specie holdings in the assets section.</li></div>
                <div class="px-5 pb-1"><li>Check "tools" to add, edit, delete and select addenda.</li></div>
                <div class="px-5 pb-1"><li>Wordings, if there are any, will show Preamble, if any, followed by remaining terms in alphanumeric order.</li></div>
                <div class="px-5 pb-1"><li>Addenda, if there are any, will show in the order created/edited.</li></div>
                <div class="px-5 pb-1"><li>Click on an addendum title to open editor.</li></div>
              </ul>
              <div class="px-4 pt-6 pb-0"><strong>After you check the "tools" box, you can:</strong></div>
              <ul>
                <div class="px-5 pb-0 my-n1"><li>Click <v-btn class="mx-n1" color="primary" fab style="transform: scale(.7)" x-small><v-icon>mdi-plus</v-icon></v-btn> to create a new addendum.</li></div>
                <div class="px-5 pb-0"><li>Click <v-icon>mdi-trash-can-outline</v-icon> to delete an addendum.</li></div>
                <div class="px-5 pb-0"><li>Click <v-icon>mdi-checkbox-blank-outline</v-icon> to select an addendum to show and print.</li></div>
                <div class="px-5 pb-1"><li>Toggle "All/None" to select all or none of that addenda section to show/print.</li></div>
              </ul>
              <div class="px-4 pt-6 pb-0"><strong>Printing:</strong></div>
              <ul>
                <div class="px-5 pb-2"><li>"PRINT" - Be sure "tools" is unchecked and select default margins for best
                  results. Also, please be aware that the Firefox browser does not currently support widow/orphan handling.</li></div>
                <div class="px-5 pb-2"><li>"PRINT PDF" - Available in the Firefox browser.  Please note that some
                  formatting issues, such as lines being split at page breaks, may occur.  Also, the document may take
                  several seconds to load.
                </li></div>
              </ul><br>
              <div class="px-4 pb-2">If no assets are selected and "specie" is not checked, ASSETS section will not print (or show with tools off).</div>
              <div class="px-4 pb-2">If no terms are selected, TERMS section will not print (or show with tools off).</div>
              <div class="px-4 pb-2">If no addenda are selected, ADDENDA section will not print (or show with tools off).</div>
              <v-card-actions class="px-2 pb-4">
                <v-spacer/>
                <v-btn color="primary" @click="infoDialog = false">Got it!</v-btn>
              </v-card-actions>
            </v-card>
          </v-dialog>
          <ErrorDialog ref="errorDialog"/>
          <TrustEditorDialog :addendum="addendumProp" ref="TrustEditorDialog" :username="member.email"/>
        </div>
      </div>

    </div>
    <div v-else class="pl-12 pr-12 pt-12 mt-12 headline text-center">Sorry, this page is not available.</div>
  </div>
</template>

<script>
  // @ is an alias to /src
  import autoTable from "jspdf-autotable";
  import ErrorDialog from "@/components/ErrorDialog";
  import jspdf from "jspdf";
  import SearchMembers from "@/components/SearchMembers";
  import TrustEditorDialog from "@/components/reports/TrustEditorDialog";
  import TrustTitle from "@/components/reports/TrustTitle";
  import { font } from "@/font";//Cinzel font - fancy for headings
  import { font2 } from "@/font2";//Great Vibes font - cursive for signatures
  import _ from 'lodash';

  export default {
    name: 'Trust',
    components: { ErrorDialog, SearchMembers, TrustEditorDialog, TrustTitle },
    data: () => ({

      //--------- Toggle Variables ---------//

      addendumProp: false,
      allAssets: false,// are all the addendaAssets selected?
      allDefaultTerms: false,// are all the defaultTerms selected?
      allTerms: false,// are all the terms selected?
      dialog: false,
      indeterminateAssets: false,
      indeterminateDefaultTerms: false,
      indeterminateTerms: false,
      infoDialog: false,
      refresh: false,
      showSpecie: false,
      showTools: false,

      //--------- Data Variables ---------//

      account: {},
      accounts: {},
      accts: [],
      addenda: [],// assets and terms specific to selected member
      addendaAssets: [],// all user addenda with asset property = true
      addendaTerms: [],// all user addenda with asset property = false
      addendaUuidsToPrint: [],
      agents: [],
      allowedComponents: JSON.parse(localStorage.getItem('allowedComponents') || ''),
      beneficiaries: [],
      body: '',
      defaultTerms: [],// terms for all members
      defaultTermsUuidsToPrint: [],
      editorText: '',
      markdownWordings: '',
      member: {},
      partners: [],
      species: ['Paper Dollar','Silver Dollar','Gold Dollar','Goldback'],
      test: '',
      usernameDefaultTerms: 'lts@upma.org'
    }),

    computed: {
      dateAs() {// Ex: 'As of May 21, 2022'
        return `As of
        ${ new Date().toLocaleString('default', { month: 'long' })}
        ${ new Date().getDate() },
        ${ new Date().getFullYear() }`;
      },
    },

    watch: {
      defaultTerms: {
        handler() {
          this.printChangeHandlerForDefaultTerms();// update which defaultTerms to show/print after defaultTerms changes
        },
        deep: true,
      }
    },

    mounted() {
      localStorage.title = 'Trust';
      this.getAddendumWithDefaultTerms();
    },

    methods: {
      addStyle(html) {
        return '<style>table {border: 1px solid #b3b3b3; border-collapse: collapse} td {border: .8px solid #b3b3b3;min-width: 2em;padding: 0.4em} li {padding-top: 16px !important} p {margin-top: 0 !important; margin-bottom: 0 !important} blockquote {border-left: 3px solid #ccc; font-style: italic; margin-top: 7px; margin-left: 0; padding-left: 20px}</style><div>' +  html + '</div>';
      },

      createAddendum: async function (asset) {// post to create new addendum
        const data = {
          username: this.member.email,
          title: `Click here to edit ${asset ? 'assets' : 'terms'} title and body...`,
          body: 'Type here...',
          asset,
        };
        try {
          await this.$axios.post('/admin/trust/addenda', data);
          this.getAddendum(this.member.email, asset);// send asset because All/None checks might need updating
        } catch (e) {
          this.$emit('showErrorDialog', {
            errorMessage: "There was a problem saving the addendum changes.",
            forDevelopers: e
          });
        }
      },

      deactivateAddendum: async function (addendum) {// patch to expire addendum
        if (confirm('Are you sure you want to DELETE this addendum?')) {
          try {
            let data = { uuid: addendum.uuid, title: null, body: null } //null title and body to just expire the addendum
            await this.$axios.patch('/admin/trust/addenda', data);
            this.getAddendum(this.member.email,addendum.asset);
          } catch (e) {
            this.$emit('showErrorDialog', {
              errorMessage: "There was a problem saving the addendum changes.",
              forDevelopers: e
            });
          }
        }
      },

      editAddenda(addendum) {
        this.addendaUuidsToPrint = [];
        for (let a of this.addenda) if (a.print) this.addendaUuidsToPrint.push(a.uuid);// save which uuids to print, to reset after getting addenda again.
        this.addendumProp = addendum;
        this.$refs.TrustEditorDialog.toggleShown();
      },

      getAccount: async function (member) {
        try {
          if (!member.email) {
            this.$refs.errorDialog.showError({
              errorMessage: 'There was a problem selecting the accounts for a user. No user was selected prior to attempting to fulfill the request.',
              forDevelopers: `Got a falsy value for user email. The value was: ${member.email} .`
            })
          }
          let res = await this.$axios.get(`/account?username=${member.email}`);
          this.account = res.data;
          // format cellphone number
          this.account.cellPhone = this.account.cellPhone.replace(/(\d{3})(\d{3})(\d{4})/,'($1) $2-$3');
          this.refresh = !this.refresh;
        } catch (e) {
          this.$refs.errorDialog.showError({
              errorMessage: `There was a problem trying to get the accounts for the selected user with the email of ${member.email}. If the problem persists please let the development team know.`,
              forDevelopers: e
          });
        }
      },

      getAccounts: async function (member) {
        try {
          if (!member.email) {
            this.$refs.errorDialog.showError({
              errorMessage: 'There was a problem selecting the accounts for a user. No user was selected prior to attempting to fulfill the request.',
              forDevelopers: `Got a falsy value for user email. The value was: ${member.email} .`
            })
          }
          let res = await this.$axios.get(`/admin/user/balances?username=${member.email}`);
          this.accounts = res.data;
          for (let type of this.species) {// initialize values for the array used to display account amounts by specie
            this.accts[type] = {};
            this.accts[type].vaulted = '0.00';
            this.accts[type].leased  = '0.00';
            this.accts[type].pawned  = '0.00';
          }
          for (let a of this.accounts.accountBreakdowns) {
            // Inside each account is totalHoldings, leased, pawned. Vaulted is totalHoldings minus leased and pawned.

            // 1. calculate/format leased, pawned, and vaulted values to display as shown below:
            // Currencies   Vaulted        Leased       Pawned
            // Paper         123.33          0.00         0.00
            // Silver      1,455.55     24,533.31         2.22
            // Gold          432.55      1,233.31       888.22
            // Goldbacks       9.90      1,233.31        22.54

            a.leased = a.leased.replace(/\$/g, '');// remove $
            a.leasedNoCommas = a.leased.replace(/,/g, '');// remove commas
            a.pawned = a.pawned.replace(/\$/g, '');
            a.pawnedNoCommas = a.pawned.replace(/,/g, '');
            a.totalHoldings = a.totalHoldings.replace(/\$|,/g, '');// remove $ and commas
            //vaulted = holdings - (leased + pawned), then convert to string and add in commas
            a.vaulted = (parseFloat(a.totalHoldings) - (parseFloat(a.leasedNoCommas) + parseFloat(a.pawnedNoCommas))).toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ',') ;

            // 2. assign the values based on assetType to this.accts - to control order accounts are listed in
            this.accts[a.assetType].vaulted = a.vaulted;
            this.accts[a.assetType].leased = a.leased;
            this.accts[a.assetType].pawned = a.pawned;
          }
          this.refresh = !this.refresh;
        } catch (e) {
          // this.$refs.errorDialog.showError({
          //     errorMessage: `There was a problem trying to get the accounts for the selected user with the email of ${member.email}. If the problem persists please let the development team know.`,
          //     forDevelopers: e
          // });
        }
      },

      getAddendum: async function (username, asset) {
        this.addendaUuidsToPrint = [];
        for (let a of this.addenda) if (a.print) this.addendaUuidsToPrint.push(a.uuid);// save which uuids to print, to reset after getting addenda again.
        try {
          if (!username) {
            this.$refs.errorDialog.showError({
              errorMessage: 'There was a problem selecting the accounts for a user. No user was selected prior to attempting to fulfill the request.',
              forDevelopers: `Got a falsy value for user email. The value was: ${username} .`
            })
          }
          let inactive = 0;// don't get inactive
          let filter = 'all';// assets, non-assets, or all
          let res = await this.$axios.get(`/admin/trust/addenda?username=${username}&inactive=${inactive}&filter=${filter}`);
          this.addenda = res.data;
          this.addendaTerms = [];
          this.addendaAssets = [];
          for (let a of this.addenda) {
            a.print = this.addendaUuidsToPrint.includes(a.uuid);// reset the .print property of each addendum based on saved array of addendaUuidsToPrint
            a.asset ? this.addendaAssets.push(a) : this.addendaTerms.push(a);// sort them to easily check if displaying All/None checkbox
          }
          this.$nextTick(() => {if (asset !== undefined) this.printChangeHandler(asset)});// All/None checks might need updating
          this.refresh = !this.refresh;
        } catch (e) {
          this.$refs.errorDialog.showError({
              errorMessage: `There was a problem trying to get the addenda for the selected user with the email of ${username}. If the problem persists please let the development team know.`,
              forDevelopers: e
          });
        }
      },

      getAddendumWithDefaultTerms: async function () {
        let username = this.usernameDefaultTerms;// get default trust terms from usernameDefaultTerms
        try {
          let inactive = 0;
          let filter = 'non-assets';// assets, non-assets, or all
          let res = await this.$axios.get(`/admin/trust/addenda?username=${username}&inactive=${inactive}&filter=${filter}`);
          this.defaultTerms = res.data;
          if (this.defaultTerms.length) {
            this.defaultTerms = _.sortBy( this.defaultTerms, x => x.title.toUpperCase());//sort alphanumerically by title
            if (this.defaultTerms.findIndex(x => x.title.toUpperCase() === 'PREAMBLE') !== -1) {// if there is a Preamble, move it to the top of the list
              let preambleIndex = this.defaultTerms.findIndex(x => x.title.toUpperCase() === 'PREAMBLE');
              let preamble = this.defaultTerms.find(x => x.title.toUpperCase() === 'PREAMBLE');// save preamble to add to beginning of array
              this.defaultTerms.splice(preambleIndex, 1);// remove preamble from end of array
              this.defaultTerms.unshift(preamble);// now add to beginning of array
            }
            this.refresh = !this.refresh;
          }
        } catch (e) {
          this.$refs.errorDialog.showError({
              errorMessage: `There was a problem trying to get the addenda (with the default trust terms) for the user with the email of ${username}. If the problem persists please let the development team know.`,
              forDevelopers: e
          });
        }
      },

      getRelationships: async function (member) {
        try {
          if (!member.email) {
            this.$refs.errorDialog.showError({
              errorMessage: 'There was a problem selecting the accounts for a user. No user was selected prior to attempting to fulfill the request.',
              forDevelopers: `Got a falsy value for user email. The value was: ${member.email} .`
            })
          }
          let res = await this.$axios.get(`/relationships?username=${member.email}`);
          res.data = _.sortBy(res.data, x => x.index);//sort numerically by index
          this.partners = [];
          this.agents = [];
          this.beneficiaries = [];
          for (let rel of res.data) {
            if (rel.type === 1100) this.beneficiaries.push(rel);
            if (rel.type === 1101) this.partners.push(rel);
            if (rel.type === 1105) this.agents.push(rel);
          }
        } catch (e) {
          this.$refs.errorDialog.showError({
              errorMessage: `There was a problem trying to get the accounts for the selected user with the email of ${member.email}. If the problem persists please let the development team know.`,
              forDevelopers: e
          });
        }
      },

      getTrustInfo(member) {
        this.getAddendum(member.email);
        this.getAccount(member);
        this.getAccounts(member);
        this.getRelationships(member);
      },

      getTrustName() {
        let mbr = this.member;
        let trustName = '';
        let hasTrust = !!mbr.legalName && mbr.legalName.search(/trust/i) !== -1; // has word 'trust' in legal name
        let RT = ' Revocable Trust';
        if (Object.entries(this.member).length === 0) return RT;
        trustName = mbr.legalName ? (hasTrust ? mbr.legalName : mbr.legalName + RT) : mbr.firstName + ' ' + mbr.lastName + RT;
        return trustName;
      },

      memberSelected: function (member) {
        this.member = member;
        this.showEditor = true;
        this.getTrustInfo(member);
        this.refresh = !this.refresh;
      },

      printChangeHandler(isAsset) {// updates which addenda to show and print after user checks box/es
        let countTrue = 0;
        let countFalse = 0;
        for (let addendum of this.addenda) if (addendum.asset === isAsset) addendum.print === true ? countTrue++ : countFalse++;
        isAsset ? this.allAssets = countFalse === 0 : this.allTerms = countFalse === 0;
        isAsset ? this.indeterminateAssets = countTrue !== 0 && countFalse !== 0
            : this.indeterminateTerms = countTrue !== 0 && countFalse !== 0;
      },

      printChangeHandlerForDefaultTerms() {// updates which default terms to show and print after user checks box/es
        let countTrue = 0;
        let countFalse = 0;
        for (let addendum of this.defaultTerms) addendum.print === true ? countTrue++ : countFalse++;
        this.allDefaultTerms = countFalse === 0;
        this.indeterminateDefaultTerms = countTrue !== 0 && countFalse !== 0
      },

      toggleChecks(isAsset) {// toggle all the checkboxes for assets if isAssets, otherwise terms
        for (let a of this.addenda) if (a.asset===isAsset) isAsset ? a.print = this.allAssets : a.print = this.allTerms;
        this.printChangeHandler(isAsset);
      },

      toggleChecksForDefaultTerms() {// toggle all the checkboxes for default terms
        for (let a of this.defaultTerms) a.print = this.allDefaultTerms;
        this.printChangeHandlerForDefaultTerms();
      },

      printScreen() {
        this.showTools = false;
        this.$nextTick(() => {window.print()});
      },

      printPDF() {
        const a = this.account;
        const blankLine = ['',''];
        const data = Object.entries(this.account).length > 0;
        const dateAsOf = new Date().toLocaleString('en-US', {
          day: 'numeric', // numeric, 2-digit
          year: 'numeric', // numeric, 2-digit
          month: 'long', // e.g., March
        });
        const styles = {fontSize: 12, textColor: 100, cellPadding: .02};
        const theme = 'plain';
        let accts = this.accts;
        let addendaAllTitles = [];
        let addendaAssetsToPrint = '';
        let addendaTermsToPrint = '';
        let agents = [];
        let agentFirst = 'None';
        let agentsIndex = 1;
        let bens = [];
        let benFirst = 'None';
        let benFirstShare = '';
        let bensIndex = 1;
        let date = a.sign_up ? a.sign_up : 'Sign-up date';
        let defaultTermsAllTitles = [];
        let defaultTermsToPrint = [];
        let margin = 75;
        let name = a.firstName || a.lastName ? a.firstName + ' ' + a.lastName : 'Name on account';
        let pageHeight = 792;// height of page -- need to calculate where to put Assets Addenda and Terms Addenda
        let partners = [];
        let partnerFirst = 'None';
        let partnersIndex = 1;
        let showSpecieHeight = 0;// reset this to 100 if showing specie holdings
        let specie = [];
        let styl = '<br><br><div style="padding-left: 12px"><style> .table {margin: 5px 0 5px 0} span {color: dimgrey !important} ul, ol, li {margin-left: 12px} ul {list-style-type: disc}</style>';
        let top = 60;

      // ----  prepare partners, agents, and beneficiaries for pdf   ---- //
        this.partners.forEach( (x) => {
          let name = x.first + ' ' +  x.last
          partnersIndex === 1 ? partnerFirst = name : partners.push([' ', name])
          partnersIndex++
        })
        this.agents.forEach( (x) => {
          let name = x.first + ' ' +  x.last
          agentsIndex === 1 ? agentFirst = name : agents.push([' ', name])
          agentsIndex++
        })
        this.beneficiaries.forEach( (x) => {
          let name = x.first + ' ' +  x.last
          let share = x.share === 0 || x.share === null ? 'pro-rata' : x.share + '%'
          let special = x.special_needs ? ' (special needs)' : ''
          bensIndex === 1 ? (benFirst = name + special, benFirstShare = share) : bens.push([' ', name + special, share])
          bensIndex++
        })

      // ----  prepare addenda for pdf   ---- //
        this.addenda.forEach( (x) => {// assemble addenda data
          addendaAllTitles.push([' ']);
          addendaAllTitles.push(['   ' + '   ' + x.title]);
          if (x.print && x.asset) {
            addendaAssetsToPrint = addendaAssetsToPrint + '<br>' + x.title + styl + x.body + '</div><br>';
          }
          if (x.print && !x.asset) {
            addendaTermsToPrint = addendaTermsToPrint + '<br>' + x.title + styl + x.body + '</div><br>';
          }
        })

        // ----  prepare default terms for pdf   ---- //
        this.defaultTerms.forEach( (x) => {// assemble addenda data
          defaultTermsAllTitles.push([' ']);
          defaultTermsAllTitles.push(['   ' + '   ' + x.title]);
          if (x.print && !x.asset) {
            defaultTermsToPrint = defaultTermsToPrint + '<br>' + x.title + styl + x.body + '</div><br>';
          }
        })

      // ----  pdf layout - all sections are optional except section 1: DECLARATIONS ---- //
      // section 1: DECLARATIONS heading, roman numerals, addendum titles, signature block
      // section 2: ASSETS heading, specie holdings table, assets from addenda put in HTML format
      // section 3: TERMS heading, default terms from addenda put in HTML format
      // section 4: ADDENDA heading, terms from addenda put in HTML format
      // footer on each page ex: 'Trust name  - -  page 1 of 2'

        let htmlStyle = "<div style='font-family: Helvetica, sans-serif; font-size: 12px; color: dimgrey !important; width: 462px'>"
        let htmlAssets = htmlStyle + addendaAssetsToPrint + "</div>";
        let htmlDefaultTerms = htmlStyle + defaultTermsToPrint + "</div>";
        let htmlAddendaTerms = htmlStyle + addendaTermsToPrint + "</div>";
        let pdf = new jspdf('p', 'pt', 'letter');
        pdf.addFileToVFS('Cinzel.ttf', font);
        pdf.addFont('Cinzel.ttf', 'Cinzel', 'normal');

      // ----  section 1: DECLARATIONS heading, roman numerals  ---- //
        this.printHeader('Declarations', dateAsOf, pdf, top);
        autoTable(pdf, { margin, styles, theme, startY: top + 45,
          columnStyles: { 2: { halign: 'right' } },
          body: [
            ['I. TRUST NAME: ', !data ? '' : this.getTrustName(), ' '],
            blankLine,
            ['II. TRUSTEE: ', !data ? '' : a.firstName + ' ' + a.lastName, ' '],
            ['', !data ? '' : a.address + ' ' + a.zipCode],
            ['', !data ? '' : a.username],
            ['', !data ? '' : a.cellPhone.replace(/(\d{3})(\d{3})(\d{4})/,'($1) $2-$3')],
            blankLine,
            ['III. CO-TRUSTEE: ', 'Legal Tender Services PLLC, '],
            ['', '333 S. Main St., Alpine, UT 84004 '],
            blankLine,
            ['IV. PARTNERS: ', partnerFirst],
            ...partners,
            blankLine,
            ['V. BENEFICIARIES: ', benFirst, benFirstShare],
            ...bens,
            blankLine,
            ['VI. AGENTS: ', agentFirst],
            ...agents,
            blankLine,
            ...specie,
            ['VII. WORDINGS: '],
          ],
        })

      // ----  section 1: addendum titles  ---- //
        let table2FinalY = pdf.lastAutoTable.finalY;
        autoTable(pdf, { margin, styles, theme, startY: table2FinalY + 0,
          body: [
            ...defaultTermsAllTitles,
            blankLine,
            blankLine,
            ['      ADDENDA: '],
            ...addendaAllTitles,
          ],
        })

      // ----  section 1: signature block   ---- //
        let sigLineHeight = 55.6;
        let sigLineDateStart = 369;
        let sigBlock = pdf.lastAutoTable.finalY + 100;
        if (sigBlock > 620) { // if no room for sigBlock, add new page and put sigBlock there
          pdf.addPage();
          sigBlock = 100;
        }
        pdf.setFontSize(12).text(date, sigLineDateStart, sigBlock, 'left');
        pdf.setFontSize(12).text(date, sigLineDateStart, sigBlock + sigLineHeight, 'left');
        pdf.addFileToVFS('GreatVibes-Regular-normal.ttf', font2);
        pdf.addFont('GreatVibes-Regular-normal.ttf', 'GreatVibes', 'normal');
        pdf.setFont('GreatVibes');
        pdf.setTextColor("#295491");
        pdf.setFontSize(20).text(name, margin, sigBlock, 'left');
        pdf.text('Lawrence D. Hilton', margin, sigBlock + sigLineHeight, 'left');
        autoTable(pdf, { styles, theme, startY: sigBlock - 8, margin,
          body: [
            ['_________________________________________ ', ' ________________________'],
            ['Trustee', ' Date'],
            blankLine,
            blankLine,
            ['_________________________________________ ', ' ________________________'],
            ['Legal Tender Services, Co-Trustee', ' Date'],
          ],
        })
        let endSection1pageCount = pdf.internal.getNumberOfPages();// save page count for section 1
        let vm = this;
        let areAssets = vm.showSpecie || addendaAssetsToPrint.length;// need ASSETS page?
        let areTerms = defaultTermsToPrint.length;// need TERMS page?
        let areAddenda = addendaTermsToPrint.length;// need ADDENDA page?

      // ----  section 2: ASSETS heading ---- //
        if (areAssets) {
          pdf.addPage();
          this.printHeader('Assets', dateAsOf, pdf, top);
        }

      // ----  section 2: ASSETS - specie holdings table ---- //
        if (this.showSpecie) {
          showSpecieHeight = 100; // add 100 to the y coordinate for htmlAssets, to allow room for this table
          autoTable(pdf, { styles, theme, startY: top + 65, margin,
            columnStyles: {  1: { halign: 'right' }, 2: { halign: 'right' }, 3: {halign: 'right'}},
            head: [[
              {content: "Currencies"},
              {content: "Vaulted", styles: {halign: 'right'}},
              {content: "Leased", styles: {halign: 'right'}},
              {content: "Pawned", styles: {halign: 'right'}},
            ]],
            body: [
              ['Paper Dollars', accts['Paper Dollar'].vaulted, accts['Paper Dollar'].leased, accts['Paper Dollar'].pawned],
              ['Silver Dollars', accts['Silver Dollar'].vaulted, accts['Silver Dollar'].leased, accts['Silver Dollar'].pawned],
              ['Gold Dollars', accts['Gold Dollar'].vaulted, accts['Gold Dollar'].leased, accts['Gold Dollar'].pawned],
              ['Goldbacks', accts['Goldback'].vaulted, accts['Goldback'].leased, accts['Goldback'].pawned],
            ],
          })
        }

      // -- remaining sections (2-4) are optional based on user selections and are in nested callbacks so that
      // -- printFooterAndFinishPdf() is called at the end from innermost callback, to finalize page count & output pdf

      // ----  section 2: ASSETS - assets from addenda put in HTML format  ---- //
        pdf.html( areAssets ? htmlAssets : '', { styles, html2canvas: {logging: false},
          callback: function (pdf) {
            let endSection2pageCount = areAssets ? pdf.internal.getNumberOfPages() : endSection1pageCount;// update if areAssets

      // ----  section 3: TERMS - default terms from addenda put in HTML format  ---- //
            if (areTerms) {
              pdf.addPage();
              vm.printHeader('Terms', dateAsOf, pdf, top);
            }
            pdf.html( areTerms ? htmlDefaultTerms : '', { styles, html2canvas: {logging: false},
              // autoPaging: 'text',
              callback: function (pdf) {
                let endSection3pageCount = areTerms ? pdf.internal.getNumberOfPages() : endSection2pageCount;// update if areTerms

      // ----  section 4: ADDENDA - terms from addenda put in HTML format ---- //
                if (areAddenda) {
                  pdf.addPage();
                  vm.printHeader('Addenda', dateAsOf, pdf, top);
                }
                pdf.html(areAddenda ? htmlAddendaTerms : '', { styles, html2canvas: {logging: false},
                  callback: function (pdf) {
                    vm.printFooterAndFinishPdf(pdf);// must call from innermost callback - to finalize page count & output pdf
                  },
                  y: (pageHeight - 2 * top) * (areAddenda ? endSection3pageCount : 0) + 45,// y > 0 only if areAddenda
                  x: margin,
                  margin: [top, 0, top, 0]
                });
              },
              y: (pageHeight - 2 * top) * (areTerms ? endSection2pageCount : 0) + 45,// y > 0 only if areTerms
              x: margin,
              margin: [top, 0, top, 0]
            });
          },
          y: 45 + showSpecieHeight + (pageHeight - 2 * top) * (areAssets ? endSection1pageCount : 0),// y > 0 only if areAssets
          x: margin,
          margin: [top, 0, top, 0],
        });
      },

      printFooterAndFinishPdf(pdf) {
        let pageCount = pdf.internal.getNumberOfPages();
        pdf.setFont('helvetica', 'normal');
        for(let i = 1; i <= pageCount; i++) {
          pdf.setPage(i)
          const pageSize = pdf.internal.pageSize;
          const pageWidth = pageSize.width ? pageSize.width : pageSize.getWidth();
          const pageHeight = pageSize.height ? pageSize.height : pageSize.getHeight();
          const footer = `${this.getTrustName()}   -   Page ${i} of ${pageCount}`;
          pdf.setTextColor(100,100,100 );
          pdf.setFontSize(9).text(footer, pageWidth / 2 - (pdf.getTextWidth(footer) / 2), pageHeight - 25, { baseline: 'bottom' });
        }
        pdf.autoPrint();
        pdf.output("dataurlnewwindow", {'filename': 'Trust - ' + this.getTrustName()});
      },

      printHeader(type, date, pdf, top) {
        let dateY = type !== 'Declarations' ? top + 27  : top + 15;
        let title = type === 'Terms' && !this.allDefaultTerms ? 'Selected Trust ' : 'Trust ';
        pdf.setTextColor(80);
        pdf.setFont('Cinzel');
        pdf.setFontSize(20).text( title + type, 300, top, 'center');
        if (type !== 'Declarations') pdf.setFontSize(14).text(this.getTrustName(), 300, top + 16, 'center');
        pdf.setFontSize(9).text('As of ' + date, 300, dateY, 'center')
        pdf.setFont('helvetica', 'normal');
      },

    },
  }
</script>

<style>
  @import url('https://fonts.googleapis.com/css2?family=Cinzel&family=Great+Vibes&display=swap');

  .close .v-label.theme--light {
    margin-left: -5px !important;
  }

  .closer .v-label.theme--light {
    margin-left: -8px !important;
  }

  /* wrap trust in class and then style to prevent page-break right after h4 heading (seems to work in Chrome, but not Firefox) */
  .trust-wrap h4 {
    font-weight: normal;
    page-break-inside: avoid;
    break-inside: avoid;/*page-break-inside is phasing in*/
  }
  .trust-wrap h4::after {
    content: "";
    display: block;
    height: 50px;
    margin-bottom: -50px;
  }

  .mobile-font {
    font-size: .525rem !important;
    line-height: 1.4;
  }

  .mobile-info-font {
    font-size: .7rem !important;
  }

  .info-font {
    font-size: .82rem !important;
  }

  /* signature block styles */
  .sig-date {
    padding-top: 2px !important;
  }
  .sig-line {
    border-bottom: 1px solid;
  }
  .sig {
    color:#295491;
    font-family: 'Great Vibes', serif;
    font-size: 1.8rem !important;
    line-height: .9;/* Signature needs to be closer to underline */
  }
  .sig-mobile {
    color:#295491;
    font-family: 'Great Vibes', serif;
    font-size: .975rem;
    line-height: .96;/* Signature needs to be closer to underline */
  }

  .sticky {
    position: sticky !important;
    top: 0px;
    z-index: 1;
  }

  @media screen and (min-width: 600px) { /* just for wide screen - not for mobile or print */
    .trust-wrap { padding: 40px }
  }

  /* font sizes:
mobile viewing: 15.6px (.975rem) for cursive signature, 8.4px (.525rem) everything else
larger screen viewing & all printing: 26px (1.625rem) for cursive signature, 14px (.875rem) everything else
*/
  @media print
  {
    body * { visibility: hidden }
    .noprint { display: none !important; visibility: hidden }
    .div2 * { visibility: visible }
    .div2 { position: absolute; left: 30px; font-size: .875rem; margin-top: -20px !important; width: 100% !important }
    .mobile-font * { font-size: .875rem !important }
    .addenda-print-padding { padding-right: 30px }
    .asset-table-print-padding { padding-left: 8px; padding-right: 0 }
    .sig-mobile, .sig { font-size: 1.625rem !important; line-height: .9 }
  }

  @page { /*margin for each printed piece of paper*/
    margin: .7in .04in .6in .32in !important;
  }
</style>

