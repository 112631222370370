<template>
    <v-sheet max-width="1000">
        <v-progress-linear v-if="state === 'loading'"
                           indeterminate
                           color="primary"
        />
        <v-card class="pa-10">
            <v-row justify="center">
                <v-sheet>
                    <h1 class="ml-3 mt-3 display-5">Shipping Rates</h1>
                    <v-card class="ml-2 pa-2 "
                            outlined
                            max-width="500">
                        <p class="caption">
                            Shipping rates are set by breakpoints defined below. You can modify an existing shipping option.
                        </p>
                    </v-card>
                    <v-card-text>
                        <v-row>
                            <v-sheet max-width="800">
                                <v-row :key="rate.type" v-for="rate in rates">
                                    <v-col cols="4">
                                        <v-subheader>{{ rate.type }}</v-subheader>
                                    </v-col>
                                    <v-col>
                                        <v-text-field
                                                label="Rate"
                                                v-model="rate.rate"
                                                prefix="$"
                                        ></v-text-field>
                                    </v-col>
                                    <v-col>
                                        <v-text-field
                                                label="Coin Limit"
                                                v-model="rate.coin_limit"
                                        ></v-text-field>
                                    </v-col>
                                    <v-col>
                                        <v-text-field
                                                label="Goldback Limit"
                                                v-model="rate.goldback_limit"
                                        ></v-text-field>
                                    </v-col>
                                </v-row>
                            </v-sheet>
                        </v-row>
                    </v-card-text>
                    <v-card-actions>
                        <v-spacer/>
                        <v-progress-circular v-if="state === 'loading'"
                                             indeterminate
                                             color="primary"
                        />
                        <v-btn v-else
                               color="primary"
                               dark
                               @click="saveChanges">
                            Update
                        </v-btn>
                    </v-card-actions>
                </v-sheet>
            </v-row>
        </v-card>
        <ErrorDialog ref="errorDialog"/>
        <SnackBar ref="snackbar"/>
    </v-sheet>
</template>

<script>
    import ErrorDialog from "../ErrorDialog";
    import SnackBar from "../Snackbar";
    export default {
        name: "Shipping",
        components: {SnackBar, ErrorDialog},
        data: () => ({
            state: 'display',
            rateTypes: [],
            rates: [],
            originalRates: [], // Premiums as they were before changes
        }),
        methods: {
            getRates: async function () {
                try {
                    let res = await this.$axios.get('/admin/shipping/rates');
                    let rates = res.data;
                    if (!rates) {
                        this.rates = []
                    }

                    this.rates = rates;

                    this.originalPremium = JSON.parse(JSON.stringify(rates)); // Create a deep clone
                } catch(e) {
                    this.$refs.errorDialog.showError({
                        errorMessage: 'There was an error fetching the current shipping rates.',
                        forDevelopers: e
                    })
                }
            },
            saveChanges: async function() {
                this.state = 'loading';

                await this.patchRates(this.rates);
                await this.getRates();

                this.$refs.snackbar.showSnackbar({
                    text: "Shipping Rates have been updated!",
                    color: "success"
                });
                this.state = 'display'
            },
            patchRates: async function (newRates) {
                try {
                    await this.$axios.patch('/admin/shipping/rates', {
                      newRates
                    })
                } catch (e) {
                    this.$refs.errorDialog.showError({
                        errorMessage: "There was an error saving the premiums.",
                        forDevelopers: e
                    })
                }
            }
        },
        created: async function () {
            this.state = "loading";
            await this.getRates();
            this.state = "display"
        }
    }
</script>

<style scoped>

</style>