<template>
  <div>
    <Roster/>
    <ErrorDialog/>
  </div>
</template>

<script>
import ErrorDialog from "../components/ErrorDialog";
import Roster from "../components/home/Roster";
export default {
  name: 'Home',
  components: {
    Roster,
    ErrorDialog
  },
}
</script>
