<template>
  <div class="px-5 py-1 mb-12">
    <div v-if="allowedComponents.includes('Roles')">
      <span v-for="(value, name) in allScopes" :key="name">
        <v-tooltip bottom>
          <template #activator="{ on, attrs }">
            <div v-bind="attrs" v-on="on" class = "inline-block">
              <v-btn color="primary" class="mt-1 mr-1"
                     @click="scopeTypeChange(name)"
                     :disabled="scopeType === name">
                {{ name }}
              </v-btn>
            </div>
          </template>
            <span>{{ scopeTypeDescription[name] }}</span>
        </v-tooltip>
      </span>

      <v-row class="justify-start px-4 mb-n1 my-5">
        <v-autocomplete
            class="ml-12 mt-1 mb-n1"
            :items="Object.keys(allScopes[scopeType])"
            :label="`Showing ${filter}`"
            v-model="filter"
            @change="getMembersPerScopeType()"
        >
          <template v-slot:prepend-inner>
            <v-icon class="pr-1">mdi-filter-variant</v-icon>
          </template>
        </v-autocomplete>
        <v-spacer/>
        <v-tooltip left>
          <template #activator="data">
            <span v-on="data.on">
                <v-btn small fab color="primary"
                       @click="member = {}, refreshSearch = !refreshSearch, noMemberDialog = true, newPermissions = []"
                       class="px-3 mt-3 mr-6" elevation="4"><v-icon>mdi-plus</v-icon>
                </v-btn>
              </span>
          </template>
          Search for a member and add roles
        </v-tooltip>
        <v-spacer/>
      </v-row>
      <v-row  class="justify-start ml-12 px-4 mb-6">
        <span v-html="'<h2><strong>Privileges:</strong></h2> ' + scopeDescription[allScopes[scopeType][filter]]"></span>
      </v-row>

      <v-data-table sort-by='name'
                    multi-sort
                    :headers="tableHeaders"
                    :items="members"
                    class="elevation-4 row-pointer"
                    @click:row="managePermissions"
                    :loading="state === 'loading'"
                    :footer-props="{ 'items-per-page-options': [ 50, 100, 500 ] }">
        <template v-slot:item.actions="{ item }">
          <v-icon class="mx-4" @click="managePermissions(item)">mdi-account-lock-outline</v-icon>
        </template>
      </v-data-table>

      <v-dialog v-model="noMemberDialog" max-width="800px" max-height="400">
        <v-card class="pa-8">
          <v-card-text class="mb-n2 pb-0 text-center">Add/Remove Roles for:
          </v-card-text>
          <!-- Select Member -->
          <v-row class="justify-center mt-4 mb-n2">
            <SearchMembers :key='refreshSearch' clearable :fullwidth="$vuetify.breakpoint.name === 'xs'"
                           @memberSelected="memberSelected"/>
          </v-row>
          <v-row class="pt-7 justify-end">
            <v-btn color="red"
                   dark class="mt-3 px-4 mx-8"
                   @click="noMemberDialog = false">
              Cancel
            </v-btn>
          </v-row>
          <br>
        </v-card>
      </v-dialog>

      <v-dialog v-model="permissionsDialog" max-width="800px" max-height="400">
        <v-card class="pa-8">
          <v-card-text class="mb-n2 pb-0 text-center">
            <span>Add/Remove Roles for:</span>
          </v-card-text>
          <v-card-title class="pb-0 headline justify-center">
            {{ member.name }}
          </v-card-title>
          <v-row class="pt-7">
            <v-autocomplete
                :label="(newPermissions.length === 0 ? '' : 'Click to add/remove roles')"
                placeholder="No roles selected. Click to add."
                v-model="newPermissions"
                style="width: 45%"
                multiple
                :items="Object.keys(allScopes[scopeType])"
            >
              <template v-slot:item="{ item }">
                <v-tooltip right>
                  <template #activator="{ on, attrs }">
                    <div v-bind="attrs" v-on="on">
                      {{ item }}
                    </div>
                  </template>
                  <span v-html="scopeDescription[allScopes[scopeType][item]]" ></span>
                </v-tooltip>
              </template>
            </v-autocomplete>
          </v-row>

          <v-card-actions class="pt-5 pb-0 mt-5 mb-0">
            <!-- Cancel Button -->
            <v-btn color="red"
                   dark
                   @click="permissionsDialog = false">
              Cancel
            </v-btn>

            <v-spacer/>

            <!-- Save Button -->
            <v-btn color="primary"
                   @click="savePermissions"
            >
              Save
            </v-btn>
          </v-card-actions>

        </v-card>
      </v-dialog>

      <ErrorDialog ref="errorDialog"/>
      <SnackBar ref="snackbar"/>
    </div>
    <div v-else class="pl-12 pr-12 pt-12 mt-12 headline text-center">Sorry, this page is not available.</div>
  </div>
</template>

<script>
  // @ is an alias to /src
  import ErrorDialog from "@/components/ErrorDialog";
  import SnackBar from "@/components/Snackbar";
  import SearchMembers from "@/components/SearchMembers";

  export default {
    name: "RosterRoles",
    components: {
      ErrorDialog,
      SnackBar,
      SearchMembers,
    },
    data: () => ({
      state: 'loading',
      members: [],
      noMember: false,
      permissionsDialog: false,
      noMemberDialog: false,
      refreshSearch: true,
      scopeType: '',
      scopeDescription: '',
      scopeTypeDescription: '',
      member: {},
      filter: 'STAFF',
      scopes: [],
      allScopes: [],
      permission: '',
      newPermissions: [],
      oldPermissions: [],
      possiblePermissions: [],
      allowedComponents: JSON.parse(localStorage.getItem('allowedComponents') || ''),
      tableHeaders: [
        {
          text: 'Name',
          value: 'name',
          align: 'start'
        },
        {
          text: 'Email',
          value: 'email',
          align: 'start'
        },
        {
          text: 'Phone',
          value: 'phone',
          align: 'right'
        },
        {
          text: 'Change Roles',
          value: 'actions',
          sortable: false,
          align: 'center'
        }
      ]
    }),

    methods: {
      memberSelected: function (member) {
        this.member = member;
        this.managePermissions(member);
        this.permissionsDialog = true;
        this.noMemberDialog = false;
      },

      getScopeNums: async function () {
        try {
          let res = await this.$axios.get(`/admin/scopes`);
          this.allScopes = res.data;
        } catch (e) {
          this.$refs.errorDialog.showError({
            errorMessage: `Fail`,
            forDevelopers: e
          })
        }
        this.scopeType = Object.keys(this.allScopes)[0];
      },

      getScopeDescriptions: async function () {
        try {
          let res = await this.$axios.get(`/admin/scopes/descriptions`);
          this.scopeDescription = res.data;
        } catch (e) {
          this.$refs.errorDialog.showError({
            errorMessage: `Fail`,
            forDevelopers: e
          })
        }
      },

      getScopeTypeDescriptions: async function () {
        try {
          let res = await this.$axios.get(`/admin/scopes/type/descriptions`);
          this.scopeTypeDescription = res.data;
        } catch (e) {
          this.$refs.errorDialog.showError({
            errorMessage: `Fail`,
            forDevelopers: e
          })
        }
      },

      scopeNum(scopeType, permission) {
        const scopeNum
          = this.allScopes[scopeType][permission];
        return scopeNum;
      },

      managePermissions: async function (member) {
        this.member = member;
        let data = {
          username: member.email
        };
        //get scopes for this user
        try {
          const res = await this.$axios.get(`/admin/users/scope`, {params: data});
          this.scopes = res.data;
        } catch (e) {
          this.$refs.errorDialog.showError({
            errorMessage: `Fail`,
            forDevelopers: e
          })
        }
        this.newPermissions = [];
        for (let i = 0; i < this.scopes.length; i += 1) {
          this.newPermissions.push(this.scopes[i].scope_name);
        }
        //save current member permissions for comparison to updated, to see what needs to be created and/or deleted
        this.oldPermissions = [...this.newPermissions];
        this.permissionsDialog = true;
      },

      savePermissions: async function () {
        let data = {};
        data.username = this.member.email;
        data.actions = [];
        // check newPermissions and see if any of the newPermissions is NOT already in oldPermissions, if not then create
        for (let i = 0; i < this.newPermissions.length; i += 1) {
            if (!this.oldPermissions.includes(this.newPermissions[i])) {
              let scopeNum = this.scopeNum(this.scopeType, this.newPermissions[i]);
              data.actions.push({action: 'create', scopeNum: scopeNum })
            }
          }
        //check oldPermissions and if any of oldPermissions is NOT in newPermissions, then delete
        for (let i = 0; i < this.oldPermissions.length; i += 1) {
          if (!this.newPermissions.includes(this.oldPermissions[i])) {
            const userScopeNum
              = this.scopes.find(x => x.scope_name === this.oldPermissions[i]).user_scope_num;
            data.actions.push({action: 'delete', userScopeNum: userScopeNum })
          }
        }
        if (!data.actions.length) {
          this.$refs.errorDialog.showError({
            errorMessage: `There are no role changes to save. To save role changes,
            first select roles to add or delete from the roles dropdown, then click SAVE.`,
            forDevelopers: `Actions array is empty.  There are no creates or deletes.`
          })
          this.state = 'display'
        } else {
          if (confirm('Are you sure you want to save these changes to roles?')) {
            try {
              this.state = 'loading';
              await this.$axios.patch(`/admin/users/scope`, data);
              await this.getMembersPerScopeType();
              this.$refs.snackbar.showSnackbar({
                text: "Roles have been updated!",
                color: "success"
              });
              this.permissionsDialog = false;
              this.noMember = false;
              this.state = 'display';
            } catch (e) {
            let errorMessage = `Failed to update the roles for the member.`;

            if (e.response && e.response.data && e.response.data.message) {
              errorMessage = e.response.data.message;
            } else if (e.message) {
              errorMessage = e.message;
            }

            this.$refs.errorDialog.showError({
              errorMessage: errorMessage,
              forDevelopers: e
              })
              this.state = 'display'
            }
          }
        }
      },

      resetState: async function() {
        await this.getMembersPerScopeType(Object.keys(this.allScopes)[0]);
        this.state = 'display';
      },

      scopeTypeChange: async function (scopeName) {
        this.scopeType = scopeName;
        this.filter = Object.keys(this.allScopes[scopeName])[0];
        await this.getMembersPerScopeType();
      },

      getMembersPerScopeType: async function () {
        let filter = this.scopeNum(this.scopeType, this.filter);
        let data = { params: { filter: filter }}

        try {
          let res = await this.$axios.get(`/admin/users/permission`, data);
          this.members = res.data;
        } catch (e) {
          this.$refs.errorDialog.showError({
            errorMessage: "Failed to get members.",
            forDevelopers: e
          })
        }
      },
    },

    mounted() {
      localStorage.title = 'Roles';
    },
    created: async function() {
      await this.getScopeNums();
      await this.getScopeDescriptions();
      await this.getScopeTypeDescriptions();
      this.resetState();
    },

  }
</script>

<style scoped>

  .row-pointer >>> tbody tr :hover {
    cursor: pointer;
  }

  .inline-block {
    display: inline-block;
  }

</style>
