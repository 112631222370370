<template>
  <div>
      <v-card>
          <v-stepper v-model="step">
            <v-stepper-header>
              <v-stepper-step
                  :complete="step > 1"
                  step="1"
              >
                Start
              </v-stepper-step>

              <v-divider></v-divider>

              <v-stepper-step
                  :complete="step > 2"
                  step="2"
              >
                {{ stepOneDone? (isMember ? "Member Section" : "Non-Member Section") : ""}}
              </v-stepper-step>
              <v-divider></v-divider>
              <v-stepper-step
                  :complete="step > 3"
                  step="3"
              >
                {{ step2State.requestType? step2State.requestType : "" }}
              </v-stepper-step>

              <v-divider></v-divider>

              <v-stepper-step step="4">
                Submit
              </v-stepper-step>
            </v-stepper-header>
            <v-stepper-items>

              <v-stepper-content step="1">
                <v-form v-model="validStepOne" ref="stepOneForm">
                  <v-card flat
                  >
                    <v-select
                        class="mt-1 mb-2"
                        label="How was the request received?*"
                        v-model="step1State.communicationType"
                        :items="communicationItems"
                        outlined
                        hide-details="auto"
                        :rules="[(v) => !!v  || 'Communication Type is required']"
                        style="max-width: 800px"
                    />
                    <v-select
                        class="mt-1 mb-2"
                        label="Are they acting as a member?*"
                        v-model="step1State.isMember"
                        :items="memberBoolean"
                        outlined
                        :rules="[(v) => v !== undefined  || 'Acting As Member? is required']"
                        hide-details="auto"
                        style="max-width: 800px"
                    />
                  </v-card>

                  <v-row class="ml-1 mt-2" style="max-width: 800px">
                    <v-btn
                        color="primary"
                        :disabled = "!validStepOne"
                        @click="step = 2, stepOneDone = true, onStep1Change() "
                    >
                      Next
                    </v-btn>
                  </v-row>
                </v-form>
              </v-stepper-content>

              <v-stepper-content step="2">
                <v-form v-model="validStepTwo" ref="stepTwoForm">
                  <v-card flat>
                    <div v-if="isMember">
                      <SearchMembers class="ml-0" v-if="isMember"
                                     clearable
                                     :hideDetails="'auto'"
                                     style="max-width: 800px"
                                     @memberSelected="memberSelected"
                      />
                      <div v-if="memberSelectedState === 'waiting'"><strong>Please Select a Member Above</strong></div>
                      <div v-else-if="memberSelectedState === 'loading'"><strong>Loading Member Info... </strong></div>
                      <div v-else-if="memberSelectedState === 'display'"><strong>Member Info </strong></div>
                      <div>
                        <strong>Email:</strong>
                        <span v-if="memberSelectedState === 'display'">
                          {{ memberInfo.email }}
                        </span>
                        <br/>
                        <strong>Name:</strong>
                        <span v-if="memberSelectedState === 'display'">
                          {{ memberInfo.name }}
                        </span>
                        <br/>
                        <strong>Phone #:</strong>
                        <span v-if="memberSelectedState === 'display'">
                          {{ memberInfo.phone }}
                        </span>
                        <br/>
                        <strong>Selected Member Address:</strong>
                        <span v-if="memberSelectedState === 'display'">
                          {{ accountInfo.address + ' ' + accountInfo.zipCode }}
                        </span>
                        <br/>
                        <span v-if="memberSelectedState === 'display'">
                            <span v-if="accountInfo.account_locked" style="color: red; font-weight: bold; font-size: larger;">
                              <br/>
                              This is a locked account. Do not perform any actions for this account.
                              <br/>
                            </span>
                        </span>
                        <br/>
                        <strong>Selected Member Security Question:</strong>
                        <span v-if="memberSelectedState === 'display'">
                          {{ accountInfo.security_question }}
                        </span>
                        <br/>
                        <strong>Selected Member Security Answer:</strong>
                        <span v-if="memberSelectedState === 'display'">
                          {{ accountInfo.security_response }}
                        </span>
                      </div>
                      <v-select
                          class="mt-2"
                          label="How did we verify their identity?*"
                          outlined
                          v-model="step2State.verificationType"
                          :items="verificationItems"
                          :rules="[(v) => !!v || 'Verification Type is required']"
                          hide-details="auto"
                          style="max-width: 800px"
                      />
                      <v-text-field
                          v-if="step2State.verificationType === 'Other'"
                          class="mt-2"
                          label="Verification Description*"
                          outlined
                          counter="60"
                          v-model="step2State.verificationDescription"
                          :rules="[(v) => !!v || 'Verification Description is required']"
                          hide-details="auto"
                          style="max-width: 800px"
                      />
                      <v-text-field
                          v-if="step2State.verificationType === 'One-Time Passcode'"
                          class="mt-2"
                          label="One-Time Passcode*"
                          outlined
                          counter="10"
                          :disabled="!memberInfo.email"
                          :loading="loadingOTP"
                          :color="validOTP ? 'green' : 'red'"
                          v-model="otp"
                          :rules="[(v) => !!v || 'OTP is required']"
                          hide-details="auto"
                          style="max-width: 800px"
                      />
                      <v-autocomplete
                          class="mt-2 mb-4"
                          label="Request Type*"
                          outlined
                          v-model="step2State.requestType"
                          :items="requestItemsMember"
                          :item-value="item => item"
                          :item-text="item => item"
                          :rules="[(v) => !!v || 'Request Type is required']"
                          hide-details="auto"
                          style="max-width: 800px"
                      >
                        <template v-slot:item="{ item }">
                          <v-tooltip right>
                            <template v-slot:activator="{ on, attrs }">
                              <div v-bind="attrs" v-on="on">{{ item }}</div>
                            </template>
                            <span v-html="requestDescriptions[item]"></span>
                          </v-tooltip>
                        </template>
                      </v-autocomplete>
                    </div>
                    <div v-else>
                      <v-text-field
                          class="mt-2"
                          label="Name*"
                          outlined
                          v-model="step2State.name"
                          :rules="[(v) => !!v || 'Name is required']"
                          hide-details="auto"
                          style="max-width: 800px"
                      />
                      <v-text-field
                          class="mt-2"
                          label="Phone Number"
                          outlined
                          v-model="step2State.phoneNumber"
                          hide-details="auto"
                          style="max-width: 800px"
                      />
                      <v-text-field
                          class="mt-2"
                          label="Email"
                          outlined
                          v-model="step2State.email"
                          hide-details="auto"
                          style="max-width: 800px"
                      />
                      <v-select
                          class="mt-2 mb-4"
                          label="Request Type*"
                          outlined
                          v-model="step2State.requestType"
                          :rules="[(v) => !!v || 'Request Type is required']"
                          :items="requestItemsNonMember"
                          hide-details="auto"
                          style="max-width: 800px"
                      >
                        <template v-slot:item="{ item }">
                          <v-tooltip right>
                            <template v-slot:activator="{ on, attrs }">
                              <div v-bind="attrs" v-on="on">{{ item }}</div>
                            </template>
                            <span v-html="requestDescriptions[item]"></span>
                          </v-tooltip>
                        </template>
                      </v-select>
                    </div>
                  </v-card>
                  <v-row class="ml-1 mt-2" style="max-width: 800px">
                    <v-btn color="primary" class="mr-2" @click="step = 1, stepOneDone=false">
                      Back
                    </v-btn>
                    <v-btn
                        color="primary"
                        :disabled = "!validStepTwo || (isMember && !memberInfo.email) || accountInfo.account_locked
                        || (step2State.verificationType === 'One-Time Passcode' && !validOTP) || loadingOTP"
                        @click="step = 3, onStep2Change()"
                    >
                      Next
                    </v-btn>
                    <v-spacer/>
                    <v-tooltip bottom v-if="memberInfo.email">
                      <template #activator="{ on }">
                        <v-btn  v-on="on" class="mr-2" icon @click="loginAs(memberInfo.email)">
                          <v-icon>
                          mdi-account-arrow-right
                          </v-icon>
                        </v-btn>
                      </template>
                      <span>Login as {{memberInfo.email}}</span>
                    </v-tooltip>
                  </v-row>
                </v-form>
              </v-stepper-content>

              <v-stepper-content step="3">
                <v-form v-model="validStepThree" ref="stepThreeForm">
                  <v-card
                      flat
                      v-if="step === 3"
                  >
                    <!--                // ****** In-Office Purchase/Sale ********-->
  <!--                  <div v-if="step2State.requestType === 'In-Office Purchase/Sale'">-->
  <!--                    <p>-->
  <!--                      They don't need to be a member to do this. Use this sheet: https://docs.google.com/spreadsheets/d/1GhC0Xnz2Q81wyzBZ8BcQMgSs_kC7Yn8WKWJ49hGjASc/edit?usp=sharing-->
  <!--                    </p>-->
  <!--                    <v-text-field-->
  <!--                        class="mt-2"-->
  <!--                        label="Copy and Paste Calculator Output Here (cell B50)"-->
  <!--                        outlined-->
  <!--                        v-model="step3State.calculatorOutput"-->
  <!--                        hide-details="auto"-->
  <!--                    />-->
  <!--                    <v-select-->
  <!--                        outlined-->
  <!--                        label="Payment Type"-->
  <!--                        class="mt-2"-->
  <!--                        :items="[...paymentForms, 'Cash']"-->
  <!--                        v-model="step3State.paymentForm"-->
  <!--                        hide-details="auto"/>-->
  <!--                    &lt;!&ndash;                  TODO: fields for wire and ach specifics&ndash;&gt;-->
  <!--                    <v-text-field-->
  <!--                        v-if="step3State.paymentForm === 'Wire'"-->
  <!--                        class="mt-2"-->
  <!--                        label="Bank Name"-->
  <!--                        outlined-->
  <!--                        v-model="step3State.bankName"-->
  <!--                        hide-details="auto"-->
  <!--                    />-->
  <!--                    <v-text-field-->
  <!--                        v-if="step3State.paymentForm === 'Wire'"-->
  <!--                        class="mt-2"-->
  <!--                        label="Bank Address"-->
  <!--                        outlined-->
  <!--                        v-model="step3State.bankAddress"-->
  <!--                        hide-details="auto"-->
  <!--                    />-->
  <!--                    <v-text-field-->
  <!--                        v-if="step3State.paymentForm === 'Wire'"-->
  <!--                        class="mt-2"-->
  <!--                        label="Member Name"-->
  <!--                        outlined-->
  <!--                        v-model="step3State.memberName"-->
  <!--                        hide-details="auto"-->
  <!--                    />-->
  <!--                    <v-text-field-->
  <!--                        v-if="step3State.paymentForm === 'Wire'"-->
  <!--                        class="mt-2"-->
  <!--                        label="Member Address"-->
  <!--                        outlined-->
  <!--                        v-model="step3State.memberAddress"-->
  <!--                        hide-details="auto"-->
  <!--                    />-->
  <!--                    <v-text-field-->
  <!--                        v-if="step3State.paymentForm === 'ACH' || step3State.paymentForm === 'Wire'"-->
  <!--                        class="mt-2"-->
  <!--                        label="Account Number"-->
  <!--                        outlined-->
  <!--                        v-model="step3State.accountNumber"-->
  <!--                        hide-details="auto"-->
  <!--                    />-->
  <!--                    <v-text-field-->
  <!--                        v-if="step3State.paymentForm === 'ACH' || step3State.paymentForm === 'Wire'"-->
  <!--                        class="mt-2"-->
  <!--                        label="Routing Number"-->
  <!--                        outlined-->
  <!--                        v-model="step3State.routingNumber"-->
  <!--                        hide-details="auto"-->
  <!--                    />-->
  <!--                    <v-checkbox-->
  <!--                        class="ml-2"-->
  <!--                        label="Will all or part of the purchase be shipped?"-->
  <!--                        v-model="isShipping"-->
  <!--                    />-->
  <!--                    <v-text-field-->
  <!--                        v-if="isShipping"-->
  <!--                        class="mt-2"-->
  <!--                        label="Shipping Address"-->
  <!--                        outlined-->
  <!--                        v-model="step3State.shippingAddress"-->
  <!--                        hide-details="auto"-->
  <!--                    />-->

  <!--                  </div>-->

                    <div v-if="componentsForRequestType[step2State.requestType].includes('editRecruitInfo')">
                      <v-row class="ml-1 mt-2">
                        <v-select
                          style="max-width: 800px"
                          label="Select an action"
                          :loading = "memberSelectedState === 'loading'"
                          no-data-text="Loading..."
                          :items="this.recruitItems"
                          :rules="[(v) => !!v || 'Action is required!']"
                          v-model="selectedRecruitItem"
                        ></v-select>
                      </v-row>
                      <v-row class="ml-1 mt-2" v-if="recruitOrRecruiterSelection">
                        <span class="body-2 grey--text text--darken-3">
                          {{ step3State.recruitInfo.recruitOrRecruiter === 'recruiter' ?
                            "Select the member's desired recruiter from the member search below" :
                            "Select the member's desired recruit from the member search below" }}
                        </span>
                      </v-row>
                      <v-row class="ml-1 mt-2" v-if="recruitOrRecruiterSelection">
                        <SearchMembers class="ml-0"
                                       clearable
                                       :hideDetails="'auto'"
                                       style="max-width: 800px"
                                       @memberSelected="recruitOrRecruiterSelected"
                        />
                      </v-row>
                      <v-row class="ml-1 mt-2" v-if="recruitOrRecruiterSelection">
                        <span class="body-2 grey--text text--darken-3" v-show="showSelected">
                          {{ step3State.recruitInfo.recruitOrRecruiter === 'recruiter' ?
                            "The recruiter to be added:" :
                            "The recruit to be added:" }}
                        </span>
                      </v-row>
                      <v-row class="ml-1 mt-2" v-if="recruitOrRecruiterSelection">
                        <v-text-field
                            class="ml-0 text-center"
                            v-show="showSelected"
                            readonly
                            v-model="step3State.recruitInfo.username"
                            style="max-width: 800px"
                            :rules="rules.memberSelect"/>
                      </v-row>
                    </div>

                    <div v-if="componentsForRequestType[step2State.requestType].includes('fromAsset')">
                      <div v-if="componentsForRequestType[step2State.requestType].includes('transferInfo')">
                        <v-row class="ml-1 mt-2">
                        Select the asset and amount to transfer:
                        </v-row>
                      </div>
                      <v-row class="ml-1 mt-2">
                        <v-select
                            outlined
                            label="From Asset*"
                            :disabled="isFromAssetDisabled"
                            :items="filteredAccountBreakdowns"
                            :item-text="balancesInfo => balancesInfo.assetType + ' (' + balancesInfo.availableBalance + ' Available)'"
                            :item-value="balancesInfo => assetTypes.find(({ name }) => name === balancesInfo.assetType).id"
                            :loading = "memberSelectedState === 'loading'"
                            no-data-text="Loading..."
                            @change="calculateExchange"
                            :rules="[(v) => !!v || 'From Asset is required']"
                            v-model="assets.fromAsset"
                            style="max-width: 800px"
                            hide-details="auto"/>
                        <v-btn v-if="componentsForRequestType[step2State.requestType].includes('productSelection')" @click="assets.fromAsset = null; assets.fromAmount = null; buy = {}" icon>
                          <v-icon>mdi-close</v-icon>
                        </v-btn>
                      </v-row>
                      <v-row class="ml-1 mt-2">
                        <v-text-field
                            label="From Amount (In Asset)*"
                            outlined
                            @focusout="calculateExchange"
                            :rules="[(v) => !!v || 'From Amount is required']"
                            v-model="assets.fromAmount"
                            :disabled="(componentsForRequestType[step2State.requestType].includes('productSelection')
                                        && assets.fromAsset !== 5062) ? true
                                        : toggleFromState"
                            style="max-width: 400px"
                            :suffix="mixin_suffix(assets.fromAsset)"
                            :style="{ color: assetColor(assets.fromAsset),
                                      maxWidth: '400px'}"
                            hide-details="auto"
                            type="number"
                        />
                        <v-text-field
                            label="From Amount (In USD)"
                            outlined
                            suffix="USD est."
                            @focusout="calculateExchange(5062)"
                            :disabled="(!assets.fromAsset ||
                            (componentsForRequestType[step2State.requestType].includes('productSelection') && assets.fromAsset !== 5062)
                            || componentsForRequestType[step2State.requestType].includes('transferInfo')) ? true
                             : !toggleFromState"
                            :loading="loadingAmounts"
                            v-model="usd.fromAmount"
                            :style="{ color: assetColor(5062),
                                      maxWidth: '400px'}"
                            hide-details="auto"
                            type="number"
                        />
                        <div v-if="assets.fromAmount && assets.fromAsset && assets.toAsset && assets.fromAsset !== 5062" class="ml-2">
                        Zero Spread Asset: {{ estimatedZeroSpread }}<br/>
                        Zero Spread USD: ${{ parseFloat(estimatedZeroSpread * rates[assets.fromAsset].rate).toFixed(2)}}<br/>
                        Buy Back Asset: {{ estimatedBuyBack }}<br/>
                        Buy Back USD: ${{ parseFloat(estimatedBuyBack * rates[assets.fromAsset].buy_back).toFixed(2)}}<br/>
                        </div>
                      </v-row>
                      <!-- Toggle Button for From Asset -->
                      <v-row class="ml-1 mt-2"
                             v-if="!(componentsForRequestType[step2State.requestType].includes('productSelection') && assets.fromAsset !== 5062)
                                  && !componentsForRequestType[step2State.requestType].includes('transferInfo')"
                             style="max-width: 800px"
                      >
                        <v-spacer></v-spacer>
                        <v-switch
                            v-model="toggleFromState"
                            label="Set USD"
                            hide-details="auto"
                            class="mt-n1 ml-2"
                        ></v-switch>
                      </v-row>
                    </div>


                    <div v-if="componentsForRequestType[step2State.requestType].includes('productSelection')">
                      <v-card flat v-if="assets.fromAsset && assets.fromAsset !== 5062">
                        <v-card-text>
                          <v-row class="ml-1 mr-1 mt-2" style="max-width: 800px" >
                            <SelectProducts @productsSelected="onSelectedProductChange" :assetType="assets.fromAsset"></SelectProducts>
                          </v-row>
                        </v-card-text>
                        <v-card-text>
                          <v-row class="ml-1 mr-1 mt-2" style="max-width: 800px" >
                            <v-data-table
                                hide-default-footer
                                :headers="productHeaders"
                                :loading="loadingAmounts"
                                :items="buy.products"
                                :items-per-page="-1">
                            </v-data-table>
                          </v-row>
                        </v-card-text>
                      </v-card>
                    </div>

                    <div v-if="componentsForRequestType[step2State.requestType].includes('toAssetWithOverride')">
                      <v-row class="ml-1 mt-2">
                        <v-select
                            outlined
                            label="To Asset*"
                            :items="assetTypes"
                            :item-text="assetTypes => assetTypes.name"
                            :item-value="assetTypes => assetTypes.id"
                            :disabled="componentsForRequestType[step2State.requestType].includes('toPaperOnly')"
                            @change="calculateExchange"
                            v-model="assets.toAsset"
                            :rules="[(v) => !!v || 'To Asset is required']"
                            style="max-width: 800px"
                            hide-details="auto"/>
                      </v-row>
                      <v-row class="ml-1 mt-2">
                        <v-text-field
                            :label="assets.override ? 'To Amount (In Asset)*' : 'Estimated To Amount (In Asset)'"
                            outlined
                            :disabled="assets.override ? toggleToState: !assets.override"
                            v-model="assets.toAmount"
                            :suffix="mixin_suffix(assets.toAsset) + (assets.override ? '' : ' approx.')"
                            :loading="loadingAmounts"
                            @focusout="calculateExchange"
                            :style="{ color: assetColor(assets.toAsset),
                                      maxWidth: '400px'}"
                            hide-details="auto"
                            type="number"
                        />
                        <v-text-field
                            :label="assets.override ? 'To Amount (In USD)' : 'Estimated To Amount (In USD)'"
                            outlined
                            :disabled="(assets.override ? !toggleToState: !assets.override) || !assets.toAsset"
                            suffix="USD est."
                            :loading="loadingAmounts"
                            @focusout="calculateExchange(5062)"
                            v-model="usd.toAmount"
                            :style="{ color: assetColor(5062),
                                      maxWidth: '400px'}"
                            hide-details="auto"
                            type="number"
                        />
                      </v-row>
                      <v-row class="ml-1 mt-2"
                             v-if="!(componentsForRequestType[step2State.requestType].includes('productSelection') && assets.fromAsset !== 5062)"
                             style="max-width: 800px"
                      >
                        <v-checkbox
                            label="Override To Amount"
                            class="ml-2"
                            v-model="assets.override"
                            @change="calculateExchange"
                        />
                        <v-spacer></v-spacer>
                        <!-- Toggle Button for To Asset -->
                          <v-switch
                              v-if="assets.override === true && !componentsForRequestType[step2State.requestType].includes('toPaperOnly')"
                              v-model="toggleToState"
                              label="Set USD"
                              hide-details="auto"
                              class="ml-4"
                          ></v-switch>
                      </v-row>
                    </div>

<!--                    Deposit USD Service Request-->
                    <div v-if="componentsForRequestType[step2State.requestType].includes('depositUsdInfo')">
                      <v-row class="ml-1 mt-2">
                      Select the payment method:
                      </v-row>
                      <v-select
                          class="ml-1 mt-2"
                          outlined
                          label="Payment Method*"
                          :items="paymentMethods"
                          v-model="step3State.paymentType"
                          @change="checkPaymentType"
                          :rules="[(v) => !!v || 'Payment method is required']"
                          style="max-width: 800px"
                          hide-details="auto">
                      </v-select>
                      <v-row class="ml-1 mt-2" v-if="step3State.paymentType === 'Check'">
                        Is the check physically present?
                      </v-row>
                      <v-select v-if="step3State.paymentType === 'Check'"
                          class="ml-1 mt-2"
                          outlined
                          label="Check Received*"
                          :items="[{text:'Yes',value:true},{text:'No',value:false}]"
                          v-model="step3State.checkPhysicallyPresent"
                          :rules="[(v) => typeof v === 'boolean' || 'Receipt status is required']"
                          style="max-width: 800px"
                          hide-details="auto"
                      />
                      <div v-if="step3State.paymentType === 'Check'">
                        <div  v-if="step3State.checkPhysicallyPresent === true" style="max-width: 800px">
                          <v-row class="ml-1 mt-2" style="color: red;">
                            **Based on your response, you are accepting responsibility for the member's check** <br>
                            After submission of this service request, you will be expected to provide the check to your manager for deposit
                          </v-row>
                          <v-row class="ml-1 mt-2 text--darken-3">
                            Ensure the check is made out to: Legal Tender Services PLLC
                          </v-row>
                          <v-row class="ml-1 mt-2" style="color: #295491;">
                            Following submission of the service request, go to the 'Assets' tab to sign for this deposit with the member and provide a receipt
                          </v-row>
                          <v-switch
                          class="ml-4 mt-2 mb-0 pb-0"
                          v-model="viewBillPayPreferences"
                          label="view the member's (Bill Pay) deposit preferences"/>
                          <div v-if="viewBillPayPreferences === true">
                            <div v-if="loadingPreferences === true">
                                <v-row class="ml-1 mt-2">loading preferences...</v-row>
                            </div>
                            <div v-else-if="noDepositPreference === true">
                              <v-row class="ml-1 mb-4" style="color: #295491;">No Bill Pay deposit preferences found</v-row>
                            </div>
                            <div v-else class="ml-12 mb-6">
                              <v-row>
                                {{depositPreferences.gold_percent}}%&nbsp;
                                <span :style="{color: assetColor(3373)}">Gold</span>
                                &nbsp;with DCA Period of <span :style="{color: assetColor(3373)}">&nbsp;{{depositPreferences.gold_dca}}&nbsp;</span>
                                Business Days
                              </v-row>
                              <v-row>
                                {{depositPreferences.silver_percent}}%&nbsp;
                                <span :style="{color: assetColor(3374)}">Silver</span>
                                  &nbsp;with DCA Period of
                                  <span :style="{color: assetColor(3374)}">&nbsp;{{depositPreferences.silver_dca}}&nbsp;</span>
                                  Business Days
                              </v-row>
                              <v-row>
                                {{depositPreferences.goldback_percent}}%&nbsp;
                                <span :style="{color: assetColor(7000)}">Goldbacks</span>
                              </v-row>
                            </div>
                          </div>
                        </div>
                        <div  v-if="step3State.checkPhysicallyPresent === false" style="max-width: 800px">
                          <v-row class="ml-1 mt-2" style="color: #295491;">
                              An email containing {{step3State.paymentType}} payment instructions will be sent to the member
                          </v-row>
                        </div>
                      </div>
                      <v-row class="ml-1 mt-2">
                        <v-text-field
                                      :label="'Amount (In USD)*'"
                                      outlined
                                      suffix="USD"
                                      :loading="loadingAmounts"
                                      v-model="usd.toAmount"
                                      :rules="[(v) => !!v || 'Amount is required']"
                                      :style="{ color: assetColor(5062),
                                      maxWidth: '800px'}"
                                      hide-details="auto"
                                      type="number"
                        />
                      </v-row>
                      <v-row class="ml-1 mt-2">
                        Select the asset account the member would like to contribute to:
                      </v-row>
                      <v-row class="ml-1 mt-2">
                        <v-select
                            outlined
                            label="Asset*"
                            :items="assetTypes"
                            :item-text="assetTypes => assetTypes.name"
                            :item-value="assetTypes => assetTypes.id"
                            v-model="assets.toAsset"
                            @change="handleAssetChange(assets.toAsset)"
                            :rules="[(v) => !!v || 'Asset is required']"
                            style="max-width: 800px"
                            hide-details="auto"/>
                      </v-row>
                      <div v-if="(this.assets.toAsset !== 5062 && this.assets.toAsset)">
                      <v-row  class="ml-1 mt-2">
                        <b>The current {{assetTypes.find(asset => asset.id === this.assets.toAsset)?.name}}
                          {{assetTypes.find(asset => asset.id === this.assets.toAsset)?.name === 'Gold' ? ' Dollar (1/50 oz)' : null}}
                          conversion rate is</b>
                        <span class="ml-2 mr-2" :style="{ color: assetColor(this.assets.toAsset)}">
                                1.00 {{mixin_suffix(this.assets.toAsset)}}
                              </span>
                        <v-icon>mdi-arrow-left-right-bold</v-icon>
                        <span class="ml-2" :style="{ color: assetColor(5062)}">
                                {{this.rates[this.assets.toAsset].rate}}
                                USD
                              </span>
                      </v-row>
                      </div>
                      <div v-if="(this.assets.toAsset === 3373 || this.assets.toAsset === 3374)">
                        <v-row class="ml-1 mt-2">
                          Select the desired Dollar Cost Averaging Period:
                        </v-row>
                        <v-row class="ml-1 mt-2">
                          <v-select
                              label="DCA Period*"
                              outlined
                              :items="dcaOptions"
                              style="max-width: 800px"
                              v-model="step3State.dcaPeriod"
                              :rules="[(v) => !!v || 'DCA Period is required']"
                              hide-details="auto"
                          >
                          </v-select>
                        </v-row>
                      </div>
                    </div>

                    <div v-if="componentsForRequestType[step2State.requestType].includes('toAssetNoOverride')">
                        <v-row class="ml-1 mt-2">
                          <v-select
                              outlined
                              label="Asset*"
                              :items="componentsForRequestType[step2State.requestType].includes('toMetalsOnly') ? assetTypesNoPaper : assetTypes"
                              :item-text="assetTypes => assetTypes.name"
                              :item-value="assetTypes => assetTypes.id"
                              @change="calculateExchange"
                              v-model="assets.toAsset"
                              :disabled="componentsForRequestType[step2State.requestType].includes('toPaperOnly')"
                              :rules="[(v) => !!v || 'Asset is required']"
                              style="max-width: 800px"
                              hide-details="auto"/>
                        </v-row>
                      <v-row class="ml-1 mt-2">
                        <v-text-field
                            :label="'Amount (In Asset)' +
                            (componentsForRequestType[step2State.requestType].includes('toPaperOnly')
                            ? '' : '*')"
                            outlined
                            v-model="assets.toAmount"
                            :loading="loadingAmounts"
                            @focusout="calculateExchange"
                            :suffix="mixin_suffix(assets.toAsset)"
                            :rules="[(v) => !!v || 'Amount is required']"
                            :disabled="componentsForRequestType[step2State.requestType].includes('toPaperOnly')"
                            :style="{ color: assetColor(assets.toAsset),
                                      maxWidth: '400px'}"
                            hide-details="auto"
                            type="number"
                        />
                        <v-text-field v-if="!componentsForRequestType[step2State.requestType].includes('toMetalsOnly')"
                            label="Amount (In USD)"
                            outlined
                            suffix="USD est."
                            :disabled="!assets.toAsset ||
                            componentsForRequestType[step2State.requestType].includes('trueLinkCards')"
                            @focusout="calculateExchange(5062)"
                            :loading="loadingAmounts"
                            v-model="usd.toAmount"
                            :style="{ color: assetColor(5062),
                                      maxWidth: '400px'}"
                            hide-details="auto"
                            type="number"
                        />
                      </v-row>
                    </div>

                    <div v-if="componentsForRequestType[step2State.requestType].includes('toMetalsOnly')">
                      <v-row class="ml-1 mt-2">
                        <v-select
                              outlined
                              label="Are the Metals Physically Present?*"
                              :items="[{text:'Metals are Physically Present',value:true},{text:'Metals are Not Physically Present',value:false}]"
                              v-model="step3State.metalsPhysicallyPresent"
                              :rules="[(v) => v !== null || 'Choosing whether the metals are present today is required']"
                              style="max-width: 800px"
                              hide-details="auto"/>
                      </v-row>
                      <v-row class="ml-1 mt-2">
                        <v-select
                            outlined
                            label="Convert to In-Network?*"
                            :items="[{text:'In-Network (Conversion Fee)',value:true},{text:'Out-Of-Network (Hold)',value:false}]"
                            v-model="step3State.immediateConversion"
                            :rules="[(v) => v !== null || 'Choosing a conversion type is required']"
                            style="max-width: 800px"
                            hide-details="auto"/>
                      </v-row>
                    </div>

                    <div v-if="componentsForRequestType[step2State.requestType].includes('liquidateOrPawn')">
                      <v-row class="ml-1 mt-2">
                        <v-select
                            outlined
                            label="Tax Liability Option*"
                            :items="[{text:'Pawn',value:true},{text:'Liquidate',value:false}]"
                            v-model="step3State.pawn"
                            :rules="[(v) => v !== null || 'Tax Liability option is required']"
                            style="max-width: 800px"
                            hide-details="auto"/>
                      </v-row>
                    </div>

                    <div v-if="componentsForRequestType[step2State.requestType].includes('transferInfo')">
                      <v-row class="ml-1 mt-2">
                        Select the recipient below:
                      </v-row>
                      <v-row class="ml-1 mt-2">
                        <SearchMembers class="ml-0"
                                       clearable
                                       :hideDetails="'auto'"
                                       style="max-width: 800px"
                                       @memberSelected="recipientSelected"
                        />
                      </v-row>
                      <v-row class="ml-1 mt-2" v-if="recipientSelection">
                        <span class="body-2 grey--text text--darken-3" v-show="showSelected">
                          Transfer recipient selected:
                        </span>
                      </v-row>
                      <v-row class="ml-1">
                        <v-text-field
                            class="ml-0 text-center"
                            v-show="showSelected"
                            readonly
                            v-model="step3State.transferTarget"
                            style="max-width: 800px"
                            :rules="rules.memberSelect"/>
                      </v-row>
                      <v-row class="ml-1 mt-2">
                        <v-select
                            outlined
                            label="Release funds following admin approval or leave in escrow?*"
                            :items="[{text:'Leave the transfer in escrow (the member will release the transfer funds later)',
                             value:false},
                            {text:'Release funds instantly after admin approval (the member has given approval for the release of funds)',
                            value:true}]"
                            v-model="step3State.releaseTransferFunds"
                            :rules="[(v) => v !== null ||
                            'Choosing whether the funds will be released after admin approval is required']"
                            style="max-width: 800px"
                            placeholder="Select an option**"
                            hide-details="auto"/>
                      </v-row>
                      <v-row class="ml-1 mt-2">
                        <div class="text-wrap font-weight-bold" style="max-width: 800px">
                          **If the requesting member's account is an IRA,
                          the transfer funds will be released immediately upon submission of this Service Request
                          (regardless of the option selected above)
                        </div>
                      </v-row>
                    </div>

                    <div v-if="componentsForRequestType[step2State.requestType].includes('trueLinkCards')">
                      <v-row class="ml-1 mt-2">
                        <v-select
                            outlined
                            label="Last 4 of TL Card*"
                            :items="tlInfo"
                            item-text="last_4"
                            item-value="last_4"
                            v-model="step3State.cardLast4"
                            no-data-text="No Active Cards Found"
                            :rules="[(v) => !!v || 'Last 4 of the card to load is required']"
                            style="max-width: 800px"
                            hide-details="auto"/>
                      </v-row>
                    </div>

                    <div v-if="componentsForRequestType[step2State.requestType].includes('redeemPayment') ||
                                  (step2State.requestType === 'Withdraw' && assets.fromAsset === 5062)">
                      <v-row class="ml-1 mt-2">
                      <v-select
                          outlined
                          label="Payment Type*"
                          :items="redeemPaymentForms"
                          :rules="[(v) => !!v || 'Payment Type is required']"
                          style="max-width: 800px"
                          v-model="step3State.paymentForm"
                          @change="populateInitialPaymentInfo"
                          hide-details="auto"/>
                      </v-row>
                      <span v-if="step3State.paymentForm === 'Wire' || step3State.paymentForm === 'ACH'">
                        <v-row class="ml-1 mt-2">
                          <v-select
                              outlined
                              label="Bank Account*"
                              :items="banksInfo"
                              :loading="memberSelectedState === 'loading'"
                              :item-text="bankI => bankI.nickname ? (bankI.nickname + (bankI.nickname !== 'Manual Entry' ? ' ...' + bankI.accountNumber.substr(bankI.accountNumber.length - 4) : '')) : ''"
                              :item-value="bankI => bankI"
                              v-model="step3State.paymentInfo.bank"
                              @change="forceRerender"
                              :rules="[(v) => !!v || 'Bank Account is required']"
                              style="max-width: 800px"
                              hide-details="auto"/>
                        </v-row>
                        <span :key="componentKey">
                          <v-row class="ml-1 mt-2" v-if="step3State.paymentInfo.bank === banksInfo[banksInfo.length - 1]">
                            <v-text-field
                                label="Routing Number*"
                                outlined
                                :rules="[(v) => !!v || 'Routing Number is required']"
                                style="max-width: 400px"
                                counter="9"
                                v-model="step3State.paymentInfo.bank.routingNumber"
                                hide-details="auto"
                            />
                            <v-text-field
                                label="Account Number*"
                                outlined
                                :rules="[(v) => !!v || 'Account Number is required']"
                                style="max-width: 400px"
                                counter="17"
                                v-model="step3State.paymentInfo.bank.accountNumber"
                                hide-details="auto"
                            />
                          </v-row>
                        </span>
                        <v-row class="ml-1 mt-2">
                          <v-text-field
                              label="Member Name*"
                              outlined
                              counter="60"
                              :disabled="!memberInfoOverride"
                              :rules="[(v) => !!v || 'Member Name is required']"
                              style="max-width: 800px"
                              v-model="step3State.paymentInfo.memberName"
                              hide-details="auto"
                          />
                        </v-row>
                        <v-row class="ml-1 mt-2">
                          <v-text-field
                              label="Member Full Address (for reference), please separate into below fields"
                              readonly
                              style="max-width: 800px"
                              v-model="accountInfo.addressPlusZip"
                              hide-details="auto"
                          />
                        </v-row>
                        <v-row class="ml-1 mt-2">
                          <v-text-field
                              label="Street 1*"
                              outlined
                              counter="100"
                              :disabled="!memberInfoOverride"
                              :rules="rules.street1"
                              style="max-width: 800px"
                              v-model="step3State.paymentInfo.memberAddress.street1"
                              hide-details="auto"
                          />
                        </v-row>
                        <v-row class="ml-1 mt-2">
                          <v-text-field
                              label="Street 2 (Optional)"
                              outlined
                              counter="100"
                              :disabled="!memberInfoOverride"
                              :rules="rules.street2"
                              style="max-width: 800px"
                              v-model="step3State.paymentInfo.memberAddress.street2"
                              hide-details="auto"
                          />
                        </v-row>
                        <v-row class="ml-1 mt-2">
                          <v-text-field
                              label="City*"
                              outlined
                              counter="100"
                              :disabled="!memberInfoOverride"
                              :rules="rules.city"
                              style="max-width: 800px"
                              v-model="step3State.paymentInfo.memberAddress.city"
                              hide-details="auto"
                          />
                        </v-row>
                        <v-row class="ml-1 mt-2">
                          <v-text-field
                              label="Zip/Postal Code*"
                              outlined
                              counter="100"
                              :disabled="!memberInfoOverride"
                              :rules="rules.postalCode"
                              style="max-width: 800px"
                              v-model="step3State.paymentInfo.memberAddress.postalCode"
                              hide-details="auto"
                          />
                        </v-row>
                        <v-layout row wrap class="ml-1 mt-2" style="max-width: 800px">
                      <v-flex xs6>
                        <span class="text-xs-left text--darken-1 grey--text">Country</span>
                        <v-card class="rounded-card" flat elevation="1" min-height="2rem" max-width="25rem">
                          <country-select v-model="step3State.paymentInfo.memberAddress.country" class="country-region-special" placeholder="Select Country ∨" :countryName=true :country="step3State.paymentInfo.memberAddress.country" :disabled="!memberInfoOverride" topCountry="United States"/>
                            <v-text-field v-show="false" v-model="step3State.paymentInfo.memberAddress.country" :disabled="!memberInfoOverride" :rules="rules.country"/>
                        </v-card>
                      </v-flex>
                      <v-spacer></v-spacer>
                      <v-flex xs5>
                        <span class="text-xs-left text--darken-1 grey--text">State/Region</span>
                        <v-card class="rounded-card" flat elevation="1" min-height="2rem" max-width="25rem">
                            <region-select v-if="step3State.paymentInfo.memberAddress.country === 'United States'" v-model="step3State.paymentInfo.memberAddress.stateRegion" class="country-region-special" placeholder="Select State/Region ∨" :countryName=true :country="step3State.paymentInfo.memberAddress.country" :region="step3State.paymentInfo.memberAddress.stateRegion" :disabled="!memberInfoOverride"/>
                            <region-select v-else v-model="step3State.paymentInfo.memberAddress.stateRegion" class="country-region-special" placeholder="Select State/Region ∨" :regionName=true :countryName=true :country="step3State.paymentInfo.memberAddress.country" :region="step3State.paymentInfo.memberAddress.stateRegion" :disabled="!memberInfoOverride"/>
                            <v-text-field v-show="false" v-model="step3State.paymentInfo.memberAddress.stateRegion" :rules="rules.stateRegion"/>
                        </v-card>
                      </v-flex>
                    </v-layout>
                        <v-row class="ml-2">
                          <v-checkbox
                              class="ml-2"
                              label="Override Member Info"
                              v-model="memberInfoOverride"
                          />
                        </v-row>
                      </span>
                    </div>

                    <div v-if="componentsForRequestType[step2State.requestType].includes('editProfileInfo')">
                      <v-row class="ml-1 mt-2" style="max-width: 800px">
                        <v-text-field
                            label="First Name*"
                            outlined
                            counter="30"
                            style="max-width: 400px"
                            v-model="step3State.updateProfileInfo.firstName"
                            hide-details="auto"
                            :rules="rules.firstName"
                        />
                        <v-text-field
                            label="Last Name*"
                            outlined
                            counter="30"
                            style="max-width: 400px"
                            v-model="step3State.updateProfileInfo.lastName"
                            hide-details="auto"
                            :rules="rules.lastName"
                        />
                      </v-row>
                      <v-row class="ml-1 mt-2" style="max-width: 800px">
                      <v-text-field
                            name="legalName"
                            label="Legal Name/Company"
                            counter="60"
                            v-model="step3State.updateProfileInfo.legalName"
                            :rules="rules.legalName"
                        ></v-text-field>
                      </v-row>
                      <v-row class="ml-1 mt-2">
                        <v-text-field
                            label="Member's Address on File"
                            readonly
                            style="max-width: 800px"
                            v-model="accountInfo.addressPlusZip"
                        >
                          <template v-slot:append>
                            <v-tooltip left>
                              <template v-slot:activator="{ on, attrs }">
                                <v-icon v-bind="attrs" v-on="on" color="grey lighten-1">mdi-help-circle-outline</v-icon>
                              </template>
                              Enter their current address into the fields below.
                              If there is no requested change, please confirm their address.
                            </v-tooltip>
                          </template>
                        </v-text-field>
                      </v-row>
                      <v-row class="ml-1 mt-2" style="max-width: 800px">
                        <v-text-field
                            label="Street 1*"
                            outlined
                            counter="100"
                            style="max-width: 400px"
                            v-model="step3State.updateProfileInfo.addressArray.street1"
                            hide-details="auto"
                            :rules="rules.street1"
                        />
                        <v-text-field
                            label="Street 2 (Optional)"
                            outlined
                            counter="100"
                            style="max-width: 400px"
                            hide-details="auto"
                            v-model="step3State.updateProfileInfo.addressArray.street2"
                            :rules="rules.street2"
                        />
                      </v-row>
                      <v-row class="ml-1 mt-2">
                        <v-text-field
                            label="City*"
                            outlined
                            counter="100"
                            style="max-width: 800px"
                            hide-details="auto"
                            v-model="step3State.updateProfileInfo.addressArray.city"
                            :rules="rules.city"
                        />
                      </v-row>
                      <v-row class="ml-1 mt-2">
                        <v-text-field
                            label="Zip/Postal Code*"
                            outlined
                            counter="100"
                            style="max-width: 800px"
                            hide-details="auto"
                            v-model="step3State.updateProfileInfo.addressArray.postalCode"
                            :rules="rules.postalCode"
                        />
                      </v-row>

                      <v-layout row wrap class="ml-1 mt-2" style="max-width: 800px">
                        <v-flex xs6>
                          <span class="text-xs-left text--darken-1 grey--text">Country</span>
                          <v-card class="rounded-card" flat elevation="1" min-height="2rem" max-width="25rem">
                            <country-select v-model="step3State.updateProfileInfo.addressArray.country" class="country-region-special" placeholder="Select Country ∨" :countryName=true :country="step3State.updateProfileInfo.addressArray.country" topCountry="United States"/>
                            <v-text-field v-show="false" v-model="step3State.updateProfileInfo.addressArray.country" :rules="rules.country"/>
                          </v-card>
                        </v-flex>
                        <v-spacer></v-spacer>
                        <v-flex xs5>
                          <span class="text-xs-left text--darken-1 grey--text">State/Region</span>
                          <v-card class="rounded-card" flat elevation="1" min-height="2rem" max-width="25rem">
                            <region-select v-if="step3State.updateProfileInfo.addressArray.country === 'United States'" v-model="step3State.updateProfileInfo.addressArray.stateRegion" class="country-region-special" placeholder="Select State/Region ∨" :countryName=true :country="step3State.updateProfileInfo.addressArray.country" :region="step3State.updateProfileInfo.addressArray.stateRegion"/>
                            <region-select v-else v-model="step3State.updateProfileInfo.addressArray.stateRegion" class="country-region-special" placeholder="Select State/Region ∨" :regionName=true :countryName=true :country="step3State.updateProfileInfo.addressArray.country" :region="step3State.updateProfileInfo.addressArray.stateRegion"/>
                            <v-text-field v-show="false" v-model="step3State.updateProfileInfo.addressArray.stateRegion" :rules="rules.stateRegion"/>
                          </v-card>
                        </v-flex>
                      </v-layout>
                      <v-row class="ml-1 mt-2" style="max-width: 800px">
                        <v-menu
                            v-model="birthdayMenu"
                            :close-on-content-click="false"
                            :nudge-right="40"
                            lazy
                            transition="scale-transition"
                            offset-y
                            full-width
                            min-width="290px">
                          <template v-slot:activator="{ on, attrs }">
                          <v-text-field
                              v-on="on"
                              v-bind="attrs"
                              v-model="step3State.updateProfileInfo.birthDate"
                              label="DOB"
                              prepend-icon="mdi-calendar"
                              @click:prepend="birthdayMenu = true"
                              readonly
                              :rules="rules.birthDate">
                          </v-text-field>
                          </template>
                          <v-date-picker
                              ref="picker"
                              v-model="step3State.updateProfileInfo.birthDate"
                              :max="new Date().toISOString().substr(0, 10)"
                              min="1900-01-01"
                              @input="birthdayMenu = false"
                          >
                          </v-date-picker>
                        </v-menu>
                        <v-spacer/>
                      <v-select
                          label="Authorized Partner"
                          :items="contractorOptions"
                          v-model="step3State.updateProfileInfo.isContractor"
                          style="max-width: 400px"
                      >
                      </v-select>
                      </v-row>
                    </div>


                    <div v-if="componentsForRequestType[step2State.requestType].includes('shippingOrPickUp') &&
                    step3State.paymentForm !== 'Wire' && step3State.paymentForm !== 'ACH'">
                      <v-row class="ml-2">
                        <v-checkbox
                            class="ml-2"
                            label="Shipping"
                            v-model="isShipping"
                            @change="assignShippingAddress"
                            :disabled="isPickup || step3State.paymentForm === 'Paper'"
                        />
                        <v-checkbox
                            class="ml-2"
                            label="Picking-Up"
                            v-model="isPickup"
                            @change="step3State.pickUpDate = addDays(new Date(), 3).toISOString().substr(0, 10)"
                            :disabled="isShipping"
                        />
                      </v-row>
                      <span v-if="isShipping">
                        <v-row class="ml-1 mt-2">
                          <v-text-field
                              label="Member Full Address (for reference), please separate into below fields"
                              readonly
                              style="max-width: 800px"
                              v-model="accountInfo.addressPlusZip"
                          />
                        </v-row>
                        <v-row class="ml-1 mt-2">
                          <v-text-field
                              label="Street 1*"
                              outlined
                              counter="100"
                              :disabled="!shippingOverride"
                              style="max-width: 800px"
                              v-model="step3State.shippingAddress.street1"
                              hide-details="auto"
                              :rules="rules.street1"
                          />
                        </v-row>
                        <v-row class="ml-1 mt-2">
                          <v-text-field
                              label="Street 2 (Optional)"
                              outlined
                              counter="100"
                              :disabled="!shippingOverride"
                              style="max-width: 800px"
                              hide-details="auto"
                              v-model="step3State.shippingAddress.street2"
                              :rules="rules.street2"
                            />
                        </v-row>
                        <v-row class="ml-1 mt-2">
                          <v-text-field
                              label="City*"
                              outlined
                              counter="100"
                              :disabled="!shippingOverride"
                              style="max-width: 800px"
                              hide-details="auto"
                              v-model="step3State.shippingAddress.city"
                              :rules="rules.city"
                            />
                        </v-row>
                          <v-row class="ml-1 mt-2">
                              <v-text-field
                                  label="Zip/Postal Code*"
                                  outlined
                                  counter="100"
                                  :disabled="!shippingOverride"
                                  style="max-width: 800px"
                                  hide-details="auto"
                                  v-model="step3State.shippingAddress.postalCode"
                                  :rules="rules.postalCode"
                                />
                          </v-row>
                        <!--                        Country and State Picker-->
                      <v-layout row wrap class="ml-1 mt-2" style="max-width: 800px">
                        <v-flex xs6>
                          <span class="text-xs-left text--darken-1 grey--text">Country</span>
                          <v-card class="rounded-card" flat elevation="1" min-height="2rem" max-width="25rem">
                              <country-select v-model="step3State.shippingAddress.country" class="country-region-special" placeholder="Select Country ∨" :countryName=true :country="step3State.shippingAddress.country" :disabled="!shippingOverride" topCountry="United States"/>
                              <v-text-field v-show="false" v-model="step3State.shippingAddress.country" :rules="rules.country"/>
                          </v-card>
                        </v-flex>
                        <v-spacer></v-spacer>
                        <v-flex xs5>
                        <span class="text-xs-left text--darken-1 grey--text">State/Region</span>
                          <v-card class="rounded-card" flat elevation="1" min-height="2rem" max-width="25rem">
                              <region-select v-if="step3State.shippingAddress.country === 'United States'" v-model="step3State.shippingAddress.stateRegion" class="country-region-special" placeholder="Select State/Region ∨" :countryName=true :country="step3State.shippingAddress.country" :region="step3State.shippingAddress.stateRegion" :disabled="!shippingOverride"/>
                              <region-select v-else v-model="step3State.shippingAddress.stateRegion" class="country-region-special" placeholder="Select State/Region ∨" :regionName=true :countryName=true :country="step3State.shippingAddress.country" :region="step3State.shippingAddress.stateRegion" :disabled="!shippingOverride"/>
                              <v-text-field v-show="false" v-model="step3State.shippingAddress.stateRegion" :rules="rules.stateRegion"/>
                          </v-card>
                        </v-flex>
                      </v-layout>


                        <v-row class="ml-2" v-if="isShipping">
                          <v-checkbox
                              class="ml-2"
                              label="Override Shipping Address"
                              v-model="shippingOverride"
                          />
                        </v-row>
                      </span>
                      <v-row class="ml-1 mt-2" v-if="isPickup">
                        <v-menu
                            v-model="menu"
                            :close-on-content-click="true"
                            :nudge-right="20"
                            transition="scale-transition"
                            offset-y
                            min-width="0px">
                          <template v-slot:activator="{on, attrs}">
                            <v-text-field
                                label="Requested Pick-Up Date"
                                outlined
                                style="max-width: 400px"
                                v-model="step3State.pickUpDate"
                                :rules="[(v) => !!v || 'Pick-Up Date is required']"
                                readonly
                                v-bind="attrs"
                                v-on="on"
                                clearable
                                hide-details="auto"
                            />
                          </template>
                          <v-date-picker
                              outlined
                              no-title
                              :allowed-dates="(date) => date >= new Date().toISOString().substr(0, 10)"
                              v-model="step3State.pickUpDate"
                              @input="menu = false"
                          ></v-date-picker>
                        </v-menu>
                      </v-row>
                    </div>

                    <div v-if="componentsForRequestType[step2State.requestType].includes('shippingAsset') && isShipping">
                      <v-row class="ml-1 mt-2">
                        <v-select
                            outlined
                            label="Asset Used To Pay For Shipping*"
                            :items="balancesInfo.accountBreakdowns"
                            :item-text="balancesInfo => balancesInfo.assetType + ' (' + balancesInfo.availableBalance + ' Available)'"
                            :item-value="balancesInfo => assetTypes.find(({ name }) => name === balancesInfo.assetType).id"
                            @change="calculateExchange"
                            :loading = "memberSelectedState === 'loading'"
                            no-data-text="Loading..."
                            :rules="[(v) => !!v || 'Shipping Asset is required']"
                            v-model="assets.shippingAsset"
                            style="max-width: 800px"
                            hide-details="auto"/>
                      </v-row>
                      <v-row class="ml-1 mt-2">
                        <v-text-field
                            label="Shipping Amount (In Asset)*"
                            outlined
                            :loading="loadingShipping || loadingAmounts"
                            :rules="[(v) => !!v || 'Shipping Amount is required']"
                            v-model="assets.shippingAmount"
                            @focusout="calculateExchange"
                            :suffix="mixin_suffix(assets.shippingAsset)"
                            :style="{ color: assetColor(assets.shippingAsset),
                                      maxWidth: '400px'}"
                            style="max-width: 400px"
                            hide-details="auto"
                            type="number"
                        />
                        <v-text-field
                            label="Shipping Amount (In USD)"
                            outlined
                            prefix="$"
                            suffix="USD est."
                            :disabled="!assets.shippingAsset"
                            @focusout="calculateExchange(5062)"
                            :loading="loadingShipping || loadingAmounts"
                            v-model="usd.shippingAmount"
                            :style="{ color: assetColor(5062),
                                      maxWidth: '400px'}"
                            style="max-width: 400px"
                            hide-details="auto"
                            type="number"
                        />
                        <v-btn
                            color="primary"
                            class="ml-2 mt-2"
                            :disabled = "!assets.shippingAsset || !assets.fromAmount || !assets.fromAsset"
                            @click="autoShipping"
                        >Auto Calculate Shipping</v-btn>
                      </v-row>
                    </div>

                    <div v-if="componentsForRequestType[step2State.requestType].includes('directedTo')">
                      <v-row class="ml-1 mt-2">
                        <v-select
                            outlined
                            label="Direct Towards Group"
                            class="mb-1"
                            :items="scopeItems"
                            style="max-width: 800px"
                            v-model="step3State.notifyScope"
                            hide-details="auto"/>
                      </v-row>
                      OR
                      <v-row class="ml-1 mt-2">
                        <v-select
                            outlined
                            label="Direct Towards Staff"
                            class="mb-1"
                            :items="staffInfo"
                            item-value="email"
                            item-text="name"
                            style="max-width: 800px"
                            v-model="step3State.notifyUser"
                            hide-details="auto"/>
                      </v-row>
                    </div>

                    <v-row class="ml-1 mt-2">
                      <v-textarea
                          :class="componentsForRequestType[step2State.requestType].includes('memberFacingDesc') ? '' : 'mb-4'"
                          :label="componentsForRequestType[step2State.requestType].includes('memberFacingDesc') ?
                            'Description (Displayed To Member)*' : 'Description*'"
                          outlined
                          v-model="step3State.description"
                          :counter="componentsForRequestType[step2State.requestType].includes('memberFacingDesc') ? '160' : '320'"
                          style="max-width: 800px"
                          :rules="[(v) => !!v  || 'Description is required']"
                          hide-details="auto"
                      />
                    </v-row>
                    <v-row class="ml-1 mt-2" v-if="componentsForRequestType[step2State.requestType].includes('memberFacingDesc')">
                      <v-textarea
                          class="mb-4"
                          label="Internal Note (Optional)"
                          outlined
                          counter="320"
                          style="max-width: 800px"
                          v-model="step3State.internalNote"
                          hide-details="auto"
                      />
                    </v-row>
                  </v-card>
                    <v-row class="ml-1 mt-2" style="max-width: 800px">
                      <v-btn color="primary" class="mr-2" @click="step = 2">
                        Back
                      </v-btn>
                      <v-btn
                          :disabled="!validStepThree"
                          color="primary"
                          @click="step = 4, onStep3Change()"
                      >
                        Next
                      </v-btn>
                      <v-spacer/>
                      <v-tooltip bottom v-if="memberInfo.email">
                        <template #activator="{ on }">
                          <v-btn  v-on="on" class="mr-2" icon @click="loginAs(memberInfo.email)">
                            <v-icon>
                              mdi-account-arrow-right
                            </v-icon>
                          </v-btn>
                        </template>
                        <span>Login as {{memberInfo.email}}</span>
                      </v-tooltip>
                    </v-row>
                </v-form>
              </v-stepper-content>
              <v-stepper-content step="4">
                <v-card flat
                >
                  <div v-if="preError" class="mt-2">
                    <b class="caption">Error Message</b> <br>
                    {{preErrorResponse.message}}
                  </div>
                  <div v-if="preError" class="mt-2">
                    <b class="caption">Validation Errors</b> <br>
                    {{preErrorResponse.validationErrors}}
                  </div>
                  <v-checkbox
                      class="ml-2"
                      label="Make this task a high priority?"
                      v-model="step4State.priority"
                      hide-details
                  />
                  <v-checkbox
                      class="ml-2"
                      label="Follow-up needed?"
                      v-model="followUp"
                  />
                  <v-textarea
                      v-if="followUp"
                      class="mt-2 mb-4"
                      label=" Follow-up Notes"
                      outlined
                      counter="320"
                      style="max-width: 800px"
                      v-model="step4State.followUpNote"
                      hide-details="auto"
                  />
                </v-card>
                <v-row class="ml-1 mt-2" style="max-width: 800px">
                  <v-btn color="primary" class="mr-2" @click="step = 3">
                    Back
                  </v-btn>
                  <v-btn
                      color="primary"
                      class="mr-2"
                      :loading="preLoading"
                      @click="onNewServiceRequestSubmitPre"
                  >
                    Validate Request
                  </v-btn>
                  <v-btn
                      color="primary"
                      :loading="submitLoading"
                      :disabled="!isValidated"
                      class="mr-2"
                      @click="onNewServiceRequestSubmit(0)"
                  >
                    Submit
                  </v-btn>
                  <v-btn
                      color="primary"
                      :loading="submitLoading"
                      :disabled="!isValidated || !isMember"
                      class="mr-2"
                      @click="onNewServiceRequestSubmit(1)"
                  >
                    Submit (Additional Request)
                  </v-btn>
                  <v-spacer/>
                  <v-tooltip bottom v-if="memberInfo.email">
                    <template #activator="{ on }">
                      <v-btn  v-on="on" class="mr-2" icon @click="loginAs(memberInfo.email)">
                        <v-icon>
                          mdi-account-arrow-right
                        </v-icon>
                      </v-btn>
                    </template>
                    <span>Login as {{memberInfo.email}}</span>
                  </v-tooltip>
                </v-row>
              </v-stepper-content>
            </v-stepper-items>
          </v-stepper>

      </v-card>
    <v-card class="mt-2" flat>
      <v-card-text>
        <v-data-table
            :loading="recentRequestsTableLoading"
            disable-sort
            class="elevation-2 mt-n3 mb-2 pb-0"
            loading-text="Loading... Please Wait"
            hide-default-footer
            :headers="recentRequestsTableHeaders"
            :items="recentRequestsData"
            no-data-text="No Service Requests to Display">

          <template v-slot:item.created="{ item }">
            {{mixin_UTCToPretty(item.created)}}
          </template>

          <template v-slot:item.last_updated="{ item }">
            {{mixin_UTCToPretty(item.last_updated)}}
          </template>

          <template v-slot:item.status="{ item }">
            <span :class="item.priority ? 'red--text' : item.simple_status === 'Active' ? '' : 'grey--text'">{{ item.status }}</span>
          </template>

          <template v-slot:item.to_amount ="{ item }">
            <span v-if="item.from_asset" :style="{color: assetColor(item.from_asset)}">
              -{{ item.from_amount }}
            </span>
            <br v-if="item.from_asset && item.to_asset"/>
            <span v-if="item.to_amount" :style="{color: assetColor(item.to_asset)}">
              {{ item.to_amount }}
            </span>
          </template>>

          <template v-slot:item.to_asset ="{ item }">
            <span :style="{color: assetColor(item.from_asset)}">
              {{ assetIDToName(Number(item.from_asset)) }}
            </span>
            <br v-if="item.from_asset && item.to_asset"/>
            <span :style="{color: assetColor(item.to_asset)}">
              {{ assetIDToName(Number(item.to_asset)) }}
            </span>
          </template>

          <template v-slot:item.directed_to ="{ item }">
            {{ item.simple_status === 'Active' ? item.directed_to : '' }}
          </template>


          <template v-slot:item.follow_up ="{ item }">
            <v-tooltip bottom v-if="item.follow_up">
              <template #activator="{ on }">
                <span v-on="on"> {{item.follow_up ? 'Y' : 'N'}} </span>
              </template>
              <span >{{ item.follow_up_note }} <br/> {{ mixin_UTCToPretty(item.timestamp)}}</span>
            </v-tooltip>
            <span v-else>{{item.follow_up ? 'Y' : 'N'}}</span>
          </template>

          <template v-slot:item.new_tab ="{ item }">
            <v-tooltip bottom>
              <template #activator="{ on }">
                <v-btn v-on="on" icon>
                  <v-icon @click="rowClick(item)">mdi-tab-plus</v-icon>
                </v-btn>
              </template>
              <span>Open Task in a New Tab</span>
            </v-tooltip>
          </template>

        </v-data-table>

      </v-card-text>
    </v-card>
    <ErrorDialog ref="errorDialog"/>
    <v-snackbar color="success" class="mt-n2 ml-n2" v-model="preSuccess" top>
      {{preResponse}}
      <v-btn color="white" text @click="preSuccess = false">Close</v-btn>
    </v-snackbar>
    <v-snackbar color="success" class="mt-n2 ml-n2" v-model="submitSuccess" top>
      {{submitResponse}}
      <v-btn color="white" text @click="submitSuccess = false">Close</v-btn>
    </v-snackbar>

<!--                      finCEN Report Popup-->
    <fin-c-e-n-dialog ref="finCENDialog" class="text-center"></fin-c-e-n-dialog>
    <!--                      Hold Dialog Popup-->
    <hold-dialog ref="holdDialog" class="text-center"></hold-dialog>

  </div>

</template>

<script>

import SearchMembers from "../components/SearchMembers";
import ErrorDialog from "../components/ErrorDialog";
import SelectProducts from "../components/SelectProducts";
import FinCENDialog from "../components/FinCENDialog";
import HoldDialog from "../components/holdDialog.vue";
export default {
  name: 'NewServiceRequest',
  components: {
    FinCENDialog, HoldDialog, ErrorDialog, SearchMembers,
    SelectProducts,},
  data() {
    return {
      rates: [],
      estimatedZeroSpread: 0,
      estimatedBuyBack: 0,
      componentKey: 0,
      validStepOne: false,
      validStepTwo: false,
      validStepThree: false,
      validStepFour: false,
      stepOneDone: false,
      isMember: false,
      menu: false,
      birthdayMenu: false,
      toggleFromState: false,
      toggleToState: false,
      memberInfo: {},
      accountInfo: {
        address: null,
        zipCode: null,
        addressPlusZip: null,
        isContractor: null,
        account_locked: false,
        addressArray: {
          street1: null,
          street2: null,
          city: null,
          postalCode: null,
          stateRegion: null,
          country: null
        },
      },
      banksInfo: [],
      tlInfo: [],
      recruitItems: [],
      balancesInfo: {},
      recruitInfo: {
        recruits: {
        },
        recruiter: null
      },
      requestType: {},
      staffInfo: {},
      shippingOverride: false,
      memberInfoOverride: false,
      recipientSelection: false,
      showSelected: false,
      recipientInfo: {},
      recruitOrRecruiterSelection: false,
      selectedRecruitItem: null,
      recruitOrRecruiterInfo: {},
      step: 1,
      newServiceRequest: {},
      step1State: {},
      step2State: {},
      step3State: {
        metalsPhysicallyPresent: null,
        immediateConversion: null,
        paymentType: null,
        dcaPeriod: null,
        checkPhysicallyPresent: null,
        transferTarget:null,
        releaseTransferFunds: null,
        recruitInfo: {
          username: null,
          action: null,
          recruitOrRecruiter: null
        },
        updateProfileInfo: {
          firstName: null,
          lastName: null,
          legalName: null,
          birthDate: null,
          isContractor: null,
          addressArray: {
            street1: null,
            street2: null,
            city: null,
            postalCode: null,
            stateRegion: null,
            country: null
          }
        },
        shippingAddress: {
          street1: null,
          street2: null,
          city: null,
          postalCode: null,
          stateRegion: null,
          country: null
        },
        paymentInfo: {
          memberName: null,
          memberAddress : {
            street1: null,
            street2: null,
            city: null,
            postalCode: null,
            stateRegion: null,
            country: null
          }
        }
      },
      step4State: {priority: false},
      isShipping: false,
      shippingInfo: {},
      otp: '',
      validOTP: false,
      assets: {},
      buy: {},
      usd: {
        toAmount: 0,
        fromAmount: 0,
        shippingAmount: 0
      },
      depositPreferences: {},
      noDepositPreference: false,
      isPickup: false,
      paymentForm: '',
      isJournal: false,
      inOffice: false,
      isPhysicalMetalsRequested: false,
      followUp: false,
      isValidated: false,
      preSuccess: false,
      preResponse: '',
      preError: false,
      preErrorResponse: {},
      preLoading: false,
      submitSuccess: false,
      submitResponse: '',
      memberSelectedState: 'waiting',
      submitLoading: false,
      loadingShipping: false,
      loadingAmounts: false,
      loadingOTP: false,
      loadingPreferences: false,
      viewBillPayPreferences: false,
      verificationItems: ["Security question", "Email address matches our records", "Not needed", "One-Time Passcode", "Other"],
      communicationItems: ["Phone", "Email", "In-Person", "Mail"],
      scopeItems: ['STAFF', 'ADMIN', 'ACCTG', 'SALES', 'LEASE', 'SUPER', 'SHIPG', 'ESCRW', 'SIGNR'],
      contractorOptions:[
        {
          text: "true",
          value: true
        },
        {
          text: "false",
          value: false
        }
      ],
      memberBoolean: [
        {
          text: "Yes",
          value: true
        },
        {
          text: "No",
          value: false
        }
      ],
      requestItemsMember: [
        "General Question",
        "Exchange",
        "Deposit Metals",
        "Deposit USD",
        "Member To Member Transfer",
        "Withdraw",
        "Redeem Metals",
        "Load TrueLink Card",
        "Order TrueLink Card",
        "Update Profile Info",
        "Update Recruit Info",
        "Request Account Changes",
        "Special Action Item"
      ],
      requestItemsNonMember: [
        "General Question"
      ],
      requestDescriptions: {
        "General Question":
            "This option serves as a channel for general inquiries across departments.<br>" +
            "Using discord is preferred.",
        "Exchange": "Utilized when a member desires to swap one type of asset for another in their account.<br>" +
            "This service does not include depositing assets, which must be done separately.",
        "Deposit Metals": "Utilized when a member is depositing physical metals into their UPMA account.<br>" +
            "Only for UPMA-accepted metals (US minted silver or gold eagles or gold buffalos).",
        "Deposit USD": "Utilized when a member intends to fund their account with a check or paper.<br>" +
            "If a member is depositing paper and wants to allocate funds to a metal account, " +
            "include that asset in this submission.",
        "Member To Member Transfer":
            "Utilized when a member wishes to transfer funds from their account to another UPMA member.<br>" +
            "These can be set up to leave in escrow or release funds following additional approval.",
        "Withdraw": "Utilized when a member wishes to withdraw assets from their UPMA account without an exchange being performed.",
        "Redeem Metals": "Utilized when a member intends to liquidate their metals (into USD).",
        "Load TrueLink Card": "Load your TrueLink card with funds.<br>" +
            "Members must maintain a balance of $10USD in their UPMA account for the card to remain active.",
        "Order TrueLink Card": "Utilized when a member wishes to request a Truelink Card for their account.<br>" +
            "A minimum available balance of $50USD is required to order a card, " +
            "and the account must maintain an available balance of $10USD or more.",
        "Update Profile Info": "Utilized when a member wishes to update either their name, address, phone number, DOB, and/or contractor status.",
        "Update Recruit Info": "Utilized when a member needs to add/ remove recruits or add/remove/update their recruiter on their account.<br>"+
            "Any change that includes the removal of a recruit or recruiter will need Head MSR approval.",
        "Request Account Changes": "Utilized when a member requests specific actions on their account.<br>" +
            "Examples include: adjustments to leases, updating their contact email, " +
            "disabling their account (DND), or enabling/disabling security options.",
        "Special Action Item": "Approval from the Head MSR is mandatory before submitting a Special Action Item.<br>" +
            "Reserved for requests that do not align with the aforementioned categories " +
            "or when a specific task needs to be performed by a particular department. "
      },
      componentsForRequestType: {
        "General Question": [
        ],
        "Exchange": [
          "fromAsset",
          "toAssetWithOverride",
          "memberFacingDesc"
        ],
        "Deposit Metals": [
          "toAssetNoOverride",
          "memberFacingDesc",
          "toMetalsOnly"
        ],
        "Deposit USD": [
          "depositUsdInfo",
          "memberFacingDesc"
        ],
        "Member To Member Transfer": [
          "transferInfo",
          "fromAsset",
          "memberFacingDesc"
        ],
        "Withdraw": [
          "fromAsset",
          "productSelection",
          "shippingOrPickUp",
          "shippingAsset",
          "memberFacingDesc"
        ],
        "Redeem Metals": [
          "fromAsset",
          "toAssetWithOverride",
          "toPaperOnly",
          "redeemPayment",
          "liquidateOrPawn",
          "shippingOrPickUp",
          "memberFacingDesc"
        ],
        "Load TrueLink Card": [
          "fromAsset",
          "toAssetNoOverride",
          "trueLinkCards",
          "liquidateOrPawn",
          "toPaperOnly",
          "memberFacingDesc"
        ],
        "Order TrueLink Card": [
        ],
        "Request Account Changes": [
        ],
        "Special Action Item": [
            "directedTo"
        ],
        "Update Recruit Info": [
            "editRecruitInfo"
        ],
        "Update Profile Info": [
            "editProfileInfo"
        ]
      },

      productHeaders: [
        {text: 'Product Group', value: 'group_name', align: 'start'},
        {text: 'Product', value: 'name', align: 'start'},
        {text: 'Quantity', value: 'quantity', align: 'end'}
      ],

      //"In-Office Purchase/Sale"
      assetTypes: [
        {id: 3373, name: "Gold"},
        {id: 3374, name: "Silver"},
        {id: 7000, name: 'Goldback'},
        {id: 5062, name: 'Paper'}
      ],
      assetTypesNoPaper: [
        {id: 3373, name: "Gold"},
        {id: 3374, name: "Silver"},
        {id: 7000, name: 'Goldback'}
      ],
      paymentMethods: [
        'Check',
        'Paper'
      ],
      dcaOptions: [
        {text: 'One Day (Next Business Day)', value: 1},
        {text: 'One Week (5 Business Days)', value: 5},
        {text: 'Two Weeks (10 Business Days)', value: 10},
        {text: 'One Month (20 Business Days)', value: 20},
      ],
      redeemPaymentForms: ['ACH', 'Wire', 'Check', 'Paper'],

      recentRequestsTableLoading: false,
      recentRequestsData: [],
      recentRequestsTableHeaders: [
        {value: 'created', text: 'Created'},
        {value: 'last_updated',  text: 'Last Updated'},
        {value: 'day_due', text: 'Actionable On'},
        {value: 'type',  text: 'Type'},
        {value: 'status',  text: 'Status'},
        {value: 'name',text: 'Name'},
        {value: 'username',   text: 'Username'},
        {value: 'to_amount', text: 'Amounts', align: 'end'},
        {value: 'to_asset', text: 'Assets', align: 'start'},
        {value: 'order_id', text: 'Order #'},
        {value: 'directed_to', text: 'Directed Towards'},
        {value: 'follow_up',   text: 'Follow Up?'},
        {value: 'new_tab',   text: '', align: 'end'}
      ],

//--------- Validation Variables ---------//

      //to fill out
      requiredFields: [],

      rules: {
        firstName: [
          (v) => !!v || 'First Name is required',
          (v) => (v || '').length <= 60 || 'First Name cannot be longer than 60 characters'
        ],
        lastName: [
          (v) => !!v || 'Last Name is required',
          // (v) => (v || '').length >= 2 || 'Last Name must have at least 2 characters',
          (v) => (v || '').length <= 75 || 'Last Name cannot be longer than 75 characters'
        ],
        legalName: [
          (v) => !!v || 'Legal Name is required',
          (v) => (v || '').length <= 75 || 'Legal Name cannot be longer than 75 characters'
        ],
        street1: [
          (v) => !!v || 'Street 1 is required',
          (v) => (v || '').length <= 100 || 'Street 1 cannot be longer than 100 characters'
        ],
        street2: [
          (v) => (v || '').length <= 100 || 'Street 2 cannot be longer than 100 characters'
        ],
        city: [
          (v) => !!v || 'City is required',
          (v) => (v || '').length <= 60 || 'City cannot be longer than 60 characters'
        ],
        stateRegion: [
          (v) => !!v || 'Select a state'
        ],
        country: [
          (v) => !!v || 'Select a country'
        ],
        postalCode: [
          (v) => !!v || 'Zip/Postal Code is required',
          (v) => (v || '').length >= 4 || 'Zip/Postal Code must have at least 4 digits',
          (v) => (v || '').length <= 8 || 'Zip/Postal Code cannot have more than 8 digits',
          (v) => /^[a-z0-9 -]+$/i.test(v) || 'Zip/Postal Code must be alphanumeric and can include spaces and hyphens'
        ],
        memberSelect: [
          (v) => !!v || 'Select a member'
        ],
        birthday: [
          (v) => !!v || 'Birth-date is required',
          (v) => /^(19|20)\d\d[-](0[1-9]|1[012])[-](0[1-9]|[12][0-9]|3[01])$/.test(v) || 'Invalid Birth-date format'
        ],
      },

      valid: {
        stateRegion: '',
        country: '',
        memberSelect: '',
        paymentInfo: {
          stateRegion: '',
          country: ''
        }
      },


    }
  },
  watch: {
    'followUp'(newval, oldval) {
      if(this.followUp){
        this.step4State.followUp = newval
      }
      else{
        delete this.step4State.followUp
        delete this.step4State.followUpNote
      }
      this.onRequestChange()
      console.log(newval, oldval)
    },
    'step4State.followUpNote'(newval, oldval) {
      this.onRequestChange()
      console.log(newval, oldval)
    },
    'otp'(newval) {
      if (newval.length === 10) {
        this.verifyOTP()
      }
      else {
        this.validOTP = false;
      }
    },
    'selectedRecruitItem'(newValue) {
      if (!newValue) return; // Do nothing if null or undefined

      // Reset the states
      this.step3State.recruitInfo.action = null;
      this.step3State.recruitInfo.recruitOrRecruiter = null;
      this.step3State.recruitInfo.username = null;
      this.recruitOrRecruiterSelection = false;
      this.showSelected = false;

      // Handle action
      if (newValue.includes('Add')) {
        this.step3State.recruitInfo.action = 'add';
      } else if (newValue.includes('Update')) {
        this.step3State.recruitInfo.action = 'update';
      } else if (newValue.includes('Remove')) {
        this.step3State.recruitInfo.action = 'remove';
      }

      // Handle 'recruit' or 'recruiter' — Note the order of checking
      if (newValue.includes('recruiter')) {
        this.step3State.recruitInfo.recruitOrRecruiter = 'recruiter';
      } else if (newValue.includes('recruit')) {
        this.step3State.recruitInfo.recruitOrRecruiter = 'recruit';
      }

      // Handle username for the 'remove recruiter' scenario
      if (this.step3State.recruitInfo.action === 'remove' && this.step3State.recruitInfo.recruitOrRecruiter === 'recruiter' && newValue.includes(this.recruitInfo.recruiter)) {
        this.step3State.recruitInfo.username = this.recruitInfo.recruiter;
        this.recruitOrRecruiterSelection = false;
      } else {
        // Handle username for recruit
        const match = newValue.match(/recruit: ([\s\S]+)/);
        if (match) {
          this.step3State.recruitInfo.username = match[1]; // Captured email value
        } else {
          this.recruitOrRecruiterSelection = true;
        }
      }
    }
  },
  computed: {
    name: {
      get() {
        return this.step2State.name
      },
      set(val) {
        if(val === ' ') {
          this.step2State.name = ''
          return val
        }
        this.step2State.name = val
        return val
      }
    },
    username: {
      get() {
        return this.step2State.memberUsername
      },
      set(val) {
        this.step2State.memberUsername = val
        return val
      }
    },
    isFromAssetDisabled() {
      return (
          this.componentsForRequestType[this.step2State.requestType].includes('productSelection') &&
          !!this.assets.fromAsset
      )
    },
    filteredAccountBreakdowns() {
      if (this.componentsForRequestType[this.step2State.requestType].includes('transferInfo') ||
          this.componentsForRequestType[this.step2State.requestType].includes('redeemPayment')) {
        return this.balancesInfo.accountBreakdowns.filter(item => item.assetType !== 'Paper');
      } else {
        return this.balancesInfo.accountBreakdowns;
      }
    },
  },
  mounted() {
    localStorage.title = 'New Service Request';
    // this.getProductGroups(true)
    this.getRates()
    this.getStaff()
    this.getRecentRequests()
  },

  methods: {

    assignShippingAddress: function () {
      this.step3State.shippingAddress.street1 = this.accountInfo.address_array.street_1;
      this.step3State.shippingAddress.street2 = this.accountInfo.address_array.street_2;
      this.step3State.shippingAddress.city = this.accountInfo.address_array.city;
      this.step3State.shippingAddress.stateRegion = this.accountInfo.address_array.state_region;
      this.step3State.shippingAddress.postalCode = this.accountInfo.address_array.postal_code;
      this.step3State.shippingAddress.country = this.accountInfo.address_array.country;
    },

    assignProfileInfo: function() {
      this.step3State.updateProfileInfo.firstName = this.accountInfo.firstName;
      this.step3State.updateProfileInfo.lastName = this.accountInfo.lastName;
      this.step3State.updateProfileInfo.legalName = this.accountInfo.legalName;
      this.step3State.updateProfileInfo.birthDate = this.accountInfo.birthDate;
      this.step3State.updateProfileInfo.isContractor = this.accountInfo.is_contractor;
      this.step3State.updateProfileInfo.addressArray.street1 = this.accountInfo.address_array.street_1;
      this.step3State.updateProfileInfo.addressArray.street2 = this.accountInfo.address_array.street_2;
      this.step3State.updateProfileInfo.addressArray.city = this.accountInfo.address_array.city;
      this.step3State.updateProfileInfo.addressArray.stateRegion = this.accountInfo.address_array.state_region;
      this.step3State.updateProfileInfo.addressArray.country = this.accountInfo.address_array.country;
      this.step3State.updateProfileInfo.addressArray.postalCode = this.accountInfo.address_array.postal_code;
    },

    handleAssetChange: function (asset) {
      this.assets.toAsset = asset;
    },

    rowClick: function (item) {
      window.open('/tasks/' + item.uuid, '_blank');
    },

    clearForm(anotherRequest) {
      this.balancesInfo = {}
      this.accountInfo = {}
      this.banksInfo = []
      this.tlInfo = []
      this.memberSelectedState = 'waiting'
      if (anotherRequest) {
        this.step = 2
        let verificationType = this.step2State.verificationType;
        let verificationDescription = this.step2State.verificationDescription;
        let memberInfo = this.memberInfo;
        this.memberInfo = {}
        this.step2State = {}
        this.step2State.verificationType = verificationType;
        this.step2State.verificationDescription = verificationDescription;
        this.memberSelected(memberInfo);
        this.isMember = true
        this.stepOneDone = true
      } else {
        this.step = 1
        this.step1State = {}
        this.step2State = {}
        this.memberInfo = {}
        this.isMember = false
        this.stepOneDone = false
      }
      this.componentKey = 0
      this.requestType = {}
      this.newServiceRequest =  {}
      this.step3State =  {
        recruitInfo: {
          username: null,
          action: null,
          recruitOrRecruiter: null
        },
        updateProfileInfo: {
          firstName: null,
          lastName: null,
          legalName: null,
          birthDate: null,
          isContractor: null,
          addressArray: {
            street1: null,
            street2: null,
            city: null,
            postalCode: null,
            stateRegion: null,
            country: null
          }
        },
        shippingAddress: {
          street1: null,
          street2: null,
          city: null,
          postalCode: null,
          stateRegion: null,
          country: null
        },
        paymentInfo: {
          memberName: null,
          memberAddress: {
            street1: null,
            street2: null,
            city: null,
            postalCode: null,
            stateRegion: null,
            country: null
          }
        }
      }
      this.step4State = {}
      this.isShipping = false
      this.shippingOverride = false
      this.memberInfoOverride = false
      this.shippingInfo = {}
      this.otp = ''
      this.validOTP = false
      this.isPickup =  false
      this.paymentForm =  ''
      this.isJournal =  false
      this.inOffice = false
      this.isPhysicalMetalsRequested =  false
      this.followUp =  false
      this.isValidated = false
      this.assets = {}
      this.buy = {}
      this.usd = {
        toAmount: 0,
        fromAmount: 0,
        shippingAmount: 0
      }
      this.loadingAmounts = false;
      if (!anotherRequest) {
        if (this.$refs.stepOneForm) {
          this.$refs.stepOneForm.reset()
        }
        if (this.$refs.stepTwoForm) {
          this.$refs.stepTwoForm.reset()
        }
      }
      if (this.$refs.stepThreeForm) {
        this.$refs.stepThreeForm.reset()
      }
    },

    memberSelected: async function (member) {
      this.memberSelectedState = 'loading'
      this.balancesInfo = {}
      this.accountInfo = {}
      this.banksInfo = []
      this.tlInfo = []
      this.validOTP = false
      this.otp = ''
      this.memberInfo = {...member}
      this.requestType = {};
      if (!member.lastName) {
        this.memberInfo.name = 'Unnamed';
      }
      this.name = this.memberInfo.name
      this.username = member.email

      await this.setAccountInfo(member.email);
      await this.setBalancesInfo(member.email);
      await this.setTLInfo(member.email);
      await this.setBanksInfo(member.email);
      await this.getMemberRates(member.email);
      this.memberSelectedState = 'display'
    },

    recruitOrRecruiterSelected: async function (recruitOrRecruiter) {
      this.recruitOrRecruiterInfo = {...recruitOrRecruiter};
      this.step3State.recruitInfo.username = this.recruitOrRecruiterInfo.email;
      this.showSelected = true;
    },

    getRates: async function () {
      let rates = await this.$axios.get('/public/rates');
      this.rates[3373] = {
        rate: rates.data.gold_rate.replace(/\$|,/g, ''),
        buy_back: parseFloat(rates.data.gold_buy_back.replace(/\$|,/g, '') / 50).toFixed(2)
      };
      this.rates[3374] = {
        rate: rates.data.silver_rate.replace(/\$|,/g, ''),
        buy_back: rates.data.silver_buy_back.replace(/\$|,/g, '')
      };
      this.rates[7000] = {
        rate: rates.data.goldback_rate.replace(/\$|,/g, ''),
        buy_back: rates.data.goldback_buy_back.replace(/\$|,/g, '')
      };
      this.rates[5062] = {
        rate: 1,
        buy_back: 1
      };
    },

    //Pulls the member-specific rate and updates rate used (necessary for wholesale accounts) SMS 2023-05-11
    getMemberRates: async function (username) {
      let body = {
        username: username
      }
      let rates = await this.$axios.get('/rates/member', {params: body});
      this.rates[3373].rate = rates.data.gold_rate.replace(/\$|,/g, '');
      this.rates[3374].rate = rates.data.silver_rate.replace(/\$|,/g, '');
      this.rates[7000].rate = rates.data.goldback_rate.replace(/\$|,/g, '');
      },

    getUSDEq: function (amount, asset) {
      if (amount && asset) {
        return parseFloat(amount * this.rates[asset].rate).toFixed(2);
      }
    },

    getSpecieEq: function (amount, asset) {
      if (amount && asset) {
        return parseFloat(amount / this.rates[asset].rate).toFixed(5);
      }
    },

    verifyOTP: function () {
      const url = '/otp/verify'

      this.loadingOTP = true;
      this.$axios.post(url, {'username': this.memberInfo.email, 'otp': this.otp}).then(() => {
        this.validOTP = true;
        this.loadingOTP = false;
      }).catch(() => {
        this.validOTP = false;
        this.loadingOTP = false;
      });
    },

    recipientSelected: async function (recipient) {
      this.recipientInfo = {...recipient};
      this.step3State.transferTarget = this.recipientInfo.email;
      this.showSelected = true;
      this.recipientSelection = true;
    },

    autoShipping: function() {
      let body = {
        assetAmount: this.assets.fromAmount,
        assetTypeID: this.assets.fromAsset,
        username: this.username
      }
      this.loadingShipping = true;
      this.$axios.get('/shipping', {params: body})
      .then(({data}) => {
        this.assets.shippingAmount = parseFloat(data.shipping_cost_usd / this.rates[this.assets.shippingAsset].rate).toFixed(5);
        this.calculateExchange();
        this.loadingShipping = false;
      }).catch(({response}) => {

          this.loadingShipping = false;
          this.$refs.errorDialog.showError({
            errorMessage: response.message ? response.message : "There was an error calculating shipping. If you continue to experience problems please notify the development team.",
            forDevelopers: response
          })
      });
    },

    setEquivalents(assetType) {
      if (assetType === 5062) {
        if (this.usd.toAmount && this.assets.toAsset) {
          this.assets.toAmount = this.getSpecieEq(this.usd.toAmount, this.assets.toAsset)
        }
        if (this.usd.fromAmount && this.assets.fromAsset) {
          this.assets.fromAmount = this.getSpecieEq(this.usd.fromAmount, this.assets.fromAsset)
        }
        if (this.usd.shippingAmount && this.assets.shippingAsset) {
          this.assets.shippingAmount = this.getSpecieEq(this.usd.shippingAmount, this.assets.shippingAsset)
        }
      }
      if (assetType !== 5062) {
        if (this.assets.toAmount && this.assets.toAsset) {
          this.usd.toAmount = this.getUSDEq(this.assets.toAmount, this.assets.toAsset)
        }
        if (this.assets.fromAmount && this.assets.fromAsset) {
          this.usd.fromAmount = this.getUSDEq(this.assets.fromAmount, this.assets.fromAsset)
        }
        if (this.assets.shippingAmount && this.assets.shippingAsset) {
          this.usd.shippingAmount = this.getUSDEq(this.assets.shippingAmount, this.assets.shippingAsset)
        }
      }
    },

    checkPaymentType: function (paymentType) {
      if (paymentType === 'Paper' || paymentType === 'Check') {
        this.assets.fromAsset = 5062;
      }
    },

    calculateExchange: function (assetType) {
      this.loadingAmounts = true;
      this.setEquivalents(assetType);
      if (this.assets.fromAsset && !this.assets.override && this.assets.fromAmount) {
        this.assets.fromAmount = parseFloat(this.assets.fromAmount).toFixed(5);
        let zero_spread_amount = (this.balancesInfo.remaining10KAllowance.replace(/\$|,/g, '')
            - (this.assets.fromAmount * this.rates[this.assets.fromAsset].rate)) >= 0
            ? this.assets.fromAmount
            : parseFloat(this.balancesInfo.remaining10KAllowance.replace(/\$|,/g, '') / this.rates[this.assets.fromAsset].rate).toFixed(5);
        this.estimatedZeroSpread = zero_spread_amount;
        this.estimatedBuyBack = this.assets.fromAmount - zero_spread_amount;
        if (this.assets.toAsset) {
          this.assets.toAmount = parseFloat((this.estimatedZeroSpread * this.rates[this.assets.fromAsset].rate
                  + this.estimatedBuyBack * this.rates[this.assets.fromAsset].buy_back)
              / this.rates[this.assets.toAsset].rate).toFixed(5);
        }
      }
      this.setEquivalents(!assetType);
      this.loadingAmounts= false;
    },

    populateInitialPaymentInfo: function () {
      if (this.step3State.paymentForm === 'Paper') {
        this.isShipping = false
        this.isPickup = true;
      }
      this.step3State.paymentInfo.memberName = this.name;
      this.step3State.paymentInfo.memberAddress.street1 = this.accountInfo.address_array.street_1;
      this.step3State.paymentInfo.memberAddress.street2 = this.accountInfo.address_array.street_2;
      this.step3State.paymentInfo.memberAddress.city = this.accountInfo.address_array.city;
      this.step3State.paymentInfo.memberAddress.stateRegion = this.accountInfo.address_array.state_region;
      this.step3State.paymentInfo.memberAddress.country = this.accountInfo.address_array.country;
      this.step3State.paymentInfo.memberAddress.postalCode = this.accountInfo.address_array.postal_code;
    },

    setAccountInfo: async function (username) {
      let res = await this.$axios.get('/account?username=' + username);
      this.accountInfo = res.data;

      //JDS 2023-04-25 Added this to prevent using improper v-model
      this.accountInfo.addressPlusZip = this.accountInfo.address + ' ' + this.accountInfo.zipCode;
    },

    setTLInfo: async function (username) {
      let res = await this.$axios.get('/cards?username=' + username);
      res.data.cards.forEach(item => {
        if (item.status === 'Activated') this.tlInfo.push(item);
      });
    },

    setBanksInfo: async function (username) {
      let res = await this.$axios.get('/banks?username=' + username);
      let defaultBank = {
        accountNumber: '',
        routingNumber: '',
        nickname: 'Manual Entry',
      };
      res.data.push(defaultBank);
      this.banksInfo = res.data;
    },

    setBalancesInfo: async function (username) {
      let res = await this.$axios.get('/admin/user/balances?username=' + username);
      let balanceInfo = res.data;
      balanceInfo.accountBreakdowns.forEach(
          breakdown => {
            breakdown.assetType = breakdown.assetType.replace(' Dollar', '');
            breakdown.availableBalance = breakdown.availableBalance.replace('$', '');
          }
      )
      this.balancesInfo = balanceInfo;
    },

    getRecruitInfo: async function (username) {
      let res = await this.axios.get('/recruits?username=' + username);
      this.recruitInfo = res.data;
      await this.setRecruitDropdownItems();
    },

    getDepositPreferences: async function (username) {
      this.loadingPreferences = true;
      let res = await this.axios.get('admin/user/depositPreferences?username=' + username);
      this.depositPreferences = res.data;
      this.noDepositPreference = Object.values(this.depositPreferences).every(value => value === null);
      if (this.noDepositPreference === false) {
        this.formatDepositPreferences();
      }
      this.loadingPreferences = false;
    },

    formatDepositPreferences() {
      this.depositPreferences.gold_percent = this.depositPreferences.gold_percent ?
          Number(this.depositPreferences.gold_percent) * 100 : 0;
      this.depositPreferences.silver_percent = this.depositPreferences.silver_percent ?
          Number(this.depositPreferences.silver_percent) * 100 : 0;
      this.depositPreferences.goldback_percent = this.depositPreferences.goldback_percent ?
          Number(this.depositPreferences.goldback_percent) * 100 : 0;
      this.depositPreferences.gold_dca = this.depositPreferences.gold_dca ?
          this.depositPreferences.gold_dca : 1;
      this.depositPreferences.silver_dca = this.depositPreferences.silver_dca ?
          this.depositPreferences.silver_dca : 1;
    },

    setRecruitDropdownItems() {
      this.recruitItems = [];

      // Handle recruiter
      if (this.recruitInfo.recruiter === null) {
        this.recruitItems.push('Add recruiter');
      } else {
        this.recruitItems.push('Update recruiter: ' + this.recruitInfo.recruiter);
        this.recruitItems.push('Remove recruiter: ' + this.recruitInfo.recruiter);
      }

      this.recruitItems.push('Add recruit');

      // Handle recruits
      if (this.recruitInfo.recruits.length > 0) {
        Object.values(this.recruitInfo.recruits).forEach(item => {
          if (item && item.recruit) {
            this.recruitItems.push('Remove recruit: ' + item.recruit);
          }
        });
      }
    },

    forceRerender() {
      this.componentKey += 1;
    },

    openFinCENDialog() {
      this.$refs.finCENDialog.openDialog();
    },

    openHoldDialog() {
      this.$refs.holdDialog.openDialog();
    },

    onRequestChange() {
        if( JSON.stringify({...this.step1State, ...this.step2State, ...this.step3State, ...this.step4State, assets: {...this.assets}, buy: {...this.buy}}) !== JSON.stringify(this.newServiceRequest)){
          this.isValidated = false
        }
    },

    onSelectedProductChange(products) {
      this.loadingAmounts = true;
      this.buy.products = this.formatProduct(products);
      this.assets.fromAmount = 0;
      this.buy.products.forEach(item => {
        this.assets.fromAmount += Number(item.quantity) * Number(item.denomination);
      });
      this.calculateExchange(this.assets.fromAsset);
      this.loadingAmounts = false;
    },

    formatProduct(products) {
      let tableArray = [];
      let unrefinedProducts = products;

      Object.keys(unrefinedProducts).forEach(key => {
        Object.keys(unrefinedProducts[key]).forEach(key2 => {
          if(unrefinedProducts[key][key2].quantity > 0) {
            unrefinedProducts[key][key2].group_name = key;
            tableArray.push({...unrefinedProducts[key][key2]});
          }
        })
      });
      return tableArray;
    },

    onStep1Change() { //add step 4?
      if(this.step1State.isMember !== undefined && this.step1State.isMember !== this.isMember) {
        this.isMember = this.step1State.isMember
        this.step2State = {}
        this.isShipping = false
        this.shippingInfo = {}
        this.otp = ''
        this.validOTP = false
        this.step3State = {
          updateProfileInfo: {
            firstName: null,
            lastName: null,
            legalName: null,
            birthDate: null,
            isContractor: null,
            addressArray: {
              street1: null,
              street2: null,
              city: null,
              stateRegion: null,
              country: null,
              postalCode: null
            }
          },
          shippingAddress: {
            street1: null,
            street2: null,
            city: null,
            postalCode: null,
            stateRegion: null,
            country: null
          }
        }
      }
      this.onRequestChange()
    },

    onStep2Change() { //add step 4?
      if(this.step2State.requestType !== this.requestType){
        this.requestType = this.step2State.requestType
        this.isShipping = false
        this.isPickup = false
        this.shippingOverride = false;
        this.memberInfoOverride = false;
        this.recipientSelection = false;
        this.recipientInfo = false;
        this.recruitOrRecruiterSelection = false;
        this.selectedRecruitItem = null;
        this.depositPreferences = {};
        this.viewBillPayPreferences = false;
        this.recruitOrRecruiterInfo = {};
        this.shippingInfo = {}
        this.step3State = {
          metalsPhysicallyPresent: null,
          immediateConversion: null,
          paymentType: null,
          dcaPeriod: null,
          checkPhysicallyPresent: null,
          transferTarget: null,
          releaseTransferFunds: null,
          recruitInfo: {
            username: null,
            action: null,
            recruitOrRecruiter: null
          },
          updateProfileInfo: {
            firstName: null,
            lastName: null,
            legalName: null,
            birthDate: null,
            isContractor: null,
            addressArray: {
              street1: null,
              street2: null,
              city: null,
              postalCode: null,
              stateRegion: null,
              country: null
            }
          },
          shippingAddress: {
            street1: null,
            street2: null,
            city: null,
            postalCode: null,
            stateRegion: null,
            country: null
          },
          paymentInfo: {
            memberName: null,
            memberAddress: {
              street1: null,
              street2: null,
              city: null,
              postalCode: null,
              stateRegion: null,
              country: null
            }
          }
        }
        this.assets = {}
        this.buy = {}
        this.usd = {
          toAmount: 0,
          fromAmount: 0,
          shippingAmount: 0
        }
        if(this.componentsForRequestType[this.step2State.requestType].includes('toPaperOnly')) {
          this.assets.toAsset = 5062;
        }
        if(this.componentsForRequestType[this.step2State.requestType].includes('editRecruitInfo')) {
          this.getRecruitInfo(this.memberInfo.email);
        }
        if(this.componentsForRequestType[this.step2State.requestType].includes('depositUsdInfo')) {
          this.getDepositPreferences(this.memberInfo.email);
        }
        if(this.componentsForRequestType[this.step2State.requestType].includes('editProfileInfo')) {
          this.assignProfileInfo();
        }
      }
      this.onRequestChange()
    },

    async onNewServiceRequestSubmitPre() {
      this.newServiceRequest = this.setSRAndDeleteUnused();
      if (this.requestType !== 'Withdraw' || this.assets.fromAsset === 5062) delete this.newServiceRequest.buy; //should only have to delete in pre-request
      else {
        this.newServiceRequest.buy.rates = {
          gold: Number(this.rates[3373].rate),
          silver: Number(this.rates[3374].rate),
          goldback: Number(this.rates[7000].rate)
        }
        this.newServiceRequest.buy.summary = {
          paidAmount: 0.00,
          totalAmount: 0.00
        }
      }

      // SMS backend was changed to separate 'Deposit Metals/Cash' Service Request into 'Deposit Metals' Service Request and 'Deposit USD' Service Request
      // if (this.requestType === 'Deposit USD' || this.requestType === 'Deposit Metals') {
      //   if (this.requestType === 'Deposit USD') this.newServiceRequest.immediateConversion = false;
      //   if (this.requestType === 'Deposit USD') this.newServiceRequest.metalsPhysicallyPresent = true; //returns true so will not go through pending
      // this.newServiceRequest.requestType = 'Deposit Metals/Cash'; //had to split this out for MSR to stop messing up
      if (this.requestType === 'Deposit USD' && this.assets.toAsset === 5062) {
        //SMS if a member is making a non-Paper deposit to Paper
        if (this.step3State.paymentType !== 'Paper') this.openHoldDialog();
        //SMS If a member is making a paper deposit over $10000, we are required to submit a finCEN report
        if (this.usd.toAmount > 10000 && this.step3State.paymentType === 'Paper') this.openFinCENDialog();
      }

      const url = '/admin/service/request/pre'
      this.preLoading = true
      await this.$axios.post(url, this.newServiceRequest).then(({data}) => {
        this.isValidated = true
        this.preResponse = data.message
        this.preSuccess = true
        this.preError = false
      })
      .catch(({response}) => {
        this.preError = true
        this.preErrorResponse = response.data
      })
      this.preLoading = false

    },
    async onNewServiceRequestSubmit(anotherRequest) {
      const url = '/admin/service/request'
      this.submitLoading = true
      await this.$axios.post(url, this.newServiceRequest).then(({data}) => {
        this.getRecentRequests()
        this.submitSuccess= true
        this.submitResponse = data.message
        this.clearForm(anotherRequest)
      })
      .catch(({response}) => {
        this.$refs.errorDialog.showError({

          errorMessage: response.data.message,
          forDevelopers: response.data.validationErrors
        });
      })
      this.submitLoading = false


    },

    loginAs: async function (username) {

      let res = await this.$axios.post('/admin/token', {
        grant_type: 'delegation',
        client_id: 'NA',
        target_username: username
      });

      let token = res.data;

      let memberURL = '';

      // If the local environment is set, return it.
      const localEnv = process.env.VUE_APP_DEV_MEMBER_URL;

      if (localEnv) {
        memberURL = localEnv;

      } else {

        // If the sub-domain is a valid environment include it in the url for the api.
        let environments = ['qa', 'new', 'dev'];
        let environment = window.location.host.slice(0, window.location.host.indexOf('.'));
        // console.log(environment);


        if (environments.includes(environment)) {
          memberURL = `https://${environment}.member.upma.org/`;

        } else {

          // Else have it connect with the production api.
          memberURL = process.env.VUE_APP_MEMBER_URL;
        }
      }
      memberURL = memberURL + '';
      window.open(`${memberURL}?token=${token.access_token}`);
    },

    getStaff: async function () {
      let res = await this.$axios.get(`/admin/list/usernamesByScope?filter=STAFF`);
      this.staffInfo = res.data;
    },

    getRecentRequests: async function () {
      this.recentRequestsTableLoading = true;
      let res = await this.$axios.get(`/admin/service/requests`);
      this.recentRequestsData = res.data;
      this.recentRequestsTableLoading = false;
    },

    setSRAndDeleteUnused() {
      let newServiceRequest = {'otp': this.otp, ...this.step1State, ...this.step2State, ...this.step3State, ...this.step4State, assets: {...this.assets}, buy: {...this.buy}}
      if (newServiceRequest.notifyUser) {
        delete newServiceRequest.notifyScope
      }
      if(!this.isPickup){
        delete newServiceRequest.pickUpDate
      }
      if(!this.isShipping) {
        delete newServiceRequest.shippingAddress
        delete newServiceRequest.assets.shippingAmount
        delete newServiceRequest.assets.shippingAsset
      }
      else{
        delete newServiceRequest.journalNum
      }
      if(!newServiceRequest.assets.override){
        delete newServiceRequest.assets.override
      }

      if(newServiceRequest.paymentForm === 'Check' || newServiceRequest.paymentForm === 'Paper'){
        delete newServiceRequest.paymentInfo
      }
      else if(newServiceRequest.paymentForm === 'ACH'){
        delete newServiceRequest.shippingAddress
        delete newServiceRequest.assets.shippingAmount
        delete newServiceRequest.assets.shippingAsset
        delete newServiceRequest.pickUpDate

      }
      else if(newServiceRequest.paymentForm === 'Wire' || newServiceRequest.paymentForm === 'ACH'){
        delete newServiceRequest.shippingAddress
        delete newServiceRequest.assets.shippingAmount
        delete newServiceRequest.assets.shippingAsset
        delete newServiceRequest.pickUpDate
      }

      if(this.componentsForRequestType[this.step2State.requestType].includes('depositUsdInfo')) {
        if(newServiceRequest.paymentType !== 'Check') {
          delete newServiceRequest.checkPhysicallyPresent;
        }
        if (newServiceRequest.assets.toAsset === 5062 || newServiceRequest.assets.toAsset === 7000) {
          newServiceRequest.dcaPeriod = 1;
        }
        if (!newServiceRequest.dcaPeriod) {
          newServiceRequest.dcaPeriod = 1;
        }
        newServiceRequest.assets.fromAsset = 5062;
        newServiceRequest.assets.fromAmount = this.usd.toAmount;
        delete newServiceRequest.assets.toAmount;
      }

      if(!(this.componentsForRequestType[this.step2State.requestType].includes('redeemPayment'))) {
        delete newServiceRequest.paymentInfo;
      }

      if(!(this.componentsForRequestType[this.step2State.requestType].includes('transferInfo'))) {
        delete newServiceRequest.releaseTransferFunds;
        delete newServiceRequest.transferTarget;
      }

      if(!(this.componentsForRequestType[this.step2State.requestType].includes('editRecruitInfo'))) {
        delete newServiceRequest.recruitInfo;
      }

      if(!(this.componentsForRequestType[this.step2State.requestType].includes('editProfileInfo'))) {
        delete newServiceRequest.updateProfileInfo;
      }

      if(!(this.componentsForRequestType[this.step2State.requestType].includes('toMetalsOnly'))) {
        delete newServiceRequest.immediateConversion;
        delete newServiceRequest.metalsPhysicallyPresent;
      }

      if(!(this.componentsForRequestType[this.step2State.requestType].includes('depositUsdInfo'))) {
        delete newServiceRequest.dcaPeriod;
        delete newServiceRequest.paymentType;
        delete newServiceRequest.checkPhysicallyPresent;
      }


      return newServiceRequest;
    },

    onStep3Change() {
      if(this.step3State.paymentForm === 'Check'){
        this.paymentForm = this.step3State.paymentForm
      }
      else if(this.step3State.paymentForm === 'ACH'){
        this.paymentForm = this.step3State.paymentForm
        this.isShipping = false
        this.isPickup = false
      }
      else if(this.step3State.paymentForm === 'Wire' || this.step3State.paymentForm === 'ACH'){
        this.paymentForm = this.step3State.paymentForm
        this.isShipping = false
        this.isPickup = false
      }
      this.onRequestChange()

    }

  },
}
</script>
<style scoped>

</style>