<template>
  <div>
    <CreditDebit/>
    <ErrorDialog/>
  </div>
</template>

<script>
  import ErrorDialog from "../components/ErrorDialog";
  import CreditDebit from "../components/reports/CreditDebit";
  export default {
    name: "ReportsCreditDebit",
    components: {
      CreditDebit,
      ErrorDialog
    },
  }
</script>
