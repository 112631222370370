<template>
  <div class="pa-3">
    <div v-if="allowedComponents.includes('Batch ACH')">
      <v-row>
        <v-col md="4">
          <v-card class="elevation-4">
            <v-toolbar color="#e9edf2" elevation="2" dense>
              <v-toolbar-title>Batches</v-toolbar-title>

              <v-spacer></v-spacer>

              <v-btn class="mx-1" small @click="createDailyBatch()">Daily Batch</v-btn>
              <v-btn class="mx-1" small @click="createMonthlyBatch()">Monthly Batch</v-btn>
            </v-toolbar>

            <v-text-field v-model="searchBatches" append-icon="mdi-magnify" label="Search Batches" class="mx-4 pt-4"></v-text-field>

            <v-data-table
                :headers="batchTableHeaders"
                :items="batches"
                :search="searchBatches"
                :loading="batchState === 'loading'"
                :single-select="true"
                item-key="num"
                @click:row="selectBatch"
                class="row-pointer"
            >
            </v-data-table>
          </v-card>
        </v-col>
        <v-col md="8">
          <v-card class="elevation-4">
            <v-toolbar color="#e9edf2" elevation="2" dense>
              <v-toolbar-title>Transactions: {{ selectedBatch.num }}</v-toolbar-title>

              <v-spacer></v-spacer>

              <v-btn class="mx-1" small @click="$refs.editTransactionsDialog.buildDialog(selectedTransactions)">Edit</v-btn>
              <v-btn class="mx-1" small @click="$refs.batchClearDialog.buildDialog(selectedTransactions)">Clear</v-btn>
              <v-btn class="mx-1" small @click="$refs.nachaDialog.buildDialog(selectedTransactions)">Nacha</v-btn>
            </v-toolbar>

            <v-text-field v-model="searchTransactions" append-icon="mdi-magnify" label="Search Transactions" class="mx-4 pt-4"></v-text-field>

            <v-data-table
                v-model="selectedTransactions"
                :headers="transactionTableHeaders"
                :items="transactions"
                :search="searchTransactions"
                :loading="transactionsState === 'loading'"
                :single-select="false"
                item-key="journal_num"
                show-select
                @click:row="selectTransaction"
                class="row-pointer"
            >
            </v-data-table>
          </v-card>
        </v-col>
      </v-row>

      <!--NACHA Dialog-->
      <NachaDialog ref="nachaDialog"/>

      <!--Edit Transactions Dialog-->
      <EditTransactionsDialog ref="editTransactionsDialog"/>

      <!--Clear Transactions Dialog-->
      <BatchClearDialog ref="batchClearDialog"/>

      <ErrorDialog ref="errorDialog"/>
    </div>
    <div v-else class="pl-12 pr-12 pt-12 mt-12 headline text-center">Sorry, this page is not available.</div>
  </div>
</template>

<script>
  // @ is an alias to /src
  import ErrorDialog from "@/components/ErrorDialog";
  import BatchClearDialog from "./BatchClearDialog";
  import EditTransactionsDialog from "./EditTransactionsDialog";
  import NachaDialog from "@/components/reports/NachaDialog";

  export default {
    name: "BatchACH",
    components: {
      ErrorDialog,
      BatchClearDialog,
      EditTransactionsDialog,
      NachaDialog
    },
    data: () => ({

      //-------- Batch Variables --------//

      batches: [],
      selectedBatch: {num: ''},
      batchState: 'loading',
      searchBatches: "",
      allowedComponents: JSON.parse(localStorage.getItem('allowedComponents') || ''),

      batchTableHeaders: [
        {
          text: 'Batch Number',
          value: 'num',
          align: 'start'
        },
        {
          text: 'Qty',
          value: 'count',
          align: 'start'
        },
        {
          text: 'Total',
          value: 'amount',
          align: 'end'
        },
        {
          text: 'Status',
          value: 'status',
          align: 'end'
        }
      ],

      //-------- Transaction Variables --------//

      transactions: [],
      selectedTransactions: [],
      transactionsState: 'display',
      searchTransactions: "",

      transactionTableHeaders: [
        {
          text: 'Payee',
          value: 'full_name',
          align: 'start'
        },
        {
          text: 'DCA',
          value: 'meta.numBusinessDays',
          align: 'start'
        },
        {
          text: 'Day Due',
          value: 'day_due',
          align: 'start'
        },
        {
          text: 'Requested',
          value: 'meta.desiredAssetType',
          align: 'start'
        },
        {
          text: 'EA#',
          value: 'meta.externalBankAccountNumber',
          align: 'start'
        },
        {
          text: 'ER#',
          value: 'meta.externalRoutingNumber',
          align: 'start'
        },
        {
          text: 'Amount',
          value: 'amount',
          align: 'end'
        },
        {
          text: 'Status',
          value: 'status',
          align: 'end'
        }
      ]
    }),

    mounted() {
      localStorage.title = 'ACH Batch Reports';
    },
    methods: {
      getBatchReport: async function () {
        try {
          let res = await this.$axios.get(`/admin/reports/batch`);
          this.batches = res.data;

        } catch (e) {
          this.$refs.errorDialog.showError({
            errorMessage: "Failed to get ACH Batch Report.",
            forDevelopers: e
          })
        }
      },

      getTransactions: async function () {
        this.transactionsState = 'loading';
        this.selectedTransactions = [];
        try {
          let res = await this.$axios.get(`/admin/reports/batchContent`, {params: {batchNum: this.selectedBatch.num}});

          this.transactions = res.data.map((item, index) => ({
            id: index,
            amount: item.amount,
            batch_num: item.batch_num,
            day_due: item.day_due,
            full_name: item.full_name,
            journal_num: item.journal_num,
            status: item.status,
            type: item.type,
            username: item.username,
            meta: JSON.parse(item.meta)
          }));

        } catch (e) {
          this.$refs.errorDialog.showError({
            errorMessage: "Failed to get transactions for batch " + this.selectedBatch.num + ".",
            forDevelopers: e
          })
        }
        this.transactionsState = 'display';
      },

      createDailyBatch: async function () {
        try {
          await this.$axios.patch(`/admin/reports/batch`);
          this.getBatchReport();

        } catch (e) {
          this.$refs.errorDialog.showError({
            errorMessage: "Failed to create new Daily ACH Batch.",
            forDevelopers: e
          })
        }
      },

      createMonthlyBatch: async function () {
        if (confirm('Are you sure you want to create a new monthly batch?')){
          try {
            await this.$axios.post(`/admin/reports/monthly`);
            this.getBatchReport();

          } catch (e) {
            this.$refs.errorDialog.showError({
              errorMessage: "Failed to create new Monthly ACH Batch.",
              forDevelopers: e
            })
          }
        }
      },

      selectBatch: async function (item, row) {
        this.selectedBatch = item;
        row.select(true);
        this.getTransactions();
      },

      selectTransaction: async function (item, row) {
        row.select(!row.isSelected);
      }
    },

    created: async function() {
      await this.getBatchReport();
      this.batchState = 'display';
    }
  }
</script>

<style scoped>
  .row-pointer >>> tbody tr :hover {
    cursor: pointer;
  }

  .v-data-table >>> td {
    font-size: .75rem !important;
    height: 40px;
  }

</style>