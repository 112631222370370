<template>
  <div>
    <!-- UPMA logo display -->
    <div class="logo-container">
      <img :src="logo" alt="Logo" class="logo"/>
    </div>

    <v-container>

      <div v-if="policies.length > 0">
        <!-- Instructions-->
        <div class="instructions">
        <h3>Please read and acknowledge the following policies to continue to the admin website:</h3>
        </div>
        <!-- Paginated Policies -->
        <v-row v-for="policy in paginatedPolicies" :key="policy.uuid">
          <v-col>
            <v-row>
              <h3>{{ policy.title }}</h3>
            </v-row>
            <v-row>
              <span v-html="policy.description"></span>
            </v-row>
            <v-row>
              <a v-if="policy.link" :href="policy.link" target="_blank" rel="noopener noreferrer">More info</a>
            </v-row>
            <v-row>
              <v-checkbox v-model="policy.acknowledged" label="I have read and understand the policy above"/>
            </v-row>
          </v-col>
        </v-row>

        <div class="action-controls">
          <!-- Pagination Controls -->
          <div class="pagination-controls">
            <v-icon @click="prevPage" :disabled="currentPage <= 1" class="pagination-button">mdi-chevron-left</v-icon>
            <v-icon @click="nextPage" :disabled="currentPage >= totalPages" class="pagination-button">mdi-chevron-right</v-icon>
          </div>
          <!-- Submit Button -->
          <v-btn :disabled="!allAcknowledged" class="primary pagination-button" @click="submitPolicies">Submit</v-btn>
        </div>
      </div>

      <div v-else>
        <!-- Loading Display -->
        <loader line-fg-color="#295491" size="massive"></loader>
      </div>
    </v-container>

    <ErrorDialog ref="errorDialog"/>
    <SnackBar ref="snackbar"/>

  </div>
</template>

<script>
import ErrorDialog from "@/components/ErrorDialog";
import SnackBar from "@/components/Snackbar";
import loader from "vue-simple-spinner";
import logo from "../webApp/images/logo.png";

export default {
  name: "PolicyAcknowledgement.vue",
  components: {SnackBar, ErrorDialog, loader},
  data() {
    return {
      logo,
      policies: [],
      currentPage: 1,
      itemsPerPage: 5
    }
  },
  props: ['unacknowledgedPolicies'],

  mounted() {
    localStorage.title = 'hideAppBar';
    if (!this.unacknowledgedPolicies || this.unacknowledgedPolicies.length === 0) {
      this.getUnacknowledgedPolicies().then(() => {
        if (this.policies.length === 0) {
          this.redirect();
        }
      })
    }
  },
  computed: {
    totalPages() {
      return Math.ceil(this.policies.length / this.itemsPerPage);
    },
    paginatedPolicies() {
      const start = (this.currentPage - 1) * this.itemsPerPage;
      return this.policies.slice(start, start + this.itemsPerPage);
    },
    allAcknowledged() {
      return this.policies.every(policy => policy.acknowledged);
    }
  },
  methods: {
    getUnacknowledgedPolicies: async function () {
      try {
        let res = await this.$axios.get(`/admin/user/policyAcknowledgement`);
        const fetchedPolicies = res.data;

        // Update policies if they are not already in the state
        if (!this.policies.length) {
          this.policies = fetchedPolicies.map(policy => ({ ...policy, acknowledged: false }));
        }
      } catch (e) {
        this.$refs.errorDialog.showError({
          errorMessage: "Failed to get unacknowledged policies.",
          forDevelopers: e
        })
      }
    },
    nextPage() {
      if (this.currentPage < this.totalPages) this.currentPage++;
    },
    prevPage() {
      if (this.currentPage > 1) this.currentPage--;
    },
    async submitPolicies() {
      await this.getUnacknowledgedPolicies();

      // Extract UUIDs of currently displayed policies
      const displayedUUIDs = this.policies.map(policy => policy.uuid);

      // Filter out policies that have been acknowledged
      const acknowledgedUUIDs = this.policies
          .filter(policy => policy.acknowledged)
          .map(policy => policy.uuid);

      // Check if there are any new policies that have not been acknowledged
      if (displayedUUIDs.some(uuid => !acknowledgedUUIDs.includes(uuid))) {
        // Deny submission and prompt the user to acknowledge new policies
        this.$refs.errorDialog.showError({
          errorMessage: "New unacknowledged policies detected. Please acknowledge them before submitting.",
        });
        return;
      }

        try {
          await this.$axios.post('/admin/user/policyAcknowledgement', { uuids: acknowledgedUUIDs });
          this.$refs.snackbar.showSnackbar({
            text: `Successfully acknowledged all new policies`,
            color: 'success',
            style: "margin-top: calc(-8px + .25vw)"
          });

          await this.redirect();

        } catch (e) {
          this.$refs.errorDialog.showError({
            errorMessage: 'An error occurred while trying to acknowledge the policies.',
            forDevelopers: e + '\n' + e.message
          })
        }
    },
    async redirect() {
      await this.$router.push({ name: 'Tasks'});
    }
  }
};
</script>

<style scoped>
.instructions {
  text-align: center;
  margin-bottom: 20px;
}
.logo-container {
  text-align: center;
  margin-top: 10px;
  background-color: #295491;
}
.logo {
  width: 200px;
  height: auto;
}
.action-controls {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  margin-top: 20px;
}
.pagination-controls {
  display: flex;
  margin-right: 10px; /* Spacing between right arrow and submit button */
}
.pagination-button {
  margin: 0 5px; /* Spacing between buttons */
  min-width: 40px;
  height: 40px;
  color: #295491;
}
</style>