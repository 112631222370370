<template>
  <v-dialog v-model="RedirectPrompt" persistent max-width="600px">
    <v-card>
      <v-card-title class="headline">
        <v-spacer/>You are not using the QA Admin Website
      <v-spacer/>
      </v-card-title>
      <v-card-text class="text-center mt-2">
        Is there an issue with QA?
      </v-card-text>
      <v-row justify="center" :style="{ maxWidth: '600px' }">
      <v-btn color="primary" @click="revokeAccessAndRedirect">No, take me to QA admin website</v-btn>
      </v-row>
      <v-card-text class="text-center mt-4">
        If so, please describe the issue below:
      <v-textarea v-model="issueContent"
                  :error-messages="issueErrorMessages"
                  maxlength="200"
                  counter
                  required/>
      <v-btn color="error" text @click="validateAndSendEmail">Report problem and continue on admin website</v-btn>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  name: "RedirectToQADialog.vue",
  data() {
    return {
      RedirectPrompt: false,
      issueContent: '',
      issueErrorMessages: [],
    };
  },
  methods: {
    openDialog() {
      this.RedirectPrompt = true;
    },
    closeDialog() {
      this.RedirectPrompt = false;
    },
    async revokeAccessAndRedirect() {
      await this.$root.handleLoggedOut();
      window.location.href = 'https://qa.admin.upma.org';
    },
    validateAndSendEmail() {
      this.issueErrorMessages = [];

      if (!this.issueContent) {
        this.issueErrorMessages.push('Please describe the issue.');
        return;
      }

      this.sendEmail();
    },
    sendEmail() {
      this.RedirectPrompt = false;
      //send email to software development
      this.$axios.post(`/admin/user/qaIssue`, {issue: this.issueContent});
      this.$emit('popupHandled');
    }
  }
};
</script>

<style scoped>
</style>