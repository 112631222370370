<template>
    <v-sheet>
        <v-progress-linear v-if="state === 'loading'"
                           indeterminate
                           color="primary"
        />
        <v-calendar
                :class="state === 'loading' ? '' : 'mt-3'"
                :now="today"
                color="primary"
                :events="rates"
                :start="curMonth.start"
                :end="curMonth.end"
        />
        <ErrorDialog ref="errorDialog"/>
    </v-sheet>
</template>

<script>
    import moment from 'moment'
    import ErrorDialog from "../ErrorDialog";
    const dateFormat = 'YYYY-MM-DD';
    export default {
        name: "RatesCalendar",
        components: {ErrorDialog},
        data: () => ({
            rates: [], // These ought to be formatted as events for veutify to display in the calendar.
            curMonth: "",
            state: 'display'
        }),
        methods: {
            getRates: async function(startDate, endDate) {
                try {
                    this.state = 'loading';
                    let res = await this.$axios.get('/admin/rates', {params: {
                            startDate: startDate,
                            endDate: endDate
                        }});
                    let rates = res.data;
                    this.rates = [];// clear old rates so day of newly set rate doesn't show both old and new rates

                    // Lazily add to rates in an event format
                    rates.forEach((newRate) => {
                        // Check if the rate already exists.
                        let matches = this.rates.filter((rate) => {
                            return rate.start === newRate.day
                                && rate.name === `${newRate.type.mini}: ${newRate.fix ? newRate.fix : 'N/A'} | ${newRate.rate ? newRate.rate : 'N/A'} |
                                ${newRate.buy_back ? newRate.buy_back : 'N/A'} | ${newRate.business_cost ? newRate.business_cost : 'N/A'} `
                        });

                        // If the rate already exists we've already added this month, so return.
                        if(matches.length > 0){
                            return
                        }

                        this.rates.push({
                            name: `${newRate.type.mini}: ${newRate.fix ? newRate.fix : 'N/A'} | ${newRate.rate ? newRate.rate : 'N/A'} |
                                ${newRate.buy_back ? newRate.buy_back : 'N/A'} | ${newRate.business_cost ? newRate.business_cost : 'N/A'} `,
                            start: newRate.day,
                            end: newRate.day,
                            color: { text: 'Yellow', value: 'yellow' }
                        })
                    });

                    this.state = 'display';
                } catch (e) {
                    this.$refs.errorDialog.showError({
                        errorMessage: "There was a problem loading the rates.",
                        forDevelopers: e
                    })
                }
            },
            prev(){
                let date = moment(this.curMonth.start).add(-1, 'M');
                this.curMonth = {
                    start: date.startOf('month').format(dateFormat),
                    end: date.endOf('month').format(dateFormat)
                }
            },
            next(){
                let date = moment(this.curMonth.start).add(1, 'M');
                this.curMonth = {
                    start: date.startOf('month').format(dateFormat),
                    end: date.endOf('month').format(dateFormat)
                }
            },
            refresh() {
                this.curMonth = this.thisMonth;
                this.getRates(this.curMonth.start, this.curMonth.end);
            }
        },
        computed: {
            today() {
                return moment().format(dateFormat);
            },
            thisMonth() {
                return {
                    start: moment().startOf('month').format(dateFormat),
                    end: moment().endOf('month').format(dateFormat)
                }
            }
        },
        created() {
            this.refresh()
        },
        watch: {
            curMonth(newMonth){
                this.getRates(newMonth.start, newMonth.end)
            }
        }
    }
</script>

<style>

.v-calendar-weekly__day {
  /* min-height - give calendar days a more consistent look regardless of how many set or loaded rates for each day */
  min-height: 108px;
}
</style>