<template>
  <v-container>
    <v-stepper v-model="step">

      <v-stepper-header>
        <v-stepper-step
            :complete="step > 1"
            step="1"
        >Create</v-stepper-step>
        <v-divider></v-divider>
        <v-stepper-step
            :complete="step > 2"
            step="2"
        >Payment & Delivery Method</v-stepper-step>
        <v-divider></v-divider>
        <v-stepper-step step="3"
        >Review & Submit</v-stepper-step>
    </v-stepper-header>

    <v-stepper-items>
      <v-stepper-content step="1">
        <v-row no-gutters>
          <v-col sm="6">
            <v-form v-model="validAccountInfo" ref="accountInfoForm">
              <v-card flat>
                <v-card-text>
                  <SearchMembers style="max-width: 800px" class="ml-0" clearable :hideDetails="'true'" dense  @memberSelected="memberSelected"/>
                  <v-row class="ml-1 mr-1 mt-2" style="max-width: 800px">
                    <v-text-field
                        label="Name*"
                        outlined
                        dense
                        v-model="name"
                        :rules="[(v) => !!v  || 'Name is required']"
                        hide-details="auto"
                    />
                  </v-row>
                  <v-row class="ml-1 mr-1 mt-2" style="max-width: 800px">
                    <v-text-field
                        label="Phone Number"
                        outlined
                        dense
                        v-model="phoneNumber"
                        :rules="[
                                  (v) => (!v || v.length >= 10) || 'Phone Number must be 10 digits or longer',
                                  (v) => (!v || v.length <= 15) || 'Phone Number may not be longer than 15 digits',
                                  (v) => (!v || /^\+?\d+$/.test(v)) || 'Phone Number may only contain digits 0-9'
                                ]"
                        hide-details="auto"
                    />
                  </v-row>
                  <v-row class="ml-1 mr-1 mt-2" style="max-width: 800px">
                    <v-text-field
                        label="Email"
                        outlined
                        dense
                        :readonly="!emailEditable"
                        :clearable="!emailEditable"
                        @click:clear="emailEditable = true"
                        v-model="email"
                        :rules="[
                              (v) => (!v || /[!-~]+@([!-~]+\.)+[!-~]+$/.test(v)) || 'E-mail must be valid'
                        ]"
                        hide-details="auto"
                    />
                  </v-row>
                  <span>
                    <v-row class="ml-1 mt-2 mr-1">
                      <v-text-field
                          label="Member Full Address (for reference)"
                          readonly
                          style="max-width: 800px"
                          v-model="fullAddress"
                      />
                    </v-row>
                    <v-row class="ml-1 mr-1 mt-2" style="max-width: 800px">
                      <v-text-field
                          label="Street 1"
                          outlined
                          dense
                          v-model="addressArray.street1"
                          hide-details="auto"
                      />
                      </v-row>
                      <!--                      hide if possible Address 2 if none-->
                    <v-row class="ml-1 mr-1 mt-2" style="max-width: 800px">
                      <v-text-field
                            label="Street 2"
                            outlined
                            dense
                            v-model="addressArray.street2"
                            hide-details="auto"
                        />
                    </v-row>
                    <v-row class="ml-1 mr-1 mt-2" style="max-width: 800px">
                      <v-text-field
                            label="City"
                            outlined
                            dense
                            v-model="addressArray.city"
                            hide-details="auto"
                        />
                    </v-row>
                    <v-row class="ml-1 mr-1 mt-2" style="max-width: 800px">
                      <v-text-field
                            label="Zip/Postal Code"
                            outlined
                            dense
                            v-model="addressArray.postalCode"
                            hide-details="auto"
                      />
                    </v-row><v-layout row wrap class="ml-1 mt-2 mr-1" style="max-width: 800px">
                    <v-flex xs6>
                      <span class="text-xs-left text--darken-1 grey--text">Country</span>
                      <v-card class="rounded-card" flat elevation="1" min-height="2rem" max-width="25rem">
                        <v-form ref="countryForm">
                          <country-select v-model="addressArray.country" class="country-region-special" placeholder="Select Country ∨" :countryName=true :country="addressArray.country" topCountry="United States"/>
                        </v-form>
                      </v-card>
                    </v-flex>
                    <v-spacer></v-spacer>
                    <v-flex xs5>
                      <span class="text-xs-left text--darken-1 grey--text">State/Region</span>
                      <v-card class="rounded-card" flat elevation="1" min-height="2rem" max-width="15rem">
                        <v-form ref="stateForm">
                          <region-select v-if="addressArray.country === 'United States'" v-model="addressArray.stateRegion" class="country-region-special" placeholder="Select State/Region ∨" :countryName=true :country="addressArray.country" :region="addressArray.stateRegion" />
                          <region-select v-else v-model="addressArray.stateRegion" class="country-region-special" placeholder="Select State/Region ∨" :regionName=true :countryName=true :country="addressArray.country" :region="addressArray.stateRegion" />
                        </v-form>
                      </v-card>
                    </v-flex>
                  </v-layout>
                  </span>
                </v-card-text>
              </v-card>
            </v-form>
          <v-tabs v-model="tab" class="ml-4">
            <v-tab v-if="buyToggle" ref="buy">
              Buy
              <v-tooltip bottom>
                <template v-slot:activator="{ on, attrs }">
                  <v-icon small class="ml-2" v-bind="attrs" v-on="on">mdi-information-outline</v-icon>
                </template>
                <span>The member is buying from UPMA</span>
              </v-tooltip>
            </v-tab>
            <v-tab v-if="sellToggle" ref="sell">
              Sell
              <v-tooltip bottom>
                <template v-slot:activator="{ on, attrs }">
                  <v-icon small class="ml-2" v-bind="attrs" v-on="on">mdi-information-outline</v-icon>
                </template>
                <span>The member is selling to UPMA</span>
              </v-tooltip>
            </v-tab>
          </v-tabs>
            <v-tabs-items v-model="tab">
              <v-tab-item v-if="buyToggle">
                <v-card flat>
                  <v-card-text>
                    <v-row class="ml-1 mr-1 mt-2" style="max-width: 800px" >
                      <SelectProducts v-if="showBuySelectProducts" @productsSelected="onBuySelectedProductsChange"></SelectProducts>
                    </v-row>
                  </v-card-text>
                </v-card>
              </v-tab-item>
              <v-tab-item v-if="sellToggle">
                <v-card flat>
                  <v-card-text>
                    <v-row class="ml-1 mr-1 mt-2" style="max-width: 800px" >
                      <SelectProducts v-if="showSellSelectProducts" @productsSelected="onSellSelectedProductsChange"></SelectProducts>
                    </v-row>
                  </v-card-text>
                </v-card>
              </v-tab-item>
            </v-tabs-items>
          </v-col>

          <v-col sm="6">
            <v-card flat>
              <v-card-text>
                <v-form v-model="validRatesInfo" ref="ratesInfoForm">
                <v-row class="ml-1 mr-1 mt-2" style="max-width: 800px">
                  <v-spacer></v-spacer>
                  <v-btn :color="buyToggle ? 'red' : 'primary'"
                         class="mr-2"
                         dark
                         @click="buyToggle = !buyToggle">
                    {{buyToggle ? 'Remove Buy' : 'Add Buy'}}
                  </v-btn>
                  <v-btn :color="sellToggle ? 'red' : 'primary'"
                         dark
                         @click="sellToggle = !sellToggle">
                    {{sellToggle ? 'Remove Sell' : 'Add Sell'}}
                  </v-btn>
                </v-row>
                <v-row class="ml-1 mr-1 mt-2" v-if="buyToggle" style="max-width: 800px">
                  <v-select
                      label="Buy Offer Level*"
                      outlined
                      dense
                      :disabled="buyOverride"
                      v-model="buyOfferLevel"
                      type="number"
                      :items="offerLevelItems"
                      @change="setSolidRates(true)"
                      hide-details="auto"
                  />
                  <v-btn icon @click="showCalculator = true">
                    <v-icon>mdi-calculator</v-icon>
                  </v-btn>
                </v-row>
                <v-row class="ml-1 mr-1 mt-2" v-if="buyToggle" style="max-width: 800px">
                  <v-text-field
                      label="Gold Buy Offer*"
                      outlined
                      dense
                      prefix="$"
                      :loading="ratesLoading"
                      :disabled="!buyOverride"
                      @focus="$event.target.select()"
                      :rules="[(v) => !!v  || 'Buy Offer is required']"
                      v-model="buy.rates.gold"
                      type="number"
                      hide-details="auto"
                      style="max-width: 150px"
                  />
                  <v-text-field
                      label="Silver Buy Offer*"
                      class="ml-1"
                      outlined
                      dense
                      prefix="$"
                      :loading="ratesLoading"
                      :disabled="!buyOverride"
                      @focus="$event.target.select()"
                      :rules="[(v) => !!v  || 'Buy Offer is required']"
                      v-model="buy.rates.silver"
                      type="number"
                      hide-details="auto"
                      style="max-width: 150px"
                  />
                  <v-text-field
                      label="Goldback Buy Offer*"
                      class="ml-1"
                      outlined
                      dense
                      prefix="$"
                      :loading="ratesLoading"
                      :disabled="!buyOverride"
                      @focus="$event.target.select()"
                      :rules="[(v) => !!v  || 'Buy Offer is required']"
                      v-model="buy.rates.goldback"
                      type="number"
                      hide-details="auto"
                      style="max-width: 150px"
                  />
                  <v-text-field
                      label="90% Silver Buy Offer*"
                      class="ml-1"
                      outlined
                      dense
                      prefix="$"
                      :loading="ratesLoading"
                      :disabled="!buyOverride"
                      @focus="$event.target.select()"
                      :rules="[(v) => !!v  || 'Buy Offer is required']"
                      v-model="buy.rates.junk_silver"
                      type="number"
                      hide-details="auto"
                      style="max-width: 150px"
                  />
                  <v-checkbox
                      class="ml-2 mt-1"
                      label="Override"
                      hide-details
                      @change="setSolidRates(true)"
                      v-model="buyOverride"
                  />
                </v-row>
                <v-row class="ml-1 mr-1 mt-2 mb-4" v-if="buyToggle" style="max-width: 800px">
                  <v-card width="100%">
                    <v-card-text>Purchases From UPMA: ${{Number(totalUSD(true)).toFixed(2)}}</v-card-text>
                    <v-data-table
                        hide-default-footer
                        :headers="buyCartHeaders"
                        :loading="buyCartLoading"
                        :items="buy.products"
                        :items-per-page="-1">

                      <template v-slot:item.given="{ item }">
                        <v-text-field
                            class="flex-grow-0 flex-shrink-0"
                            dense
                            style="width: 75px;"
                            :rules="[
                                () => parseInt(item.given) >= 0 || 'Given must be non-negative',
                                () => parseInt(item.quantity) >= 0 || 'Quantity must be non-negative',
                                () => parseInt(item.given) <= parseInt(item.quantity) || 'Given must be less than or equal to Quantity'
                            ]"
                            @focus="$event.target.select()"
                            @wheel="$event.target.blur()"
                            v-model="item.given"
                            type="number"
                            outlined
                            hide-details="auto"
                        />
                      </template>

                      <template v-slot:item.owed="{ item }">
                        {{item.quantity - item.given}}
                      </template>

                      <template v-slot:item.price="{ item }">
                        <div v-if="item.override" class="d-flex">
                          <v-layout justify-end>
                            <v-icon small>
                              mdi-currency-usd
                            </v-icon>
                            <v-text-field
                                class="flex-grow-0 flex-shrink-0"
                                dense
                                style="width: 75px;"
                                :rules="[(v) => !!v  || 'Required']"
                                v-model="item.price"
                                @focus="$event.target.select()"
                                type="number"
                                outlined
                                hide-details="auto"
                            />
                            <v-tooltip bottom>
                              <template v-slot:activator="{ on }">
                                <v-btn icon x-small class="mt-2" v-on="on" @click="item.override = false; delete item.price">
                                  <v-icon>
                                    mdi-arrow-u-left-top
                                  </v-icon>
                                </v-btn>
                              </template>
                              <span> Undo override and use original price.</span>
                            </v-tooltip>
                          </v-layout>
                        </div>
                        <div v-else>
                          {{ '$' + calcPrice(true, item)}}
                          <v-tooltip bottom>
                            <template v-slot:activator="{ on }">
                              <v-btn icon v-on="on" x-small @click="item.override = true">
                                <v-icon>
                                  mdi-pencil
                                </v-icon>
                              </v-btn>
                            </template>
                            <span> Override price.</span>
                          </v-tooltip>
                        </div>
                      </template>

                    </v-data-table>
                  </v-card>
                </v-row>
                <v-row class="ml-1 mt-2" v-if="sellToggle" style="max-width: 800px">
                  <v-text-field
                      label="Gold Sell Offer*"
                      outlined
                      dense
                      prefix="$"
                      :loading="ratesLoading"
                      :disabled="!sellOverride"
                      @focus="$event.target.select()"
                      :rules="[(v) => !!v  || 'Sell Offer is required']"
                      v-model="sell.rates.gold"
                      type="number"
                      hide-details="auto"
                      style="max-width: 150px"
                  />
                  <v-text-field
                      label="Silver Sell Offer*"
                      class="ml-1"
                      outlined
                      dense
                      prefix="$"
                      :loading="ratesLoading"
                      :disabled="!sellOverride"
                      @focus="$event.target.select()"
                      :rules="[(v) => !!v  || 'Sell Offer is required']"
                      v-model="sell.rates.silver"
                      type="number"
                      hide-details="auto"
                      style="max-width: 150px"
                  />
                  <v-text-field
                      label="Goldback Sell Offer*"
                      class="ml-1"
                      outlined
                      dense
                      prefix="$"
                      :loading="ratesLoading"
                      :disabled="!sellOverride"
                      @focus="$event.target.select()"
                      :rules="[(v) => !!v  || 'Sell Offer is required']"
                      v-model="sell.rates.goldback"
                      type="number"
                      hide-details="auto"
                      style="max-width: 150px"
                  />
                  <v-text-field
                      label="90% Silver Sell Offer*"
                      class="ml-1"
                      outlined
                      dense
                      prefix="$"
                      :loading="ratesLoading"
                      :disabled="!sellOverride"
                      @focus="$event.target.select()"
                      :rules="[(v) => !!v  || 'Sell Offer is required']"
                      v-model="sell.rates.junk_silver"
                      type="number"
                      hide-details="auto"
                      style="max-width: 150px"
                  />
                  <v-checkbox
                      class="ml-2 mt-1"
                      label="Override"
                      hide-details
                      @change="setSolidRates(false)"
                      v-model="sellOverride"
                  />
                </v-row>
                </v-form>
                <v-row class="ml-1 mr-1 mt-2 mb-4" v-if="sellToggle" style="max-width: 800px">
                  <v-card width="100%">
                    <v-card-text>Sales To UPMA: ${{Number(totalUSD(false)).toFixed(2)}}</v-card-text>
                    <v-data-table
                        hide-default-footer
                        :headers="sellCartHeaders"
                        :loading="sellCartLoading"
                        :items="sell.products"
                        :items-per-page="-1">

                      <template v-slot:item.price="{ item }">
                        <div v-if="item.override" class="d-flex">
                          <v-layout justify-end>
                            <v-icon small>
                              mdi-currency-usd
                            </v-icon>
                            <v-text-field
                                class="flex-grow-0 flex-shrink-0"
                                dense
                                style="width: 75px;"
                                :rules="[(v) => !!v  || 'Required']"
                                v-model="item.price"
                                @focus="$event.target.select()"
                                type="number"
                                outlined
                                hide-details="auto"
                            />
                            <v-tooltip bottom>
                              <template v-slot:activator="{ on }">
                                <v-btn icon x-small class="mt-2" v-on="on" @click="item.override = false; delete item.price">
                                  <v-icon>
                                    mdi-arrow-u-left-top
                                  </v-icon>
                                </v-btn>
                              </template>
                              <span> Undo override and use original price.</span>
                            </v-tooltip>
                          </v-layout>
                        </div>
                        <div v-else>
                          {{ '$' + calcPrice(false, item)}}
                          <v-tooltip bottom>
                            <template v-slot:activator="{ on }">
                              <v-btn icon v-on="on" x-small @click="item.override = true">
                                <v-icon>
                                  mdi-pencil
                                </v-icon>
                              </v-btn>
                            </template>
                            <span> Override price.</span>
                          </v-tooltip>
                        </div>
                      </template>

                    </v-data-table>
                  </v-card>
                </v-row>
                <v-row class="ml-1 mr-1 mt-2 mb-4" v-if="buyToggle && sellToggle" style="max-width: 800px">
                  <v-card width="100%">
                    <v-card-text>
                      <div v-if="netUSD() >= 0">
                        Owed To UPMA:
                        <span>
                        ${{this.netUSD().toFixed(2)}}
                      </span>
                      </div>
                      <div v-else class="green--text">
                        Owed To Member:
                        <span class="green--text">
                        ${{Math.abs(this.netUSD()).toFixed(2)}}
                      </span>
                      </div>
                    </v-card-text>
                  </v-card>
                </v-row>
              </v-card-text>
            </v-card>
          </v-col>
          <v-row class="ml-1 mr-1 mb-2">
            <v-btn color="red" class="mr-2" dark @click="clearForm">
              Clear Form
            </v-btn>
            <v-btn color="primary"
                   :disabled="!(validAccountInfo && validRatesInfo && ((buyToggle && (buy.products.length !== 0))
                   || (sellToggle) && (sell.products.length !== 0)) && (phoneNumber || email))"
                   @click="toStep(2)">
              Next
            </v-btn>
          </v-row>
        </v-row>
      </v-stepper-content>

      <v-stepper-content step="2">
        <v-row no-gutters>
          <v-col sm="6">
            <v-form v-model="validShipAndPayInfo" ref="shipAndPayForm">
              <v-card flat>
                <v-card-text>
                  <v-row class="ml-1 mt-2" v-if="deliveryNecessary" style="max-width: 800px">
                    <v-select
                        label="Delivery Method*"
                        outlined
                        dense
                        v-model="deliveryMethod"
                        :rules="[(v) => !!v  || 'Required']"
                        :items="deliveryMethodItems"
                        hide-details="auto"
                    />
                  </v-row>
                  <v-row class="ml-1 mt-2" v-if="deliveryMethod === 'Pick-Up'" style="max-width: 800px">
                    <v-spacer/>
                    <v-menu
                        v-model="menu"
                        :close-on-content-click="true"
                        :nudge-right="20"
                        transition="scale-transition"
                        offset-y
                        min-width="0px">
                      <template v-slot:activator="{on, attrs}">
                        <v-text-field
                            label="Requested Pick-Up Date*"
                            outlined
                            dense
                            style="max-width: 400px"
                            v-model="pickUpDate"
                            :rules="[(v) => !!v || 'Required']"
                            readonly
                            v-bind="attrs"
                            v-on="on"
                            clearable
                            hide-details="auto"
                        />
                      </template>
                      <v-date-picker
                          outlined
                          no-title
                          :allowed-dates="(date) => date >= new Date().toISOString().substr(0, 10)"
                          v-model="pickUpDate"
                          @input="menu = false"
                      ></v-date-picker>
                    </v-menu>
                  </v-row>
                  <v-row class="ml-1 mt-2" v-if="buyToggle && deliveryMethod === 'Shipping'"  style="max-width: 800px">
                    <v-select
                        label="Package Type*"
                        outlined
                        dense
                        v-model="buy.summary.shippingAmount"
                        :rules="[(v) => !!v  || 'Required']"
                        item-text="type"
                        item-value="rate"
                        :items="shippingRates"
                        hide-details="auto"
                    />
                  </v-row>
                  <v-row class="ml-1 mt-2" v-if="buyToggle && deliveryMethod === 'Shipping'" style="max-width: 800px">
                    <v-checkbox
                        class="ml-2"
                        dense
                        label="Override Shipping Amount"
                        v-model="shippingOverride"
                        hide-details
                    />
                    <v-spacer/>
                    <v-text-field
                        label="Shipping Amount*"
                        outlined
                        dense
                        :disabled="!shippingOverride"
                        @focus="$event.target.select()"
                        prefix="$"
                        :rules="[(v) => !!v  || 'Required']"
                        v-model="buy.summary.shippingAmount"
                        type="number"
                        hide-details="auto"
                        style="max-width: 200px"
                    />
                  </v-row>
                  <v-row class="ml-1 mt-2" v-if="buyToggle && deliveryMethod === 'Shipping'" style="max-width: 800px">
                    <v-checkbox
                        class="ml-2"
                        dense
                        label="Override Insurance Amount"
                        v-model="insuranceOverride"
                        hide-details
                    />
                    <v-spacer/>
                    <v-text-field
                        label="Insurance Amount*"
                        outlined
                        dense
                        :disabled="!insuranceOverride"
                        prefix="$"
                        :rules="[(v) => !!v  || 'Required']"
                        v-model="insuranceAmount"
                        @focus="$event.target.select()"
                        type="number"
                        hide-details="auto"
                        style="max-width: 200px"
                    />
                  </v-row>
                  <v-row class="ml-1 mt-2" style="max-width: 800px">
                    <v-autocomplete
                        label="Sale Location*"
                        outlined
                        dense
                        v-model="warehouseUuid"
                        item-text="name"
                        item-value="uuid"
                        :rules="[(v) => !!v  || 'Required']"
                        :items="warehouses"
                        hide-details="auto"
                    />
                    <v-tooltip top>
                      <template v-slot:activator="{ on }">
                          <v-icon class="ml-1" v-on="on">
                            mdi-information-outline
                          </v-icon>
                      </template>
                      <span>All products not given at purchase will ALWAYS go to the Alpine Shipping Department</span>
                    </v-tooltip>
                  </v-row>
                  <v-row class="ml-1 mt-2" style="max-width: 800px" v-if="anyGivenItems">
                    <v-select
                        label="Payment Method*"
                        outlined
                        dense
                        v-model="paymentForm"
                        :rules="[(v) => !!v  || 'Required']"
                        :items="paymentFormItemsImmediate"
                        hide-details="auto"
                    />
                    <v-tooltip bottom>
                      <template v-slot:activator="{ on }">
                        <v-icon class="ml-1" v-on="on">
                          mdi-information-outline
                        </v-icon>
                      </template>
                      <span>If you would like another payment method please correct the previous page's 'Given' quantities to 0</span>
                    </v-tooltip>
                  </v-row>
                  <v-row class="ml-1 mt-2" style="max-width: 800px" v-else>
                    <v-select
                        label="Payment Method*"
                        outlined
                        dense
                        v-model="paymentForm"
                        :rules="[(v) => !!v  || 'Required']"
                        :items="paymentFormItemsLater"
                        hide-details="auto"
                    />
                  </v-row>
                  <v-row class="ml-1 mt-2" v-if="buyToggle" style="max-width: 800px">
                    <v-spacer/>
                    <v-text-field
                        label="Payment Amount*"
                        outlined
                        dense
                        prefix="$"
                        :rules="[(v) => !!v  || 'Required']"
                        v-model="buy.summary.paidAmount"
                        @focus="$event.target.select()"
                        type="number"
                        hide-details="auto"
                        style="max-width: 200px"
                    />
                  </v-row>
                  <v-row class="ml-1" v-if="buyToggle" style="max-width: 800px">
                    <v-checkbox
                        class="ml-2"
                        dense
                        label="Apply Credit From A Previous Transaction?"
                        v-model="creditToggle"
                        hide-details
                    />
                    <v-spacer/>
                    <v-text-field
                        label="Credit Amount*"
                        class="ml-1 mt-2"
                        outlined
                        dense
                        v-if="creditToggle"
                        @focus="$event.target.select()"
                        prefix="$"
                        :rules="[(v) => !!v  || 'Required']"
                        v-model="buy.summary.creditAmount"
                        type="number"
                        hide-details="auto"
                        style="max-width: 200px"
                    />
                  </v-row>
                  <v-row class="ml-1 mt-2" v-if="buyToggle && creditToggle" style="max-width: 800px">
                    <v-textarea
                        class="mb-4"
                        label="Credit Description (Optional)"
                        outlined
                        counter="160"
                        style="max-width: 800px"
                        v-model="internalNote"
                        hide-details="auto"
                    />
                  </v-row>
                  <v-row class="ml-1" v-if="buyToggle && paymentForm && paymentForm !== 'Paper'" style="max-width: 800px">
                    <v-checkbox
                        class="ml-2"
                        dense
                        :label="'Email ' + paymentForm + ' instructions?'"
                        v-model="emailInstructionsToggle"
                        hide-details
                    />
                  </v-row>
                </v-card-text>
              </v-card>
            </v-form>
          </v-col>
          <v-col sm="6">
            <v-card flat>
              <v-card-text>
                <v-row class="ml-1 mt-2" style="max-width: 800px">
                  <v-card   style="position: sticky; min-width: 270px"  class="ml-2 mr-2 ">
                    <v-card-text>
                      <div class="d-flex" v-if="buyToggle">
                        <div>
                          Purchases:
                        </div>
                        <v-spacer/>
                        <div>
                          ${{Number(totalUSD(true)).toFixed(2)}}
                        </div>
                      </div>
                      <div class="d-flex" v-if="buyToggle && deliveryMethod === 'Shipping'">
                        <div>
                          Shipping & Insurance:
                        </div>
                        <v-spacer/>
                        <div>
                          ${{(Number(buy.summary.shippingAmount) + Number(insuranceAmount)).toFixed(2)}}
                        </div>
                      </div>
                      <div class="d-flex" v-if="sellToggle">
                        <div>
                          Sales:
                        </div>
                        <v-spacer/>
                        <div>
                          -${{Number(totalUSD(false)).toFixed(2)}}
                        </div>
                      </div>
                      <v-divider></v-divider>
                      <div class="d-flex">
                        <v-spacer/>
                        <div v-if="netUSD() >= 0">
                          ${{netUSD().toFixed(2)}}
                        </div>
                        <div v-else class="green--text">
                          ${{Math.abs(netUSD()).toFixed(2)}}
                        </div>
                      </div>
                    </v-card-text>
                    <v-card-text>
                      <div class="d-flex">
                        <div>
                          {{netUSD() >= 0 ? 'Total Owed To UPMA:' : 'Total Owed To Member:'}}
                        </div>
                        <v-spacer/>
                        <div v-if="netUSD() >= 0">
                          ${{netUSD().toFixed(2)}}
                        </div>
                        <div v-else class="green--text">
                          ${{Math.abs(netUSD()).toFixed(2)}}
                        </div>
                      </div>
                      <div class="d-flex" v-if="buyToggle">
                        <div v-if="!paymentForm || paymentForm === 'Paper'">
                          Paid:
                        </div>
                        <div v-else>
                          Amount to be Paid:
                        </div>
                        <v-spacer/>
                        <div>
                          -${{Number(buy.summary.paidAmount).toFixed(2)}}
                        </div>
                      </div>
                      <div class="d-flex" v-if="creditToggle">
                        <div>
                          Credits:
                        </div>
                        <v-spacer/>
                        <div>
                          -${{Number(buy.summary.creditAmount).toFixed(2)}}
                        </div>
                      </div>
                      <v-divider v-if="buyToggle"/>
                      <div class="d-flex" v-if="buyToggle">
                        <v-spacer/>
                        <div v-if="Number(deficitUSD().toFixed(2)) === 0">
                          ${{deficitUSD().toFixed(2)}}
                        </div>
                        <div v-else-if="deficitUSD() > 0" class="red--text">
                          ${{deficitUSD().toFixed(2)}}
                        </div>
                        <div v-else class="green--text">
                          ${{Math.abs(deficitUSD()).toFixed(2)}}
                        </div>
                      </div>
                    </v-card-text>
                  </v-card>
                </v-row>
              </v-card-text>
            </v-card>
          </v-col>
          <v-row class="ml-1 mr-1 mb-2">
            <v-btn color="primary" class="mr-2" @click="step = 1">
              Back
            </v-btn>
            <v-btn color="primary"
                   :disabled="!(validShipAndPayInfo && Number(deficitUSD().toFixed(2)) <= 0)"
                   @click="toStep(3)">
              Next
            </v-btn>
          </v-row>
        </v-row>
      </v-stepper-content>
      <v-stepper-content step="3">
        <v-row no-gutters>
          <v-col sm="12">
              <v-card flat>
                <v-card-text>
                  <v-card color="grey lighten-3">
                    <v-card-text>
                      <v-layout justify-center>
                        <h2 class="mb-2">Order Summary</h2>
                      </v-layout>
                      <div v-for="(value, propertyName) in formattedRequest" :key="propertyName" >
                          <span v-if="propertyName === 'buy' || propertyName === 'sell'" >
                            {{!Object.values(value).every((v) => v === null) ? fromSnakeToCamel(propertyName) + ': ' : ''}}
                            <div v-for="(internalValue, internalPropertyName) in value"
                                 :key="internalPropertyName" class="ml-2" style="color: gray">
                              {{!Object.values(internalValue).every((v) => v === null) ? fromSnakeToCamel(internalPropertyName) + ': ' : ''}}
                              <span v-if="internalPropertyName === 'products'">
                                <div v-for="(nextInternalValue, nextInternalPropertyName) in internalValue"
                                     :key="nextInternalPropertyName" class="ml-2" style="color: gray">
                                  {{nextInternalValue.uuid}}: Owed: {{nextInternalValue.owed}},
                                  Quantity: {{nextInternalValue.quantity}}, Price: {{nextInternalValue.price}}
                                </div>
                              </span>
                              <span v-else>
                                <div v-for="(nextInternalValue, nextInternalPropertyName) in internalValue"
                                     :key="nextInternalPropertyName" class="ml-2" style="color: gray">
                                  {{nextInternalValue ? fromSnakeToCamel(nextInternalPropertyName) + ': ' + nextInternalValue : ''}}
                                </div>
                              </span>
                            </div>
                          </span>
                        <span v-else-if="propertyName === 'shippingAddress'" >
                                                       Shipping Address:
                           <div class="ml-2" style="color: gray">
                                  Street 1: {{value.street1}},
                                  Street 2: {{value.street2}},
                                  City: {{value.city}},
                                  State: {{value.stateRegion}},
                                  Postal Code: {{value.postalCode}},
                                  Country: {{value.country}}
                                </div>
                        </span>
                        <span v-else>
                            {{value ? fromSnakeToCamel(propertyName) + ': ' + value : ''}}
                          </span>
                      </div>
                    </v-card-text>
                  </v-card>
                  {{preResponse}}
                </v-card-text>
              </v-card>
          </v-col>
          <v-row class="ml-1 mr-1 mb-2">
            <v-btn color="primary" class="mr-2" @click="step = 2">
              Back
            </v-btn>
            <v-btn color="primary" :disabled="!isValidated" :loading="submitLoading" @click="placeOrder(true)">
              Submit
            </v-btn>
          </v-row>
        </v-row>
      </v-stepper-content>
    </v-stepper-items>
    </v-stepper>

    <v-dialog
        persistent
        v-model="orderSubmitted"
        max-width="1000">
      <v-card>
        <v-banner single-line sticky>
          <template v-slot:actions="{ }">
            <v-btn icon>
              <v-icon @click="orderSubmitted = false">mdi-close</v-icon>
            </v-btn>
          </template>
        </v-banner>
        <Task v-if="showTask" :uuid="uuidFromSubmit"/>
      </v-card>
    </v-dialog>


    <v-dialog
        v-model="showCalculator"
        max-width="600">
      <v-card style="overflow-x: hidden">
        <v-row>
          <div id="title" class="ml-4">
            <v-card-title>'How Many Can I Get?' Calculator</v-card-title>
          </div>
          <v-spacer/>
          <v-btn icon class="mr-4 mt-2">
            <v-icon @click="showCalculator = false">mdi-close</v-icon>
          </v-btn>
        </v-row>
        <v-row class="ml-1 mr-1 mt-5">
          <v-spacer></v-spacer>
          <v-text-field
              label="USD Brought"
              outlined
              dense
              prefix="$"
              @focus="$event.target.select()"
              v-model="usdBrought"
              type="number"
              hide-details="auto"
              style="max-width: 150px"
          />
          <v-spacer/>
        </v-row>
        <v-row class="ml-1 mr-1 mt-5">
          <v-spacer/>
          <div>
            <strong>{{Math.trunc(Number(usdBrought) / Number(buy.rates.goldback))}} Goldbacks</strong> @ {{Number(buy.rates.goldback).toFixed(2)}}
            (${{(Number(usdBrought) - Math.trunc(Number(usdBrought) / Number(buy.rates.goldback)) * Number(buy.rates.goldback)).toFixed(2)}} Remaining)
          </div>
          <v-spacer/>
        </v-row>
        <v-row class="ml-1 mr-1 mt-5">
          <v-spacer/>
          <div>
            <strong>{{Math.trunc(Number(usdBrought) / Number(buy.rates.gold))}} Gold Coins</strong> @ {{Number(buy.rates.gold).toFixed(2)}}
            (${{(Number(usdBrought) - Math.trunc(Number(usdBrought) / Number(buy.rates.gold)) * Number(buy.rates.gold)).toFixed(2)}} Remaining)
          </div>
          <v-spacer/>
        </v-row>
        <v-row class="ml-1 mr-1 mt-5">
          <v-spacer/>
          <div>
            <strong>{{Math.trunc(Number(usdBrought) / Number(buy.rates.silver))}} Silver Coins</strong> @ {{Number(buy.rates.silver).toFixed(2)}}
            (${{(Number(usdBrought) - Math.trunc(Number(usdBrought) / Number(buy.rates.silver)) * Number(buy.rates.silver)).toFixed(2)}} Remaining)
          </div>
          <v-spacer/>
        </v-row>
        <v-row class="ml-1 mr-1 mt-5">
          <v-spacer/>
          <div>
            <strong>{{Math.trunc(Number(usdBrought) / Number(buy.rates.junk_silver))}} Junk Silver Coins</strong> @ {{Number(buy.rates.junk_silver).toFixed(2)}}
            (${{(Number(usdBrought) - Math.trunc(Number(usdBrought) / Number(buy.rates.junk_silver)) * Number(buy.rates.junk_silver)).toFixed(2)}} Remaining)
          </div>
          <v-spacer/>
        </v-row>
        <v-row class="ml-1 mr-1 mt-5">
        </v-row>
      </v-card>
    </v-dialog>

    <v-snackbar color="success" class="mt-n2 ml-n2" v-model="submitSuccess" top>
      {{submitResponse}}
      <v-btn color="white" text @click="submitSuccess = false">Close</v-btn>
    </v-snackbar>

    <!--                      finCEN Report Popup-->
    <fin-c-e-n-dialog ref="finCENDialog"></fin-c-e-n-dialog>

  </v-container>
</template>

<script>
import SearchMembers from "../components/SearchMembers";
import SelectProducts from "../components/SelectProducts";
import Task from "../components/service/task";
import FinCENDialog from "../components/FinCENDialog";

export default {
  name: "BuySell",
  components: {
    SearchMembers,
    SelectProducts,
    Task,
    FinCENDialog
  },
  data: () => ({
    //-------- Toggle Variables --------//
    buyToggle: true,
    sellToggle: false,
    creditToggle: false,
    emailInstructionsToggle: false,
    emailEditable: true,
    orderSubmitted: false,
    showCalculator: false,
    showTask: false,

    shippingOverride: false,
    insuranceOverride: false,
    buyOverride: false,
    sellOverride: false,

    validAccountInfo: false,
    validRatesInfo: false,
    validShipAndPayInfo: false,

    ratesLoading: false,
    buyCartLoading: false,
    sellCartLoading: false,
    submitLoading: false,
    showBuySelectProducts: false,
    showSellSelectProducts: false,
    deliveryNecessary: true,
    anyGivenItems: false,
    isValidated: false,
    submitSuccess: false,

    //-------- Pass-In Variables --------//
    formattedRequest: {},
    email: '',
    phoneNumber: '',
    name: '',
    fullAddress:'',
    addressArray: {
      street1: '',
      street2: '',
      city:'',
      stateRegion: '',
      country: 'United States',
      postalCode: ''
    },
    pickUpDate: '',
    paymentForm: '',
    warehouseUuid: '',
    internalNote: '',
    buy: {
      rates: {},
      products: [],
      summary: {
        paidAmount: '0',
        creditAmount: '0',
        shippingAmount: '0'
      }
    },
    sell: {
      rates: {},
      products: [],
      summary: {}
    },


    //------ Use Variables -------//
    tab: null,
    retrievedRates: {},
    shippingRates: [],
    warehouses: [],
    unrefinedBuyProducts: {},
    unrefinedSellProducts: {},
    deliveryMethod: '',
    buyOfferLevel: 'Walk-In',
    insuranceAmount: '0',
    menu: false,
    preResponse: '',
    submitResponse: '',
    step: 1,
    uuidFromSubmit: '',
    usdBrought: '0',

    //-------- Formats --------//
    offerLevelItems: ['Walk-In', 'Employee'],
    paymentFormItemsImmediate: ['Paper'],
    paymentFormItemsLater: ['Paper', 'Check', 'ACH', 'Wire'],
    deliveryMethodItems: ['Pick-Up', 'Shipping'],
    buyCartHeaders: [
      {text: 'Product Group', value: 'group_name', align: 'start'},
      {text: 'Product', value: 'name', align: 'start'},
      {text: 'Given', value: 'given', align: 'start'},
      {text: 'Owed', value: 'owed', align: 'end'},
      {text: 'Quantity', value: 'quantity', align: 'end'},
      {text: 'Price', value: 'price', align: 'end'},
    ],
    sellCartHeaders: [
      {text: 'Product Group', value: 'group_name', align: 'start'},
      {text: 'Product', value: 'name', align: 'start'},
      {text: '', value: '', align: 'end'},
      {text: '', value: '', align: 'end'},
      {text: 'Quantity', value: 'quantity', align: 'end'},
      {text: 'Value', value: 'price', align: 'end'},
    ],

  }),

  methods: {
    fromSnakeToCamel(sentence) {
      let sentenceString = sentence.toString();
      let words = sentenceString.split("_");

      for (let i = 0; i < words.length; i++) {
        words[i] = words[i][0].toUpperCase() + words[i].substr(1);
      }
      return words.join(" ");
    },

    memberSelected: async function (member) {
      this.email = member.email;
      this.emailEditable = false;
      await this.setAccountInfo(member.email);
    },

    setAccountInfo: async function (username) {
      let res = await this.$axios.get('/account?username=' + username);
      this.addressArray.street1 = res.data.address_array.street_1 ?? this.addressArray.street1;
      this.addressArray.street2 = res.data.address_array.street_2 ?? this.addressArray.street2;
      this.addressArray.city = res.data.address_array.city ?? this.addressArray.city;
      this.addressArray.stateRegion = res.data.address_array.state_region ?? this.addressArray.stateRegion;
      this.addressArray.postalCode = res.data.address_array.postal_code ?? this.addressArray.postalCode;
      this.addressArray.country = res.data.address_array.country ?? this.addressArray.country;
      this.fullAddress = res.data.address;
      this.phoneNumber = res.data.cellPhone;
      this.name = res.data.firstName + ' ' + res.data.lastName;
    },

    clearForm() {
      this.clearMemberInfo();
      this.clearRatesInfo();
      this.clearBuyProducts();
      this.clearSellProducts();
      this.clearShipAndPayInfo();
      this.buyToggle = true;
      this.sellToggle = false;
      this.step = 1;
    },

    clearMemberInfo() {
      this.emailEditable = true;
      if (this.$refs.accountInfoForm) {
        this.$refs.accountInfoForm.reset();
      }
    },

    clearRatesInfo() {
      this.buyOfferLevel = 'Walk-In';
      this.buyOverride = false;
      this.sellOverride = false;
      this.getPublicRates();
    },

    clearShipAndPayInfo() {
      if (this.$refs.shipAndPayForm) {
        this.$refs.shipAndPayForm.reset();
      }
      this.$nextTick(() => {
        this.buy.summary = {
          paidAmount: '0',
          creditAmount: '0',
          shippingAmount: '0'
        }
        this.sell.summary = {}
        this.insuranceAmount = '0'
      });
    },

    checkGivenItems() {
      this.anyGivenItems = false;

      if (this.buyToggle && this.buy.products.length !== 0) {
        this.buy.products.forEach(item => {
          if (Number(item.given) > 0) this.anyGivenItems = true;
        });
      }
    },

    clearBuyProducts() {
      this.showBuySelectProducts = false;
      this.buy.products = [];
      this.unrefinedBuyProducts = {};
      this.$nextTick(() => {
        this.showBuySelectProducts = true;
      });
    },

    clearSellProducts() {
      this.showSellSelectProducts = false;
      this.sell.products = [];
      this.unrefinedSellProducts = {};
      this.$nextTick(() => {
        this.showSellSelectProducts = true;
      });
    },

    setSolidRates(isBuy = true) {
      this.ratesLoading = true;
      let rates = {
        gold: isBuy ? this.retrievedRates.gold_rate : this.retrievedRates.gold_buy_back,
        silver: isBuy ? this.retrievedRates.silver_rate : this.retrievedRates.silver_buy_back,
        goldback: isBuy ? this.retrievedRates.goldback_rate : this.retrievedRates.goldback_buy_back,
        junk_silver: isBuy ?
            Number(this.retrievedRates.silver_rate.replace(/[^0-9.-]+/g,"") * 4 / 7 * 1.075) :
            Number(this.retrievedRates.silver_rate.replace(/[^0-9.-]+/g,"") * 4 / 7)
      };
      rates.gold = Number(rates.gold.replace(/[^0-9.-]+/g,"")) * (isBuy ? 50 : 1);
      rates.silver = Number(rates.silver.replace(/[^0-9.-]+/g,""));
      rates.goldback = Number(rates.goldback.replace(/[^0-9.-]+/g,""));
      rates.junk_silver = Number(rates.junk_silver).toFixed(2);

      if (isBuy && this.buyOfferLevel !== 'Walk-In') {
        let gb_secret = (Number(this.retrievedRates.gold_spot.replace(/[^0-9.-]+/g,"")) * 1.05 + 1200) / 1000;
        if (this.buyOfferLevel === 'GB Shareholder') {
          rates.goldback = Number(gb_secret + 0.20).toFixed(2);
        }
        else if (this.buyOfferLevel === 'GB Wholesale') {
          rates.goldback = Number(gb_secret + 0.10).toFixed(2);
        }
        else if (this.buyOfferLevel === 'GB Secret Wholesale') {
          rates.goldback = Number(gb_secret).toFixed(2);
        }
        else if (this.buyOfferLevel === 'Employee') {
          rates.gold = Number(rates.gold * 0.96).toFixed(2);
          rates.silver = Number(rates.silver * 0.96).toFixed(2);
          rates.junk_silver = Number(this.retrievedRates.silver_rate.replace(/[^0-9.-]+/g,"") * 4 / 7).toFixed(2);
        }
      }

      isBuy ? (this.buy.rates = rates) : (this.sell.rates = rates);
      this.ratesLoading= false;
    },

    onBuySelectedProductsChange(products) {
      this.buyCartLoading = true;
      this.unrefinedBuyProducts = products;
      this.buy.products = this.formatProduct(true);
      this.buyCartLoading = false;
    },

    onSellSelectedProductsChange(products) {
      this.sellCartLoading = true;
      this.unrefinedSellProducts = products;
      this.sell.products = this.formatProduct(false);
      this.sellCartLoading = false;
    },

    openFinCENDialog() {
      this.$refs.finCENDialog.openDialog();
    },

    formatProduct(isBuy = true) {
      let tableArray = []
      let refinedProducts = isBuy ? this.buy.products : this.sell.products;
      let unrefinedProducts = isBuy ? this.unrefinedBuyProducts : this.unrefinedSellProducts;

      refinedProducts.forEach(item => {
        if(unrefinedProducts[item.group_name]) {
          if (unrefinedProducts[item.group_name][item.name]) {
            if (item.quantity !== item.given) {
              unrefinedProducts[item.group_name][item.name].given = Number(unrefinedProducts[item.group_name][item.name].quantity) - (Number(item.quantity) - Number(item.given));
            } else {
              unrefinedProducts[item.group_name][item.name].given = Number(unrefinedProducts[item.group_name][item.name].quantity);
            }
            if (item.override) {
              unrefinedProducts[item.group_name][item.name].price = item.price;
              unrefinedProducts[item.group_name][item.name].override = item.override;
            }
          }
        }
      });

      Object.keys(unrefinedProducts).forEach(key => {
        Object.keys(unrefinedProducts[key]).forEach(key2 => {
          if(unrefinedProducts[key][key2].quantity > 0) {
            //given is initially undefined
            if (unrefinedProducts[key][key2].given === undefined) {
              unrefinedProducts[key][key2].given = Number(unrefinedProducts[key][key2].quantity);
            }
            if (!unrefinedProducts[key][key2].override) {
              unrefinedProducts[key][key2].override = false;
            }
            unrefinedProducts[key][key2].group_name = key;
            tableArray.push({...unrefinedProducts[key][key2]});
          } else {
            unrefinedProducts[key][key2].quantity = 0;
            unrefinedProducts[key][key2].given = undefined;
          }
        })
      });
      return tableArray;
    },

    toStep(step) {
      switch (step) {
        case 1:
          this.step = 1;
          break;
        case 2:
          this.updateDeliveryNecessary()
          this.checkGivenItems()
          this.$nextTick(() => {
            this.step = 2;
            this.showTask = false;
          });
          break;
        case 3:
          this.formatRequest();
          this.isValidated = false;
          this.preResponse = '';
          this.placeOrder(false);
          this.$nextTick(() => {
            this.step = 3;
          });
          break;
        default:
          this.step = 1;
          break;
      }
    },


    async placeOrder(submit = false) {
      const url = (submit ? '/admin/service/request' : '/admin/service/request/pre')

      this.submitLoading = true
      await this.$axios.post(url, this.formattedRequest).then(({data}) => {
        if (submit) {
          this.submitSuccess= true
          this.submitResponse = data.message
          this.uuidFromSubmit = data.uuid
          this.orderSubmitted = true
          this.showTask = true;
          this.clearForm()
        } else {
          this.isValidated = true
          this.preResponse = data.message
        }
      })
          .catch(({response}) => {
            this.preResponse = response.data
          })
      this.submitLoading = false
    },

    formatRequest() {
      this.formattedRequest = {
        requestType: 'Buy/Sell',
        email: this.email,
        phoneNumber: this.phoneNumber,
        name: this.name,
        communicationType: 'In-Person',
        verificationType: 'Not Needed',
        description: 'In Office Buy/Sell',
        paymentForm: this.paymentForm,
        warehouseUuid: this.warehouseUuid
      };

      if (!this.emailEditable) {
        this.formattedRequest.memberUsername = this.email;
      }

      if (this.deliveryMethod === 'Pick-Up') {
        this.formattedRequest.pickUpDate = this.pickUpDate;
      }
      else if (this.deliveryMethod === 'Shipping') {
        this.formattedRequest.shippingAddress = this.addressArray;
      }

      if (this.buyToggle) {

        let buyRatesCopy = {...this.buy.rates};
        delete buyRatesCopy.junk_silver;

        this.formattedRequest.buy = {
          rates: buyRatesCopy,
          products: [],
          summary: {}
        };

        this.formattedRequest.buy.summary.totalAmount = this.totalUSD(true).toFixed(2);
        if (this.deliveryMethod === 'Shipping') {
          this.formattedRequest.buy.summary.shippingAmount =
              (Number(this.buy.summary.shippingAmount) + Number(this.insuranceAmount)).toFixed(2);
        }
        if (this.creditToggle) {
          this.formattedRequest.buy.summary.creditAmount = Number(this.buy.summary.creditAmount).toFixed(2);
        }
        if (this.paymentForm !== 'Paper' && this.emailInstructionsToggle) {
          this.formattedRequest.emailInstructions = true;
        }
        if (this.paymentForm === 'Paper' && this.buy.summary.paidAmount > 10000) {
          this.openFinCENDialog();
        }
        this.formattedRequest.buy.summary.paidAmount = Number(this.buy.summary.paidAmount).toFixed(2);
        this.buy.products.forEach(item => {
          let product = {};
          product.owed = Number(item.quantity) - Number(item.given);
          product.quantity = item.quantity;
          product.price = this.calcPrice(true, item);
          product.uuid = item.uuid;
          this.formattedRequest.buy.products.push(product);
        });
      }

      if (this.sellToggle) {

        let sellRatesCopy = {...this.sell.rates};
        delete sellRatesCopy.junk_silver;

        this.formattedRequest.sell = {
          rates: sellRatesCopy,
          products: [],
          summary: {
            totalAmount: this.totalUSD(false).toFixed(2)
          }
        };

        this.sell.products.forEach(item => {
          let product = {};
          product.quantity = item.quantity;
          product.price = this.calcPrice(false, item);
          product.uuid = item.uuid;
          this.formattedRequest.sell.products.push(product);
        });
      }
    },

    updateDeliveryNecessary() {
      if (this.sellToggle && !this.buyToggle) {
        this.deliveryNecessary = true; //if only a sell and no buy
      }
      else if (this.totalUSD(true) - this.totalUSD(false) < 0) {
        this.deliveryNecessary = true; //if the sell is bigger than the buy
      }
      else {
        this.deliveryNecessary = this.owedProducts(); //check for owed products
      }

      if (this.deliveryNecessary) {
        this.insuranceAmount = this.calcInsurance().toFixed(2);
      } else {
        this.deliveryMethod = '';
      }
    },

    owedProducts() {
      let owed = false;
      this.buy.products.forEach(item => {
        if (item.given !== item.quantity) owed = true;
      });
      return owed;
    },

    deficitUSD() {
      return this.netUSD() - Number(this.buy.summary.creditAmount) - Number(this.buy.summary.paidAmount);
    },

    netUSD() {
      let total = 0;
      if (this.buyToggle) {
        total += this.totalUSD(true);
        if (this.deliveryMethod === 'Shipping') {
          total += Number(this.buy.summary.shippingAmount);
          total += Number(this.insuranceAmount);
        }
      }
      if (this.sellToggle) {
        total -= this.totalUSD(false);
      }
      return total;
    },

    totalUSD(isBuy = true, owedOnly = false) {
      let total = 0;
      let products = isBuy ? this.buy.products : this.sell.products;
      products.forEach(item => {
          total += Number(this.calcPrice(isBuy, item, owedOnly))
      });
      return total;
    },

    calcInsurance() {
      let total = 0;
      if (this.totalUSD(true, true) > 110) {
        total = this.totalUSD(true, true) * 0.004;
      }
      return total;
    },

    calcPrice(isBuy = true, item, owedOnly = false) {
      if (item.override && item.price) return item.price;

      let quantity = (owedOnly ? item.quantity - item.given : item.quantity)

      let price = 0;
      if(!item.asset_type_id && (item.name === 'Goldback Wallet - Black' || item.name === 'Goldback Wallet - Brown')) {
        price = parseInt(quantity) * 25;
      } else if (!item.asset_type_id && item.group_name === '90% Silver') {
        price = parseInt(quantity) * parseInt(item.denomination) / 20 *
            (isBuy ? Number(this.buy.rates.junk_silver) : Number(this.sell.rates.junk_silver));
      } else if (item.asset_type_id === 3373) {
        price = parseInt(quantity) * parseInt(item.denomination) / 50 *
            (isBuy ? Number(this.buy.rates.gold) : Number(this.sell.rates.gold));
      } else if (item.asset_type_id === 3374) {
        price = parseInt(quantity) * parseInt(item.denomination) *
            (isBuy ? Number(this.buy.rates.silver) : Number(this.sell.rates.silver));
      } else if (item.asset_type_id === 7000) {
        price = parseInt(quantity) * parseInt(item.denomination) *
            (isBuy ? Number(this.buy.rates.goldback) : Number(this.sell.rates.goldback));
      } else {
        price = 0;
      }
      price = price.toFixed(2);
      return price;
    },

    getPublicRates() {
      const url = '/public/rates';
      this.$axios.get(url).then(({data}) => {
        this.retrievedRates = data;
        this.setSolidRates(true); //buy rates set
        this.setSolidRates(false); //sell rates set
        })
        .catch(({response}) => {
          console.log(response.data);
          this.$refs.errorDialog.showError({

            errorMessage: response.data.message,
            forDevelopers: response.data.validationErrors
          });
        })
    },

    getShippingRates() {
      const url = '/admin/shipping/rates';
      this.$axios.get(url).then(({data}) => {
        this.shippingRates = data;
        })
        .catch(({response}) => {
          console.log(response.data);
          this.$refs.errorDialog.showError({

            errorMessage: response.data.message,
            forDevelopers: response.data.validationErrors
          });
        })
    },

    getWarehouses() {
      const url = '/warehouse/all';
      this.$axios.get(url).then(({data}) => {
        this.warehouses = data;
        })
        .catch(({response}) => {
          console.log(response.data);
          this.$refs.errorDialog.showError({

            errorMessage: response.data.message,
            forDevelopers: response.data.validationErrors
          });
        })
    },
  },

  mounted() {
    localStorage.title = 'Buy/Sell';
    this.getPublicRates();
    this.getShippingRates();
    this.getWarehouses();
    this.showBuySelectProducts = true;
    this.showSellSelectProducts = true;
  }
}
</script>

<style scoped>


</style>