<template>
  <v-dialog v-model="holdPrompt" persistent max-width="600px">
    <v-card>
      <v-card-title class="headline">
        <v-spacer/>Attention: Communicate this to the member!
      <v-spacer/>
      </v-card-title>
      <v-card-text class="text-center mt-2">
        <v-container class="subheading" style="margin-top: -14px" fluid grid-list-md>
          For the fastest service, select the currency you wish to acquire now. Once funds have been received,
          your account will be credited with the selected currency at the next business day's rate.<br><br>

          Deposits made directly into your account's USD holdings may not be converted to other supported currencies until after the funds are not on hold.<br><br>

          <strong>Deposits made by Check will be on hold for 10 days after funds are received. </strong>
<!--            <br>-->
<!--            Deposits made by ACH can take up to 60 days to clear.</strong>-->

        </v-container>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="error" text @click="holdPrompt = false">Close</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  name: "HoldDialog.vue",
  data() {
    return {
      holdPrompt: false
    };
  },
  methods: {
    openDialog() {
      this.holdPrompt = true;
    },
    closeDialog() {
      this.holdPrompt = false;
    }
  }
};
</script>

<style scoped>
</style>