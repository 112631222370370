<template>

  <v-row justify="center">
    <v-dialog v-model="shown" max-width="500">
      <v-card>

        <v-row>
            <!-- Title -->
            <v-col class="pa-2 pt-1" cols="10">
                <div id="title" class="pl-3 pt-0">
                  <v-card-title class="headline" style="word-break: unset">Clear Transactions Confirmation</v-card-title>
                </div>
            </v-col>
            <v-col class="text-right" cols="2">
                <v-btn
                    color="primary"
                    @click="shown = false"
                    icon
                    class="px-3 ml-n6 mr-3"
                    style="margin-top: 6px"
                >
                  <v-icon>mdi-close</v-icon>
                </v-btn>
            </v-col>
        </v-row>

        <!-- Body -->
        <v-card-text>
          <v-row>
            <v-col cols="12" sm="12" class="d-flex justify-center">
              <span class="body-1">Transactions Selected: {{ transactions.length }}</span>
            </v-col>
          </v-row>
        </v-card-text>

        <v-divider></v-divider>

        <v-card-actions>
          <v-btn
              @click="shown = false"
              text
          >Cancel
          </v-btn>

          <v-btn
              @click="clearTransactions()"
              text
          >Clear Transactions
          </v-btn>
        </v-card-actions>

      </v-card>
    </v-dialog>

    <ErrorDialog ref="errorDialog"/>

  </v-row>
</template>

<script>
  // @ is an alias to /src
  import ErrorDialog from "@/components/ErrorDialog";

  export default {
    name: "PendingClearDialog",
    components: {
      ErrorDialog
    },

    data(){
      return {
        shown: false,
        transactions: {}
      }
    },

    methods: {

      buildDialog: function (transactions) {
        this.transactions = transactions;
        this.shown = true;
      },

      clearTransactions: async function () {
        try {
          let journalNums = this.transactions.map(item => item.journal_num);

          this.shown = false;
          await this.$axios.patch(`/admin/reports/clear`, {journalNums: journalNums});
          this.$parent.getTransactions();
          alert("Successfully cleared the transaction(s).");

        } catch (e) {
          this.$refs.errorDialog.showError({
            errorMessage: "Failed to clear the transaction(s).",
            forDevelopers: e
          })
        }
      }
    }
  }
</script>
<style scoped>

</style>