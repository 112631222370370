<template>
  <v-dialog v-model="finCENPrompt" persistent max-width="600px">
    <v-card>
      <v-card-title class="headline">
        <v-spacer/>FinCEN Report Required
      <v-spacer/>
      </v-card-title>
      <v-card-text class="text-center mt-2">
        A finCEN report will be required for this transaction
        <v-btn class="mt-2"  color="primary" href="https://www.irs.gov/pub/irs-pdf/f8300.pdf" target="_blank">Begin Report in new tab</v-btn>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="error" text @click="finCENPrompt = false">Close</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  name: "FinCENDialog.vue",
  data() {
    return {
      finCENPrompt: false
    };
  },
  methods: {
    openDialog() {
      this.finCENPrompt = true;
    },
    closeDialog() {
      this.finCENPrompt = false;
    }
  }
};
</script>

<style scoped>
</style>