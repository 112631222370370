<template>
  <div>
    <v-layout justify-center class="mt-12" v-if="initialLoad" >
       <v-progress-circular
           indeterminate
           color="primary"
           :size="100"
       ></v-progress-circular>
    </v-layout>
      <v-card v-else flat class="mb-8">
        <v-card-title>
          {{data.type}} {{ data.subtype ? ' - ' + data.subtype : ''}} {{data.order_id ? 'Order #' + data.order_id : ''}}
          <v-tooltip bottom v-if="data.username">
            <template #activator="{ on }">
              <v-btn v-on="on" class="ml-2" small icon @click="loginAs(data.username)" ><v-icon >
                mdi-account-arrow-right</v-icon></v-btn>
            </template>
            <span>Login as {{data.username}}</span>
          </v-tooltip>
          <v-spacer />
        </v-card-title>
        <v-card-subtitle >
          Status History
          <div class="d-flex">
          <div v-for="(item, index) in [...data.service_request_actions].reverse()" :key="item.uuid" >
            <v-tooltip bottom>
              <template #activator="{ on }">
                <v-chip v-on="on" max-width="100" small> {{item.to_status}} </v-chip>
              </template>
              <span>{{ item.admin_name }} <br/> {{ mixin_UTCToPretty(item.timestamp)}}</span>
            </v-tooltip>
            <v-icon small v-if="data.service_request_actions.length - 1 !== index ">
              mdi-arrow-right
            </v-icon>
          </div>
        </div>
        <b class="caption" v-if="data.service_request_actions[0].to_status !== 'Archived'
        && data.service_request_actions[0].to_status !== 'Cancelled'">
          <v-menu
              v-model="menu"
              :close-on-content-click="true"
              :nudge-right="20"
              transition="scale-transition"
              offset-y
              min-width="0px">
            <template v-slot:activator="{on}">
              <strong v-on="on" style="cursor: pointer">Next Actionable Date: {{data.service_request_actions[0].day_due}}</strong>
            </template>
            <v-date-picker
                outlined
                no-title
                :allowed-dates="(date) => date >= new Date().toISOString().substr(0, 10)"
                v-model="actionableDate"
                @input="changeActionableDate(actionableDate)"
            ></v-date-picker>
          </v-menu>
          <br>
          <strong>Actionable By: {{data.service_request_actions[0].notified_party}}</strong>
        </b>
        </v-card-subtitle>
        <v-card-text>
          <div class="d-flex">
            <v-card class="mr-4" flat max-width="800">
              <div v-if="data.description" style="white-space: pre-wrap">
                <b class="caption"><strong>Description</strong></b>
                <br>{{data.description}}
              </div>
              <div class="mt-4">
                <b class="caption"><strong>General Information</strong></b> <br>
              </div>
              <div v-if="data.username" >
                Username: {{data.username}}
              </div>
              <div v-for="(value, propertyName) in data.service_request" :key="propertyName" >
                <span v-if="propertyName === 'payment_info'" >
                  {{!Object.values(value).every((v) => v === null) ? fromSnakeToCamel(propertyName) + ': ' : ''}}
                  <div v-for="(internalValue, internalPropertyName) in value"
                      :key="internalPropertyName" class="ml-2" style="color: gray">
                    {{internalValue !== null && internalValue !== "" ? fromSnakeToCamel(internalPropertyName) + ': ' + internalValue : ''}}
                  </div>
                </span>

<!--               Previous Profile Info Display-->
                <div v-else-if="propertyName === 'update_profile_info'" class="flex-container" >
                  <div v-for="(prevValue, propertyName) in data.service_request.previous_profile_info" :key="propertyName">
                    <div v-if="propertyName !== 'address_array'">
                        <div class="previous-info">
                          <!-- Display properties other than address_array from previous_profile_info -->
                          {{ prevValue !== null && prevValue !== "" ? fromSnakeToCamel(propertyName) + ': ' + prevValue : '' }}
                        </div>
                        <div class="arrow">→</div>
                        <div class="updated-info">
                          <!-- Display the corresponding properties from update_profile_info other than address_array -->
                          {{ data.service_request.update_profile_info && data.service_request.update_profile_info[propertyName] !== null &&
                        data.service_request.update_profile_info[propertyName] !== "" ? data.service_request.update_profile_info[propertyName] : '' }}
                        </div>
                      </div>
                      <div v-else>
                        <!-- Sets the order for address so it isn't in a wonky order -->
                        <template v-for="addressPropertyName in ['street_1', 'street_2', 'city', 'state_region', 'country', 'postal_code']">
                          <div v-if="(prevValue && prevValue[addressPropertyName]) ||
                          (data.service_request.update_profile_info && data.service_request.update_profile_info.address_array && data.service_request.update_profile_info.address_array[addressPropertyName])"
                               :key="addressPropertyName"
                               class="address-container">
                            <div class="previous-info">
                              <!-- Shows "None" if the property exists in update_profile_info but not previous_profile_info (to handle Street 2 being optional), doesn't display properties that haven't changed-->
                              {{ prevValue && prevValue[addressPropertyName] !== null && prevValue[addressPropertyName] !== "" ? fromSnakeToCamel(addressPropertyName) + ': ' + prevValue[addressPropertyName] : data.service_request.update_profile_info.address_array[addressPropertyName] ? fromSnakeToCamel(addressPropertyName) + ': None' : '' }}
                            </div>
                            <div class="arrow">→</div>
                            <div class="updated-info">
                              <!-- Only shows values of properties that have changed -->
                              {{ data.service_request.update_profile_info && data.service_request.update_profile_info.address_array && data.service_request.update_profile_info.address_array[addressPropertyName] !== null && data.service_request.update_profile_info.address_array[addressPropertyName] !== "" ? data.service_request.update_profile_info.address_array[addressPropertyName] : '' }}
                            </div>
                          </div>
                        </template>
                      </div>
                  </div>
                </div>
<!--                Since this property is displayed in the above span, hides-->
                <span v-else-if="propertyName === 'previous_profile_info'"/>

<!--                Merchant Updates are displayed in separate cards-->
                <span v-else-if="propertyName === 'previous_merchant_uuid'"/>
                <span v-else-if="propertyName === 'merchant_uuid'"/>

                <span v-else-if="propertyName === 'recruit_info'" >
                  <span v-if="data.service_request.recruit_info.action !== null && value !== ''">
                  <strong>Member's Request: </strong>
                  {{fromSnakeToCamel(data.service_request.recruit_info.action) + ' ' +
                data.service_request.recruit_info.recruit_or_recruiter + ' ' +
                ((data.service_request.recruit_info.username !== null) ?
                    data.service_request.recruit_info.username : '')}}
                    </span>
<!--                  do nothing for this property if null so there are no issues-->
                  <span v-else></span>
                </span>

                <span v-else-if="propertyName === 'shipping_address' && value">
                  {{value !== null && value !== "" ? fromSnakeToCamel(propertyName) + ': ' + value : ''}}
                  <v-btn icon x-small style="color: grey" v-on="data.on" @click="copyShipping"><v-icon>mdi-content-copy</v-icon></v-btn>
                </span>
                <span v-else>
                  {{value !== null && value !== ""  ? fromSnakeToCamel(propertyName) + ': ' + value : ''}}
                </span>
                <span v-if="propertyName === 'verification_type' && value === 'Not Needed'" class="red--text">
                  Please verify that the verification type is not needed!
                  <v-tooltip right>
                    <template #activator="{ on }">
                      <v-btn v-on="on" class="ml-2" small icon>
                        <v-icon >
                          mdi-information-outline
                        </v-icon>
                      </v-btn>
                    </template>
                        <div style="max-width: 200px">If this request should have had a verification type
                          (I.E. It does not come from the user directly) please ask the original requester
                          to add a verification reason in the notes.</div>
                  </v-tooltip>
                </span>
                <span v-if="propertyName === 'time_delay_flag' && value === true" class="red--text">
                  The bank account was added less than 3 days before the request.
                  <v-tooltip right>
                    <template #activator="{ on }">
                      <v-btn v-on="on" class="ml-2" small icon>
                        <v-icon >
                          mdi-information-outline
                        </v-icon>
                      </v-btn>
                    </template>
                        <div style="max-width: 200px">The exchange will still happen at the next business day, but payment will not happen until 2 days after the exchange has settled.</div>
                  </v-tooltip>
                </span>
              </div>
              <div class="mt-4" v-if="!Object.values(data.assets).every((v) => v === null)">
                <b class="caption"><strong>Assets</strong></b> <br>
              </div>
              <div v-for="(value, propertyName) in data.assets" :key="propertyName" >
                {{ value !== null && value !== "" ? fromSnakeToCamel(propertyName) + ': ' +
                  (propertyName.split("_")[1] === 'asset' ? assetIDToName(Number(value)) : value) : ''}}
                <span v-if="propertyName === 'override' && value === true" class="red--text">
                  The exchange rate has been overridden!
                  <v-tooltip right>
                    <template #activator="{ on }">
                      <v-btn v-on="on" class="ml-2" small icon>
                        <v-icon >
                          mdi-information-outline
                        </v-icon>
                      </v-btn>
                    </template>
                        <div style="max-width: 200px">Please verify the exchange rate and reasoning before pushing this request forward.</div>
                  </v-tooltip>
                </span>
              </div>
              <div v-if="data.follow_up" class="mt-4">
                <b class="caption"><strong>Follow Up Description</strong></b> <br>
                {{data.follow_up_note}}
              </div>
              <div class="mt-4" v-if="data.buy.length !== 0">
                <b class="caption"><strong>Products Purchased From UPMA</strong></b> <br>
                Rates: <br>
                <span class="ml-2" :style="{color: assetColor(3373)}">
                  ${{Number(data.buy.rates[3373]).toFixed(2)}} / Gold Dollar
                </span> <br>
                <span class="ml-2" :style="{color: assetColor(3374)}">
                  ${{Number(data.buy.rates[3374]).toFixed(2)}} / Silver Dollar
                </span> <br>
                <span class="ml-2" :style="{color: assetColor(7000)}">
                  ${{Number(data.buy.rates[7000]).toFixed(2)}} / Goldback
                </span>
                <v-data-table
                    :headers="buyHeaders"
                    :items="data.buy.products"
                    v-if="data.buy.products.length !== 0"
                    class="elevation-2 mt-2 mb-2 pb-0 text-xs"
                    hide-default-footer
                    :items-per-page=50
                    dense
                >

                  <template v-slot:item.group_name="{ item }">
                    <span :style="item.asset_type_id ? {color: assetColor(item.asset_type_id)} : {}">
                      {{item.group_name + ' - ' + item.product_name}}
                    </span>
                  </template>
                  <template v-slot:item.holder_1 ="{  }">
                    x
                  </template>
                  <template v-slot:item.owed ="{ item }">
                    <strong v-if="!['Fulfilled', 'Cancelled', 'Archived'].includes(data.service_request_actions[0].to_status)">{{item.owed}}</strong>
                    <span v-else>{{item.owed}}</span>
                  </template>
                  <template v-slot:item.denomination ="{ item }">
                    <span :style="item.asset_type_id ? {color: assetColor(item.asset_type_id)} : {}">
                      ${{(item.price / item.quantity).toFixed(2)}}
                    </span>
                  </template>
                  <template v-slot:item.holder_2 ="{  }">
                    =
                  </template>
                  <template v-slot:item.price ="{ item }">
                    <span>
                      ${{(item.price / 1).toFixed(2)}}
                    </span>
                  </template>
                </v-data-table>
              </div>
              <div class="mt-4" v-if="data.sell.length !== 0">
                <b class="caption"><strong>Products Sold To UPMA</strong></b> <br>
                Rates: <br>
                <span class="ml-2" :style="{color: assetColor(3373)}">
                  ${{data.sell.rates[3373]}} / Gold Oz
                </span> <br>
                <span class="ml-2" :style="{color: assetColor(3374)}">
                  ${{data.sell.rates[3374]}} / Silver Oz
                </span> <br>
                <span class="ml-2" :style="{color: assetColor(7000)}">
                  ${{data.sell.rates[7000]}} / Goldback
                </span>
                <v-data-table
                    :headers="sellHeaders"
                    :items="data.sell.products"
                    v-if="data.sell.products.length !== 0"
                    class="elevation-2 mt-2 mb-2 pb-0 text-xs"
                    hide-default-footer
                    :items-per-page=50
                    dense
                >
                  <template v-slot:item.group_name="{ item }">
                    <span :style="item.asset_type_id ? {color: assetColor(item.asset_type_id)} : {}">
                      {{item.group_name + ' - ' + item.product_name}}
                    </span>
                  </template>
                  <template v-slot:item.holder_1 ="{  }">
                    x
                  </template>
                  <template v-slot:item.denomination ="{ item }">
                    <span :style="item.asset_type_id ? {color: assetColor(item.asset_type_id)} : {}">
                      ${{(item.price / item.quantity).toFixed(2)}}
                    </span>
                  </template>
                  <template v-slot:item.holder_2 ="{  }">
                    =
                  </template>
                  <template v-slot:item.price ="{ item }">
                    <span>
                      ${{(item.price / 1).toFixed(2)}}
                    </span>
                  </template>
                </v-data-table>
              </div>
              <div class="mt-4" v-if="data.buy.length !== 0 || data.sell.length !== 0 ">
                <b class="caption"><strong>Buy/Sell Summary</strong></b>
                  <v-card   style="position: sticky; min-width: 270px">
                    <v-card-text>
                      <div class="d-flex" v-if="data.buy.summary">
                        <div>
                          Purchases:
                        </div>
                        <v-spacer/>
                        <div>
                          ${{Number(data.buy.summary.total_amount).toFixed(2)}}
                        </div>
                      </div>
                      <div class="d-flex" v-if="data.sell.summary">
                        <div>
                          Sales:
                        </div>
                        <v-spacer/>
                        <div>
                          -${{Number(data.sell.summary.total_amount).toFixed(2)}}
                        </div>
                      </div>
                      <span v-if="data.buy.summary">
                        <div class="d-flex" v-if="data.buy.summary.shipping_amount">
                          <div>
                            Shipping & Insurance:
                          </div>
                          <v-spacer/>
                          <div>
                            ${{Number(data.buy.summary.shipping_amount).toFixed(2)}}
                          </div>
                        </div>
                        <div class="d-flex" v-if="data.buy.summary.credit_amount">
                          <div>
                            Credit:
                          </div>
                          <v-spacer/>
                          <div>
                            -${{Number(data.buy.summary.credit_amount).toFixed(2)}}
                          </div>
                        </div>
                      </span>
                      <v-divider></v-divider>
                      <div class="d-flex">
                        <div>
                          Total USD:
                        </div>
                        <v-spacer/>
                        <div v-if="netUSD() < 0">
                          -${{Math.abs(netUSD()).toFixed(2)}}
                        </div>
                        <div v-else>
                          ${{Number(netUSD()).toFixed(2)}}
                        </div>
                      </div>
                      <div class="d-flex" v-if="data.buy.summary">
                        <v-spacer/>
                        <div v-if="['Cancelled', 'Requested'].includes(this.data.service_request_actions[0].to_status) && this.data.service_request.payment_form !== 'Paper'">
                          Paid: $0.00
                        </div>
                        <div v-else>
                          Paid: ${{Number(data.buy.summary.paid_amount).toFixed(2)}}
                        </div>
                        <v-spacer/>
                        <div>
                          Change Remitted: ${{Math.abs(Number(netUSD()) - Number(data.buy.summary.paid_amount)).toFixed(2)}}
                        </div>
                        <v-spacer/>
                      </div>
                      <div class="d-flex" v-if="data.buy.summary">
                        <v-spacer/>
                        <div>
                          Value of Products Received: ${{valueOfRemain(false).toFixed(2)}}
                        </div>
                        <v-spacer/>
                        <div>
                          Value of Products Owed: ${{valueOfRemain(true).toFixed(2)}}
                        </div>
                        <v-spacer/>
                      </div>
                      <div class="d-flex mt-2">
                        <v-spacer/>
                        <div>
                          <v-btn color="primary" @click="printConfigDialog = true">Print/Email</v-btn>
                        </div>
                      </div>
                    </v-card-text>
                  </v-card>
              </div>
              <div class="mt-4" v-if="data.associated_transactions.length !== 0">
                <b class="caption"><strong>Associated Transactions</strong></b>
                <v-tooltip right v-if="data.username">
                  <template #activator="{ on }">
                    <v-btn v-on="on" class="ml-2" small icon @click="assetsAs(data.username, data.service_request.name)" ><v-icon >
                      mdi-currency-usd</v-icon></v-btn>
                  </template>
                  <span>Go To Assets</span>
                </v-tooltip>
                <br>
              </div>
              <v-data-table
                  :headers="headers"
                  :items="data.associated_transactions"
                  v-if="data.associated_transactions.length !== 0"
                  class="elevation-2 mt-2 mb-2 pb-0 text-xs"
                  dense
                  hide-default-footer
              >
                <template v-slot:item.posted="{ item }">
                  {{mixin_UTCToPretty(item.posted)}}
                </template>
                <template v-slot:item.last_settled_date="{ item }">
                  {{mixin_UTCToPretty(item.last_settled_date)}}
                </template>


                <template v-slot:item.amount="{ item }">
                  {{item.amount ? item.asset_type !== 'Paper Dollar' ? item.amount.replace(/\$|,/g, '') : item.amount : null}}
                </template>

              </v-data-table>
            </v-card>
            <!-- Merchant Update Info -->
            <v-card v-if="lastMerchant.business_name" class="ml-4 mr-4" flat max-width="250">
              <div>
                <strong>Previous Merchant Info</strong>
                <br><br>
                <div v-for="(value, propertyName) in lastMerchant" :key="propertyName">
                  <!-- logo displayed as an image -->
                  <template v-if="propertyName === 'logo_display' && value">
                    <div class="property-container">
                      <strong>Logo:</strong><br>
                      <img :src="value" alt="Logo" style="width: 100%; height: auto;"><br>
                    </div>
                  </template>
                  <!-- html needed for location formatting to work -->
                  <template v-else-if="propertyName === 'locations' && value">
                    <div class="property-container">
                      <strong>{{ fromSnakeToCamel(propertyName) + ':' }}</strong><br>
                      <div v-html="formatLocations(value)"></div>
                    </div>
                  </template>
                  <template v-else-if="propertyName === 'phone' && value">
                    <div class="property-container">
                      <strong>Phone:</strong> {{ formatPhoneNumber(value) }}<br>
                    </div>
                  </template>
                  <!-- website should be a link -->
                  <template v-else-if="propertyName === 'website' && value">
                    <div class="property-container">
                      <strong>{{ fromSnakeToCamel(propertyName) + ': ' }}</strong>
                      <a :href="value" target="_blank">{{ value }}</a><br>
                    </div>
                  </template>
                  <!-- all other properties normal -->
                  <template v-else-if="value">
                    <div class="property-container">
                      <strong>{{ fromSnakeToCamel(propertyName) + ':' }}</strong>
                      {{ value }}<br>
                    </div>
                  </template>
                </div>
              </div>
            </v-card>

            <v-card v-if="merchantRequested.business_name" class="ml-4 mr-4" flat max-width="250">
              <div>
                <strong v-if="lastMerchant.business_name">Requested Merchant Update</strong>
                <strong v-else>Merchant Application</strong>
                <br><br>
                <div v-for="(value, propertyName) in merchantRequested" :key="propertyName">
                  <!-- logo displayed as an image -->
                  <template v-if="propertyName === 'logo_display' && value">
                    <div class="property-container">
                      <strong>Logo:</strong><br>
                      <img :src="value" alt="Logo" style="width: 100%; height: auto;"><br>
                    </div>
                  </template>
                  <!-- html needed for location formatting to work -->
                  <template v-else-if="propertyName === 'locations' && value">
                    <div class="property-container">
                      <strong>{{ fromSnakeToCamel(propertyName) + ':' }}</strong><br>
                      <div v-html="formatLocations(value)"></div>
                    </div>
                  </template>
                  <template v-else-if="propertyName === 'email' && value">
                    <div class="property-container">
                      <strong>{{ fromSnakeToCamel(propertyName) + ': ' }}</strong>
                      {{ value }}<br>
                      <span style="color: red; font-weight: bold;">
                        Email must be verified via a successful contact before approval
                      </span>
                    </div>
                  </template>
                  <template v-else-if="propertyName === 'phone' && value">
                    <div class="property-container">
                      <strong>Phone:</strong> {{ formatPhoneNumber(value) }}<br>
                      <span style="color: red; font-weight: bold;">
                        Phone must be verified via a successful contact before approval
                      </span>
                    </div>
                  </template>
                  <!-- website should be a link -->
                  <template v-else-if="propertyName === 'website' && value">
                    <div class="property-container">
                      <strong>{{ fromSnakeToCamel(propertyName) + ': ' }}</strong>
                      <a :href="value" target="_blank">{{ value }}</a><br>
                    </div>
                  </template>
                  <!-- all other properties normal -->
                  <template v-else-if="value">
                    <div class="property-container">
                      <strong>{{ fromSnakeToCamel(propertyName) + ':' }}</strong>
                      {{ value }}<br>
                    </div>
                  </template>
                </div>
              </div>
            </v-card>


            <v-spacer/>

            <v-divider vertical />
            <v-card class="ml-4" flat>
              <strong>Notes</strong>
              <div v-for="item in data.service_request_notes" :key="item.uuid" class="mt-2 mb-2" style="white-space: pre-wrap">
                <b class="caption">{{item.admin_name}} - {{ mixin_UTCToPretty(item.timestamp)}}</b>
                <br>{{item.note}}
                <v-divider class="mt-2 mb-2"/>
              </div>
                <v-textarea
                  outlined
                  label="New Note"
                  counter="320"
                  v-model="newNote"
                />
                <v-btn
                    class="mt-2"
                    color="primary"
                    :loading="newNoteLoading"
                    :disabled="!newNote"
                    @click="addNoteToServiceRequest()">
                  ADD NOTE
                </v-btn>
            </v-card>
            <v-spacer/>
          </div>
        </v-card-text>
        <v-card-actions>
          <div v-for="(value, propertyName) in data.next_actions" :key="propertyName" class="mr-2">
            <v-btn :loading="loading" :color="data.service_request_actions[0].day_due <= new Date().toISOString().slice(0, 10) ? 'primary' : 'gray'" @click="value.parameters ? triggerActionDialog(value, propertyName) : patchServiceRequest(value.to_status)">
              {{propertyName}}
            </v-btn>
          </div>
        </v-card-actions>
      </v-card>

    <v-dialog v-model="actionDialog" width="600">
      <v-card>
        <v-card-title />
        <v-card-text >
          <div v-for="item in actionDialogParams.required" class="mt-2" :key="item[0]">
            <v-text-field :label="item[0] + '*'" v-model="actionDialogData[item[0]]" outlined hide-details />
          </div>
          <div v-for="item in actionDialogParams.optional" class="mt-2" :key="item[0]">
            <v-select v-if="item[0] === 'trackingCarrier'" clearable v-model="actionDialogData[item[0]]" :items="['FEDEX', 'UPS', 'USPS', 'DHL']"></v-select>
            <v-text-field v-else :label="item[0]" v-model="actionDialogData[item[0]]" outlined hide-details />
          </div>
        </v-card-text>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="primary" :loading="loading" @click="patchServiceRequest(actionDialogToStatus, true)">
            {{actionDialogButtonName}}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>



    <v-dialog v-model="printConfigDialog" max-width="600">
      <v-card class="pa-4">
        <v-card-title>Print Configuration</v-card-title>
        <v-card-text>
          <v-card outlined class="pa-8">
            <v-checkbox v-model="printConfig.packingSlipOnly" label="Packing slip only"></v-checkbox>
            <v-checkbox v-model="printConfig.signatureLines" label="Include signature lines"></v-checkbox>
            <v-checkbox v-model="printConfig.addNote" label="Add notes"></v-checkbox>
            <v-textarea v-model="printConfig.note" v-if="printConfig.addNote" dense></v-textarea>
          </v-card>
        </v-card-text>

        <!-- Print & Cancel Buttons -->
        <v-card-actions>
          <v-btn color="red"
                 dark
                 @click="printConfigDialog = false">
            Cancel
          </v-btn>
          <v-spacer/>
          <v-btn color="primary"
                 @click="printOrder(printConfig.signatureLines, printConfig.packingSlipOnly, (printConfig.addNote ? printConfig.note : null), true)">
            Print
          </v-btn>
          <v-btn color="primary"
                 :loading="loadingPDF"
                 :disabled="!this.email && !this.data.username"
                 @click="printOrder(printConfig.signatureLines, printConfig.packingSlipOnly, (printConfig.addNote ? printConfig.note : null), false)">
            Email
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <ErrorDialog ref="errorDialog"/>
    <SnackBar ref="snackbar"/>
  </div>
</template>

<script>
// import axios from "axios"

import ErrorDialog from "../ErrorDialog";
import jspdf from "jspdf";
import autoTable from "jspdf-autotable";
import SnackBar from "../../components/Snackbar";

export default {
  name: 'Task',
  props: {
    uuid: String
  },
  components: {ErrorDialog, SnackBar},
  data() {
    return {
      validNote: false,
      printConfig: {
        signatureLines: false,
        packingSlipOnly: false,
        addNote: false,
        note: null,
      },
      printConfigDialog: false,
      data: {},
      noteData: [],
      actionDialog: false,
      menu: false,
      actionableDate: '',
      actionDialogParams: {},
      actionDialogButtonName: '',
      actionDialogToStatus: '',
      actionDialogData: {},
      email: '',
      newNote: '',
      newNoteLoading: false,
      loadingPDF: false,
      loading: false,
      initialLoad: true,
      merchantRequested: {
        business_name: '',
        summary: '',
        description: '',
        email: '',
        phone: '',
        members_may_contact_my_business_by: '',
        type: '',
        subtypes: [],
        logo_display: null,
        website: null,
        locations: []
      },
      lastMerchant: {
        business_name: '',
        summary: '',
        description: '',
        email: '',
        phone: '',
        members_may_contact_my_business_by: '',
        type: '',
        subtypes: [],
        logo_display: null,
        website: null,
        locations: []
      },
      headers: [
        { text: 'Posted', value: 'posted', sortable: false},
        { text: 'Settled', value: 'last_settled_date', sortable: false},
        { text: 'Transaction Type', value: 'type',sortable: false },
        { text: 'Status', value: 'status',sortable: false },
        { text: 'Account Name', value: 'name', sortable: false },
        { text: 'Asset Type', value: 'asset_type', sortable: false},
        { text: 'Amount', value: 'amount', align: 'right', sortable: false},
        { text: 'Percent Done (if DCA)', value: 'percent_done', align: 'right', sortable: false}
      ],
      buyHeaders: [
        { text: 'Product', value: 'group_name', sortable: false},
        { text: 'Units Owed', value: 'owed', align: 'end', sortable: false },
        { text: 'Total Units', value: 'quantity', align: 'end', sortable: false},
        { text: '', value: 'holder_1', align: 'end', sortable: false},
        { text: 'Unit Price', value: 'denomination', sortable: false},
        { text: '', value: 'holder_2', sortable: false},
        { text: 'Price', value: 'price', align: 'end', sortable: false }
      ],
      sellHeaders: [
        { text: 'Product', value: 'group_name', sortable: false},
        { text: 'Units', value: 'quantity', align: 'end', sortable: false},
        { text: '', value: 'holder_1', align: 'end', sortable: false},
        { text: 'Unit Price', value: 'denomination', sortable: false},
        { text: '', value: 'holder_2', sortable: false},
        { text: 'Price', value: 'price', align: 'end', sortable: false }
      ]
      // headers: [
      //   { text: 'Date'},
      //   { text: 'Transaction Type', sortable: true },
      //   { text: 'Counterparty', sortable: true },
      //   { text: 'Asset Type', sortable: true },
      //   { text: 'Amount', align: 'right', sortable: true },
      //   {  align: 'right', sortable: false}
      // ],
    }
  },
  watch: {
  },
  computed: {
  },
  async mounted() {
    localStorage.title = 'Tasks';
    // this.getProductGroups(true)
    await this.getServiceRequest();
    if (this.data.service_request.merchant_uuid) {
      this.merchantRequested = await this.getMerchantUpdateInfo(this.data.service_request.merchant_uuid)
      if (this.data.service_request.previous_merchant_uuid) {
        this.lastMerchant = await this.getMerchantUpdateInfo(this.data.service_request.previous_merchant_uuid)
      }
    }
  },

  methods: {

    copyShipping() {
      let splitStr = this.data.service_request.name.split(', ');
      let name = '';
      for (let i = splitStr.length - 1; i >= 0; i--) {
        name += splitStr[i];
        if (i !== 0) name += ' ';
      }
      console.log(this.data.service_request.shippingAddress);
      navigator.clipboard.writeText(name + ', ' + this.data.service_request.shipping_address);
    },

    printOrder(printSignatureLines = false, packingSlipOnly = false, note = null, print = true) {
      if (!print) this.loadingPDF = true;
      const pdf = new jspdf({
        orientation: "portrait",
        unit: "in",
        format: "letter"

      });
      let orderDate = (this.data.buy.timestamp ? this.data.buy.timestamp : this.data.sell.timestamp);
      const date = new Date(orderDate).toLocaleString('en-US', {
        weekday: 'long', // e.g., Thursday
        day: 'numeric', // numeric, 2-digit
        year: 'numeric', // numeric, 2-digit
        month: 'long', // e.g., March
      })
      let y_align = 0.58;

      if (this.data.buy.rates) {
        pdf.setFontSize(7).text("Applicable Exchange Rates: ", .5, y_align - 0.2);
        pdf.setTextColor('#b49230');// set color to gold
        pdf.setFontSize(7).text("Gold Dollar: $" + Number(this.data.buy.rates[3373]).toFixed(2), 1.9, y_align - 0.2);
        pdf.setTextColor('#9E9E9E');// set text color to silver
        pdf.setFontSize(7).text("Silver Dollar: $" + Number(this.data.buy.rates[3374]).toFixed(2), 2.95, y_align - 0.2);
        pdf.setTextColor('#1C97A5');// set color to goldback
        pdf.setFontSize(7).text("Goldback: $" + Number(this.data.buy.rates[7000]).toFixed(2), 4.05, y_align - 0.2);
        pdf.setTextColor(0, 0, 0);// set text color back to black
      }
      pdf.setFontSize(13).text("Order #" + this.data.order_id, .5, y_align);
      pdf.setFontSize(10).text("Order Date: " + date, .5, y_align += 0.2);
      pdf.setFontSize(10).text("Recipient Name: " + this.data.service_request.name, .5, y_align += 0.2);
      if (!packingSlipOnly) pdf.setFontSize(10).text("Teller Name: " + this.data.service_request_actions[this.data.service_request_actions.length - 1].admin_name, .5, y_align += 0.2);

      if (this.data.buy.summary) {

        if (!packingSlipOnly) pdf.setFontSize(11).text("Purchases", .5, y_align += 0.4)

        pdf.setFontSize(10);
        let buyTransactions = []
        this.data.buy.products.forEach(item => {
          if (packingSlipOnly) {
            if (item.owed)
            buyTransactions.push([
              item.group_name + ' - ' + item.product_name,
              Number(item.owed)
            ])
          } else {
            buyTransactions.push([
              item.group_name + ' - ' + item.product_name,
              item.quantity,
              (this.valueOfRemain(true) ? Number(item.quantity) - Number(item.owed) : item.quantity),
              '$' + (item.price / item.quantity).toFixed(2),
              '$' + item.price
            ])
          }
        })
        let purchaseHeaders = [];
        let columnStyle = {};

        if (packingSlipOnly) {
          purchaseHeaders = [
            {content: "Item"},
            {content: "Quantity", styles: {halign: 'right'}}
          ];
          columnStyle = { 1: {halign: 'right'}};
        } else {
          purchaseHeaders = [
            {content: "Item"},
            {content: "Quantity"},
            {content: "Received"},
            {content: "Unit Price"},
            {content: "Price", styles: {halign: 'right'}}
          ];
          columnStyle = { 4: {halign: 'right'}};
        }
        autoTable(pdf, { // put bold on number amounts
          startY: y_align += 0.1,
          theme: 'plain',
          headStyles: {fontStyle: 'bold', fontSize: 11},
          styles: {
            cellPadding: .03
          },
          columnStyles: columnStyle,
          head: [
              purchaseHeaders
          ],
          body: [
            ...buyTransactions
          ],
          didDrawCell: function(data) {
            y_align = data.cursor.y + data.table.getHeadHeight(data.table.columns)
            if (data.section === 'head' && data.column.index === 0) {
              pdf.setDrawColor(0,0,0);
              pdf.setLineWidth(.0125)
              pdf.line(data.cursor.x, data.cursor.y + data.table.getHeadHeight(data.table.columns), data.cursor.x + data.table.getWidth(8.5), data.cursor.y + data.table.getHeadHeight(data.table.columns))
            }

          },
        });
      }

      if (!packingSlipOnly) {
        if (this.data.sell.summary) {
          pdf.setFontSize(11).text("Sales", .5, y_align += 0.4)
          pdf.setFontSize(10)
          let sellTransactions = []
          this.data.sell.products.forEach(item => {
            sellTransactions.push([
              item.group_name + ' - ' + item.product_name,
              item.quantity,
              '$' + (item.price / item.quantity).toFixed(2),
              '$' + item.price
            ])
          })
          autoTable(pdf, { // put bold on number amounts
            startY: y_align += 0.1,
            theme: 'plain',
            headStyles: {fontStyle: 'bold', fontSize: 11},
            styles: {
              cellPadding: .03
            },
            columnStyles: {3: {halign: 'right'}},
            head: [[
              {content: "Item"},
              {content: "Quantity"},
              {content: "Unit Price"},
              {content: "Price", styles: {halign: 'right'}},

            ]],
            body: [
              ...sellTransactions
            ],
            willDrawCell: function () {
            },
            didDrawCell: function (data) {
              y_align = data.cursor.y + data.table.getHeadHeight(data.table.columns)
              if (data.section === 'head' && data.column.index === 0) {
                pdf.setDrawColor(0, 0, 0);
                pdf.setLineWidth(.0125)
                pdf.line(data.cursor.x, data.cursor.y + data.table.getHeadHeight(data.table.columns), data.cursor.x + data.table.getWidth(8.5), data.cursor.y + data.table.getHeadHeight(data.table.columns))
              }
            },
          });
        }

        let summaryBody = [];
        let paymentInfoHeaders = [];
        let paymentInfoBody = [];
        paymentInfoHeaders.push({content: "Payment Method"});
        paymentInfoBody.push([this.data.service_request.payment_form]);

        if (this.data.buy.summary) {
          if (this.valueOfRemain(true)) {
            paymentInfoHeaders.push({content: "USD Value of Received"});
            paymentInfoBody.push(['$' + this.valueOfRemain(false).toFixed(2)])
            paymentInfoHeaders.push({content: "USD Value of Owed"});
            paymentInfoBody.push(['$' + this.valueOfRemain(true).toFixed(2)])
          }
          paymentInfoHeaders.push({content: "USD Paid"});
          if (['Cancelled', 'Requested'].includes(this.data.service_request_actions[0].to_status) && this.data.service_request.payment_form !== 'Paper') {
            paymentInfoBody.push(['$0.00'])
          } else {
            paymentInfoBody.push(['$' + Number(this.data.buy.summary.paid_amount).toFixed(2)])
          }
          paymentInfoHeaders.push({content: "Change Remitted"});
          paymentInfoBody.push(['$' + Math.abs(Number(this.netUSD()) - Number(this.data.buy.summary.paid_amount)).toFixed(2)])
          summaryBody.push(["Purchases:", '$' + Number(this.data.buy.summary.total_amount).toFixed(2)])
          if (this.data.sell.summary) {
            summaryBody.push(["Sales:", '-$' + Number(this.data.sell.summary.total_amount).toFixed(2)])
          }
          if (this.data.buy.summary.shipping_amount) {
            summaryBody.push(["Shipping & Insurance:", '$' + Number(this.data.buy.summary.shipping_amount).toFixed(2)])
          }
          if (this.data.buy.summary.credit_amount) {
            summaryBody.push(["Credit:", '-$' + Number(this.data.buy.summary.credit_amount).toFixed(2)])
          }
          summaryBody.push(["Total USD:", '$' + this.netUSD().toFixed(2)])
        } else {
          summaryBody.push(["Sale Total USD:", '$' + Math.abs(this.netUSD()).toFixed(2)])
        }


        autoTable(pdf, {
          startY: y_align += 0.5,
          theme: 'plain',
          margin: 0,
          bodyStyles: {halign: 'right'},
          body: [
            ...summaryBody
          ],
          didDrawCell: function (data) {
            y_align = data.cursor.y + data.table.getHeadHeight(data.table.columns)
          }
        })
        pdf.setLineWidth(.0125);
        pdf.line(3.75, y_align, 8, y_align);

        autoTable(pdf, {
          startY: y_align += 0.35,
          theme: 'plain',
          margin: 0,
          head: [
            paymentInfoHeaders
          ],
          body: [
            paymentInfoBody
          ],
          didDrawCell: function (data) {
            y_align = data.cursor.y + data.table.getHeadHeight(data.table.columns)
          }
        })

      }
      if (note) {
        pdf.text('Notes: ' + note, .5, y_align += .5);
      }

      if(printSignatureLines){
        autoTable(pdf, { // put bold on number amounts
          theme: 'plain',
          pageBreak: 'avoid',
          headStyles: {fontStyle: 'normal', fontSize: 11, cellPadding: {top: 1}},
          styles: {
            cellPadding: .02
          },
          head: [[
            {content: "__________________"},
            {content: "__________________"},
            {content: "__________________"},
            {content: "__________________"},
          ]],
          body: [
            ["Teller", "Witness", "Member", "Identification"]
          ]
        })
      }

      const pageCount = pdf.internal.getNumberOfPages();
      for(let i = 1; i < pageCount+1; i++) {
        pdf.setPage(i);
        autoTable(pdf,{ // put bold on number amounts
          startY: .2,
          theme: 'plain',
          margin: 0,
          headStyles: {fontStyle: 'normal', halign: 'right'},
          columnStyles: { 0: {halign: 'right'}},
          head: [[
            {content: "Page " + i + " of " + pageCount},
          ]],
        })
      }

      if (print) {
        let string = pdf.output("bloburi", {filename: 'Order Receipt'})
        let x = window.open();
        x.document.open();
        x.document.location=string;
        this.loadingPDF = false;
      } else {
        const pdfBlob = new Blob([pdf.output('blob')]);
        const formData = new FormData();
        formData.append('pdf', pdfBlob, 'order_receipt_#' + this.data.order_id + '.pdf');
        formData.append('username', this.data.username);
        formData.append('email', this.email);
        formData.append('email_title', 'Order Receipt #' + this.data.order_id);

        this.$axios.post('/email_pdf', formData, {
          headers: {
            'Content-Type': 'multipart/form-data',
          }
        }).then(response => {
              this.$refs.snackbar.showSnackbar({
                text: response.data.message,
                color: 'success'
              });
              this.loadingPDF = false;
              this.printConfigDialog = false;
            }
        ).catch(({response}) => {
          this.$refs.errorDialog.showError({

            errorMessage: response.data.message,
            forDevelopers: response.data.validationErrors
          });
        });
      }
    },

    formatLocations(locations) {
      return locations.map(location => {
        const parts = [];
        if (location.street_1) parts.push(location.street_1);
        if (location.street_2) parts.push(location.street_2);
        if (location.city) parts.push(location.city);
        if (location.postal_code) parts.push(location.postal_code);
        if (location.state_region) parts.push(location.state_region);
        if (location.country) parts.push(location.country);
        let locationString = parts.join(', ');
        if (location.approximate) {
          locationString += `<br>*approximate location only (for member view)`;
        }
        return locationString;
      }).join('<br><br>');
    },

    formatPhoneNumber(phone) {
      if (!phone) return null;
      // Remove non-digit characters for safety
      const digits = phone.replace(/\D/g, '');
      if (digits.length === 10) {
        return `(${digits.slice(0, 3)}) ${digits.slice(3, 6)}-${digits.slice(6)}`;
      } else if (digits.length === 11) {
        return `${digits.slice(0, 1)} (${digits.slice(1, 4)}) ${digits.slice(4, 7)}-${digits.slice(7)}`;
      } else {
        return phone; // Return original if it doesn't match expected lengths
      }
    },

    fromSnakeToCamel(sentence) {
      let sentenceString = sentence.toString();
      let words = sentenceString.split("_");

      for (let i = 0; i < words.length; i++) {
        words[i] = words[i][0].toUpperCase() + words[i].substr(1);
      }
      return words.join(" ");
    },

    netUSD() {
      let total = 0;
      if (this.data.buy.summary) {
        total += Number(this.data.buy.summary.total_amount)
        if (this.data.buy.summary.shipping_amount) {
          total += Number(this.data.buy.summary.shipping_amount);
        }
        if (this.data.buy.summary.credit_amount) {
          total -= Number(this.data.buy.summary.credit_amount);
        }
      }
      if (this.data.sell.summary) {
        total -= Number(this.data.sell.summary.total_amount)
      }
      return total;
    },

    valueOfRemain(owed = true) {
      let total = 0;
      if (this.data.buy.summary) {
        if (['Fulfilled', 'Archived'].includes(this.data.service_request_actions[0].to_status)) {
          return owed ? 0 : Number(this.data.buy.summary.total_amount);
        }
        this.data.buy.products.forEach(item => {
          if (owed) {
            total += Number((Number(item.price) / Number(item.quantity) * Number(item.owed)).toFixed(2));
          } else {
            if (Number(item.quantity) - Number(item.owed) > 0) {
              total += Number((Number(item.price) / Number(item.quantity) * (Number(item.quantity) - Number(item.owed))).toFixed(2));
            }
          }
        });
      }
      return total;
    },

    triggerActionDialog(val, buttonName){
      this.actionDialogParams = val.parameters
      this.actionDialogButtonName = buttonName
      this.actionDialogToStatus = val.to_status
      this.actionDialogData = {}
      this.actionDialog = true
    },

    assetsAs: async function (username, name) {
      let memberURL = '';
      memberURL = window.location.origin + '/Assets?username=' + username + '&name=' +name;
      window.open(memberURL);
    },

    loginAs: async function (username) {

      let res = await this.$axios.post('/admin/token', {
        grant_type: 'delegation',
        client_id: 'NA',
        target_username: username
      });

      let token = res.data;

      let memberURL = '';

      // If the local environment is set, return it.
      const localEnv = process.env.VUE_APP_DEV_MEMBER_URL;

      if (localEnv) {
        memberURL = localEnv;

      } else {

        // If the sub-domain is a valid environment include it in the url for the api.
        let environments = ['qa', 'new', 'dev'];
        let environment = window.location.host.slice(0, window.location.host.indexOf('.'));
        // console.log(environment);


        if (environments.includes(environment)) {
          memberURL = `https://${environment}.member.upma.org/`;

        } else {

          // Else have it connect with the production api.
          memberURL = process.env.VUE_APP_MEMBER_URL;
        }
      }
      memberURL = memberURL + '';
      window.open(`${memberURL}?token=${token.access_token}`);
    },

    async getMerchantUpdateInfo(uuid) {
      const url = 'admin/merchant/' + uuid;
      this.loading = true;
      try {
        const response = await this.$axios.get(url);
        const data = response.data;
        let merchantInfo = {
          business_name: data.businessName || null,
          description: data.description || null,
          summary: data.summary || null,
          email: data.email || null,
          phone: data.phone || null,
          members_may_contact_my_business_by: data.contactPreference,
          type: data.type || null,
          subtypes: data.subtypes ? data.subtypes.join(', ') : null,
          logo_display: data.logo_display_link || null,
          website: data.website || null,
          locations: data.locations ? data.locations.map(location => ({
            street_1: location.street_1,
            street_2: location.street_2,
            city: location.city,
            postal_code: location.postal_code,
            state_region: location.state_region,
            country: location.country,
            approximate: location.approximate
          })) : null
        };
        this.loading = false;
        return merchantInfo;
      } catch (error) {
        this.$refs.errorDialog.showError({
          errorMessage: error.response.data.message,
          forDevelopers: error.response.data.validationErrors
        });
        this.loading = false;
        return {};
      }
    },

    async getServiceRequest() {
      const url = '/admin/service/request/' + this.uuid
      this.loading = true
      await this.$axios.get(url).then(({data}) => {
        this.data = data
        this.email = data.service_request.email
        this.actionableDate = data.service_request_actions[0].day_due
      })
          .catch(({response}) => {
            this.$refs.errorDialog.showError({

              errorMessage: response.data.message,
              forDevelopers: response.data.validationErrors
            });
          })
      this.loading = false
      this.initialLoad = false
    },

    async changeActionableDate(actionableDate) {
      const url = '/admin/service/request/action'
      let body = {
        actionableDate: actionableDate,
        uuid: this.uuid
      }

      this.loading = true
      await this.$axios.patch(url, body).then(() => {
        this.getServiceRequest()
        this.actionableDate = '';
      })
          .catch(({response}) => {
            this.$refs.errorDialog.showError({

              errorMessage: response.data.message,
              forDevelopers: response.data.validationErrors
            });
          })
      this.loading = false
    },

    async addNoteToServiceRequest() {
      const url = '/admin/service/request/note'
      const body = {
        note: this.newNote,
        uuid: this.uuid
      }
      this.newNoteLoading = true
      await this.$axios.post(url, body).then(({data}) => {
        this.data.service_request_notes = data
        this.newNote = ''
      })
          .catch(({response}) => {
            this.$refs.errorDialog.showError({

              errorMessage: response.data.message,
              forDevelopers: response.data.validationErrors
            });
          })
      this.newNoteLoading = false
    },

    async patchServiceRequest(toStatus, isDialog = false) {
      if (toStatus !== 'Cancelled' || confirm('Are you sure you would like to cancel this task?')) {
        const url = '/admin/service/request'
        let body = {
          to_status: toStatus,
          uuid: this.uuid
        }
        if (isDialog) {
          body = {...this.actionDialogData, ...body}
        }
        this.loading = true
        await this.$axios.patch(url, body).then(() => {
          if (isDialog) this.actionDialog = false
          this.getServiceRequest().then(() => {
                this.loading = false
          }
          ).catch(() => {
            this.loading = false
          })

        })
            .catch(({response}) => {
              this.$refs.errorDialog.showError({

                errorMessage: response.data.message,
                forDevelopers: response.data.validationErrors
              });
              this.loading = false
            })
      }
    }
  }
}
</script>
<style scoped>
.flex-container {
  display: flex;
  flex-direction: column;
}

.previous-info, .updated-info, .arrow {
  display: inline-block;
  vertical-align: middle;
}

.property-container {
  border: 1px solid #ccc; /* Gray border */
  padding: 10px; /* Padding inside the border */
  margin-bottom: 5px; /* Space between entries */
  background-color: #f9f9f9; /* Light background color */
}

.arrow {
  margin: 0 10px;
}
</style>