<template>
    <v-container
            class="fill-height"
            fluid
    >
      <v-row
              align="center"
              justify="center"
      >
        <v-col
                cols="12"
                sm="8"
                md="4"
                v-if="this.state === 'display'"
        >
          <v-card class="elevation-5 mt-12">
              <v-toolbar
                      color="primary"
                      dark
                      flat
              >
                  <v-toolbar-title>Admin Login</v-toolbar-title>
              </v-toolbar>
              <v-card-text>
                  <v-form>
                      <v-text-field
                              label="Username"
                              name="login"
                              prepend-icon="mdi-account"
                              type="text"
                              v-model="username"
                              :disabled="totpEnabled"
                              @keyup.enter="$refs.passwordField.focus()"
                      />

                      <v-text-field
                              v-model="password"
                              ref="passwordField"
                              id="password"
                              label="Password"
                              name="password"
                              prepend-icon="mdi-lock"
                              type="password"
                              :disabled="totpEnabled"
                              @keyup.enter="check2FA"
                      />
                    <v-text-field
                        class="centered-input"
                        name="totp"
                        label="2FA"
                        v-model="totp"
                        v-if="totpEnabled"
                        prepend-icon="mdi-lock-clock"
                        :rules="rules.totpRules"
                        required
                        @keyup.enter="login()"
                    />
                  </v-form>
              </v-card-text>
              <v-card-actions>
                  <v-spacer></v-spacer>
                  <v-btn color="primary"
                         v-if="!totpEnabled"
                         @click="check2FA"
                  >
                      Login
                  </v-btn>
                  <v-btn color="primary"
                         v-if="totpEnabled"
                         @click="login"
                  >
                    Login
                  </v-btn>
              </v-card-actions>
          </v-card>
        </v-col>

        <v-col
              cols="12"
              sm="8"
              md="4"
              v-else
          >
          <v-spacer/>
          <loader line-fg-color="#295491" v-if="this.state === 'loading'" size="massive"/>
          <v-spacer/>
        </v-col>
      </v-row>

      <RedirectToQADialog ref="redirectDialog" @popupHandled="handlePopup"/>
      <ErrorDialog ref="errorDialog"/>
    </v-container>
</template>

<script>
    import loader from "vue-simple-spinner";
    import ErrorDialog from "../components/ErrorDialog";
    import RedirectToQADialog from "@/components/RedirectToQADialog";
    export default {
        name: "Login",
        components: {ErrorDialog, loader, RedirectToQADialog},
        data: () => ({
            state: 'display',
            username: "",
            password: "",
            totp: "",
            rules: {
              totpRules: [
                (v) => !!v || 'Auth code is required',
                (v) => (!!v && v.length === 6) || 'Auth code must be 6 digits',
              ],
            },
            totpEnabled: false,
            popupHandled: false
        }),

        mounted() {
            localStorage.title = '';
        },

        methods: {
          check2FA: async function() {
            try {
              var res = await this.$axios.post('/admin/2fa/enabled', {
                username: this.username,
                password: this.password,
                grant_type: 'password',
                client_id: 'testclient'
              });

              let data = res.data

              if (data.totp_enabled) {
                this.totpEnabled = true;
              }
              else {
                if (window.location.hostname === 'localhost') await this.login()
                else {
                  this.$refs.errorDialog.showError({
                    errorTitle: "2FA Required for Admin Access",
                    errorMessage: "Please enable 2FA in the member portal."
                  })
                }
              }
            } catch (e) {
              this.$refs.errorDialog.showError({
                errorTitle: "Incorrect Username and Password",
                errorMessage: "The username and password combination was incorrect. Please try again.",
                forDevelopers: e
              })
            }
          },

          async handlePopup() {
            this.popupHandled = true;
            await this.checkAndNavigate();
          },

          checkAndNavigate: async function () {
            try {
              const res = await this.$axios.get('/admin/user/policyAcknowledgement');
              const unacknowledgedPolicies = res.data;

              if (unacknowledgedPolicies.length > 0) {
                await this.$router.push({ name: 'Policy Acknowledgement', params: { policies: unacknowledgedPolicies } });
              } else {
                await this.$router.push('/tasks');
              }
            } catch (e) {
              this.$refs.errorDialog.showError({
                errorTitle: "Error Checking Policies",
                errorMessage: "There was a problem checking for unacknowledged policies.",
                forDevelopers: e
              });
            }
          },

          login: async function () {
              try {
                this.state = 'loading';

                var res = await this.$axios.post('/admin/login', {
                      username: this.username,
                      password: this.password,
                      totp: this.totp,
                      grant_type: 'password',
                      client_id: 'testclient'
                  });

                  let tokenSet = res.data;

                  // We save the refresh token to use later for resumption.
                  localStorage.setItem('refresh_token', tokenSet.refresh_token);
                  this.$axios.defaults.headers.common['Authorization'] = `Bearer ${tokenSet.access_token}`;

                  res = await this.$axios.get('/admin/user');

                  this.$root.user = res.data;

                  let roles = res.data.userScopes;
                  roles = roles.map(function(x){ return x.toUpperCase(); })// in case scopes are not uppercase
                  let allowedComponents = [];
                  if (roles.includes('ADMIN')) allowedComponents.push('Balances', 'Settings', 'Products', 'Franchises');
                  if (roles.includes('ACCTG')) allowedComponents.push('Credit/Debit/ACH',
                    'Pending Transactions', 'Wire/Check', 'Cards', 'Rates', 'Holdings', 'Pawn', 'Adjustment', 'Receipts');
                  if (roles.includes('STAFF')) allowedComponents.push('Assets', 'Receipts', 'Service Requests',
                      'BuySell', 'Policy', 'Policy Acknowledgement');
                  if (roles.includes('SUPER')) allowedComponents.push('Roles');
                  if (roles.includes('LEASE')) allowedComponents.push('Lease');
                  if (roles.includes('ESCRW')) allowedComponents.push('Trust');
                  if (roles.includes('IRAEX')) allowedComponents.push('IRA Accounts');

                  // local storage only accepts strings
                  localStorage.setItem("allowedComponents", JSON.stringify(allowedComponents));
                  this.$eventBus.$emit('allowedComponents', allowedComponents);

                  this.$emit('showSnackbar', {
                      text: `Welcome back, ${this.$root.user.firstName}!`,
                      color: 'success'
                  });

                if (window.location.hostname === 'admin.upma.org') {
                  this.$refs.redirectDialog.openDialog();
                } else {
                  await this.handlePopup();
                }

              } catch (e) {

                this.state = 'display';

                this.$refs.errorDialog.showError({
                  errorTitle: "Incorrect Username and Password",
                  errorMessage: "The username and password combination was incorrect. Please try again.",
                  forDevelopers: e
                })
              }
          }
        }
    }
</script>

<style scoped>

</style>