import { render, staticRenderFns } from "./NewServiceRequest.vue?vue&type=template&id=82b74426&scoped=true&"
import script from "./NewServiceRequest.vue?vue&type=script&lang=js&"
export * from "./NewServiceRequest.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "82b74426",
  null
  
)

export default component.exports