<template>
    <v-sheet max-width="1000">
        <v-progress-linear v-if="state === 'loading'"
                           indeterminate
                           color="primary"
        />
        <v-card class="pa-10">
            <v-row justify="center">
                <v-sheet>
                    <h1 class="ml-3 mt-3 display-5">Premiums</h1>
                    <v-card class="ml-2 pa-2 "
                            outlined
                            max-width="500">
                        <p class="caption">
                            Premiums are the percentages that we charge above spot price. These numbers are incorporated into the algorithms for setting the rates each day. You may adjust the premium percentage for each asset type below.
                        </p>
                    </v-card>
                    <v-card-text>
                        <v-row>
                            <v-sheet max-width="800">
                                <v-row :key="premium.assetType.mini" v-for="premium in premiums">
                                    <v-col cols="4">
                                        <v-subheader>{{ premium.assetType.maxi }}</v-subheader>
                                    </v-col>
                                    <v-col cols="8">
                                        <v-text-field
                                                label="Premium"
                                                v-model="premium.value"
                                                prefix="%"
                                        ></v-text-field>
                                    </v-col>
                                </v-row>
                            </v-sheet>
                        </v-row>
                    </v-card-text>
                    <v-card-actions>
                        <v-spacer/>
                        <v-progress-circular v-if="state === 'loading'"
                                             indeterminate
                                             color="primary"
                        />
                        <v-btn v-else
                               color="primary"
                               dark
                               @click="saveChanges">
                            Save
                        </v-btn>
                    </v-card-actions>
                </v-sheet>
            </v-row>
        </v-card>
        <ErrorDialog ref="errorDialog"/>
        <SnackBar ref="snackbar"/>
    </v-sheet>
</template>

<script>
    import ErrorDialog from "../ErrorDialog";
    import SnackBar from "../Snackbar";
    export default {
        name: "Premiums",
        components: {SnackBar, ErrorDialog},
        data: () => ({
            state: 'display',
            rateTypes: [],
            premiums: [],
            originalPremium: [], // Premiums as they were before changes
        }),
        methods: {
            getPremiums: async function () {
                try {
                    let res = await this.$axios.get('/admin/premiums/current');
                    let premiums = res.data.premiums;
                    if (!premiums) {
                        this.premiums = []
                    }

                    this.premiums = premiums;
                    this.premiums.forEach(premium => {
                        premium.value = premium.value * 100
                    });

                    this.originalPremium = JSON.parse(JSON.stringify(premiums)); // Create a deep clone
                } catch(e) {
                    this.$refs.errorDialog.showError({
                        errorMessage: 'There was an error fetching the current premiums.',
                        forDevelopers: e
                    })
                }
            },
            saveChanges: async function() {
                this.state = 'loading';

                // Compare new and original premiums, if there is a change send it to the server to be updated.
                for (const newPremium of this.premiums) {
                    for (const origPremium of this.originalPremium) {

                        // If there was a change, send it to the server to be updated.
                        if(newPremium.assetType.mini === origPremium.assetType.mini && newPremium.value !== origPremium.value) {
                            await this.postPremium(newPremium);
                        }
                    }
                }

                await this.getPremiums();

                this.$refs.snackbar.showSnackbar({
                    text: "Premiums have been updated!",
                    color: "success"
                });
                this.state = 'display'
            },
            postPremium: async function (newPremium) {
                try {
                    await this.$axios.post('/admin/premiums', {
                        premium: {
                            value: newPremium.value / 100,
                            assetTypeMini: newPremium.assetType.mini
                        }
                    })
                } catch (e) {
                    this.$refs.errorDialog.showError({
                        errorMessage: "There was an error saving the premiums.",
                        forDevelopers: e
                    })
                }
            }
        },
        created: async function () {
            this.state = "loading";
            await this.getPremiums();
            this.state = "display"
        }
    }
</script>

<style scoped>

</style>