"use strict";

import Vue from 'vue';
import axios from "axios";
import router from "../router";

// Full config:  https://github.com/axios/axios#request-config
// axios.defaults.baseURL = process.env.baseURL || process.env.apiUrl || '';
// axios.defaults.headers.common['Authorization'] = AUTH_TOKEN;
// axios.defaults.headers.post['Content-Type'] = 'application/x-www-form-urlencoded';

// window.location.hostname and parse env out of it.
// Follow pattern on 16 and 17 to build the url.
let isRefreshing = false;
const retryQueue = [];

let config = {
  // baseURL: process.env.baseURL || process.env.apiUrl || ""
  // timeout: 60 * 1000, // Timeout
  // withCredentials: true, // Check cross-site Access-Control

  baseURL: `/api`,
  withCredentials: true
};

const _axios = axios.create(config);



_axios.interceptors.request.use(
  function(config) {
    // Do something before request is sent.
    // Append XDEBUG when in development.
    // For this to work, install the XDEBUG Chrome extension: https://chrome.google.com/webstore/detail/xdebug-helper/eadndfjplgieldjbigjakmdgkmoaaaoc?hl=en-US
    // Right click the extension > options > IDE Key <- PhpStorm.
    if (process.env.NODE_ENV === 'development') {
      let matches = document.cookie.match(/(?:(?:^|.*;\s*)XDEBUG_SESSION\s*=\s*([^;]*).*$)/);
        if (matches != null) {
          if (config.params) {
            config.params.XDEBUG_SESSION_START = matches[1];
          } else {
            config.params = {XDEBUG_SESSION_START: matches[1]};
          }
        }
      }
    return config;
  },
  function(error) {
    // Do something with request error
    return Promise.reject(error);
  }
);

// Add a response interceptor
_axios.interceptors.response.use(
    response => response,
    error => {
      if (error.config._skipInterceptor) {
        return Promise.reject(error); // Bypass the interceptor for the refresh token request
      }

      const originalRequest = error.config;

      if (error.response.status === 401 && !originalRequest._retry) {
        originalRequest._retry = true;

        if (!isRefreshing) {
          isRefreshing = true;
          // Call your refresh token API here
          _axios.post(`/admin/token`, {
            refresh_token: `${localStorage.getItem('refresh_token')}`,
            grant_type: 'refresh_token',
            client_id: 'testclient'
          },
              { _skipInterceptor: true } // Add custom property to bypass the interceptor
          ).then(newToken => {
            // Update the access token in your HTTP client and storage
            localStorage.setItem('access_token', newToken.data.access_token);
            localStorage.setItem('refresh_token', newToken.data.refresh_token);
            _axios.defaults.headers.common['Authorization'] = `Bearer ${localStorage.getItem('access_token')}`;
            // Process the retry queue
            processRetryQueue(null);

            // Reset the isRefreshing flag
            isRefreshing = false;
          }).catch((refreshError) => {
            // Handle token refresh failure
            processRetryQueue(refreshError);
            isRefreshing = false;

            router.push('/login');
          });
        }

        // Add the request to the retry queue and wait for the token to be refreshed
        return new Promise((resolve, reject) => {
          addToRetryQueue(() => {
            originalRequest.headers.Authorization = `Bearer ${localStorage.getItem('access_token')}`;
            axios(originalRequest)
                .then(resolve)
                .catch(reject);
          });
        });
      }

      return Promise.reject(error);
    }
);

function addToRetryQueue(callback) {
  retryQueue.push(callback);
}

function processRetryQueue(error) {
  if(error === null) {
    retryQueue.forEach(callback => callback(error));
  }
  retryQueue.length = 0; // Clear the queue
}

// eslint-disable-next-line no-unused-vars
Plugin.install = function(Vue, options) {
  Vue.axios = _axios;
  window.axios = _axios;
  Object.defineProperties(Vue.prototype, {
    axios: {
      get() {
        return _axios;
      }
    },
    $axios: {
      get() {
        return _axios;
      }
    },
  });
};

Vue.use(Plugin);

export default Plugin;
