<template>
  <div class="pa-5">
    <div v-if="allowedComponents.includes('Credit/Debit/ACH')">
      <v-data-table
          :headers="tableHeaders"
          :items="items"
          :search="search"
          single-select
          class="elevation-4 row-pointer"
          @click:row="rowClick"
          sort-by="posted"
          :sort-desc="true"
          @mouseover="statusHover = true"
          :loading="state === 'loading'"
      >
        <template v-slot:top>
          <v-text-field v-model="search" append-icon="mdi-magnify" label="Search Credit/Debit/ACH Transactions"
                        class="mx-4 pt-7"></v-text-field>
        </template>
        <template v-slot:item.posted="props">
          {{ mixin_UTCToPretty(props.item.posted)}}
        </template>
        <template v-slot:item.journalNum="props">
          {{ Number.parseInt(props.item.journalNum)}}
        </template>
      </v-data-table>


      <ErrorDialog ref="errorDialog"/>
      <SnackBar ref="snackbar"/>

      <v-dialog v-model="statusDialog" width='300'>
        <v-card>
          <v-card-title>
            Transaction Status
          </v-card-title>
          <v-card-text>

            <v-select label="status" class="mb-2" :items="selectedTransaction.oldStatus === 'Requested' ? requestedItems : pendingItems" v-model="statusValue">

            </v-select>

            <b>Transaction Type:</b> {{selectedTransaction.type + ' - ' + selectedTransaction.subtype }}
            <br>
            <b>Journal Num:</b> {{Number.parseInt(selectedTransaction.journalNum)}}

            <br>
            <b>Email: </b> {{selectedTransaction.username}}
            <br>
            <b>Paper Amount: </b> {{selectedTransaction.paperAmount}}
            <br>
            <b>Asset Type: </b> {{selectedTransaction.requestedAsset}}
            <br>
            <b>Requested:</b> {{date(selectedTransaction.posted)}}
          </v-card-text>
          <v-card-actions>
            <v-spacer/>
            <v-btn outlined @click="statusDialog = false, selectedTransaction = {}">
              CANCEl
            </v-btn>
            <v-btn  v-if="selectedTransaction.oldStatus !== selectedTransaction.status" class="mr-2" color="primary" @click="updateTransaction(selectedTransaction), statusDialog=false">
              UPDATE
              <v-progress-circular v-if="statusLoading" class="ml-1" size="25" indeterminate />
            </v-btn>
            <v-btn disabled v-if="selectedTransaction.oldStatus === selectedTransaction.status"  class="mr-2">
              UPDATE
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>

    </div>
    <div v-else class="pl-12 pr-12 pt-12 mt-12 headline text-center">Sorry, this page is not available.</div>
  </div>
</template>

<script>
  // @ is an alias to /src
  import moment from 'moment'
  import ErrorDialog from "@/components/ErrorDialog";
  import SnackBar from "@/components/Snackbar";

  const dateFormat = 'YYYY-MM-DD';

  export default {
    name: "CreditDebit",
    components: {
      ErrorDialog,
      SnackBar,
    },
    data: () => ({
      state: 'loading',
      search: "",
      statusDialog: false,
      statusLoading: false,
      manualDeposits: [],
      items: [],
      requestedItems: ['Requested', 'Cancelled', 'Pending'],
      pendingItems: ['Pending', 'Settled', 'Cancelled'],
      selectedTransaction: {},
      transaction: {},
      allowedComponents: JSON.parse(localStorage.getItem('allowedComponents') || ''),
      tableHeaders: [
        {
          text: 'Status',
          value: 'status',
          align: 'start',
        },
        {
          text: 'Subtype',
          value: 'subtype',
          align: 'start',
        },
        {
          text: 'Journal Num',
          value: 'journalNum',
          align: 'start'
        },
        {
          text: 'Email',
          value: 'username',
          align: 'start'
        },
        {
          text: 'Paper Amount',
          value: 'paperAmount',
          align: 'end',
          width: '140px'
        },
        {
          text: 'Asset Type',
          value: 'requestedAsset',
          align: 'start',
          width: '140px'
        },
        {
          text: 'Requested',
          value: 'posted',
          align: 'start',
          width: '140px'
        },
      ]
    }),
    computed: {
      statusValue: {
        get() {
          return this.selectedTransaction.status
        },
        set(val) {
          this.selectedTransaction.status = val
        }

      }
    },

    methods: {

      close() {
        if (this.$refs.autocomplete) {
          this.$refs.autocomplete.isMenuActive = false
        }
      },
      rowClick: function (item, row) {
        row.select(true);
        this.statusDialog=true
        this.selectedTransaction={...item}
      },

      date(date) {
        return moment(date).format(dateFormat);
      },

      updateTransaction: async function (item) {
        this.statusLoading=true
        let journalNum = item.journalNum;

        if (item.status !== item.oldStatus) { //status is changing - if not, do nothing
          if ((item.oldStatus === 'Requested' && item.status === 'Pending') //update from requested to pending
            || (item.oldStatus === 'Pending' && item.status === 'Settled')) {//or from pending to settled
            let params = {journalNum: journalNum};//This is the one that should work
            try {
              await this.$axios.patch('/admin/transactions/credit', params);
              this.$refs.snackbar.showSnackbar({
                text: `Successfully updated the transaction`,
                color: 'success',
                style: "margin-top: calc(-8px + .25vw)"
              });
            } catch (e) {
              item.status = item.oldStatus; // Reverses action.
              this.$refs.errorDialog.showError({
                errorMessage: 'An error occurred while trying to update the transaction. Please ensure the status is selected correctly. If the problem persists please notify the development team with a detailed description of the error.',
                forDevelopers: e.response.data.message
              })
            }
          }
            if (item.status === 'Cancelled') {
              if (confirm('Are you sure you want to CANCEL this transaction?')) {
                let params = {journalNums: [journalNum]};
                try {
                  await this.$axios.patch('/admin/transactions/cancel', params );
                  this.$refs.snackbar.showSnackbar({
                    text: `Successfully cancelled the transaction`,
                    color: 'success',
                    style: "margin-top: calc(-8px + .25vw)"
                  });
                } catch (e) {
                  item.status = item.oldStatus; // Reverses action.
                  this.$refs.errorDialog.showError({
                    errorMessage: 'An error occurred while trying to cancel the transaction. Please select cancelled in the dropdown and press enter. If the problem persists please notify the development team with a detailed description of the error.',
                    forDevelopers: e + '\n' + e.message
                  })
                }
              } else {
                item.status = item.oldStatus;
              }
            }
          }
          item.oldStatus = item.status;
        this.getManualDeposits();
        this.statusLoading=false;
        this.selectedTransaction = {};
        },

      getManualDeposits: async function () {
        try {
          this.state = 'loading';
          let res = await this.$axios.get(`/admin/reports/credit`)
          this.manualDeposits = res.data;
          this.state = 'display';
          //set oldStatus and getAssetName for each transaction
          for (let i = 0; i < this.manualDeposits.length; i += 1) {
            if (this.manualDeposits[i]) {
              this.manualDeposits[i].oldStatus = this.manualDeposits[i].status;
              this.manualDeposits[i].assetName = this.getAssetName(this.manualDeposits[i].assetTypeID);
            }
          }
          this.items = this.manualDeposits;
        } catch (e) {
          this.$refs.errorDialog.showError({
            errorMessage: "Failed to get Credit/Debit Transactions.",
            forDevelopers: e
          })
        }
      },

      getAssetName: function (assetType){
        switch (assetType){
          case 3373:
            return 'Gold Dollar';
          case 3374:
            return 'Silver Dollar';
          case 5062:
            return 'Paper Dollar';
          case 7000:
            return 'Goldback';
        }
      },
    },

    created: async function() {
      await this.getManualDeposits();
    },
    mounted() {
      localStorage.title = 'Credit/Debit/ACH';
    },
  }
</script>

<style scoped>
.row-pointer >>> tbody tr :hover {
  cursor: pointer;
}
</style>