<template>
    <v-dialog v-model="shown"
              max-width="450"
    >
        <ValidationObserver ref="validationOb" v-slot="{ invalid, validated, handleSubmit, valid }">
            <v-card>
                <v-card-title class="pb-0 mt-1 mb-n1">
                    <span>Set Rates</span>
                    <v-spacer/>
                    <v-btn class="mt-n1 mr-n1" @click="toggleShown" icon><v-icon>mdi-close</v-icon></v-btn>
                </v-card-title>

                <v-card-text>
                    <div v-if="state === 'display'">
                        <v-row :key="type.mini" v-for="type in rateTypes">
                            <v-col cols="4">
                                <v-subheader>{{ type.maxi }}</v-subheader>
                            </v-col>
                            <v-col cols="8">

  <!--ValidationProvider requires unique names, so for looping through rateTypes add ${type.mini} to correct behavior-->
                              <ValidationProvider rules="required:Fix|validFix"
                                                    :name="`${type.mini} Fix`"
                                                    v-slot="{ errors }">
                                    <v-text-field
                                            label="Fix"
                                            value="0.00"
                                            prefix="$"
                                            @focus="$event.target.select()"
                                            v-model="fixes[type.mini]"
                                            :error-messages="errors"
                                    ></v-text-field>
                                </ValidationProvider>

                              <ValidationProvider rules="required:BuyBack|validFix"
                                                  :name="`${type.mini} Buy Back`"
                                                  v-slot="{ errors }">
                                <v-text-field
                                    label="Buy Back"
                                    value="0.00"
                                    prefix="$"
                                    @focus="$event.target.select()"
                                    v-model="buyBacks[type.mini]"
                                    :error-messages="errors"
                                ></v-text-field>
                              </ValidationProvider>
                              <ValidationProvider rules="required:BusinessCost|validFix"
                                                  :name="`${type.mini} Business Cost`"
                                                  v-slot="{ errors }">
                                <v-text-field
                                    label="Business Cost"
                                    value="0.00"
                                    prefix="$"
                                    @focus="$event.target.select()"
                                    v-model="businessCosts[type.mini]"
                                    :error-messages="errors"
                                ></v-text-field>
                              </ValidationProvider>
                            </v-col>
                        </v-row>
                    </div>

                    <div v-else-if="state === 'loading'">
                        <div class="text-center pa-10">
                            <v-progress-circular color="primary" indeterminate size="100"/>
                        </div>
                    </div>
                    <h1 v-else-if="state === 'ratesSet'" class="mx-4 my-12 headline">The rates have already been set for today.</h1>
                    <h1 v-else class="mx-4 my-8 headline">Today is not configured to have a rate.<br><br> Rates must be
                      configured a month in advance by clicking "CONFIGURE" in the lower left corner of this calendar page.</h1>

                </v-card-text>

                <v-card-actions>
                    <v-btn @click="toggleShown()"
                           color="error"
                           v-if="state === 'display'">
                        Cancel
                    </v-btn>
                    <v-spacer/>
                    <v-btn @click="showConfirmDialog()"
                           color="primary"
                           :disabled="state === 'ratesSet' || state === 'loading' || invalid || !valid"
                           v-if="state === 'display'">
                        Next
                    </v-btn>
                </v-card-actions>
            </v-card>

            <v-dialog v-model="confirmDialog" width="450">
                <v-card>
                    <v-card-title class="px-4 pb-0 mt-1 mb-n1">
                        <span v-if="state === 'ratesSet'">Today's Rates Now Set:</span>
                        <span v-else>Confirm Rates for Today</span>
                        <v-spacer/>
                        <v-btn class="mt-n1 mr-n1" @click="state === 'ratesSet' ? toggleShown() : confirmDialog = false"
                               icon><v-icon>mdi-close</v-icon></v-btn>
                    </v-card-title>
                    <v-row class="px-4" :key="type.mini" v-for="type in rateTypes">
                        <v-col cols="4">
                            <v-subheader>{{ type.maxi }}</v-subheader>
                        </v-col>
                        <v-col cols="8">
                            <v-text-field
                                dense
                                label="Fix"
                                value="0.00"
                                prefix="$"
                                readonly
                                @focus="$event.target.select()"
                                v-model="fixes[type.mini]"
                            />
                            <v-text-field
                                dense
                                label="Buy Back"
                                value="0.00"
                                prefix="$"
                                readonly
                                @focus="$event.target.select()"
                                v-model="buyBacks[type.mini]"
                            />
                            <v-text-field
                                dense
                                label="Business Cost"
                                value="0.00"
                                prefix="$"
                                readonly
                                @focus="$event.target.select()"
                                v-model="businessCosts[type.mini]"
                            />
                        </v-col>
                    </v-row>
                    <v-card-actions class="mt-n2">
                        <v-btn @click="toggleShown()" color="error">
                            <span v-if="state === 'ratesSet'">Close</span>
                            <span v-else>Cancel</span>
                        </v-btn>
                        <v-spacer/>
                        <v-btn @click="handleSubmit(setRatesBtnClicked)"
                               color="primary"
                               :disabled="state === 'loading' || invalid || !valid"
                               v-if="state !== 'ratesSet'">
                          Set Rates - This is Final
                        </v-btn>
                    </v-card-actions>
                </v-card>
            </v-dialog>
        </ValidationObserver>
        <v-progress-linear v-if="state === 'loading'"
                           indeterminate
                           color="primary"
        />
    </v-dialog>
</template>

<script>
    import { ValidationObserver, ValidationProvider } from 'vee-validate'
    import { extend } from 'vee-validate'
    import { required } from 'vee-validate/dist/rules'

    extend("required", {
        ...required,
        message: "{_field_} is required"
    });
    extend("validFix", {
        validate: value => {
            // Is not a valid float.
            if (isNaN(value)) {
                return false;
            }

            // Is greater than a penny?
            return value >= 0.01
        },
        message: 'The fix must be a valid decimal number and greater than $0.01'
    });

    export default {
        name: "SetRatesDialog",
        components: { ValidationObserver, ValidationProvider },
        data: () => ({
            confirmDialog: false,
            shown: false, // This has to be here because vuetify makes it difficult to v-model to a state of type string for the dialog.
            state: 'loading',
            rateTypes: [],
            fixes: {},
            buyBacks: {},
            businessCosts: {},
        }),
        methods: {
            showConfirmDialog: function () {
              this.confirmDialog = true;
            },
            toggleShown: function () {
                this.shown = !this.shown
            },
            getRateTypes: async function() {
                try {
                    let res = await this.$axios.get('/admin/rates/types');
                    this.rateTypes = res.data.rateTypes;
                    this.rateTypes.forEach(type => {
                        this.fixes[type.mini] = 0.00;
                        this.buyBacks[type.mini] = 0.00;
                        this.businessCosts[type.mini] = 0.00;
                    })
                } catch (e) {
                    this.$emit('showErrorDialog', {
                        errorMessage: 'There was an error retrieving the rate types.',
                        forDevelopers: e
                    })
                }
            },
            setRatesBtnClicked: async function() {
                this.state = 'loading';
                try {
                    let fixes = this.fixes;
                    let buyBacks = this.buyBacks;
                    let businessCosts = this.businessCosts;
                    await this.$axios.post('/admin/rates', { fixes, buyBacks, businessCosts });
                    this.$emit('changesSaved');// tells RatesCalendar.vue to getRates and refresh so new rates show
                    this.state = 'ratesSet'
                } catch (e) {
                    let errorMessage = 'There was an error setting the rates';
                    if(e.response.status === 400) {
                        errorMessage = e.response.data.message
                    }
                    this.$emit('showErrorDialog', {
                        errorMessage: errorMessage,
                        forDevelopers: e
                    });
                    this.state = 'display'
                }
            },
            setInitialState: async function () {
                this.state = 'loading';
                // If the rates have already been set today, then set the state to reflect that.
                let res = await this.$axios.get('/admin/rates/current');

                if (res.data.length < 1) {
                    this.state = 'dayNotConfigured';
                    return;
                }

                let ratesSet = true;
                Object.keys(res.data).forEach(key => {
                    if ([0, '0.00', null].includes(res.data[key].fix)){
                        ratesSet = false // One of the rates is 0, '0.00' or null so it isn't set.
                    }
                });

                // .5 delay to smooth user experience
                setTimeout(() => { this.state = ratesSet === true ? 'ratesSet' : 'display' }, 500);
            }
        },
        watch: {
            shown(newShown){
                this.setInitialState();

                // Lazy load rate types
                if(this.state === 'ratesSet'|| this.state === 'dayNotConfigured' || this.rateTypes.length > 0 || !newShown){ return }

                this.getRateTypes()
            }
        },
        created() {
            this.setInitialState()
        }
    }
</script>

<style scoped>

</style>