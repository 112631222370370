<template>
  <div>
    <v-card flat>
      <v-card-title>
        <v-row>
          <v-flex class="ml-6 mr-6" s3>
            <v-row>
              <v-switch label="Current" v-model="active"/>
              <v-switch class="ml-8" label="Future" v-model="future"/>
              <v-switch class="ml-8" label="Cancelled" v-model="cancelled"/>
              <v-switch  class="ml-8" label="Archived" v-model="archived"/>
              <v-switch  class="ml-8" label="Out of Scope" v-model="outOfScope"/>
            </v-row>
          </v-flex>
          <v-flex class="ml-6 mr-6" s3>
            <v-row>
              <v-menu
                v-model="menu"
                :close-on-content-click="true"
                :nudge-right="20"
                transition="scale-transition"
                offset-y
                min-width="0px"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field
                      class="ml-8"
                      clearable
                      label="Created Start Date"
                      readonly
                      v-bind="attrs"
                      v-model="startDate"
                      v-on="on"
                      style="max-width: 200px"
                  ></v-text-field>
                </template>
                <v-date-picker v-model="startDate" no-title @input="menu = false"></v-date-picker>
              </v-menu>
              <v-menu
                  v-model="menu2"
                  :close-on-content-click="true"
                  :nudge-right="20"
                  transition="scale-transition"
                  offset-y
                  min-width="0px"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field
                      class="ml-8"
                      clearable
                      label="Created End Date"
                      readonly
                      v-bind="attrs"
                           v-model="endDate"
                      v-on="on"
                      style="max-width: 200px"
                  ></v-text-field>
                </template>
                <v-date-picker v-model="endDate" no-title @input="menu2 = false"></v-date-picker>
              </v-menu>
              <v-text-field
                  class="ml-8"
                  append-icon="mdi-magnify"
                  clearable
                  v-model="search"
                  label="Search Requests"
              />
            </v-row>
          </v-flex>
        </v-row>
      </v-card-title>
      <v-card-text>
        <v-data-table
          :loading="tableLoading"
          :server-items-length="serverItemsLength"
          :options.sync="options"
          :footer-props="{'items-per-page-options': [10, 25, 50]}"
          scrollable
          disable-sort
          class="elevation-2 mt-n3 mb-2 pb-0"
          loading-text="Loading... Please wait"
          :headers="requestTableHeaders"
          @click:row="rowClick"
          :items="data"
          no-data-text="No Service Requests to Display">

          <template v-slot:item.created="{ item }">
            {{mixin_UTCToPretty(item.created)}}
          </template>

          <template v-slot:item.last_updated="{ item }">
            {{mixin_UTCToPretty(item.last_updated)}}
          </template>

          <template v-slot:item.status="{ item }">
            <span :class="item.priority ? 'red--text' : item.simple_status === 'Active' ? '' : 'grey--text'">{{ item.status }}</span>
          </template>

          <template v-slot:item.to_amount ="{ item }">
            <span v-if="item.from_asset" :style="{color: assetColor(item.from_asset)}">
              -{{ item.from_amount }}
            </span>
            <br v-if="item.from_asset && item.to_asset"/>
            <span v-if="item.to_amount" :style="{color: assetColor(item.to_asset)}">
              {{ item.to_amount }}
            </span>
          </template>>

          <template v-slot:item.to_asset ="{ item }">
            <span :style="{color: assetColor(item.from_asset)}">
              {{ assetIDToName(Number(item.from_asset)) }}
            </span>
            <br v-if="item.from_asset && item.to_asset"/>
            <span :style="{color: assetColor(item.to_asset)}">
              {{ assetIDToName(Number(item.to_asset)) }}
            </span>
          </template>

          <template v-slot:item.directed_to ="{ item }">
            {{ item.simple_status === 'Active' ? item.directed_to : '' }}
          </template>

          <template v-slot:item.follow_up ="{ item }">
            <v-tooltip bottom v-if="item.follow_up">
              <template #activator="{ on }">
                <span v-on="on"> {{item.follow_up ? 'Y' : 'N'}} </span>
              </template>
              <span >{{ item.follow_up_note }} <br/> {{ mixin_UTCToPretty(item.timestamp)}}</span>
            </v-tooltip>
            <span v-else>{{item.follow_up ? 'Y' : 'N'}}</span>
          </template>

          <template v-slot:item.new_tab ="{ }">
            <v-tooltip bottom>
              <template #activator="{ on }">
                <v-btn v-on="on" icon>
                  <v-icon @click="newTab = true">mdi-tab-plus</v-icon>
                </v-btn>
              </template>
              <span>Open Task in a New Tab</span>
            </v-tooltip>
          </template>


        </v-data-table>

      </v-card-text>
    </v-card>
    <error-dialog ref="errorDialog" />
  </div>
</template>

<script>
// import axios from "axios"

import ErrorDialog from "../components/ErrorDialog";
export default {
  name: 'Tasks',
  components: {ErrorDialog},
  data() {
    return {
      search: '',
      menu: false,
      menu2: false,
      data: [],
      options: {},
      serverItemsLength: null,
      tableLoading: false,
      awaitingSearch: false,
      active: true,
      archived: false,
      cancelled: false,
      outOfScope: false,
      startDate: null,
      endDate: null,
      future: false,
      newTab: false,
      timeout: 0,
      selectedServiceRequest: {},
      requestTableHeaders: [
        {value: 'created', text: 'Created'},
        {value: 'last_updated',  text: 'Last Updated'},
        {value: 'day_due', text: 'Actionable On'},
        {value: 'type',  text: 'Type'},
        {value: 'status',  text: 'Status'},
        {value: 'name',text: 'Name'},
        {value: 'username',   text: 'Username'},
        {value: 'to_amount', text: 'Amounts', align: 'end'},
        {value: 'to_asset', text: 'Assets', align: 'start'},
        {value: 'order_id', text: 'Order #'},
        {value: 'directed_to', text: 'Directed Towards'},
        {value: 'follow_up',   text: 'Follow Up?'},
        {value: 'new_tab',   text: '', align: 'end'}
      ],
    }
  },
  watch: {
    'active'() {
      this.getServiceRequests()
    },
    'archived'() {
      this.getServiceRequests()
    },
    'cancelled'() {
      this.getServiceRequests()
    },
    'future'() {
      this.getServiceRequests()
    },
    'outOfScope'() {
      this.getServiceRequests()
    },
    'startDate'() {
      this.getServiceRequests()
    },
    'endDate'() {
      this.getServiceRequests()
    },
    'search'() {
      if (!this.awaitingSearch) {
        setTimeout(() => {
          if (this.options.page !== 1) this.options.page = 1;
          this.getServiceRequests();
          this.awaitingSearch = false;
        }, 750); // .75 sec delay
      }
      this.awaitingSearch = true;
    },
    options: {
      handler () {
        this.getServiceRequests()
      },
      deep: true,
    },
  },
  computed: {
  },
  mounted() {
    localStorage.title = 'Tasks';
  },

  methods: {
    rowClick: function (item) {
      if (this.newTab) window.open('/tasks/' + item.uuid, '_blank');
      else this.$router.push({path: '/tasks/' + item.uuid});
      this.newTab = false;
    },

    async getServiceRequests() {
      const url = '/admin/service/requests'
      this.tableLoading = true
      const body = {
        offset: (this.options.itemsPerPage * this.options.page) - this.options.itemsPerPage,
        length: this.options.itemsPerPage,
        active: this.active,
        archived: this.archived,
        search: this.search,
        cancelled: this.cancelled,
        future: this.future,
        outOfScope: this.outOfScope,
        startDate: this.startDate,
        endDate: this.endDate

      }
      this.$axios.post(url, body).then(({data}) => {
        this.serverItemsLength = data.record_count
        this.data = data.data
        this.tableLoading = false
      })
      .catch(({response}) => {
        this.tableLoading = false
        this.$refs.errorDialog.showError({

          errorMessage: response.data.message,
          forDevelopers: response.data.validationErrors
        });
      })
    },

    async getServiceRequest(uuid) {
      const url = '/admin/service/request/' + uuid
      this.requestLoading = true
      await this.$axios.get(url).then(({data}) => {
        this.selectedServiceRequest = data
      })
          .catch(({response}) => {
            this.$refs.errorDialog.showError({

              errorMessage: response.data.message,
              forDevelopers: response.data.validationErrors
            });
          })
      this.requestLoading = false
    }


  },
}
</script>
<style scoped>

</style>