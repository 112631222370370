<template>
    <div class="pa-5 mb-12">
      <v-btn color="primary"
             v-if="allowedComponents.includes('Holdings')"
             @click="getAccountingAccounts()">Get Accounting Accounts</v-btn>
<!--        <h1 class="display-3 pb-5">Roster</h1>-->
        <v-data-table
            :headers="tableHeaders"
            :items="members"
            :disable-filtering="true"
            :disable-sort="disableSort"
            hide-default-footer
            :page.sync="page"
            @page-count="pageCount = $event"
            :items-per-page="membersPerPage"
            class="elevation-4"
            :loading="state === 'loading'"
        >
            <template v-slot:top>
                <v-text-field
                        class="mx-6 pt-4 mb-n4"
                        v-model="searchText"
                        label="Search Members"/>
            </template>
            <template v-slot:item.actions="{ item }">
                <v-icon
                        @click="loginAs(item)"
                >
                    mdi-account-arrow-right
                </v-icon>
            </template>
        </v-data-table>
        <v-row v-if="pageCount > 1">
            <v-spacer/>
            <div>
                <v-pagination
                        v-model="page"
                        :length="pageCount"
                        :total-visible="7"
                        @input="onPagination"
                />
            </div>
        </v-row>

        <ErrorDialog ref="errorDialog"/>
    </div>
</template>

<script>
    // @ is an alias to /src
    import ErrorDialog from "@/components/ErrorDialog";

    export default {
    name: "Roster",
    components: {
        ErrorDialog
    },
    data: () => ({
        state: 'loading',
        allowedComponents: JSON.parse(localStorage.getItem('allowedComponents') || ''),
        members: [],
        page: 1,
        pageCount: 1,
        membersPerPage: 11,
        searchText: "",
        awaitingSearch: false,
        disableSort: true,
        tableHeaders: [
            {
                text: 'Name',
                value: 'name',
                align: 'start'
            },
            {
                text: 'Email',
                value: 'email',
                align: 'start'
            },
            {
                text: 'Phone',
                value: 'phone',
                align: 'right'
            },
            {
                text: 'View Account',
                value: 'actions',
                sortable: false,
                align: 'center'
            }
        ]
    }),
    methods: {
        resetState: async function() {
            await this.getMembers();
            await this.getPageCount();
            this.state = 'display';
            this.disableSort = true;
            this.membersPerPage = 11
        },
        getMembers: async function (offset) {
            if(!offset){
                offset = 0
            }

            try {
                let res = await this.$axios.get(`/admin/users?offset=${offset * 11}`);
                this.members = res.data;
            } catch (e) {
                this.$refs.errorDialog.showError({
                    errorMessage: "Failed to get members.",
                    forDevelopers: e
                })
            }
        },
        searchMembers: async function (searchText) {
            try {
                this.state = 'loading';
                let res = await this.$axios.get(`/admin/users/search?searchText=${searchText}`);
                this.members = res.data;
                this.membersPerPage = this.members.length;
                this.pageCount =   1;
                this.disableSort = false;
                this.state = 'display'
            } catch (e) {
                this.$refs.errorDialog.showError({
                    errorMessage: `Failed to search for ${searchText}. Please try again with different text.`,
                    forDevelopers: e
                })
            }
        },
        getAccountingAccounts: async function () {
          try {
            this.state = 'loading';
            let res = await this.$axios.get(`/admin/users/search?accounting=1`);
            this.members = res.data;
            this.membersPerPage = this.members.length;
            this.pageCount =   1;
            this.disableSort = false;
            this.state = 'display'
          } catch (e) {
            this.$refs.errorDialog.showError({
              errorMessage: `Failed to get admin accounts.`,
              forDevelopers: e
            })
          }
        },
        getPageCount: async function () {
            try {
                let res = await this.$axios.get(`/admin/users/numberOfPages?offset=${this.offset ? this.offset * 11: 11}`);
                this.pageCount = res.data.numPages
            } catch (e) {
                this.$refs.errorDialog.showError({
                    errorMessage: "Error determining how many pages of members there are.",
                    forDevelopers: e
                })
            }
        },
        onPagination: async function(page) {
            this.state = 'loading';
            await this.getMembers(page - 1);
            this.state = 'display'
        },
        loginAs: async function (member) {

          try {
            let res = await this.$axios.post('/admin/token', {
                    grant_type: 'delegation',
                    client_id: 'NA',
                    target_username: member.email
                });

            let token = res.data;

            let memberURL = '';

            // If the local environment is set, return it.
            const localEnv = process.env.VUE_APP_DEV_MEMBER_URL;

            if (localEnv) {
                memberURL = localEnv;

            } else {

                // If the sub-domain is a valid environment include it in the url for the api.
                let environments = ['qa', 'new', 'dev'];
                let environment = window.location.host.slice(0, window.location.host.indexOf('.'));
                // console.log(environment);


                if (environments.includes(environment)) {
                    memberURL = `https://${environment}.member.upma.org/`;

                } else {

                    // Else have it connect with the production api.
                    memberURL = process.env.VUE_APP_MEMBER_URL;
                }
            }
            memberURL = memberURL + '';
            window.open(`${memberURL}?token=${token.access_token}`);
        } catch (e) {
            console.log(e);
            this.$refs.errorDialog.showError({
              errorMessage: "Error proxying into the account",
              forDevelopers: e.response.data.message
            })
          }
        }
    },
    mounted() {
      localStorage.title = 'Roster';
      this.title = localStorage.title;
    },
    created: async function() {
        this.resetState()
    },
    watch: {
        searchText: function (newSearchText) {
            // We delay searching by a half second to give the user time to finish typing. The awaitSearch data member
            // with the setTimeout method are used to accomplish this.
            if (!this.awaitingSearch) {
                setTimeout(() => {
                    if (newSearchText === ""){
                        this.resetState();

                    } else {
                        this.searchMembers(newSearchText);

                    }

                    this.awaitingSearch = false;
                }, 500); // .5 second delay
            }

            this.awaitingSearch = true;
        }
    }
}
</script>

<style scoped>


</style>
