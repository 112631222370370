<template>
  <div>
    <v-card flat v-if="allowedComponents.includes('Franchises')">
      <v-card-title>
        <v-spacer/>
        <v-btn fab x-small color="primary" @click="console.log('to be added')" disabled>
          <v-icon>
            mdi-plus
          </v-icon>
        </v-btn>
      </v-card-title>
      <v-card-text>
        <v-data-table
            :loading="loading"
            class="elevation-2 mt-n3 mb-2 pb-0"
            scrollable
            loading-text="Loading... Please wait"
            :headers="franchiseTableHeaders"
            fixed-header
            :sort-by="['entname']"
            multi-sort
            :items="franchiseItems"
            :items-per-page="10">
          <template v-slot:item.active="{ item }">
            <v-lazy>
              <div v-if="item.active" class="green--text">
                Active
              </div>
              <div v-if="!item.active" class="red--text">
                Inactive
              </div>
            </v-lazy>
          </template>
          <template v-slot:item.proxy="{ item }">
            <v-btn small icon @click.stop.prevent="loginAs(item)" ><v-icon >
              mdi-account-arrow-right</v-icon></v-btn>
          </template>
          <template v-slot:item.actions="{ /*item*/ }">
            <v-lazy>
              <div>
                <v-tooltip bottom style="z-index: 99" class="ml-n2 mr-n4 py-0 pl-0 pr-2">
                  <template v-slot:activator="{ on }">
                    <v-btn small icon disabled><v-icon v-on="on" @click="console.log('to be added')">
                      mdi-pencil</v-icon></v-btn>
                  </template>
                  <span>Edit Franchise Terms</span>
                </v-tooltip>
              </div>
            </v-lazy>
          </template>
        </v-data-table>
      </v-card-text>
    </v-card>
  </div>
</template>

<script>
    export default {
      name: "Franchises",
      props: {
      },
      data: () => ({
        loading: false,
        franchiseItems: [],
        allowedComponents: JSON.parse(localStorage.getItem('allowedComponents') || ''),
        franchiseTableHeaders: [
          {value: 'entname', text: 'Entity Name'},
          {value: 'username', text: 'Associated Username'},
          {value: 'active', text: 'Active Agreement'},
          {value: 'margin_percentage_retained', text: 'Margin Percent'},
          {value: 'lease_percentage_retained', text: 'Lease Percent'},
          {value: 'fees_percentage_retained', text: 'Fees Percent'},
          {value: 'per_member_due_gold_eq_retained', text: 'Per Funded Account GD'},
          {value: 'actions', text: '', width: '75px'},
          {value: 'proxy', text: '', width: '75px'}
        ],
      }),
      methods: {
        async getFranchises() {
          this.loading = true;
          const url = '/admin/franchises';
          await this.$axios.get(url).then(({data}) => {
            this.franchiseItems = data;
          })
              .catch(({response}) => {
                alert(response);
              })
          this.loading = false;
        },

        loginAs: async function (member) {
          let res = await this.$axios.post('/admin/token', {
            grant_type: 'delegation',
            client_id: 'NA',
            target_username: member.username
          });
          let token = res.data;
          let memberURL = '';

          // If the local environment is set, return it.
          const localEnv = process.env.VUE_APP_DEV_MEMBER_URL;

          if (localEnv) {
            memberURL = localEnv;

          } else {

            // If the sub-domain is a valid environment include it in the url for the api.
            let environments = ['qa', 'new', 'dev'];
            let environment = window.location.host.slice(0, window.location.host.indexOf('.'));

            // console.log(environment);
            if (environments.includes(environment)) {
              memberURL = `https://${environment}.member.upma.org`;

            } else {

              // Else have it connect with the production api.
              memberURL = process.env.VUE_APP_MEMBER_URL;
            }
          }
          memberURL = memberURL + '';
          window.open(`${memberURL}?token=${token.access_token}`);
        }
      },
      mounted() {
        localStorage.title = 'Franchises';
        this.getFranchises();
      },
      computed: {
        },
        watch: {
        },
    }
</script>

<style scoped>

</style>