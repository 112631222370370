<template>
  <div>
    <WireCheck/>
    <ErrorDialog/>
  </div>
</template>

<script>
  import ErrorDialog from "../components/ErrorDialog";
  import WireCheck from "../components/reports/WireCheck";
  export default {
    name: 'ReportsWireCheck',
    components: {
      WireCheck,
      ErrorDialog
    },
  }
</script>