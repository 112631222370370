<template>
  <div class="px-5 py-2">
    <div v-if="allowedComponents.includes('Pending Transactions')">
      <v-row>
        <v-col md="12">
          <v-card class="elevation-4">
            <v-toolbar color="#e9edf2" elevation="2" dense>
              <v-toolbar-title>Pending Transactions</v-toolbar-title>

              <v-spacer></v-spacer>

              <v-btn class="mx-1" small @click="$refs.editTransactionsDialog.buildDialog(selectedTransactions)">Edit</v-btn>
              <v-btn class="mx-1" small @click="$refs.pendingClearDialog.buildDialog(selectedTransactions)">Clear</v-btn>
            </v-toolbar>

            <v-text-field v-model="searchPending" append-icon="mdi-magnify" label="Search Transactions" class="mx-4 pt-2"></v-text-field>

            <v-data-table
                v-model="selectedTransactions"
                :headers="pendingTableHeaders"
                :items="pendingTransactions"
                :search="searchPending"
                :loading="pendingState === 'loading'"
                :single-select="false"
                @click:row="rowClick"
                @item-selected="selectTransaction"
                item-key="journal_num"
                show-select
                class="row-pointer"
                style="white-space: pre"
            >
              <template v-slot:item.proxy="{ item }">
                  <v-btn small icon @click.stop.prevent="loginAs(item.username)" ><v-icon >
                    mdi-account-arrow-right</v-icon></v-btn>
              </template>
            </v-data-table>
          </v-card>
        </v-col>
      </v-row>

      <!--Edit Transactions Dialog-->
      <EditTransactionsDialog ref="editTransactionsDialog"/>

      <v-dialog scrollable v-model="transactionInfoDialog" width="350">
        <v-card>
          <v-card-title>
            Pending Transaction
            <v-spacer/>
            <v-btn
                color="primary"
                @click="transactionInfoDialog = false"
                icon
                class="ml-4 mb-2">
              <v-icon>
                mdi-close
              </v-icon>
            </v-btn>
          </v-card-title>
          <v-card-text>
            <div>
            <b class="caption">Payee</b> <br>
            {{selectedTransaction.full_name}}
            </div>
            <div class="mt-1">
            <b class="caption mt-4">Email </b> <br>
            {{selectedTransaction.username}}
            </div>
            <div class="mt-1">
            <b class="caption mt-4">Type</b> <br>
            {{selectedTransaction.type}}
            </div>
            <div class="mt-1">
            <b class="caption mt-4">Posted</b> <br>
            {{date(selectedTransaction.posted)}}
            </div>
            <div class="mt-1">
            <b class="caption mt-4">Day Due</b> <br>
            {{date(selectedTransaction.day_due)}}
            </div>
            <div class="mt-1">
            <b class="caption mt-2">Journal Num</b> <br>
            {{selectedTransaction.journal_num}}
            </div>
            <div class="d-flex mt-1">
              <div>
                <b class="caption">Amounts</b> <br>
                <div v-html="addBreaks(selectedTransaction.amounts)">

                </div>
                <br>
              </div>
              <div class="ml-8">
                <b class="caption">Assets</b> <br>
                <div v-html="addBreaks(selectedTransaction.asset_types)">

                </div>
                <br>
              </div>
            </div>
            <div class="mt-1">
              <b class="caption mt-2">Description</b> <br>
              {{selectedTransaction.description ? selectedTransaction.description : "None"}}
            </div>
            <div class="mt-1">
              <b class="caption mt-2">Shipping Amount</b> <br>
              {{selectedTransaction.shipping_amount ? selectedTransaction.shipping_amount : "N/A"}}
            </div>
            <div class="mt-1">
              <b class="caption mt-2">Shipping Asset Type</b> <br>
              {{selectedTransaction.shipping_asset_type ? selectedTransaction.shipping_asset_type : "N/A"}}
            </div>
            <div class="mt-1">
              <b class="caption mt-2"> Shipping Description</b> <br>
              {{selectedTransaction.shipping_description ? selectedTransaction.shipping_description : "N/A"}}
            </div>
          </v-card-text>
        </v-card>
      </v-dialog>

      <!--Clear Pending Dialog-->
      <PendingClearDialog ref="pendingClearDialog"/>

      <ErrorDialog ref="errorDialog"/>
    </div>
    <div v-else class="pl-12 pr-12 pt-12 mt-12 headline text-center">Sorry, this page is not available.</div>
  </div>
</template>

<script>
  // @ is an alias to /src
  import ErrorDialog from "@/components/ErrorDialog";
  import PendingClearDialog from "@/components/reports/PendingClearDialog";
  import EditTransactionsDialog from "./EditTransactionsDialog";
  import moment from "moment";

  const dateFormat = 'YYYY-MM-DD';
  export default {
    name: "PendingTransactions",
    components: {
      PendingClearDialog,
      EditTransactionsDialog,
      ErrorDialog
    },
    data: () => ({

      //-------- Pending Variables --------//

      pendingTransactions: [],
      selectedTransactions: [],
      pendingState: 'loading',
      selectedTransaction: [],
      transactionInfoDialog: false,
      searchPending: "",
      allowedComponents: JSON.parse(localStorage.getItem('allowedComponents') || ''),
      pendingTableHeaders: [
        {
          text: 'Payee',
          value: 'full_name',
          align: 'start'
        },
        {
          text: 'Type',
          value: 'type',
          align: 'start'
        },
        {
          text: 'Posted',
          value: 'posted',
          align: 'start'
        },
        {
          text: 'Day Due',
          value: 'day_due',
          align: 'start'
        },
        {
          text: 'Amounts',
          value: 'amounts',
          align: 'end'
        },
        {
          text: 'Assets',
          value: 'asset_types',
          align: 'start',
        },
        {
          text: 'View Account',
          value: 'proxy',
          width: '75px',
          align: 'center'
        }
      ]
    }),

    methods: {
      date(date) {
        return moment(date).format(dateFormat);
      },
      addBreaks(s) {
        if(s) {return s.replace("\n", "<br>")}
        return s
      },

      rowClick: function (item) {
        this.transactionInfoDialog=true
        this.selectedTransaction={...item}
      },

      getTransactions: async function () {
        this.pendingState = 'loading';
        this.selectedTransactions = [];
        try {
          let res = await this.$axios.get(`/admin/reports/pending`);
          this.pendingTransactions = res.data;

        } catch (e) {
          this.$refs.errorDialog.showError({
            errorMessage: "Failed to get Pending Transactions.",
            forDevelopers: e
          });
        }
        this.pendingState = 'display';
      },

      selectTransaction: async function (item, row) {
        row.select(!row.isSelected);
      },

      loginAs: async function (username) {

        let res = await this.$axios.post('/admin/token', {
          grant_type: 'delegation',
          client_id: 'NA',
          target_username: username
        });

        let token = res.data;

        let memberURL = '';

        // If the local environment is set, return it.
        const localEnv = process.env.VUE_APP_DEV_MEMBER_URL;

        if (localEnv) {
          memberURL = localEnv;

        } else {

          // If the sub-domain is a valid environment include it in the url for the api.
          let environments = ['qa', 'new', 'dev'];
          let environment = window.location.host.slice(0, window.location.host.indexOf('.'));
          // console.log(environment);


          if (environments.includes(environment)) {
            memberURL = `https://${environment}.member.upma.org/`;

          } else {

            // Else have it connect with the production api.
            memberURL = process.env.VUE_APP_MEMBER_URL;
          }
        }
        memberURL = memberURL + '';
        window.open(`${memberURL}?token=${token.access_token}`);
      }
    },

    created: async function() {
      await this.getTransactions();
    },
    mounted() {
      localStorage.title = 'Pending';
    },
  }
</script>

<style scoped>
  .row-pointer >>> tbody tr :hover {
    cursor: pointer;
  }

  .v-data-table >>> td {
    font-size: .75rem !important;
    height: 40px;
  }


</style>