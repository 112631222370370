<template>
  <div>
    <v-container>
      <div v-if="policies.length > 0">
        <!-- Description-->
        <div class="description">
        <h3>Here you can find policies that have been added or updated, beginning with the most recent policies:</h3>
        </div>
        <!-- Paginated Policies -->
        <v-row v-for="(policy, index) in paginatedPolicies" :key="index">
          <v-col>
            <v-row>
              <h3>{{ policy.title }}</h3>
            </v-row>
            <v-row>
              <span v-html="policy.description"></span>
            </v-row>
            <v-row>
              <a v-if="policy.link" :href="policy.link" target="_blank" rel="noopener noreferrer">More info</a>
            </v-row>
          </v-col>
        </v-row>

        <div class="action-controls">
          <!-- Pagination Controls -->
          <div class="pagination-controls">
            <v-icon @click="prevPage" :disabled="currentPage <= 1" class="pagination-button">mdi-chevron-left</v-icon>
            <v-icon @click="nextPage" :disabled="currentPage >= totalPages" class="pagination-button">mdi-chevron-right</v-icon>
          </div>
        </div>
      </div>

      <div v-else-if="loading">
        <!-- Loading Display -->
        <loader line-fg-color="#295491" size="massive"></loader>
      </div>
      <div v-else-if="loading === false && policies.length === 0" class="description">
        <p>When a new or updated policy is released, it will be viewable here.
          No new or updated policies have been released since you became a staff member.</p>
      </div>
    </v-container>

    <ErrorDialog ref="errorDialog"/>
    <SnackBar ref="snackbar"/>

  </div>
</template>

<script>
import ErrorDialog from "@/components/ErrorDialog";
import SnackBar from "@/components/Snackbar";
import loader from "vue-simple-spinner";

export default {
  name: "Policy.vue",
  components: {SnackBar, ErrorDialog, loader},
  data() {
    return {
      loading: true,
      policies: [],
      currentPage: 1,
      itemsPerPage: 5
    }
  },

  mounted() {
    this.getPolicies();
  },
  computed: {
    totalPages() {
      return Math.ceil(this.policies.length / this.itemsPerPage);
    },
    paginatedPolicies() {
      const start = (this.currentPage - 1) * this.itemsPerPage;
      return this.policies.slice(start, start + this.itemsPerPage);
    },
  },
  methods: {
    getPolicies: async function () {
      try {
        let res = await this.$axios.get(`/admin/user/policy`);
        const fetchedPolicies = res.data;

        if (!this.policies.length) {
          this.policies = fetchedPolicies.map(policy => ({...policy}));
        }
      } catch (e) {
        this.$refs.errorDialog.showError({
          errorMessage: "Failed to get policies.",
          forDevelopers: e
        })
      }
      this.loading = false;
    },
    nextPage() {
      if (this.currentPage < this.totalPages) this.currentPage++;
    },
    prevPage() {
      if (this.currentPage > 1) this.currentPage--;
    }
  }
}
</script>

<style scoped>
.description {
  text-align: center;
  margin-bottom: 20px;
}
.action-controls {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  margin-top: 20px;
}
.pagination-controls {
  display: flex;
  margin-right: 10px; /* Spacing between right arrow and submit button */
}
.pagination-button {
  margin: 0 5px; /* Spacing between buttons */
  min-width: 40px;
  height: 40px;
  color: #295491;
}
</style>