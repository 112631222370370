<template>
  <v-row justify="center">
    <v-dialog
        v-model="shown"
        max-width="550"
        persistent
    >
      <ValidationObserver ref="validationOb" v-slot="{ invalid, valid }">
        <v-card>
          <v-row>
            <!-- Title -->
            <div id="title" class="pl-4">
              <v-card-title class="headline">Manage Transaction</v-card-title>
            </div>
            <v-spacer/>
            <v-btn
                color="primary"
                @click="shown = false"
                icon
                class="pa-4 ml-n3 mr-6"
                style="margin-top: 14px"
            >
              <v-icon>
                mdi-close
              </v-icon>
            </v-btn>
          </v-row>
        <v-card>

            <v-card-text>
              <v-container>
                <span>Transaction Number: {{ transaction.journalNum }}</span>
                <ValidationProvider rules="required:Type"
                                    name="Type"
                                    v-slot="{ errors }">
                  <v-select persistent-hint
                                  hint="Transaction Type"
                                  :items="['Wire', 'Check']"
                                  placeholder="Enter transaction type here"
                                  v-model="updatedTransaction.type"
                                  :error-messages="errors"
                  ></v-select>
                </ValidationProvider>
                <ValidationProvider rules="required:Paper Amount|validAmount"
                                    name="Paper Amount"
                                    v-slot="{ errors }">
                 <v-text-field persistent-hint
                               hint="Paper Amount"
                               prefix="$"
                               placeholder="Enter paper amount here"
                               :error-messages="errors"
                               v-model="updatedTransaction.paperAmount"
                               @change="updatedTransaction.paperAmount
                                = parseFloat(updatedTransaction.paperAmount).toFixed(2)"
                 ></v-text-field>
                </ValidationProvider>
                <ValidationProvider rules="required:Requested Asset Account"
                                    name="Requested Asset Account"
                                    v-slot="{ errors }">
                  <v-autocomplete persistent-hint
                                  hint="Requested Asset Account"
                                  :items="updatedTransaction.userAccounts"
                                  item-text='name'
                                  item-value='num'
                                  placeholder="Enter requested asset account here"
                                  :error-messages="errors"
                                  v-model="updatedTransaction.assetAccountNum"
                  ></v-autocomplete>
                </ValidationProvider>
                <ValidationProvider rules="required:DCA Period"
                                    name="DCA Period"
                                    v-slot="{ errors }">
                  <v-autocomplete persistent-hint
                            hint="DCA Period"
                            :items="['20', '10', '5', '1']"
                            placeholder="Enter DCA period here"
                            v-model="updatedTransaction.numBusinessDays"
                            :error-messages="errors"
                 ></v-autocomplete>
                </ValidationProvider>
              </v-container>
            </v-card-text>
            <v-row justify="center">
              <v-btn dark class="pa-3 mt-n1 mb-3" color="red" x-large @click="cancelTransaction(updatedTransaction)">Cancel Transaction</v-btn>
            </v-row>
            <v-card-actions>
              <v-btn color="primary" x-large text @click="shown = false">Close</v-btn>
              <v-spacer></v-spacer>
              <v-btn color="primary" class="pa-4" x-large @click="settle(updatedTransaction)"
                     :disabled="invalid || !valid">Save & Settle</v-btn>
            </v-card-actions>
          </v-card>

        </v-card>
      </ValidationObserver>
    </v-dialog>
  <ErrorDialog ref="errorDialog"/>
  <SnackBar ref="snackbar"/>
  </v-row>
</template>

<script>
  import { ValidationObserver, ValidationProvider } from 'vee-validate';
  import { extend } from 'vee-validate';
  import { required } from 'vee-validate/dist/rules';
  import ErrorDialog from "../../components/ErrorDialog";
  import SnackBar from "../../components/Snackbar";
  import _ from 'lodash';

  extend("required", {
    ...required,
    message: "{_field_} is required"
  });
  extend("validAmount", {
    validate: value => {
      // Is not a valid float.
      if (isNaN(value)) {
        return false;
      }
      // Is greater than or equal to a penny?
      return value >= 0.01
    },
    message: 'The paper amount must be a valid decimal number and greater or equal to $0.01'
  });

  export default {
    name: "WireCheckManageDialog",
    components: { SnackBar, ErrorDialog, ValidationObserver, ValidationProvider },
    props: {
      transaction: Object,
      default() {
        return {};
      }
    },
    data: () => ({
      shown: false,
      updatedTransaction: {},
    }),

    beforeUpdate () {
      this.updatedTransaction = _.cloneDeep(this.transaction);
    },

    methods: {

      toggleShown: function () {
        this.shown = !this.shown;
      },

      settle: async function (updatedTransaction) {
        this.toggleShown();
        let params = {
          journalNum: updatedTransaction.journalNum,
          paperAmount: updatedTransaction.paperAmount,
          transactionType: updatedTransaction.type,
          numBusinessDays: updatedTransaction.numBusinessDays,
          assetAccountNum: updatedTransaction.assetAccountNum
        };

        if (confirm('Are you sure you want to SETTLE this transaction?')) {
          try {
            this.$parent.state = 'loading';
            await this.$axios.patch('/admin/transactions/manual', {params} );
            this.$refs.snackbar.showSnackbar({
              text: `Successfully settled the transaction`,
              color: 'success',
              style: "margin-top: calc(-8px + .25vw)"
            });
            //update parent that transaction needs to be deleted from table
            //because it is now settled, it shouldn't show in pending table
            this.$emit('update', this.transaction );
          } catch (e) {
            this.$refs.errorDialog.showError({
              errorMessage: 'An error occurred while trying to settle the transaction. Please ensure the form is filled out completely and correctly. If the problem persists please notify the development team with a detailed description of the error.',
              forDevelopers: e
            })
          }
          this.$parent.state = 'display';
        }
      },

      cancelTransaction: async function (updatedTransaction) {
        this.toggleShown();
        let params = {journalNums: [updatedTransaction.journalNum]};

        if (confirm('Are you sure you want to CANCEL this transaction?')) {
          try {
            this.$parent.state = 'loading';
            await this.$axios.patch('/admin/transactions/cancel', params );
            this.$refs.snackbar.showSnackbar({
              text: `Successfully cancelled the transaction`,
              color: 'success',
              style: "margin-top: calc(-8px + .25vw)"
            });
            //update parent that transaction needs to be deleted from table
            //because it is now cancelled, it shouldn't show in pending table
            this.$emit('update', this.transaction );
          } catch (e) {
            this.$refs.errorDialog.showError({
              errorMessage: 'An error occurred while trying to cancel the transaction. If the problem persists please notify the development team with a detailed description of the error.',
              forDevelopers: e
            })
          }
          this.$parent.state = 'display';
        }
      },
    }
  }
</script>

<style scoped>

</style>
