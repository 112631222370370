<template>
  <div>
    <div v-if="allowedComponents.includes('Member Holdings')" class="px-8">
      <v-row class="px-4 pt-2 pb-0">
        <v-spacer/>
        <v-tooltip bottom class="d-print-none">
          <template v-slot:activator="{ on }">
            <v-btn x-large tile text color='darkGold' v-on="on"
                   class="mt-n2 mb-n12 pb-0" @click="printDiv()"
            >
              Print
            </v-btn>
          </template>
          <span>Open Print Dialog</span>
        </v-tooltip>
      </v-row>

<!--      this span below removes extra space created by PRINT button so first page will match subsequent pages in printout-->
      <span class="d-print-block" style="margin-top: -55px; margin-bottom: -55px"></span>

      <span v-for="(transaction, index) in transactions" :key="transaction">
        <div class="d-print-block div2 float-none px-9 mx-9" style="width: 100%">
          <div class="headline py-1 mt-12 mb-n6 d-print-block text-start">Journal Number: {{ transactions[0].journal_num }}</div>
            <v-row>
              <v-col cols="4"></v-col>
              <v-col cols="8"><h2 class="mt-5 d-print-block">Transaction {{ index + 1 }}</h2></v-col>
            </v-row>
            <v-row v-for="(value, name) in transaction" :key="name">
              <v-col cols="4">{{ titleCase(name) }}:</v-col>
              <v-col cols="8">{{ value }}</v-col>
            </v-row>
        </div>
        <div v-if="transactions.length > index + 1" class="break-after"></div>
      </span>
      <ErrorDialog ref="errorDialog"/>
    </div>
    <div v-else class="pl-12 pr-12 pt-12 mt-12 headline text-center">Sorry, this page is not available.</div>
  </div>
</template>

<script>
import ErrorDialog from "@/components/ErrorDialog";

export default {
  name: 'MemberTransactionsPrintDetail',
  components: { ErrorDialog },
  props: {
    journalNum: [String, Number],
  },
  data: () => ({

    //--------- Data Variables ---------//

    allowedComponents: JSON.parse(localStorage.getItem('allowedComponents') || ''),
    transactions: '',
  }),

  created: async function() {
    document.title = 'Print Transaction Details';
    await this.getDetail();
    document.getElementById("footer").style.pageBreakAfter = "always";
  },

  mounted() {
    localStorage.title = 'hideAppBar';
  },

  methods: {

    getDetail: async function() {

      try {
        let res = await this.$axios.get(`/admin/reports/vaulted/transactions?journalNum=${this.journalNum}`);
        this.transactions = res.data.transaction;
      } catch (e) {
        this.$refs.errorDialog.showError({
          errorMessage: "Failed to get detail.",
          forDevelopers: e
        })
      }
    },

    printDiv() {
      window.print();//this works in popup window, but has problems in dialog
    },

    titleCase(string) {
      let title = string.toLowerCase().split('_');
      for (let i = 0; i < title.length; i++) {
        title[i] = title[i][0].toUpperCase() + title[i].slice(1);
      }
      return title.join(" ");
    },

  },
};
</script>

<style>

  html, body, div { float: none; }

  .break-after {
    display: block;
    page-break-after: always;
    position: relative;
  }

  @media print {
    body * { visibility: hidden; }
    .div2 * { visibility: visible;  margin: 0 !important; width: 100% !important;}
    .div2 { position: absolute; left: 0px; margin: 0 !important;}
  }

</style>