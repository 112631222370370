<template>
    <v-snackbar
            v-model="shown"
            :bottom="y === 'bottom'"
            :color="color"
            :left="x === 'left'"
            :multi-line="mode === 'multi-line'"
            :right="x === 'right'"
            :timeout="timeout"
            :top="y === 'top'"
            :vertical="mode === 'vertical'"
            :style="style"
    >
        {{ text }}
        <v-btn
                dark
                text
                @click="shown = false"
        >
            Close
        </v-btn>
    </v-snackbar>
</template>

<script>
    export default {
        name: "SnackBar",
        data: () =>  ({
            color: '',
            mode: '',
            shown: false,
            text: '',
            timeout: 6000,
            x: null,
            y: 'top',
            style: '',
        }),
        methods: {
            showSnackbar: function (options) {
                let keys = Object.keys(options);

                for (const key of keys) {
                    // Set's the value of the key in the data object equal to the value of the corresponding key in the
                    // options object.
                     this._data[key] = options[key]
                }

                if (this.timeout === 0) {
                    this.timeout = 6000
                }

                this.toggleShown()
            },
            toggleShown: function () {
                this.shown = !this.shown
            },
            resetValues: function() {
                this.color = '';
                this.mode = '';
                this.shown = '';
                this.text = '';
                this.timeout = 0;
                this.x = null;
                this.y = 'top';
                this.style = '';
            }
        },
        watch: {
            shown(newShown){
                // If we are hiding the snackbar, reset the values
                if(!newShown){
                    this.resetValues()
                }
            }
        }
    }
</script>

<style scoped>

</style>