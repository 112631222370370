<template>
  <div class="px-2 pt-2 mb-12">
    <div v-if="allowedComponents.includes('Withdrawal')">
      <ValidationObserver  ref="validationOb" v-slot="{ invalid, validated, handleSubmit, valid }">
      <v-sheet class="elevation-3" style="margin-bottom: 2px">

        <!-- Select Member -->
        <v-row v-if="showSearchMembers" justify="center">
          <SearchMembers class="mt-2" @memberSelected="memberSelected" />
        </v-row>

        <v-row class="justify-center mt-n1">
          <!-- Amount Withdraw & Asset Type -->
          <v-row justify="center" class="pt-2 pb-8">
            <v-sheet elevation="1"
                     class="mx-0 px-0 mb-0 pb-0"
                     max-width="500"
                     color="grey lighten-4">
              <v-row justify="center">
                <v-col class="px-8 pb-0">
                  <v-sheet width="300" class="pt-0"
                           color="grey lighten-4">
                    <DesiredAssetPicker :clear="clear" ref="desiredAssetPicker" rules="required"
                                        @desiredAccountSelected="desiredAccountSelected"/>
                  </v-sheet>
                  <div :key="amount + balanceWithdraw"
                       style="color: red;text-align: end; margin-top: -15px; margin-bottom: 10px"
                       v-show="amount > parseFloat(balanceWithdraw.replace(/\$|,/g, ''))">
                    Insufficient Funds
                  </div>
                  <ValidationProvider ref="amountValidator"
                                      rules="required|greaterThanZero"
                                      name="Amount"
                                      v-slot="{ errors }">
                    <v-sheet width="300" style="margin-top: -2px; margin-bottom: -2px"
                             color="grey lighten-4">
                      <v-text-field
                          label="Withdrawal Amount (In Asset)"
                          reverse
                          dense
                          hint="The amount to withdraw"
                          value="0.00"
                          :error-messages="errors"
                          v-model="amount"/>
                    </v-sheet>
                  </ValidationProvider>

                  <!-- Description -->
                  <ValidationProvider ref="descriptionValidator"
                                      rules="required"
                                      name="Description"
                                      v-slot="{ errors }">
                    <v-col class="px-0 pt-1 pb-0">
                      <v-sheet max-width="300"
                               color="grey lighten-4">
                        <v-textarea
                            rows="2"
                            dense
                            outlined
                            v-model="description"
                            :error-messages="errors"
                            hint="Further information about the withdrawal."
                            label="Withdrawal Description"/>
                      </v-sheet>
                    </v-col>
                  </ValidationProvider>

                  <v-checkbox label="Add Shipping Charge"
                              color="accent"
                              class="ml-12 mt-n3 py-0"
                              style="margin-bottom: -18px"
                              v-model="addShipping"
                  ></v-checkbox>
                </v-col>
              </v-row>
            </v-sheet>
          </v-row>
          <!-- Shipping Charge -->
          <v-row v-if="addShipping" justify="center" class="pt-2 pb-8">
            <v-sheet elevation="1"
                     class="mx-6 pb-0"
                     max-width="500"
                     color="grey lighten-4">
              <v-row justify="center">
                <v-col class="px-8 pb-0">
                  <v-sheet width="300" class="pt-0"
                           color="grey lighten-4">
                    <DesiredAssetPicker :clear="clear" v-if="amountShipping !== '0'"
                                        ref="desiredAssetPickerShipping"
                                        rules="required"
                                        @desiredAccountSelected="desiredAccountSelectedShipping"
                                       />
                  </v-sheet>
                  <div :key="amountShipping + balanceShipping"
                       style="color: red;text-align: end; margin-top: -15px; margin-bottom: 10px"
                       v-show="amountShipping > parseFloat(balanceShipping.replace(/\$|,/g, ''))">
                    Insufficient Funds
                  </div>
                  <ValidationProvider ref="amountValidatorShipping"
                                      rules="required"
                                      name="Amount"
                                      v-slot="{ errors }">
                    <v-sheet width="300" style="margin-top: -2px; margin-bottom: -2px"
                             color="grey lighten-4">
                      <v-text-field
                          label="Shipping Charge (In Asset)"
                          reverse
                          dense
                          hint="The amount of the shipping charge"
                          value="0.00"
                          :error-messages="errors"
                          v-model="amountShipping"/>
                    </v-sheet>
                  </ValidationProvider>

                  <!-- Description -->
                  <ValidationProvider ref="descriptionValidatorShipping"
                                      rules="required"
                                      name="Shipping Description"
                                      v-slot="{ errors }">
                    <v-col class="px-0 pt-1">
                      <v-sheet max-width="300"
                               color="grey lighten-4">
                        <v-textarea
                            rows="2"
                            dense
                            outlined
                            v-model="descriptionShipping"
                            :error-messages="errors"
                            hint="Further information about the shipping charge."
                            label="Shipping Description"/>
                      </v-sheet>
                    </v-col>
                  </ValidationProvider>
                  <v-col
                      class="ml-12 mt-n3 py-0 mb-1">

                  <v-btn
                      color="accent"
                      @click="autoShipping"
                      :disabled="!(amount > 0) || !desiredAccountNum"
                  >Auto-Calculate</v-btn>
                  </v-col>

                </v-col>
              </v-row>
            </v-sheet>
          </v-row>
        </v-row>

        <v-row justify="center">
          <v-btn
              v-if="allowedComponents.includes('Pending Transactions')"
              :loading="state === 'saving settle'"
              color="primary"
              @click="handleSubmit(saveWithdrawal(true))"
              :disabled="invalid || !valid || state === 'saving request'
               || (amount > parseFloat(balanceWithdraw.replace(/\$|,/g, '')))
               || (amountShipping > parseFloat(balanceShipping.replace(/\$|,/g, '')))"
              height="30"
              class="mt-n7 mb-1 mx-2"
              width="150">
            Settle
          </v-btn>
          <v-btn
                  color="primary"
                  @click="$parent.state = 'initial'"
                  height="30"
                  class="mt-n7 mx-2"
                  text
                  width="150">
            Close
          </v-btn>
        </v-row>
      </v-sheet>
    </ValidationObserver>
        <ErrorDialog ref="errorDialog"/>
        <SnackBar ref="snackbar"/>
    </div>
    <div v-else class="pl-12 pr-12 pt-12 mt-12 headline text-center">Sorry, this page is not available.</div>
  </div>
</template>

<script>
  import DesiredAssetPicker from "../components/DesiredAssetPicker";
  import ErrorDialog from "../components/ErrorDialog";
  import { extend } from 'vee-validate'
  import { required } from 'vee-validate/dist/rules'
  import SnackBar from "../components/Snackbar";
  import SearchMembers from "../components/SearchMembers";
  import { ValidationObserver, ValidationProvider } from 'vee-validate'

  extend("required", {
    ...required,
    message: "{_field_} is required"
  });

  extend('greaterThanZero', {
    validate: value => {
      return value > 0
    },
    message: "{_field_} must be greater than 0"
  });

  export default {
    name: "Withdrawal",
    props: {
      showSearchMembers: {
          type: Boolean,
          default() {
              return true;
          }
      },
      memberUpdate: {
          type: Boolean,
          default() {
              return false;
          }
      },
      memberProp: {
          type: Object,
          default() {
              return {}
          }
      },
    },
    components: {DesiredAssetPicker, SearchMembers, SnackBar, ErrorDialog, ValidationObserver,
      ValidationProvider},

    data() {
        return {
            addShipping: false,
            allowedComponents: JSON.parse(localStorage.getItem('allowedComponents') || ''),
            amount: "",
            amountShipping: "",
            balanceShipping: "",
            balanceWithdraw: "",
            clear: false,
            desiredAccountNum: "",
            desiredAccountNumShipping: "",
            description: "",
            descriptionShipping: "",
            member: this.memberProp,
            state: 'display',
            assetTypeID: null
        }
    },

    watch: {
      'addShipping': function(){
        if (this.amountShipping !== '0' && this.addShipping)  this.$nextTick(() =>
          this.$refs.desiredAssetPickerShipping.setAccounts(this.member)
        )
      },
      'amountShipping': function(){
        if (this.amountShipping !== '0')  this.$nextTick(() =>
          this.$refs.desiredAssetPickerShipping.setAccounts(this.member)
        )
      },
      'memberUpdate': function(){
          this.resetValues();
          this.member = this.memberProp;
          this.$refs.desiredAssetPicker.setAccounts(this.member);
          if (this.addShipping) this.$refs.desiredAssetPickerShipping.setAccounts(this.member);
      },
    },

    computed: {
      accountDescription: function() {
        if (!this.desiredAccountNum) {
          return ""
        }
        return this.$refs.desiredAssetPicker.getAccountDescription(this.desiredAccountNum);
      },
      accountDescriptionShipping: function() {
        if (!this.desiredAccountNumShipping) {
          return ""
        }
        return this.$refs.desiredAssetPickerShipping.getAccountDescription(this.desiredAccountNumShipping);
      }
    },

    mounted() {
      localStorage.title = 'Withdrawal';
      if (this.member && this.member.email) {
        this.$refs.desiredAssetPicker.setAccounts(this.member);
        if (this.addShipping) this.$refs.desiredAssetPickerShipping.setAccounts(this.member);
      }
    },

    methods: {
      desiredAccountSelected: function (account) {
        this.desiredAccountNum = account.num;
        this.balanceWithdraw = account.availableBalance;
        this.assetTypeID = this.assetNameToID(account.assetType);
      },
      assetNameToID: function (assetName){
        switch (assetName) {
          case 'Gold Dollar':
            return 3373

          case 'Silver Dollar':
            return 3374

          case 'Paper Dollar':
            return 5062

          case 'Goldback':
            return 7000
        }
      },
      desiredAccountSelectedShipping: function (account) {
        this.desiredAccountNumShipping = account.num;
        this.balanceShipping = account.availableBalance;
      },
      memberSelected: function (member) {
        this.resetValues();
        this.member = member;
        this.$refs.desiredAssetPicker.setAccounts(this.member);
        if (this.addShipping) this.$refs.desiredAssetPickerShipping.setAccounts(this.member);
      },
      resetValues: function () {
        this.clear = !this.clear;
        this.desiredAccountNum = "";
        this.description = "";
        this.amount = "";
        this.desiredAccountNumShipping = "";
        this.descriptionShipping = "";
        this.amountShipping = "";
        this.$refs.amountValidator.reset();
        this.$refs.descriptionValidator.reset();
        if (this.addShipping) this.$refs.amountValidatorShipping.reset();
        if (this.addShipping) this.$refs.descriptionValidatorShipping.reset();
      },
      autoShipping: async function() {
        let body = {
          assetAmount: this.amount,
          assetTypeID: this.assetTypeID
        }
        try {
          let res = await this.$axios.get('/shipping', {params: body});
          this.desiredAccountNumShipping = this.desiredAccountNum;
          this.descriptionShipping = 'Shipping, Handling, and Insurance';
          this.amountShipping = res.data.shipping_cost_amount;
          this.$refs.desiredAssetPickerShipping.desiredAccount = this.$refs.desiredAssetPicker.desiredAccount;
        } catch (e) {
          this.state = 'display';
          if (e && e.response) {
            this.$refs.errorDialog.showError({
              errorMessage: e.response.message ? e.response.message : "There was an error calculating shipping. If you continue to experience problems please notify the development team.",
              forDevelopers: e
            })
          }
        }
      },
      saveWithdrawal: async function(settle) {
        this.state = settle ? 'saving settle' : 'saving request';
        let withdrawal = {
          accountNum: this.desiredAccountNum,
          dollarAmount: this.amount,
          description: this.description,
          shippingChargeAccountNum: this.desiredAccountNumShipping,
          shippingChargeDollarAmount: this.amountShipping,
          shippingChargeDescription: this.descriptionShipping,
          settle: settle
        }
        try {
          await this.$axios.post('/admin/assetManagement/withdrawal', withdrawal);
          this.state = 'display';
          this.$refs.snackbar.showSnackbar({
            text: "Recorded the Withdrawal",
            color: "success"
          })
          this.memberSelected(this.member);
          this.$emit('refreshTable');
        } catch (e) {
          this.state = 'display';
          this.$emit('refreshTable');
          if (e && e.response) {
            this.$refs.errorDialog.showError({
              errorMessage: e.response.message ? e.response.message : "There was an error creating the Withdrawal. Please ensure all of the fields are filled out correctly, then try again. If you continue to experience problems please notify the development team.",
              forDevelopers: e
            })
          }
        }
      }
    },
  }
</script>

<style scoped>

</style>