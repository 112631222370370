<template>
  <v-tooltip bottom :nudge-top="swtch ? 20 : 0">
    <template v-slot:activator="{ on }">
      <!--            class="pl-1"-->
      <v-switch :class="sclass" @change="$emit('input', $event)" :label="label" :value="value" v-if="swtch" v-on="on"></v-switch>
      <v-btn
          :a="a"
          class=""
          :class="btnclass"
          @click="raiseClickEvent()"
          :color="color"
          :disabled="disabled"
          :elevation="elevation"
          :fab="fab"
          :icon="icon"
          :small="small"
          :stop="stop"
          :style="btnstyle"
          :text="text"
          :tile="tile"
          v-else
          v-on="on"
          :x-large="xlarge"
          :x-small="xsmall"
      ><a :href="href" :style="fab ? 'text-decoration: none' : ''">
        <v-icon
            :color="icolor"
            :class="iclass"
            :style="istyle"
            v-if="icon || fab"
        >
          {{ iname }}
        </v-icon>
      </a>
        <span class="hidden-sm-and-down">{{ hideSmDown +  txt }}</span>
        <span class="hidden-md-and-up">{{ txt }}</span>
      </v-btn>
    </template>
    <span> {{ tip }} </span>
  </v-tooltip>
</template>

<script>
export default {
  name: "ButtonTip",
  props: {
    a: { type: Boolean, default: false },
    btnclass: { type: String, default: "" },
    btnstyle: { type: String, default: "" },
    color: { type: String, default: "" },
    disabled: { type: Boolean, default: false },
    elevation: { type: String, default: "" },
    fab: { type: Boolean, default: false },
    hideSmDown: { type: String, default: "" },
    href: { type: String, default: "" },
    icon: { type: Boolean, default: false },
    iname: { type: String, default: "" },
    iclass: { type: String, default: "" },
    icolor: { type: String, default: "" },
    istyle: { type: String, default: "color: rgba(0, 0, 0, 0.54)" },// custom grey default
    label: { type: String, default: "" },
    sclass: { type: String, default: "" },
    small: { type: Boolean, default: false },
    stop: { type: Boolean, default: false },
    swtch: { type: Boolean, default: false },
    tile: { type: Boolean, default: false },
    text: { type: Boolean, default: false },
    txt: { type: String, default: "" },
    tip: { type: String, default: "" },
    value: { type: Boolean, default: false },
    xlarge: { type: Boolean, default: false },
    xsmall: { type: Boolean, default: false },
  },

  data: () => ({
    space: ' ',
  }),

  methods: {
    raiseClickEvent() {
      if (this.stop) event.stopPropagation();// button's click handler called not parent's
      if (!this.href) event.preventDefault();// prevent reloading unless href, then needed for download
      this.$emit('click');
    },
  },
};
</script>