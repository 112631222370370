<template>
  <v-app>
    <v-dialog v-model="showInactivityDialog" persistent max-width="490">
    <v-card>
      <v-card-title class="headline">Inactivity Alert</v-card-title>
      <v-card-text>
        You have been inactive for 45 minutes and will be automatically logged out in: <br>
        <div class="text-center">
          <v-spacer></v-spacer>
          <h1>{{ formattedCountdown }}</h1>
          <v-spacer></v-spacer>
        </div>
      </v-card-text>
      <v-card-actions>
        <v-btn color="red" text @click="logout">
          Log Out
        </v-btn>
        <v-spacer></v-spacer>
        <v-btn color="primary" text @click="refreshTokenAndCloseDialog">
          Keep Me Logged In
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
    <NavDrawer class="d-print-none" ref="navDrawer" v-if="showHeader && title !== 'hideAppBar'"/>
    <AppBar :titleProp=title v-if="showHeader && (title !== 'hideAppBar' && title !== 'Trust') && $root.isAuthenticated" @navButtonClicked="$refs.navDrawer.toggleShown()"/>
    <SnackBar ref="snackbar"/>
    <v-content>
      <router-view @showErrorDialog="showError" @showSnackbar="showSnackbar"/>
    </v-content>
    <RatesSummary @setRatesBtnClicked="$refs.setRatesDialog.toggleShown()" ref="ratesSummary"/>
    <SetRatesDialog ref="setRatesDialog" @showErrorDialog="showError"/>
    <ErrorDialog ref="errorDialog"/>
    <Footer class="d-print-none" @ratesClicked="$refs.ratesSummary.toggleShown()"/>
  </v-app>
</template>

<script>
import AppBar from "./components/AppBar";
import Footer from "./components/Footer";
import NavDrawer from "./components/NavDrawer";
import RatesSummary from "./components/rates/RatesSummary";
import SetRatesDialog from "./components/rates/SetRatesDialog";
import ErrorDialog from "./components/ErrorDialog";
import SnackBar from "./components/Snackbar";

export default {
  name: 'App',

  components: {
    SnackBar,
    ErrorDialog,
    SetRatesDialog,
    RatesSummary,
    NavDrawer,
    AppBar,
    Footer,
  },

  data: () => ({
    showAppBar: true,
    title: '',
    showInactivityDialog: false,
    countdown: 3 * 60, //3 Minutes
    countdownInterval: null,
  }),

  mounted() {
    localStorage.title = '';
    this.title = localStorage.title;
  },

  created() {
    localStorage.setItem('keepLoginRequest', 'true'); //if user reloads page

    this.$eventBus.$on('inactiveUser', (inactiveUser) => {
      if(inactiveUser && this.showInactivityDialog === false) {
        localStorage.setItem('logoutRequest', 'false');
        localStorage.setItem('keepLoginRequest', 'false');
        this.startCountdown();
        this.showInactivityDialog = inactiveUser;
      }
    });
  },

  updated() {
    this.title = localStorage.title;
    if (this.title === 'hideAppBar') this.showAppBar = false;
  },

  computed: {
    showHeader() {
      // Change this condition according to your requirements
      return this.$route.path !== '/login';
    },
    formattedCountdown() {
      const minutes = Math.floor(this.countdown / 60);
      const seconds = this.countdown % 60;
      return `${minutes}:${seconds.toString().padStart(2, '0')}`;
    },
  },

  methods: {
    refreshTokenAndCloseDialog: function () {
      this.$axios.post(`/admin/token`, {
        refresh_token: `${localStorage.getItem('refresh_token')}`,
        grant_type: 'refresh_token',
        client_id: 'testclient'
      }).then(newTokenSet => {
        // Update the access token in your HTTP client and storage
        localStorage.setItem('access_token', newTokenSet.data.access_token);
        localStorage.setItem('refresh_token', newTokenSet.data.refresh_token);
        this.$axios.defaults.headers.common['Authorization'] = `Bearer ${localStorage.getItem('access_token')}`;
      });
      this.closeDialog();
    },
    closeDialog: function () {
      localStorage.setItem('keepLoginRequest', 'true'); //cross-browser-login
      clearInterval(this.countdownInterval);
      this.showInactivityDialog = false;
    },
    showError: function (options) {
      this.$refs.errorDialog.showError(options);
    },
    showSnackbar: function (options) {
      this.$refs.snackbar.showSnackbar(options)
    },
    logout: function () {
      localStorage.setItem('logoutRequest', 'true');
      clearInterval(this.countdownInterval);
      this.showInactivityDialog = false;
      this.$root.handleLoggedOut();
    },
    startCountdown: function () {
      if (this.countdownInterval) {
        clearInterval(this.countdownInterval);
      }

      //only call if dialog not up already
      if(!this.showInactivityDialog) {
        this.countdown = 3 * 60; //3 Minutes
      }

      this.countdownInterval = setInterval(() => {
        this.countdown--;
        if (localStorage.getItem('keepLoginRequest') === 'true') {
          this.closeDialog();
          this.$axios.defaults.headers.common['Authorization'] = `Bearer ${localStorage.getItem('access_token')}`;
        }
        if (this.countdown <= 0 || localStorage.getItem('logoutRequest') === 'true') {
          console.log('hit');
          this.logout();
        }
      }, 1000);
    },
  }
};
</script>
