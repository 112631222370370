<template>
    <v-row class="ml-0 my-2">
        <v-spacer/>

        <!-- Previous and Next Buttons -->
        <v-btn
                fab
                small
                color="primary"
                @click="$emit('prev')"
                class="mr-8"
        >
            <v-icon dark>mdi-chevron-left</v-icon>
        </v-btn>
        <v-btn
                fab
                small
                color="primary"
                @click="$emit('next')"
                class="mr-3"
        >
            <v-icon dark>mdi-chevron-right</v-icon>
        </v-btn>
    </v-row>
</template>

<script>
    export default {
        name: "RatesHeader",
    }
</script>

<style scoped>

</style>