<template>
  <div>
    <div v-if="allowedComponents.includes('Holdings')">

      <div class="px-2">
          <!--      Buttons    -->
        <v-row class="px-4 pt-1 pb-2">
          <v-spacer/>
          <v-tooltip bottom class="d-print-none">
            <template v-slot:activator="{ on }">
              <v-btn x-large tile text color='darkGold' v-on="on"
                     class="mt-n2 mb-4" @click="printDiv()"
              >
                Print
              </v-btn>
            </template>
            <span>Open Print Dialog</span>
          </v-tooltip>
        </v-row>

        <div class="scroll d-print-block div2" style="width: 100%">
          <div>
            <div class="text-h5 pl-2 py-1 mt-n8 mb-10 d-print-block text-center">
              {{ formatDay(start) }} -- {{ formatDay(end) }}
            </div>
            <v-row justify="center">
              <v-data-table
                  fixed-header
                  :headers="totalHeaders"
                  height="60vh"
                  hide-default-footer
                  :items-per-page="-1"
                  :items="totals"
                  style="border-right-style: none; border-left-style: none"
              >
                <template v-for="field in fields" v-slot:[`item.${field}`]="{ item }">
                  <span :key="field">
                    <span v-if="item[field] === '0'">0.00</span>
                    <span v-else>{{ item[field] }}</span>
                  </span>
                </template>
              </v-data-table>
            </v-row>
          </div>

          <ErrorDialog ref="errorDialog"/>
        </div>
      </div>
    </div>
    <div v-else class="pl-12 pr-12 pt-12 mt-12 headline text-center">Sorry, this page is not available.</div>
  </div>
</template>

<script>
import ErrorDialog from "@/components/ErrorDialog";
import moment from 'moment'
// const prettyDateFormat = 'DD MMM YYYY HH:mm:ss';
const prettyDateFormat = 'D MMM YYYY h:mm A';

export default {
  name: 'MemberTransactionsPrintTotals',
  components: { ErrorDialog },
  props: {
    start: [String, Number],
    end: [String, Number],
  },
  data: () => ({

    //--------- Data Variables ---------//

    allowedComponents: JSON.parse(localStorage.getItem('allowedComponents') || ''),
    totals: [],
    totalHeaders: [
      { text: 'Type',          value: 'type',           align: 'start' },
      { text: 'Paper',         value: 'paper_amount',   align: 'end' },
      { text: 'Gold',          value: 'gold_amount',    align: 'end' },
      { text: 'Silver',        value: 'silver_amount',  align: 'end' },
      { text: 'Goldback',      value: 'goldback_amount',align: 'end' },
    ],
  }),

  computed: {
    fields() {
      return this.totalHeaders.map(a => a.value);
    },
  },

  created: async function() {
    document.title = 'Print Transactions Totals';
    await this.getTotals();
  },

  mounted() {
    localStorage.title = 'hideAppBar';
  },

  methods: {
    formatDay(day){
      return moment(day).format(prettyDateFormat);
    },

    getTotals: async function() {
      try {
        let res = await this.$axios.get(`/admin/reports/vaulted/transactions?startTimestamp=${this.start}&endTimestamp=${this.end}`);
        this.totals = res.data.amounts_by_type;
      } catch (e) {
        this.$refs.errorDialog.showError({
          errorMessage: "Failed to get totals.",
          forDevelopers: e
        })
      }
    },

    printDiv() {
      window.print();//this works in popup, but has problems in dialog
    },

  },
};
</script>

<style>

  @media print {
    body * { visibility: hidden; }
    .div2 * { visibility: visible;  margin: 0 !important; width: 100% !important;}
    .div2 { position: absolute; left: 0px; margin: 0 !important;}
  }

</style>