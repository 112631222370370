<template>
  <div class="pa-5">
    <div v-if="allowedComponents.includes('Wire/Check')">
      <v-data-table
          :headers="tableHeaders"
          :items="items"
          :search="search"
          class="elevation-4"
          @click:row="manageTrans"
          :loading="state === 'loading'"
      >
        <template v-slot:top>
          <v-text-field v-model="search" append-icon="mdi-magnify" label="Search Pending Wire/Check Transactions"
                        class="mx-4 pt-7"></v-text-field>
        </template>
        <template v-slot:item.paperAmount="props">
          ${{ (parseFloat(props.item.paperAmount)).toFixed(2).toString()
          .replace(/\d(?=(\d{3})+\.)/g, '$&,') }}
        </template>
        <template v-slot:item.posted="props">
          {{ date(props.item.posted)}}
        </template>

      </v-data-table>

      <WireCheckManageDialog @update="update" :transaction="this.transaction" ref="WireCheckManageDialog"/>
      <ErrorDialog ref="errorDialog"/>
    </div>
    <div v-else class="pl-12 pr-12 pt-12 mt-12 headline text-center">Sorry, this page is not available.</div>
  </div>
</template>

<script>
  // @ is an alias to /src
  import moment from 'moment'
  import WireCheckManageDialog from "./WireCheckManageDialog";
  import ErrorDialog from "@/components/ErrorDialog";

  const dateFormat = 'YYYY-MM-DD';

  export default {
    name: "WireCheck",
    components: {
      ErrorDialog, WireCheckManageDialog
    },
    data: () => ({
      state: 'loading',
      search: "",
      manualDeposits: [],
      items: [],
      transaction: {},
      allowedComponents: JSON.parse(localStorage.getItem('allowedComponents') || ''),
      tableHeaders: [
        {
          text: 'Journal Num',
          value: 'journalNum',
          align: 'start',
          width: '160px'
        },
        {
          text: 'Email',
          value: 'username',
          align: 'start',
          width: '200px'
        },
        {
          text: 'Paper Amount',
          value: 'paperAmount',
          align: 'end',
          width: '160px'
        },
        {
          text: 'Asset Type',
          value: 'requestedAsset',
          align: 'start',
          width: '180px'
        },
        {
          text: 'DCA Period',
          value: 'numBusinessDays',
          align: 'end',
          width: '120px'
        },
        {
          text: 'Requested',
          value: 'posted',
          align: 'start',
          width: '180px'
        }
      ]
    }),

    methods: {

      update (transaction) {
        // delete this settled or cancelled transaction from the pending table
        const index = this.items.indexOf(transaction);
        if (index > -1) {this.items.splice(index, 1)}
        this.state = 'display';
      },

      date(date) {
        return moment(date).format(dateFormat);
      },

      getManualDeposits: async function () {
        try {
          let res = await this.$axios.get(`/admin/reports/manual`);
          this.manualDeposits = res.data;
        } catch (e) {
          this.$refs.errorDialog.showError({
            errorMessage: "Failed to get pending Wire/Check Transactions.",
            forDevelopers: e
          })
        }
      },

      getAssetName: function (assetType){
        switch (assetType){
          case 3373:
            return 'Gold Dollar';
          case 3374:
            return 'Silver Dollar';
          case 5062:
            return 'Paper Dollar';
          case 7000:
            return 'Goldback';
        }
      },

      manageTrans: function (transaction){
        transaction.paperAmount = parseFloat(transaction.paperAmount).toFixed(2)
        this.transaction = transaction;
        this.$refs.WireCheckManageDialog.toggleShown();
      },
    },

    created: async function() {
      await this.getManualDeposits();
      this.state = 'display';
      for (let i = 0; i < this.manualDeposits.length; i += 1) {
        if (this.manualDeposits[i]) {
          this.manualDeposits[i].assetName = this.getAssetName(this.manualDeposits[i].assetTypeID);
        }
      }
      this.items = this.manualDeposits;// items for table
    },
    mounted() {
      localStorage.title = 'Wire/Check';
    },
  }
</script>

<style scoped>

</style>