<template>
    <v-sheet class="my-n1">
        <div v-if="allowedComponents.includes('Settings')">
          <v-card>
            <v-card-title>
              <v-btn color="primary" class="mt-1 mr-1"
                     @click="pageDisplay = 'Premiums'"
                     :disabled="pageDisplay === 'Premiums'">
                Premiums
              </v-btn>
              <v-btn color="primary" class="mt-1 mr-1"
                     @click="pageDisplay = 'Shipping'"
                     :disabled="pageDisplay === 'Shipping'">
                Shipping Rates
              </v-btn>
              <v-btn color="primary" class="mt-1 mr-1"
                     @click="pageDisplay = 'Features'"
                     :disabled="pageDisplay === 'Features'">
                Feature Toggles
              </v-btn>
              <v-btn color="primary" class="mt-1 mr-1"
                     @click="pageDisplay = 'LeaseBreakpoints'"
                     :disabled="pageDisplay === 'LeaseBreakpoints'">
                Lease Breakpoints
              </v-btn>
            </v-card-title>
            <v-row justify="center" class="pb-5">
              <Premiums v-if="pageDisplay === 'Premiums'"/>
              <Shipping v-if="pageDisplay === 'Shipping'"/>
              <Features v-if="pageDisplay === 'Features'"/>
              <LeaseBreakpoints v-if="pageDisplay === 'LeaseBreakpoints'"/>
            </v-row>
          </v-card>
        </div>
        <div v-else class="pl-12 pr-12 pt-12 mt-12 headline text-center">Sorry, this page is not available.</div>
    </v-sheet>
</template>

<script>
    import Premiums from "../components/settings/Premiums";
    import Shipping from "../components/settings/Shipping";
    import Features from "../components/settings/Features";
    import LeaseBreakpoints from "../components/settings/LeaseBreakpoints";
    export default {
        name: "Settings",
        components: {Premiums, Shipping, Features, LeaseBreakpoints},
        data: () => ({
          allowedComponents: JSON.parse(localStorage.getItem('allowedComponents') || ''),
          pageDisplay: 'Premiums'
        }),
        mounted() {
            localStorage.title = 'Settings';
        },
    }
</script>

<style scoped>

</style>