import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/Home.vue'
import Rates from "../views/Rates";
import ReportsCards from "../views/ReportsCards";
import Assets from "../views/Assets";
import ReportsCreditDebit from "../views/ReportsCreditDebit";
import ReportsWireCheck from "../views/ReportsWireCheck";
import Login from "../views/Login";
import Settings from "../views/Settings";
import RosterAccess from "../views/RosterAccess";
import RosterRoles from "../views/RosterRoles";
import Franchises from "../views/Franchises";
import Bailment from "../views/Bailment";
import Exchange from "../views/Exchange";
import PhysicalDeposit from "../views/PhysicalDeposit";
import Withdrawal from "../views/Withdrawal";
import Adjustment from "../views/Adjustment";
import BatchACH from "../components/reports/BatchACH";
import PendingTransactions from "../components/reports/PendingTransactions";
import Trust from "../components/reports/Trust";
import Escrow from "../components/reports/Escrow";
import Lease from "../components/reports/Lease";
import Holdings from "../components/reports/Holdings";
import Products from "../views/Products";
import MemberTransactionsPrintDetail from "../components/reports/MemberTransactionsPrintDetail";
import MemberTransactionsPrintTotals from "../components/reports/MemberTransactionsPrintTotals";
import BuySell from "../views/BuySell";
import Tasks from "../views/Tasks";
import Task from "../components/service/task";
import NewServiceRequest from "../views/NewServiceRequest";
import IRAAccounts from "../views/IRAAccounts";
import PolicyAcknowledgement from "@/views/PolicyAcknowledgement";
import Policy from "@/views/Policy";

Vue.use(VueRouter);

  const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home
  },
  {
    path: '/rates',
    name: 'Rates',
    component: Rates
  },
    {
      path: '/buy/sell',
      name: 'Buy/Sell',
      component: BuySell
    },
  {
    path: '/assets',
    name: 'Assets',
    component: Assets
  },
  {
    path: '/products',
    name: 'Products',
    component: Products
  },
  {
    path: '/policyAcknowledgement',
    name: 'Policy Acknowledgement',
    component: PolicyAcknowledgement,
    props: true
  },
  {
    path: '/policy',
    name: 'Policy',
    component: Policy,
  },
  {
    path: '/tasks',
    name: 'Tasks',
    component: Tasks
  },
  {
    path: '/tasks/:uuid',
    name: 'Task',
    component: Task,
    props: true
  },
  {
    path: '/service/request/new',
    name: 'New Service Request',
    component: NewServiceRequest
  },
  {
    path: '/reportsCards',
    name: 'ReportsCards',
    component: ReportsCards
  },
  {
    path: '/reportsCreditDebit',
    name: 'ReportsCreditDebit',
    component: ReportsCreditDebit
  },
  {
    path: '/reportsWireCheck',
    name: 'ReportsWireCheck',
    component: ReportsWireCheck
  },
  {
    path: '/login',
    name: 'Login',
    component: Login
  },
  {
    path: '/settings',
    name: 'Settings',
    component: Settings
  },
  {
    path: '/access',
    name: 'RosterAccess',
    component: RosterAccess
  },
  {
    path: '/roles',
    name: 'RosterRoles',
    component: RosterRoles
  },
  {
    path: '/IRAAccounts',
    name: 'IRAAccounts',
    component: IRAAccounts
  },
  {
    path: '/franchises',
    name: 'Franchises',
    component: Franchises
  },
  {
    path: '/bailment',
    name: 'Bailment',
    component: Bailment
  },
  {
    path: '/exchange',
    name: 'Exchange',
    component: Exchange
  },
  {
    path: '/physicalDeposit',
    name: 'PhysicalDeposit',
    component: PhysicalDeposit
  },
  {
    path: '/withdrawal',
    name: 'Withdrawal',
    component: Withdrawal
  },
  {
    path: '/adjustment',
    name: 'Adjustment',
    component: Adjustment
  },
  {
    path: '/batchReport',
    name: 'BatchReport',
    component: BatchACH
  },
  {
    path: '/pendingTransactions',
    name: 'PendingTransactions',
    component: PendingTransactions
  },
  {
    path: '/escrow',
    name: 'Escrow',
    component: Escrow
  },
  {
    path: '/trust',
    name: 'Trust',
    component: Trust
  },
  {
    path: '/lease',
    name: 'Lease',
    component: Lease
  },
  {
    path: '/holdingsReport',
    name: 'HoldingsReport',
    component: Holdings
  },
  {
    path: '/memberTotals/:start/:end',
    name: 'memberTotals',
    component: MemberTransactionsPrintTotals,
    props: true
  },
  {
    path: '/memberTransactionDetail/:journalNum',
    name: 'memberDetail',
    component: MemberTransactionsPrintDetail,
    props: true
  },
];

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
});

// This reroutes users to the login view when logged out.
router.beforeEach(async (to, from, next) => {

  // If data isn't yet defined because of vue life cycle's, then request the user's data and wait until response.
  if(router.app.$data === undefined) {
    try {
      let res = await router.app.$axios.get('/admin/user');
      router.app.$emit('logged-in', res.data);
      // If the user is logged in, but navigated to the Login View reroute to the Home View.
      if(to.name === 'Login') {
        return next({
          path: '/',
          query: { redirect: to.fullPath }
        })
      }

    } catch (e) {
      console.log(e);

      // If user is not logged in route them to the login view.
      return next({
        path: '/login',
        query: { redirect: to.fullPath }
      })
    }
  }

  return next();
});

export default router
