<template>
    <v-col class="ml-4">
        <span
            v-for="productGroupItem in selectedProductGroupItems"
            :key="productGroupItem.name" >
          <v-row style="justify-content: right">
            <v-select class="ml-1"
                      outlined
                      v-if="!selectedProducts[productGroupItem.name]"
                      label="Add Product Group"
                      :items="displayProductGroups.filter(x => !selectedProductGroupItems.find(item => item.name === x.name))"
                      :item-text="productGroup => productGroup.name"
                      :item-value="productGroup => productGroup.name"
                      no-data-text="Loading Products..."
                      :value="productGroupItem.name"
                      @input="setGroup"
                      :loading="loadingGroup"
                      dense
            />
            <v-select class="ml-1"
                      outlined
                      v-else
                      :items="displayProductGroups"
                      readonly
                      :item-text="productGroup => productGroup.name"
                      :item-value="productGroup => productGroup.name"
                      :value="productGroupItem.name"
                      :loading="loadingGroup"
                      clearable
                      @click:clear="removeProductsUnderGroup(productGroupItem.name)"
                      dense
            />
          </v-row>
          <span :key="componentKey">
              <v-row v-if="selectedProducts[productGroupItem.name]" style="justify-content: right; flex-wrap: wrap" >
                <v-text-field class="ml-1"
                              style="min-width: 100px; max-width: 120px"
                              outlined
                              :label="product.name"
                              placeholder="0"
                              type="number"
                              v-for="product in displayProducts[productGroupItem.name]"
                              :key="product.uuid"
                              @input="emitOut"
                              v-model="selectedProducts[productGroupItem.name][product.name]['quantity']"
                              :loading="loadingProduct[productGroupItem.name]"
                              dense
                />
              </v-row>
        </span>
      </span>
    </v-col>
</template>

<script>
    export default {
        name: "SelectProducts",
        props: {
          clearable: Boolean,
          small: Boolean,
          hideDetails: String,
          chips: Boolean,
          assetType: Number
        },
        data: () => ({
          displayProductGroups: [],
          displayProducts: [],
          selectedProductGroups: [],
          selectedProductGroupItems: [{name: ''}],
          selectedProducts: [],
          state: 'display',
          search: '',
          awaitingSearch: false,
          loadingGroup: false,
          loadingProduct: [false],
          componentKey: 0
        }),
        methods: {
          forceRerender() {
            this.componentKey += 1;
          },
          fetchProductGroups() {
            this.loadingGroup = true
            const url = '/products'
            this.$axios.get(url, {params: {}}).then(({data}) => {
              if (this.assetType) {
                let onlyAsset = [];
                data.forEach(item => {
                  if (this.assetType === 7000 && item.name.includes('Goldback')) {
                    onlyAsset.push(item);
                  } else if (this.assetType === 3373 && item.name.includes('Gold Coin')) {
                      onlyAsset.push(item);
                  } else if (this.assetType === 3374 && item.name.includes('Silver Coin')) {
                      onlyAsset.push(item);
                  }
                }
                );
                this.displayProductGroups = onlyAsset;
              }
              else this.displayProductGroups = data;
              this.loadingGroup = false;
            })
            .catch(({response}) => {
              alert(response)
              this.loadingGroup = false
            })
          },
          setGroup(value) {
            this.fetchProducts(value)
          },
          fetchProducts(groupName) {
            if (!groupName) {
              return
            }
            this.loadingProduct[groupName] = true
            const url = '/products?groupName=' + groupName
            const params = this.inactiveHeaders ? {showInactive: 1} : {showInactive: 0}
            this.$axios.get(url, {params: params}).then(({data}) => {
              this.selectedProducts[groupName] = []
              data.forEach(item => this.selectedProducts[groupName][item.name] = item)
              this.displayProducts[groupName] = {...data}
              this.selectedProductGroupItems.pop()
              this.selectedProductGroupItems.push({name: groupName})
              this.selectedProductGroupItems.push({name: ''})
              this.forceRerender()
              this.loadingProduct[groupName] = false
            })
            .catch(({response}) => {
              alert(response)
              this.loadingProduct[groupName] = false
            })
          },
          removeProductsUnderGroup(groupName) {
            this.loadingGroup = true
            delete this.selectedProducts[groupName];
            this.selectedProductGroupItems = this.selectedProductGroupItems.filter(function(x) { return x.name !== groupName })
            this.forceRerender()
            this.emitOut()
            this.loadingGroup = false
          },
          emitOut() {
            this.$emit('productsSelected', this.selectedProducts);
          }
        },
      mounted() {
        this.fetchProductGroups();
      },
      computed: {
        },
        watch: {
        },
    }
</script>

<style scoped>

</style>