<template>
    <v-dialog v-model="shown"
              max-width="600">
        <v-card class="pa-2">

            <v-card-title>
               {{type}} Confirmation
            </v-card-title>

            <v-card-text>
                <!-- Table -->
                <v-card outlined class="pa-2">
                    <v-simple-table>
                        <tr>
                            <td>Member</td>
                            <td>{{ `${member.name}, ${member.email}, ${member.legalName}` }}</td>
                        </tr>
                        <tr v-if="type !== 'Exchange'">
                            <td>Amount</td>
                            <td>{{ mixin_addCommas(amount) }} {{ mixin_suffix(accountName) }}</td>
                        </tr>
                        <tr v-if="type !== 'Exchange'">
                            <td>Account</td>
                            <td>{{ accountName }}</td>
                        </tr>
                        <tr v-if="type === 'Exchange'">
                            <td>FROM</td>
                            <td>{{ fromAccount }}</td>
                            <td style="text-align:right; white-space: nowrap">
                              {{ mixin_addCommas(withdrawAmount) }} {{ mixin_suffix(fromAccount) }}</td>
                        </tr>
                        <tr v-if="type === 'Exchange'">
                            <td>TO</td>
                            <td>{{ toAccount }}</td>
                            <td style="text-align:right; white-space: nowrap">
                              {{ mixin_addCommas(depositAmount) }} {{ mixin_suffix(toAccount) }}</td>
                        </tr>
                        <tr v-if="type === 'Adjustment'">
                            <td>Link</td>
                            <td>{{ mixin_addCommas(link.prettyAmount) }}</td>
                            <td style="text-align:right; white-space: nowrap">
                              {{ link.posted ? mixin_UTCToPretty(link.posted, 'date' ) : ''}}</td>
                        </tr>
                        <tr class="pt-4">
                            <td>Description</td>
                            <td><span class="font-weight-light text-start"> {{ description }} </span></td>
                        </tr>
                    </v-simple-table>
                </v-card>
            </v-card-text>
            <!-- Save & Cancel Buttons -->
            <v-card-actions>
                <v-btn color="red"
                       dark
                       @click="cancelBtnClicked">
                    Cancel
                </v-btn>
                <v-spacer/>
                <v-btn color="primary"
                       @click="saveBtnClicked">
                  {{ settle ? 'Settle' : 'Request' }}
                </v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>

<script>
    export default {
        name: "ConfirmationDialog",
        props: [
            'amount',
            'member',
            'withdrawAmount',
            'allowedComponents',
            'depositAmount',
            'account',
            'accountName',
            'description',
            'link',
            'type',
            'fromAccount',
            'toAccount',
            'settle'
        ],
        data: () => ({
            shown: false
        }),
        methods: {
            toggleShown: function() {
                this.shown = !this.shown;
            },
            saveBtnClicked: function () {
                this.toggleShown();
                this.$emit('saveConfirmed');
            },
            cancelBtnClicked: function() {
                this.toggleShown();
                this.$emit('saveCanceled');
            }
        }
    }
</script>

<style scoped>

</style>