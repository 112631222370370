<template>
    <v-sheet max-width="1000">
        <v-progress-linear v-if="state === 'loading'"
                           indeterminate
                           color="primary"
        />
        <v-card class="pa-10">
            <v-row justify="center">
                <v-sheet>
                    <h1 class="ml-3 mt-3 display-5">Lease Breakpoints (GB Only)</h1>
                    <v-card class="ml-2 pa-2 "
                            outlined
                            max-width="500">
                        <p class="caption">
                            Lease breakpoints are set by breakpoints defined below.
                        </p>
                    </v-card>
                    <v-card-text>
                        <v-row>
                            <v-sheet max-width="800">
                                <v-row :key="breakpoint.percentage" v-for="breakpoint in breakpoints">
                                    <v-col>
                                        <v-text-field
                                                label="Asset Amount"
                                                v-model="breakpoint.asset_amount"
                                                suffix="GB"
                                                readonly
                                        ></v-text-field>
                                    </v-col>
                                    <v-col>
                                        <v-text-field
                                                label="Percentage (APR)"
                                                v-model="breakpoint.percentage"
                                                suffix="%"
                                                readonly
                                        ></v-text-field>
                                    </v-col>
                                </v-row>
                            </v-sheet>
                        </v-row>
                    </v-card-text>
                </v-sheet>
            </v-row>
        </v-card>
        <ErrorDialog ref="errorDialog"/>
        <SnackBar ref="snackbar"/>
    </v-sheet>
</template>

<script>
    import ErrorDialog from "../ErrorDialog";
    import SnackBar from "../Snackbar";
    export default {
      name: "LeaseBreakpoints",
      components: {SnackBar, ErrorDialog},
      data: () => ({
          state: 'display',
          breakpointTypes: [],
          breakpoints: [],
          originalBreakpoints: [], // Premiums as they were before changes
      }),
      methods: {
        getBreakpoints: async function () {
          try {
            let res = await this.$axios.get('/admin/lease/breakpoints');
            let breakpoints = res.data;
            if (!breakpoints) {
              this.breakpoints = []
            }

            this.breakpoints = breakpoints;

            this.originalBreakpoints = JSON.parse(JSON.stringify(breakpoints)); // Create a deep clone
            this.breakpoints.forEach(item => {
              item.percentage = (item.percentage * 100).toFixed(2);
            })
          } catch (e) {
            this.$refs.errorDialog.showError({
              errorMessage: 'There was an error fetching the current lease breakpoints.',
              forDevelopers: e
            })
          }
        }
      },
      created: async function () {
        this.state = "loading";
        await this.getBreakpoints();
        this.state = "display"
      }
    }
</script>

<style scoped>

</style>