<template>
  <div class="px-5 py-2">
    <div v-if="allowedComponents.includes('Escrow')">
      <v-row>
        <v-col md="12">
          <v-card class="elevation-4">
            <v-toolbar color="#e9edf2" elevation="2" dense>
              <v-toolbar-title>Escrow Withdrawals</v-toolbar-title>

              <v-spacer></v-spacer>
              <v-btn class="mx-1" small @click="showRejectWithdrawal = true">Reject</v-btn>
              <v-btn class="mx-1" small @click="showApproveWithdrawal = true">Approve</v-btn>
            </v-toolbar>

            <v-text-field v-model="searchPending" append-icon="mdi-magnify" label="Search Withdrawals" class="mx-4 pt-2"></v-text-field>
            <v-data-table
                v-model="selectedWithdrawals"
                :headers="pendingTableHeaders"
                :items="pendingWithdrawals"
                :search="searchPending"
                :loading="pendingState === 'loading'"
                :single-select="false"
                item-key="journal_num"
                show-select
                class="row-pointer"
                style="white-space: pre"
            >
              <template v-slot:item.full_name="{ item }">
                <v-tooltip left>
                  <template v-slot:activator="{ on }">
                    <div v-on="on">{{ item.full_name }}</div>
                  </template>
                  {{ item.address }}<br>
                  {{ item.username }}<br>
                </v-tooltip>
              </template>
              <template v-slot:item.responsible_admin="{ item }">
                <v-tooltip bottom>
                  <template v-slot:activator="{ on }">
                    <div v-on="on">{{ item.responsible_admin }}</div>
                  </template>
                  {{ item.description }}
                </v-tooltip>
              </template>
              <template v-slot:item.shipping_amount="{ item }">
                <v-tooltip bottom>
                  <template v-slot:activator="{ on }">
                    <div v-on="on">{{ item.shipping_amount }}</div>
                  </template>
                  {{ item.shipping_description }}
                </v-tooltip>
              </template>
              <template v-slot:item.flags="{ item }">
                <v-tooltip bottom>
                  <template v-slot:activator="{ on }">
                    <div v-on="on"><v-icon>mdi-flag</v-icon></div>
                  </template>
                    <v-list-item
                        v-for="flag in item.flags"
                        :key="flag"
                    ><span class="white--text">{{ flag }}</span>
                    </v-list-item>
                </v-tooltip>
              </template>
              <template v-slot:item.actions="{ item }">
                <v-icon
                    @click="loginAs(item)"
                >
                  mdi-account-arrow-right
                </v-icon>
              </template>
            </v-data-table>
          </v-card>
        </v-col>
      </v-row>

      <!--Approve Withdrawal Dialog-->

      <v-row justify="center">
        <v-dialog v-model="showApproveWithdrawal" max-width="500">
          <v-card>

            <v-row>
              <!-- Title -->
              <div id="title" class="pl-3">
                <v-card-title class="headline">Approve Withdrawal Confirmation</v-card-title>
              </div>
              <v-spacer/>
              <v-btn
                  color="primary"
                  @click="showApproveWithdrawal = false"
                  icon
                  class="mr-3"
              >
                <v-icon>mdi-close</v-icon>
              </v-btn>
            </v-row>

            <!-- Body -->
            <v-card-text>
              <v-row>
                <v-col cols="12" sm="12" class="d-flex justify-center">
                  <span class="body-1">Withdrawals Selected: {{ selectedWithdrawals.length }}</span>
                </v-col>
              </v-row>
            </v-card-text>

            <v-divider></v-divider>

            <v-card-actions>
              <v-btn
                  @click="showApproveWithdrawal = false"
                  text
              >Close
              </v-btn>

              <v-btn
                  @click="approveWithdrawals()"
                  text
              >Approve
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
      </v-row>

      <!--Reject Withdrawal Dialog-->

      <v-row justify="center">
        <v-dialog v-model="showRejectWithdrawal" max-width="600">
          <v-card>

            <v-row>
              <!-- Title -->
              <div id="title" class="pl-3">
                <v-card-title class="headline">Reject Withdrawal Confirmation</v-card-title>
              </div>
              <v-spacer/>
              <v-btn
                  color="primary"
                  @click="showRejectWithdrawal = false"
                  icon
                  class="mr-3"
              >
                <v-icon>mdi-close</v-icon>
              </v-btn>
            </v-row>

            <v-row>
              <div id="title" class="pl-3">
                <div v-for="selectedWithdrawal in uniqueHubspots" v-bind:key="selectedWithdrawal.hubspot_id">
                <v-list-item :href="'https://app.hubspot.com/contacts/3835848/contact/' + selectedWithdrawal.hubspot_id + '/?interaction=note'" target="_blank">
                  Add Hubspot note for rejecting withdrawal request(s) for {{ selectedWithdrawal.full_name }} </v-list-item>
                </div>
              </div>
            </v-row>

            <!-- Body -->
            <v-card-text>
              <v-row>
                <v-col cols="12" sm="12" class="d-flex justify-center">
                  <span class="body-1">Withdrawals Selected: {{ selectedWithdrawals.length }}</span>
                </v-col>
              </v-row>
            </v-card-text>
            <v-layout>
              <v-flex xs12 class="d-flex justify-center">
                <v-checkbox
                    label="Send 'Withdrawal Not Approved' Email(s)?"
                    color="accent"
                    v-model="sendEmails"
                    style="margin-top: 0"
                ></v-checkbox>
              </v-flex>
            </v-layout>

            <v-divider></v-divider>

            <v-card-actions>
              <v-layout align-center justify-center>
                <v-btn
                    @click="rejectWithdrawals()"
                    text
                    color="red"
                >Reject Withdrawals
                </v-btn>
              </v-layout>
            </v-card-actions>
          </v-card>
        </v-dialog>
      </v-row>

      <ErrorDialog ref="errorDialog"/>
    </div>
    <div v-else class="pl-12 pr-12 pt-12 mt-12 headline text-center">Sorry, this page is not available.</div>
  </div>
</template>

<script>
  // @ is an alias to /src
  import ErrorDialog from "@/components/ErrorDialog";

  export default {
    name: "Escrow",
    components: {
      ErrorDialog
    },
    data: () => ({

      //-------- Toggle Variables --------//

      showApproveWithdrawal: false,
      showRejectWithdrawal: false,
      sendEmails: true,


      //-------- Escrow Variables --------//


      allowedComponents: JSON.parse(localStorage.getItem('allowedComponents') || ''),
      searchPending: "",
      selectedWithdrawals: [],
      pendingState: 'loading',
      pendingTableHeaders: [
        {text:'Payee',       value: 'full_name',  align: 'start'},
        {text:'Admin',       value: 'responsible_admin',align: 'start',},
        {text:'Posted',      value: 'posted',     align: 'start'},
        {text:'Signup',      value: 'signup_date',align: 'start'},
        {text:'Amount',      value: 'amounts',    align: 'end'},
        {text:'Asset',       value: 'asset_types',align: 'start'},
        {text:'Shipping Amount',       value: 'shipping_amount',align: 'end',},
        {text:'Shipping Asset',       value: 'shipping_asset_type',align: 'start',},
        {text:'View Account',value: 'actions',    align: 'center', sortable: false},
        {text: '',      value: 'flags',align: 'start'}
        // {text:'Description', value: 'description',align: 'start'}
      ],
      pendingWithdrawals: [],
    }),

    methods: {

      approveWithdrawals: async function () {
        try {
          let journalNums = this.selectedWithdrawals.map(item => item.journal_num);

          this.showApproveWithdrawal = false;
          await this.$axios.patch(`/admin/reports/approveWithdrawals`, {journalNums: journalNums});
          await this.getWithdrawals();
          alert("Successfully approved and staged the withdrawal(s) for fulfillment in 'Pending Transactions'");

        } catch (e) {
          this.$refs.errorDialog.showError({
            errorMessage: "Failed to approved the withdrawal(s).",
            forDevelopers: e
          })
        }
      },

      rejectWithdrawals: async function () {
        try {
          let journalNums = this.selectedWithdrawals.map(item => item.journal_num);

          this.showRejectWithdrawal = false;
          await this.$axios.patch(`/admin/transactions/cancel`, {journalNums: journalNums});
          if (this.sendEmails) await this.sendRejectionEmails(journalNums);
          await this.getWithdrawals();
          alert("Successfully rejected the withdrawal(s)");

        } catch (e) {
          this.$refs.errorDialog.showError({
            errorMessage: "Failed to reject the withdrawal(s).",
            forDevelopers: e
          })
        }
      },

      sendRejectionEmails: async function (journalNums) {
            this.$axios.post(`/admin/assetManagement/withdrawal/sendRejectionEmails`, {journalNums: journalNums} );
      },

      getWithdrawals: async function () {
        this.pendingState = 'loading';
        this.selectedWithdrawals = [];
        try {
          let res = await this.$axios.get(`/admin/reports/escrow`);
          this.pendingWithdrawals = res.data;
        } catch (e) {
          this.$refs.errorDialog.showError({
            errorMessage: "Failed to get Pending Withdrawals.",
            forDevelopers: e
          });
        }
        this.pendingState = 'display';
      },

      selectTransaction: async function (item, row) {
        row.select(!row.isSelected);
      },

      loginAs: async function (member) {
        let res = await this.$axios.post('/admin/token', {
          grant_type: 'delegation',
          client_id: 'NA',
          target_username: member.username
        });
        let token = res.data;
        let memberURL = '';

        // If the local environment is set, return it.
        const localEnv = process.env.VUE_APP_DEV_MEMBER_URL;

        if (localEnv) {
          memberURL = localEnv;

        } else {

          // If the sub-domain is a valid environment include it in the url for the api.
          let environments = ['qa', 'new', 'dev'];
          let environment = window.location.host.slice(0, window.location.host.indexOf('.'));

          // console.log(environment);
          if (environments.includes(environment)) {
            memberURL = `https://${environment}.member.upma.org`;

          } else {

            // Else have it connect with the production api.
            memberURL = process.env.VUE_APP_MEMBER_URL;
          }
        }
        memberURL = memberURL + '';
        window.open(`${memberURL}?token=${token.access_token}`);
      }
    },

    computed: {
      uniqueHubspots: function () {
          var output = [];
          var keys   = [];

          this.selectedWithdrawals.forEach(function (selectedWithdrawal) {
            var key = selectedWithdrawal.hubspot_id;

            if (keys.indexOf(key) === -1) {
              keys.push(key);
              output.push(selectedWithdrawal);
            }
          });

          return output;
        }
    },

    created: async function() {
      await this.getWithdrawals();
    },
    mounted() {
      localStorage.title = 'Escrow';
    },
  }
</script>

<style scoped>
  .row-pointer >>> tbody tr :hover {
    cursor: pointer;
  }

  .v-data-table >>> td {
    font-size: .75rem !important;
    height: 40px;
  }

</style>