<template>
    <v-dialog v-model="shown"
              max-width="600"
    >
        <v-progress-linear v-if="state === 'loading'"
                           indeterminate
                           color="primary"
        />
        <v-card>
            <v-row>
                <div id="title" class="pl-4">
                    <v-card-title>Configure Rates</v-card-title>
                </div>
                <v-spacer/>
                <v-btn icon
                       class="pa-4 ml-n3 mr-6"
                       @click="cancel"
                       style="margin-top: 14px"
                >
                    <v-icon>
                        mdi-close
                    </v-icon>
                </v-btn>
            </v-row>

            <v-card-text class="pa-1">
                <div class="ma-0 mt-n2 mb-3">
                    <p class="pl-4 pr-3 font-weight-light">
                        Remember, for DCA to operate correctly, the system must have at least <span class="font-weight-bold">30 days</span> of rates at all times. Also, if you wish to remove a rate you must do so at least <span class="font-weight-bold">one month in advance.</span> If you don't the system will not let you remove it.
                    </p>
                </div>
                <div v-if="state === 'loading'" class="text-center pa-10">
                    <v-progress-circular
                            indeterminate
                            color="primary"
                    ></v-progress-circular>
                </div>
                <div class="text-center mt-10 mb-10" v-else-if="days.length < 1">
                    <h1 class="headline">
                        Click the + button to add a month of rates.
                    </h1>
                </div>
                <!-- Table of Rates -->
                <v-expand-transition v-else>
                    <v-simple-table class="compact">
                        <thead>
                        <tr>
                            <th>Date</th>
                            <th></th>
                            <th>Delete</th>
                            <v-divider/>
                        </tr>
                        </thead>
                        <tbody name="fade" is="transition-group">
                            <RateRow v-for="(day, index) in days"
                                     :day="day"
                                     :key="day"
                                     :canInsertBefore="canInsertBefore(day, index)"
                                     :canInsertAfter="canInsertAfter(day, index)"
                                     :canDelete="canDelete(day)"
                                     @insertBefore="insertDayBefore"
                                     @insertAfter="insertDayAfter"
                                     @deleteDay="deleteDay"/>
                        </tbody>
                    </v-simple-table>
                </v-expand-transition>

                <v-divider/>

                <!-- Add Rates Button -->
                <v-row class="mb-0 mt-3">
                    <v-spacer/>
                    <v-tooltip bottom>
                        <template #activator="{ on }">
                            <v-btn color="primary"
                                   dark
                                   v-on="on"
                                   x-small
                                   fab
                                   @click="addDaysBtnClicked">
                                <v-icon>
                                    mdi-plus
                                </v-icon>
                            </v-btn>
                        </template>
                        <span>Add a Month of Rates</span>
                    </v-tooltip>
                    <v-spacer/>
                </v-row>
            </v-card-text>


            <v-card-actions class="pr-5 pl-5">
                <!-- Cancel Button -->
                <v-btn color="red"
                       dark
                       @click="cancel">
                    Cancel
                </v-btn>

                <v-spacer/>

                <!-- Save Button -->
                <v-btn color="primary"
                       :disabled="newDays.length < 1 && daysToDelete.length < 1"
                       @click="save"
                >
                    Save
                </v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>

<script>
    import moment from  'moment'
    import RateRow from "./ConfigureRatesDialog/RateRow";
    const dateFormat = 'YYYY-MM-DD';
    export default {
        name: "ConfigureRatesDialog",
        components: {RateRow},
        data: () => ({
            shown: false,
            state: 'display',
            days: [], // An array of days in the format YYYY-MM-DD. Displays current and new days with rates.
            newDays: [], // An array of days in the format YYYY-MM-DD. To make saving new days easier.
            daysToDelete: [],
            tableHeaders: [
                {
                    text: 'Date',
                    value: 'day',
                    align: 'left'
                },
                {
                    text: 'Actions',
                    value: 'actions',
                    align: 'center'
                }
            ]
        }),
        methods: {
            toggleShown: function () {
                this.shown = !this.shown
            },
            getDays: async function() {
                let today = moment().format(dateFormat);
                let twoYearsInFuture = moment().add(2, 'Y').format(dateFormat);

                try {
                    this.state = 'loading';
                    let res = await this.$axios.get('/admin/rates/days', {params: {
                            startDate: today,
                            endDate: twoYearsInFuture
                        }});
                    let days = res.data.days;

                    // If the this.days doesn't contain the day push it on.
                    days.forEach((newDay) => {
                        let contains = false;

                        this.days.forEach(day => {
                            if(day === newDay) {
                                contains = true;
                            }
                        });

                        if(!contains) {
                            this.days.push(newDay);
                        }
                    });
                } catch (e) {
                    this.$emit('showErrorDialog', {
                        errorMessage: "There was a problem loading the days with rates.",
                        forDevelopers: e
                    })
                }

                this.state = 'display';
            },
            addDaysBtnClicked: function () {
                this.state = 'loading';
                // Adds 1 months of days with rates.
                let firstDay = this.days.length > 0 ? moment(this.days[this.days.length - 1]).add(1, 'd') : moment();
                let lastDay = moment(firstDay.format(dateFormat)).add(1, 'M');

                for (let curDay =  firstDay; firstDay.diff(lastDay) < 0; curDay.add(1, 'd')){

                    let day = moment(curDay).format(dateFormat);
                    // We remove it from the daysToDelete array just in case they are doing this to undo a previous deletion.
                    this.removeDay(day, this.daysToDelete);

                    // We insert to both so that later we can save the new days, but in the meantime display them to the
                    // admin.
                    this.insertDay(day, this.newDays);
                    this.insertDay(day, this.days);
                }

                this.state = 'display'
            },
            save: async function () {
                this.state = 'loading';

                try {
                    if(this.newDays.length > 0) {
                        await this.$axios.post('/admin/rates/days', this.newDays);
                        this.newDays = [];
                    }

                    if(this.daysToDelete.length > 0) {
                        await this.$axios.delete('/admin/rates/days', {
                            data: {
                                days: this.daysToDelete
                            }
                        });
                        this.daysToDelete = [];
                    }

                    this.days = [];
                    this.state = 'display';
                    await this.getDays();

                    this.$emit('changesSaved')

                } catch (e) {
                    this.$emit('showErrorDialog', {
                        errorMessage: "There was a problem saving the changes to the days for rates.",
                        forDevelopers: e
                    });
                    this.cancel();
                }
            },
            cancel: function () {
                this.days = [];
                this.newDays = [];
                this.daysToDelete  = [];
                this.toggleShown();
            },
            insertDay: function(date, array) {
                let index = false;

                // Find index of the next greatest date. We will insert one before it.
                for(let i = 0; i < array.length; i ++) {
                    let curDate = this.days[i];

                    if(curDate > date) {
                        index = i;
                        break
                    }
                }

                if(array[index] === date) {
                    return // The date already exists in the list, so return.
                }

                // Insert one before the index if the index was set, else we never found a greater rate, so insert it at
                // the end of the list.
                index ?  array.splice(index, 0, date) : array.push(date);
            },
            removeDay: function (date, array) {
                for(let i = 0; i < array.length; i ++) {
                    let curDate = array[i];

                    if(date === curDate) {
                        array.splice(i, 1);
                        return true
                    }
                }

                return false
            },
            deleteDay: function (date) {

                // Remove the day from the new days.
                let inNewDays = this.removeDay(date, this.newDays);

                // Remove the day from the days.
                let inDays = this.removeDay(date, this.days);

                // If it wasn't in the list of new days but was in the list of days, then we need to remember to
                // submit it to the server to delete.
                if(!inNewDays && inDays){
                    this.insertDay(date, this.daysToDelete)
                }
            },
            canInsertBefore: function(date, daysIndex) {
                let curDay = this.days[daysIndex];
                let dayBefore = moment(this.days[daysIndex]).add(-1, 'd').format(dateFormat);
                let elementBefore = daysIndex - 1 > 0 ? this.days[daysIndex - 1] : null;

                // If the day we are trying to insert isn't at least a month out, we can't insert it.
                if(!this.isOverAMonthOut(dayBefore)) {
                    return false;
                }

                // If the day we are trying to insert isn't at least a month out, we can't insert it, because it would
                // mess up the DCA.
                if(elementBefore === null) {
                    return true;
                }

                // If we don't already have the day in the list, then yes we can.
                if (curDay === date && elementBefore !== dayBefore) {
                    return true;
                }

                // Else we can't
                return false
            },
            canInsertAfter: function(date, daysIndex) {
                let dayAfter = moment(this.days[daysIndex]).add(1, 'd').format(dateFormat);
                let elementAfter = daysIndex + 1 > 0 ? this.days[daysIndex + 1] : null;

                // If the day we are trying to insert isn't at least a month out, we can't insert it, because it would
                // mess up the DCA.
                if(!this.isOverAMonthOut(dayAfter)) {
                    return false;
                }

                // If the element after is null and the date we want to insert is greater than today, then yes we can.
                if(elementAfter === null) {
                    return true;
                }

                // If we don't already have the day in the list, then yes we can.
                if (elementAfter !== dayAfter) {
                    return true;
                }

                // Else we can't
                return false
            },
            canDelete: function(date) {
                return this.isOverAMonthOut(date);
            },
            isOverAMonthOut: function(date) {
                // We can delete days as long as the day is at least a month out.

                date = moment(date);

                return date > moment().add(1, 'M');
            },
            insertDayBefore: function(date) {
                this.insertDay(date, this.days);
                this.insertDay(date, this.newDays);
                this.removeDay(date, this.daysToDelete);
            },
            insertDayAfter: function(date) {
                this.insertDay(date, this.days);
                this.insertDay(date, this.newDays);
                this.removeDay(date, this.daysToDelete);
            }
        },
        watch: {
            shown(newShown) {
                // If we are showing and the days haven't already been fetched.
                if(newShown && this.days.length < 1) {
                    this.getDays();
                }
            }
        },
        created() {
            this.getDays();
        }
    }
</script>

<style>
.compact.v-data-table td {
  height: 32px;
}
</style>
<style scoped>

    /*
        For the add days animation.
    */
    .fade-enter-active, .fade-leave-active {
        transition: all 1s;
    }
    .fade-enter, .fade-leave-to /* .fade-leave-active below version 2.1.8 */ {
        opacity: 0;
    }

</style>