<template>
    <div class="px-2 pt-2 mb-12">
        <div v-if="allowedComponents.includes('Adjustment')">
            <ValidationObserver ref="validationOb" v-slot="{ invalid, validated, handleSubmit, valid }">
                <v-sheet class="elevation-3">

                    <!-- Select Member -->
                    <v-row v-if="showSearchMembers" justify="center">
                        <SearchMembers class="mb-0 pb-0" @memberSelected="memberSelected" />
                    </v-row>

                    <!-- Amount Deposited & Desired Asset -->
                  <v-row justify="center" class="py-2">
                        <v-sheet elevation="1"
                                 max-width="800"
                                 color="grey lighten-4">
                            <v-row class="pt-2">
                                <v-col cols="12" sm="7" class="pa-8">
                                    <v-sheet width="350"
                                             class="mx-auto"
                                             color="grey lighten-4">
                                        <DesiredAssetPicker :clear="clear" ref="desiredAssetPicker"
                                                            @desiredAccountSelected="desiredAccountSelected"/>
                                        <ValidationProvider ref="amountValidator"
                                                            rules="required"
                                                            name="Amount"
                                                            v-slot="{ errors }">
                                            <v-text-field
                                                    label="Adjustment Amount (In Asset)"
                                                    hint="The amount you wish to adjust the account by. This is valued in the unit of the asset type selected (i.e Silver Dollar, Goldback). Be sure to include a negative sign if you wish to perform a debit."
                                                    value="0.00"
                                                    reverse dense
                                                    :error-messages="errors"
                                                    v-model="amount"/>
                                        </ValidationProvider>
                                    </v-sheet>
                                </v-col>

                                <!-- Description -->
                              <v-col cols="12" sm="5">
                                <ValidationProvider ref="descriptionValidator"
                                                    rules="required"
                                                    name="Description"
                                                    v-slot="{ errors }">
                                    <v-col class="px-8">
                                        <v-sheet max-width="200"
                                                 class="mx-auto"
                                                 color="grey lighten-4">
                                            <v-textarea
                                                style="margin-bottom: -40px"
                                                outlined dense
                                                v-model="description"
                                                :error-messages="errors"
                                                label="Adjustment Description"/>
                                        </v-sheet>
                                    </v-col>
                                </ValidationProvider>
                              </v-col>
                            </v-row>

                          <div :style="$vuetify.breakpoint.name === 'xs' ? '' : 'margin-top: -26px'"
                            class="pb-4">
                            <b class="ml-6 mr-0">Transaction Link:</b>
                            <v-btn class="mt-n1" icon @click="linkInfoDialog = true">
                              <v-icon color="grey">mdi-information-outline</v-icon>
                            </v-btn><br>
                            <v-row v-if="Object.entries(link).length !== 0" :key="refreshLink"
                                   class="mt-n1 mb-n4 mx-3 row-wrap justify-space-between caption">
                              <v-btn @click="link={}, $parent.link={}, refreshLink = !refreshLink" x-small
                                     class="py-0 my-0 mx-n2" icon><v-icon>mdi-close</v-icon></v-btn>
                              <span>{{ link.posted ? mixin_UTCToPretty(link.posted, 'dateTime' ) : ''}}</span>
                              <span>{{ link.type }}</span>
                              <span>{{ link.subtype }}</span>
                              <span>{{ link.prettyAmount }}</span>
                              <span>{{ link.counterparties }}</span>
                            </v-row>
                          </div>

                        </v-sheet>
                    </v-row>

                    <v-row justify="center">
                      <v-btn
                          v-if="allowedComponents.includes('Pending Transactions')"
                          :loading="state === 'saving settle'"
                          color="primary"
                          @click="settle = true; handleSubmit(saveBtnClicked)"
                          :disabled="invalid || !valid || state === 'saving request'"
                          height="30"
                          class="mt-n1 mb-1 mx-2"
                          width="150">
                        Settle
                      </v-btn>
                        <v-btn
                               color="primary"
                               @click="$parent.state = 'initial'"
                               height="30"
                               class="mt-n1 mb-1 mx-2"
                               text
                               width="150">
                          Close
                        </v-btn>
                    </v-row>
                </v-sheet>

              <v-dialog v-model="linkInfoDialog" width="500">
                <v-card>
                  <v-card-title class="headline grey lighten-2" primary-title>Link Transaction</v-card-title>
                  <v-divider></v-divider>
                  <div class="px-6"><br>
                    A transaction can optionally be linked to an adjustment by clicking on a row in the
                    transaction table below. Click 'X' on the left to remove the link.
                  </div>
                  <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn color="primary" flat @click="linkInfoDialog = false">Got it!</v-btn>
                  </v-card-actions>
                </v-card>
              </v-dialog>
            </ValidationObserver>
            <ErrorDialog ref="errorDialog"/>
            <SnackBar ref="snackbar"/>
            <ConfirmationDialog ref="confirmationDialog" @saveConfirmed="saveConfirmed"
              v-bind="{member, amount, link, description, accountName: accountDescription, type:'Adjustment', settle}"/>
        </div>
    <div v-else class="px-12 pt-12 mt-12 headline text-center">Sorry, this page is not available.</div>
    </div>
</template>

<script>
    import ErrorDialog from "../components/ErrorDialog";
    import SnackBar from "../components/Snackbar";
    import SearchMembers from "../components/SearchMembers";
    import DesiredAssetPicker from "../components/DesiredAssetPicker";
    import { ValidationObserver, ValidationProvider } from 'vee-validate'
    import { extend } from 'vee-validate'
    import { required } from 'vee-validate/dist/rules'
    import ConfirmationDialog from "../components/physicalDeposit/ConfirmationDialog";

    extend("required", {
        ...required,
        message: "{_field_} is required"
    });

    export default {
        name: "Adjustment",
        props: {
            showSearchMembers: {
                type: Boolean,
                default() {
                    return true;
                }
            },
            linkProp: {
            type: Object,
              default() {
                return {};
              }
            },
            memberUpdate: {
                type: Boolean,
                default() {
                    return false;
                }
            },
            memberProp: {
                type: Object,
                default() {
                    return {}
                }
            },
        },
        components: {ConfirmationDialog,
          DesiredAssetPicker,
          SearchMembers, SnackBar,
          ErrorDialog,
          ValidationObserver,
          ValidationProvider},
        data() {
            return {
                allowedComponents: JSON.parse(localStorage.getItem('allowedComponents') || ''),
                amount: "",
                clear: false,
                description: "",
                desiredAccountNum: "",
                desiredAccountName: "",
                desiredJournalNum: "",
                link: this.linkProp, // avoid mutating prop
                linkInfoDialog: false,
                member: this.memberProp,
                refreshLink: false,
                state: 'display',
                settle: false
            }
        },
        mounted() {
            localStorage.title = 'Adjustment';
            if (this.member && this.member.email) this.$refs.desiredAssetPicker.setAccounts(this.member);
        },

        watch: {
            'linkProp': function(){
              this.link = this.linkProp;
            },

            'memberUpdate': function(){
                this.resetValues();
                if (this.member && this.member.email) {
                    this.member = this.memberProp;
                    this.link = {};
                    this.$refs.desiredAssetPicker.setAccounts(this.member);
                }
            },
        },

        methods: {
            memberSelected: function (member) {
                this.resetValues();
                this.member = member;
                this.$refs.desiredAssetPicker.setAccounts(member);
            },
            desiredAccountSelected: function (account) {
                this.desiredAccountName = account.name;
                this.desiredAccountNum = account.num;
            },
            desiredTransactionSelected: function (transaction) {
              this.desiredJournalNum = transaction.journal_num;
            },
            resetValues: function () {
                this.clear = !this.clear;
                this.desiredAccountNum = "";
                this.description = "";
                this.amount = "";
                this.link = {};
                this.$refs.amountValidator.reset();
                this.$refs.descriptionValidator.reset();
            },
            saveBtnClicked: function () {
                this.$refs.confirmationDialog.toggleShown()
            },
            saveConfirmed: async function() {
                this.state = this.settle ? 'saving settle' : 'saving request';

                try {
                    await this.$axios.post('/admin/assetManagement/adjustment', {
                        amount: this.amount,
                        description: this.description,
                        metalAccountNum: this.desiredAccountNum,
                        journalNum: this.link.journal_num || '',
                        settle: this.settle
                    });

                    this.$refs.snackbar.showSnackbar({
                        text: `The adjustment has been requested`,
                        color: "success"
                    });
                    this.memberSelected(this.member);
                    this.$emit('refreshTable');
                    this.state = 'display';
                } catch (e) {
                    this.state = 'display';
                    this.$refs.errorDialog.showError({
                        errorMessage: e.response.message ? e.response.message : "There was an error creating the adjustment. Please ensure all of the fields are filled out correctly, then try again. If you continue to experience problems please notify the development team.",
                        forDevelopers: e
                    })
                }
            }
        },
        computed: {
            accountDescription: function() {
                if (!this.desiredAccountNum) {
                    return ""
                }
                return this.$refs.desiredAssetPicker.getAccountDescription(this.desiredAccountNum);
            }
        }
    }
</script>

<style scoped>

</style>