<template>
    <div>
        <v-app-bar
                app
                color="primary"
                dark
        >
            <v-app-bar-nav-icon @click.stop="navButtonClicked"/>
            <h3 v-if="$vuetify.breakpoint.name === 'xs'" style="font-size: 1.1rem !important" class="display-3 px-1">{{title}}</h3>
            <h3 v-if="$vuetify.breakpoint.name !== 'xs'" style="font-size: 2rem !important" class="display-3 px-1">{{title}}</h3>
            <v-spacer/>
            <SearchMembers small clearableAddedIcon @memberSelected="memberSelected"/>
            <v-spacer/>
        </v-app-bar>

      <ErrorDialog ref="errorDialog"/>
    </div>
</template>

<script>
    import SearchMembers from "./SearchMembers";
    import ErrorDialog from "@/components/ErrorDialog";

    export default {
        name: "AppBar",
        props: {
            titleProp: String,
            default() {
                return {};
            }
        },
        components: {ErrorDialog, SearchMembers},
        data: () => ({
            assetManagementItems: ['Contribution', 'Physical Deposit', 'Withdrawal', 'Exchange', 'Bailment', 'Transfer'],
            reportItems: ['Pending Transactions', 'Tithing', 'Credit/Debit/ACH', 'Wire/Check', 'Cards', 'Checks'],
        }),

        computed: {

            title () {
                if (this.titleProp) {
                    return this.titleProp;
                } else {
                    return '';
                }
            },
        },

        methods: {
            navButtonClicked: function () {
                this.$emit('navButtonClicked')
            },
            memberSelected: async function (member) {
                try {
                    let res = await this.$axios.post('/admin/token', {
                      grant_type: 'delegation',
                      client_id: 'testClient',
                      target_username: member.email
                  });

                  let token = res.data;

                  let memberURL = '';

                  // If the local environment is set, return it.
                  const localEnv = process.env.VUE_APP_DEV_MEMBER_URL;

                  if (localEnv) {
                      memberURL = localEnv;

                  } else {

                      // If the sub-domain is a valid environment include it in the url for the api.
                      let environments = ['qa', 'new', 'dev'];
                      let environment = window.location.host.slice(0, window.location.host.indexOf('.'));

                      if (environments.includes(environment)) {
                          memberURL = `https://${environment}.member.upma.org/`;

                      } else {

                          // Else have it connect with the production api.
                          memberURL = process.env.VUE_APP_MEMBER_URL;
                      }
                  }

                  window.open(`${memberURL}?token=${token.access_token}`);

                } catch (e) {
                  console.log(e);
                  this.$refs.errorDialog.showError({
                    errorMessage: "Error proxying into the account",
                    forDevelopers: e.response.data.message
                })
              }
            }
        }
    }
</script>

<style scoped>

</style>