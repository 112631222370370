<template>
  <div class="px-5 py-2 mb-12">
     <div v-if="allowedComponents.includes('Access')">

        <v-row class="justify-start px-4 mb-n1">
          <!--      <h1 class="display-3 pb-5">Roles</h1>-->
          <v-autocomplete
              class="ml-12 mt-1 mb-n1"
              :items="possibleAccess"
              :label="`Showing members with ${filter} access`"
              v-model="filter"
              @change="getMembers()"
          >
            <template v-slot:prepend-inner>
              <v-icon class="pr-1">mdi-filter-variant</v-icon>
            </template>
          </v-autocomplete>
          <v-spacer/>
          <v-tooltip right>
            <template #activator="data">
              <span v-on="data.on">
                  <v-btn small fab color="primary" @click="member = {}, refreshSearch = !refreshSearch, noMemberDialog = true"
                         class="px-3 mt-3 mr-6" elevation="4"><v-icon>mdi-plus</v-icon>
                  </v-btn>
                </span>
            </template>
            Search for a member and add access
          </v-tooltip>
          <v-spacer/>
        </v-row>

        <v-data-table sort-by='name'
                      multi-sort
                      :headers="tableHeaders"
                      :items="members"
                      class="elevation-4"
                      :loading="state === 'loading'"
                      :footer-props="{ 'items-per-page-options': [ 50, 100, 500 ] }">
          <template v-slot:item.actions="{ item }">
            <v-icon class="mx-4" @click="managePermissions(item)">mdi-account-lock-outline</v-icon>
          </template>
        </v-data-table>

        <v-dialog v-model="noMemberDialog" max-width="800px" max-height="400">
          <v-card class="pa-8">
            <v-card-text class="mb-n2 pb-0 text-center">Add/Remove Roles for:
            </v-card-text>
            <!-- Select Member -->
            <v-row class="justify-center mt-4 mb-n2">
              <SearchMembers :key='refreshSearch' clearable @memberSelected="memberSelected" />
            </v-row>
            <v-row class="pt-7 justify-end">
              <v-btn color="red"
                     dark class="mt-3 px-4 mx-8"
                     @click="noMemberDialog = false">
                Cancel
              </v-btn>
            </v-row>
            <br>
          </v-card>
        </v-dialog>

        <v-dialog v-model="permissionsDialog" max-width="800px" max-height="400">
          <v-card class="pa-8">
            <v-card-text class="mb-n2 pb-0 text-center">
              <span>Add/Remove Access for:</span>
            </v-card-text>
            <v-card-title class="pb-0 headline justify-center">
              {{ member.name }}
            </v-card-title>
            <v-row class="pt-7">
              <v-autocomplete
                  :label="(newPermissions.length === 0 ? '' : 'Click to add/remove access')"
                  placeholder="No access currently selected. Click to add."
                  v-model="newPermissions"
                  multiple
                  :items="possibleAccess"
              ></v-autocomplete>
              <v-btn color="red"
                     dark class="mt-3 px-4 ml-8 mr-2"
                     @click="permissionsDialog = false">
                Cancel
              </v-btn>
              <v-btn class="mt-3 px-5 mx-2" color="primary"
                     @click="savePermissions">
                Save
              </v-btn>
            </v-row>
            <br>
          </v-card>
        </v-dialog>

        <ErrorDialog ref="errorDialog"/>
        <SnackBar ref="snackbar"/>
      </div>
      <div v-else class="pl-12 pr-12 pt-12 mt-12 headline text-center">Sorry, this page is not available.</div>
  </div>
</template>

<script>
  // @ is an alias to /src
  import ErrorDialog from "@/components/ErrorDialog";
  import SnackBar from "@/components/Snackbar";
  import SearchMembers from "@/components/SearchMembers";

  export default {
    name: "RosterAccess",
    components: {
      ErrorDialog,
      SnackBar,
      SearchMembers,
    },
    data: () => ({
      state: 'loading',
      members: [],
      noMember: false,
      permissionsDialog: false,
      noMemberDialog: false,
      refreshSearch: true,
      member: {},
      filter: 'UPMA',
      scopes: [],
      allScopes: [],
      permission: '',
      newPermissions: [],
      oldPermissions: [],
      possibleAccess: [],
      allowedComponents: JSON.parse(localStorage.getItem('allowedComponents') || ''),
      tableHeaders: [
        {
          text: 'Name',
          value: 'name',
          align: 'start'
        },
        {
          text: 'Email',
          value: 'email',
          align: 'start'
        },
        {
          text: 'Phone',
          value: 'phone',
          align: 'right'
        },
        {
          text: 'Change Access',
          value: 'actions',
          sortable: false,
          align: 'center'
        }
      ]
    }),

    methods: {
      memberSelected: function (member) {
        this.member = member;
        this.managePermissions(member);
        this.permissionsDialog = true;
        this.noMemberDialog = false;
      },

      capitalize: function (value) {
        if (!value) return ''
        value = value.toString()
        return value.charAt(0).toUpperCase() + value.slice(1)
      },

      getScopeNums: async function () {
        this.possibleAccess = ['UPMA', 'LTS', 'CHSB', 'USGold', 'Quintric'];
        // try {
        //   let res = await this.$axios.get(`/admin/scopes`);
        //   this.allScopes = res.data;
        // } catch (e) {
        //   this.$refs.errorDialog.showError({
        //     errorMessage: `Fail`,
        //     forDevelopers: e
        //   })
        // }
        // //use allScopes results to set up permissions arrays and scopeNum lookup array
        // this.possiblePermissions = [];
        // for (let i = 0; i < this.allScopes.length; i += 1) {
        //   let name = this.allScopes[i].scope_name;
        //   this.possiblePermissions.push(this.capitalize(name))
        // }
      },

      scopeNum(permission) {//TODO update this when scopeNums are available
        const scopeNum
          = this.allScopes.find(x => x.scope_name === permission.toLowerCase()).scope_num;
        return scopeNum;
      },

      managePermissions: async function (member) {
        this.member = member;
        let data = {
          username: member.email
        };
        //get scopes for this user
        try {
          const res = await this.$axios.get(`/admin/users/scope`, {params: data});
          this.scopes = res.data;
        } catch (e) {
          this.$refs.errorDialog.showError({
            errorMessage: `Fail`,
            forDevelopers: e
          })
        }
        this.newPermissions = [];
        for (let i = 0; i < this.scopes.length; i += 1) {
          this.newPermissions.push(this.capitalize(this.scopes[i].scope_name));
        }
        //save current member permissions for comparison to updated, to see what needs to be created and/or deleted
        this.oldPermissions = [...this.newPermissions];
        this.permissionsDialog = true;
      },

      savePermissions: async function () {
        let data = {};
        data.username = this.member.email;
        data.actions = [];
        // check newPermissions and see if any of the newPermissions is NOT already in oldPermissions, if not then create
        for (let i = 0; i < this.newPermissions.length; i += 1) {
          if (!this.oldPermissions.includes(this.newPermissions[i])) {
            let scopeNum = this.scopeNum(this.newPermissions[i]);
            data.actions.push({action: 'create', scopeNum: scopeNum })
          }
        }
        //check oldPermissions and if any of oldPermissions is NOT in newPermissions, then delete
        for (let i = 0; i < this.oldPermissions.length; i += 1) {
          if (!this.newPermissions.includes(this.oldPermissions[i])) {
            const userScopeNum
              = this.scopes.find(x => x.scope_name === this.oldPermissions[i].toLowerCase()).user_scope_num;
            data.actions.push({action: 'delete', userScopeNum: userScopeNum })
          }
        }
        if (!data.actions.length) {
          this.$refs.errorDialog.showError({
            errorMessage: `There are no role changes to save. To save role changes,
            first select roles to add or delete from the roles dropdown, then click SAVE.`,
            forDevelopers: `Actions array is empty.  There are no creates or deletes.`
          })
          this.state = 'display'
        } else {
          if (confirm('Are you sure you want to save these changes to roles?')) {
            try {
              this.state = 'loading';
              await this.$axios.patch(`/admin/users/scope`, data);
              await this.getMembers();
              this.$refs.snackbar.showSnackbar({
                text: "Roles have been updated!",
                color: "success"
              });
              this.permissionsDialog = false;
              this.noMember = false;
              this.state = 'display';
            } catch (e) {
              this.$refs.errorDialog.showError({
                errorMessage: `Failed to update the roles for the member.`,
                forDevelopers: e
              })
              this.state = 'display'
            }
          }
        }
      },

      resetState: async function() {
        await this.getMembers();
        this.state = 'display';
      },

      getMembers: async function () {

        // let filter = this.scopeNum(this.filter);//TODO add back in
        // let data = { params: { filter: filter }}//TODO add back in

        try {
          let res = await this.$axios.get(`/admin/users?offset=${0}`);//TODO replace with new axios.get similar to below
          // let res = await this.$axios.get(`/admin/users/permission`, data);
          this.members = res.data;
        } catch (e) {
          this.$refs.errorDialog.showError({
            errorMessage: "Failed to get members.",
            forDevelopers: e
          })
        }
      },
    },

    mounted() {
      localStorage.title = 'Access';
    },
    created: async function() {
      await this.getScopeNums();
      this.resetState();
    },

  }
</script>

<style>
</style>
