<template>
    <v-navigation-drawer v-model="shown"
                         width="300"
                         app>
        <div class="d-flex align-center">

        </div>
        <v-list>
            <!-- Roster Button -->
            <v-list-item
                    text
                    to="/"
            >
                <v-list-item-icon>
                    <v-icon>
                        mdi-account-box-multiple-outline
                    </v-icon>
                </v-list-item-icon>
                <v-list-item-content>
                    Roster
                </v-list-item-content>
            </v-list-item>

          <!-- Service Request Button -->
          <v-list-item
              v-if="allowedComponents.includes('Service Requests')"
              text
              to="/service/request/new"
          >
            <v-list-item-icon>
              <v-icon>
                mdi-note-plus
              </v-icon>
            </v-list-item-icon>
            <v-list-item-content>
              Service Request
            </v-list-item-content>
          </v-list-item>

          <v-list-item
              v-if="allowedComponents.includes('BuySell')"
              text
              to="/buy/sell"
          >
            <v-list-item-icon>
              <v-icon>
                mdi-swap-horizontal
              </v-icon>
            </v-list-item-icon>
            <v-list-item-content>
              Buy/Sell
            </v-list-item-content>
          </v-list-item>

          <!-- Tasks Button -->
          <v-list-item
              v-if="allowedComponents.includes('Service Requests')"
              text
              to="/tasks"
          >
            <v-list-item-icon>
              <v-icon>
                mdi-checkbox-multiple-marked
              </v-icon>
            </v-list-item-icon>
            <v-list-item-content>
              Tasks
            </v-list-item-content>
          </v-list-item>

          <!-- Policies Button -->
          <v-list-item
              v-if="allowedComponents.includes('Policy')"
              text
              to="/policy"
          >
            <v-list-item-icon>
              <v-icon>
                mdi-file-document-refresh
              </v-icon>
            </v-list-item-icon>
            <v-list-item-content>
              Policies
            </v-list-item-content>
          </v-list-item>

          <!-- Assets Button -->
          <v-list-item
              v-if="allowedComponents.includes('Assets')"
              text
              to="/Assets"
          >
            <v-list-item-icon>
              <v-icon>
                mdi-currency-usd
              </v-icon>
            </v-list-item-icon>
            <v-list-item-content>
              Assets
            </v-list-item-content>
          </v-list-item>

            <!-- Asset Management Dropdown -->
<!--            <v-list-group v-if="assetManagementItems.length"-->
<!--                    prepend-icon="mdi-currency-usd">-->
<!--                <template v-slot:activator>-->
<!--                    <v-list-item>-->
<!--                        <v-list-item-content>-->
<!--                            <v-list-item-title>Manage Assets</v-list-item-title>-->
<!--                        </v-list-item-content>-->
<!--                    </v-list-item>-->
<!--                </template>-->
<!--                <v-list-item :key="item.text"-->
<!--                             v-for="item in assetManagementItems"-->
<!--                             :to="`${item.path}`">-->
<!--                    <v-list-item-content> {{ item.text }}</v-list-item-content>-->
<!--                </v-list-item>-->
<!--            </v-list-group>-->

            <!-- Reports Dropdown -->
            <v-list-group v-if="reportItems.length"
                          prepend-icon="mdi-file-chart-outline">
                <template v-slot:activator>
                    <v-list-item>
                        <v-list-item-content>
                            <v-list-item-title>Reports</v-list-item-title>
                        </v-list-item-content>
                    </v-list-item>
                </template>
                <v-list-item :key="item.text"
                             v-for="item in reportItems"
                             :to="`${item.path}`">
                  <v-list-item-content>{{ item.text }}</v-list-item-content>
                </v-list-item>

              <!-- Trust menu item opens new window for better print layout -->
              <v-list-item class="hoverlink" v-if="allowedComponents.includes('Trust')">
                <v-list-item-title @click="trustPopup()" color="primary" style="cursor: pointer">Trust
                  <v-icon class="mb-1">mdi-open-in-new</v-icon></v-list-item-title>
              </v-list-item>

            </v-list-group>

            <!-- Rates Link -->
            <v-list-item
                    v-if="allowedComponents.includes('Rates')"
                    text
                    to="/rates"
            >
                <v-list-item-icon>
                    <v-icon>
                        mdi-calendar-month
                    </v-icon>
                </v-list-item-icon>
                <v-list-item-content>
                    Rates
                </v-list-item-content>
            </v-list-item>


          <v-list-item
              v-if="allowedComponents.includes('Products')"
              text
              to="/products"
          >
            <v-list-item-icon>
              <v-icon>
                mdi-basket
              </v-icon>
            </v-list-item-icon>
            <v-list-item-content>
              Products
            </v-list-item-content>
          </v-list-item>

            <!-- Roles Management Button -->
            <v-list-item
                v-if="allowedComponents.includes('Roles')"
                text
                to="/roles"
            >
                <v-list-item-icon>
                    <v-icon>
                        mdi-account-group
                    </v-icon>
                </v-list-item-icon>
                <v-list-item-content>
                    Roles
                </v-list-item-content>
            </v-list-item>

<!--           IRA Accounts Management Button -->
          <v-list-item
              v-if="allowedComponents.includes('IRA Accounts')"
              text
              to="/IRAAccounts"
          >
            <v-list-item-icon>
              <v-icon>
                mdi-account-multiple-plus
              </v-icon>
            </v-list-item-icon>
            <v-list-item-content>
              IRA Accounts
            </v-list-item-content>
          </v-list-item>

          <v-list-item
              v-if="allowedComponents.includes('Franchises')"
              text
              to="/franchises"
          >
            <v-list-item-icon>
              <v-icon>
                mdi-store
              </v-icon>
            </v-list-item-icon>
            <v-list-item-content>
              Franchises
            </v-list-item-content>
          </v-list-item>

            <!-- Access Button -->
<!--            <v-list-item-->
<!--                v-if="allowedComponents.includes('Access')"-->
<!--                text-->
<!--                to="/access"-->
<!--            >-->
<!--                <v-list-item-icon>-->
<!--                    <v-icon>-->
<!--                        mdi-sitemap-->
<!--                    </v-icon>-->
<!--                </v-list-item-icon>-->
<!--                <v-list-item-content>-->
<!--                    Access-->
<!--                </v-list-item-content>-->
<!--            </v-list-item>-->

            <!-- Settings Link -->
            <v-list-item
                    v-if="allowedComponents.includes('Settings')"
                    text
                    to="/settings"
            >
                <v-list-item-icon>
                    <v-icon>
                        mdi-cog
                    </v-icon>
                </v-list-item-icon>
                <v-list-item-content>
                    Settings
                </v-list-item-content>
            </v-list-item>

            <!-- Logout Button -->
            <v-list-item
                    text
                    @click="logout"
            >
                <v-list-item-icon>
                    <v-icon color="red">
                        mdi-logout-variant
                    </v-icon>
                </v-list-item-icon>
                <v-list-item-content>
                    <span class="red--text"
                    >
                        Log Out
                    </span>
                </v-list-item-content>
            </v-list-item>
        </v-list>
    </v-navigation-drawer>
</template>

<script>
    export default {
        name: "NavDrawer",
        data: () => ({
            user: {},
            // allowedComponents: '',
            allowedComponents: JSON.parse(localStorage.getItem('allowedComponents') || ''),
            shown: false,
            oldAdminURL: '',
        }),

        created() {
            this.$eventBus.$on('allowedComponents', (allowedComponents) => {
                this.allowedComponents = allowedComponents;
            });
            this.oldAdminURL = process.env.VUE_APP_OLD_ADMIN_URL;
        },

        computed: {
            reportItems () {
                let items = [];
                if (this.allowedComponents.includes('Holdings'))
                    items.push({text: 'Holdings',path: '/holdingsReport'});
                //JDS 2024-05-01 Deprecated feature, just removed from frontend.
                // if (this.allowedComponents.includes('Batch ACH'))
                //     items.push({text: 'Batch ACH',path: '/batchReport'});
                if (this.allowedComponents.includes('Pending Transactions'))
                    items.push({text: 'Pending Transactions', path: '/pendingTransactions'});
                if (this.allowedComponents.includes('Credit/Debit/ACH'))
                    items.push({text: 'Credit/Debit/ACH', path: '/reportsCreditDebit'});
                if (this.allowedComponents.includes('Wire/Check'))
                    items.push({text: 'Wire/Check', path: '/reportsWireCheck'});
                if (this.allowedComponents.includes('Cards'))
                    items.push({text: 'Cards', path: '/reportsCards'});
                // if (this.allowedComponents.includes('Receipts'))
                //     items.push({text: 'Receipts', path: 'receipts'});
                if (this.allowedComponents.includes('Escrow'))
                    items.push({text: 'Escrow', path: '/escrow'});
                if (this.allowedComponents.includes('Lease'))
                    items.push({text: 'Lease', path: '/lease'});
                return items;
            },
            assetManagementItems () {
                let items = [];
                if (this.allowedComponents.includes('Withdrawal')) items.push({text: 'Withdrawal', path: '/withdrawal'});
                if (this.allowedComponents.includes('Physical Deposit')) items.push({text: 'Physical Deposit',path: '/physicalDeposit'});
                if (this.allowedComponents.includes('Exchange')) items.push({text: 'Exchange', path: '/exchange'});
                if (this.allowedComponents.includes('Bailment')) items.push({text: 'Bailment', path: '/bailment'});
                if (this.allowedComponents.includes('Adjustment')) items.push({text: 'Adjustment', path: '/adjustment'});
                return items;
            },
        },

        methods: {
            toggleShown: function () {
                console.log('clicked');// leave this in so navDrawer will work in more browsers
                this.shown = !this.shown;
            },
            trustPopup() {
              let baseUrl = window.location.origin;
              window.open(baseUrl +  '/trust/', '_blank',// for better printability open new window
                  'resizeable=yes, width=' + (950) + ', left=' + (500) + ', height=' + (900));
            },
            navigate: function (title) {
                this.$router.push(`/${title}`)
            },
            logout: async function () {
                this.toggleShown();
                await this.$root.handleLoggedOut();
            }
        }
    }
</script>

<style scoped>

.hoverlink.v-list-item:hover {
  background-color: whitesmoke;
}

</style>
