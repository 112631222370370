<template>
    <div class="px-2 pt-2 mb-12">
        <div v-if="allowedComponents.includes('Bailment')">
          <ValidationObserver ref="validationOb" v-slot="{ invalid, validated, handleSubmit, valid }">
            <v-sheet class="elevation-3">

                  <!-- Select Member -->
                  <v-row v-if="showSearchMembers" justify="center">
                      <SearchMembers class="mb-0 pb-0" @memberSelected="memberSelected" />
                  </v-row>

                  <!-- Amount Deposited & Desired Asset -->
              <v-row justify="center" class="py-2">
                      <v-sheet elevation="1"
                               max-width="800"
                               color="grey lighten-4">
                          <v-row class="pt-2">
                            <v-col cols="12" sm="7" class="px-8">
                                  <v-sheet width="350"
                                           class="mx-auto"
                                           color="grey lighten-4">
                                  <DesiredAssetPicker :clear="clear" ref="desiredAssetPicker" :label="'Target Currency'"
                                                      :hideBalance="true" :reverse="false" :no-paper="true"
                                                      @desiredAccountSelected="desiredAccountSelected"/>
                                  <ValidationProvider ref="amountValidator"
                                                      rules="required|greaterThanZero"
                                                      name="Amount"
                                                      v-slot="{ errors }">
                                      <v-text-field
                                              label="Bailment Value (In Paper)"
                                              hint="Paper amount received for sold assets"
                                              style="margin-top: 20px"
                                              dense
                                              :error-messages="errors"
                                              v-model="amount"/>
                                  </ValidationProvider>
                                  </v-sheet>
                              </v-col>

                            <v-col cols="12" sm="5">
                              <ValidationProvider ref="descriptionValidator"
                                                rules="required"
                                                name="Description"
                                                v-slot="{ errors }">
                              <v-col class="px-8">
                                  <v-sheet max-width="200"
                                           class="mx-auto"
                                           color="grey lighten-4">
                                      <v-textarea style="margin-bottom: -18px"
                                              outlined dense
                                              v-model="description"
                                              :error-messages="errors"
                                              label="Bailment Description"/>
                                  </v-sheet>
                              </v-col>
                            </ValidationProvider>
                            </v-col>
                          </v-row>
                      </v-sheet>
                  </v-row>

                  <v-row justify="center">
                      <v-btn
                          v-if="allowedComponents.includes('Pending Transactions')"
                          :loading="state === 'saving settle'"
                          color="primary"
                          @click="save(true)"
                          :disabled="invalid || !valid || !amount || !member || state === 'saving request'"
                          height="30"
                          class="mt-n1 mb-1 mx-2"
                          width="150">
                        Settle
                      </v-btn>
                      <v-btn
                            color="primary"
                            @click="$parent.state = 'initial'"
                            height="30"
                            class="mt-n1 mb-1 mx-2"
                            text
                            width="150">
                        Close
                      </v-btn>
                  </v-row>
              </v-sheet>
          </ValidationObserver>
          <ErrorDialog ref="errorDialog"/>
          <SnackBar ref="snackbar"/>
         </div>
         <div v-else class="pl-12 pr-12 pt-12 mt-12 headline text-center">Sorry, this page is not available.</div>
    </div>
</template>

<script>
    import SearchMembers from "../components/SearchMembers";
    import ErrorDialog from "../components/ErrorDialog";
    import SnackBar from "../components/Snackbar";
    import { ValidationObserver, ValidationProvider } from 'vee-validate'
    import { extend } from 'vee-validate'
    import { required } from 'vee-validate/dist/rules'
    import DesiredAssetPicker from "../components/DesiredAssetPicker";

    extend("required", {
      ...required,
      message: "{_field_} is required"
    });

    extend('greaterThanZero', {
      validate: value => {
        return value > 0
      },
      message: "{_field_} must be greater than 0"
    });

    export default {
        name: "Bailment",
        props: {
            showSearchMembers: {
                type: Boolean,
                default() {
                    return true;
                }
            },
            memberUpdate: {
                type: Boolean,
                default() {
                    return false;
                }
            },
            memberProp: {
                type: Object,
                default() {
                    return {}
                }
            },
        },
        components: {DesiredAssetPicker, SnackBar, ErrorDialog, SearchMembers, ValidationObserver,
          ValidationProvider},
        data() {
            return {
                accounts: {},
                allowedComponents: JSON.parse(localStorage.getItem('allowedComponents') || ''),
                amount: "",
                clear: false,
                desiredAccount: "",
                description: "",
                member: this.memberProp,
                state: 'display',
            }
        },

        watch: {
          'memberUpdate': function () {
            if (this.member && this.member.email) {
              this.member = this.memberProp;
              this.$refs.desiredAssetPicker.setAccounts(this.member);
            }
          },
        },

        mounted() {
            this.resetData();
            localStorage.title = 'Bailment';
            if (this.showSearchMembers) this.member = {};
            if (this.member && this.member.email) this.$refs.desiredAssetPicker.setAccounts(this.member);
        },
        methods: {
            memberSelected: function (member) {
                this.resetData();
                this.member = member;
                this.$refs.desiredAssetPicker.setAccounts(this.member);
            },
            desiredAccountSelected: function(newAccount) {
                this.desiredAccount = newAccount
            },
            resetData: function () {
                this.clear = !this.clear;
                this.desiredAccount = "";
                this.amount = "";
                this.description = "";
                this.$refs.amountValidator.reset();
                this.$refs.descriptionValidator.reset();
            },
            save: async function (settle) {
                this.state = settle ? 'saving settle' : 'saving request';
                // Find desired account num using desiredAccount
                let desiredAccountNum = this.desiredAccount.num;

                // Validate input params.
                if (!desiredAccountNum) {
                    this.$refs.errorDialog.showError({
                        errorMessage: "Please first select a member and account for which you want to perform the deposit for."
                    });
                    this.state = 'display';
                    return;
                }

                if (!this.amount > 0) {
                    this.$refs.errorDialog.showError({
                        errorMessage: "Please enter an amount greater than $0.00"
                    });
                    this.state = 'display'
                    return;
                }

                try {
                    let res = await this.$axios.post('/admin/assetManagement/bailment', {
                        desiredAccountNum: desiredAccountNum,
                        amount: this.amount,
                        description: this.description
                    });
                    if (settle) {
                      await this.$axios.patch(`/admin/reports/clear`, {journalNums: [res.data.journal_num]});
                    }
                    this.$refs.snackbar.showSnackbar({
                        text: `Successfully created the Bailment`,
                        color: 'success'
                    });
                    this.memberSelected(this.member);
                    this.$emit('refreshTable');
                } catch (e) {
                    this.$refs.errorDialog.showError({
                        errorMessage: 'An error occurred while trying to create the Bailment. Please ensure the form is filled out completely and correctly. If the problem persists please notify the development team with a detailed description of the error.',
                        forDevelopers: e
                    })
                }

                this.state = 'display'
            }
        },
        computed: {
        }
    }
</script>

<style scoped>

</style>