<template>
    <v-sheet max-width="1000">
        <v-progress-linear v-if="state === 'loading'"
                           indeterminate
                           color="primary"
        />
        <v-card class="pa-10">
            <v-row justify="center">
                <v-sheet>
                    <h1 class="ml-3 mt-3 display-5">Features</h1>
                    <v-card class="ml-2 pa-2 "
                            outlined
                            max-width="500">
                        <p class="caption">
                            Features can be turned on and off by ADMIN role users.
                        </p>
                    </v-card>
                    <v-card-text>
                        <v-row>
                            <v-sheet max-width="800">
                                <v-row :key="feature.uuid" v-for="feature in features">
                                  <v-col>
                                    <v-tooltip bottom>
                                      <template #activator="{ on }">
                                        <v-switch
                                            v-model="feature.enabled"
                                            :label="feature.title"
                                            v-on="on"
                                            @change="updateFeature(feature.uuid, feature.enabled)"
                                        ></v-switch>
                                      </template>
                                      <span>{{ feature.description }}</span>
                                    </v-tooltip>
                                  </v-col>

                                </v-row>
                            </v-sheet>
                        </v-row>
                    </v-card-text>
                </v-sheet>
            </v-row>
        </v-card>
        <ErrorDialog ref="errorDialog"/>
        <SnackBar ref="snackbar"/>
    </v-sheet>
</template>

<script>
    import ErrorDialog from "../ErrorDialog";
    import SnackBar from "../Snackbar";
    export default {
        name: "Features",
        components: {SnackBar, ErrorDialog},
        data: () => ({
            state: 'display',
            features: []
        }),
        methods: {
            getFeatures: async function () {
              try {
                  let res = await this.$axios.get('/admin/features');
                  let features = res.data;
                  if (!features) {
                      this.features = []
                  }

                  this.features = features;
              } catch(e) {
                  this.$refs.errorDialog.showError({
                      errorMessage: 'There was an error fetching the features.',
                      forDevelopers: e
                  })
              }
            },
            updateFeature: async function(uuid, enabled) {
              await this.$axios.patch('/admin/features', {
                'uuid': uuid,
                'enabled' : enabled
              }).then(({data}) => {
                this.features.find(x => x.uuid === data.uuid).enabled = data.enabled;
              })

            },
        },
        created: async function () {
            this.state = "loading";
            await this.getFeatures();
            this.state = "display"
        }
    }
</script>

<style scoped>

</style>